<template>
  <div id="app" class="ma-lg-10 ml-lg-10 ma-md-10 ml-md-10">
    <!-- <div class="py-md-7  d-block d-md-none "></div> -->
    <div class="scroll-container px-4 ">
      <div class="px-md-3">
        <v-list-item class="pl-0 pl-md-1 ">
            <v-list-item-content>
                <v-list-item-title class="text-start"
                    style="color: #000000;font-size: 20px;font-weight:559;line-height:24px;letter-spacing:-1px">
                    Annual Income</v-list-item-title>
                <v-list-item-subtitle class="text-start pt-1"
                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Check income details and revise annual income.
                </v-list-item-subtitle>
            </v-list-item-content>
       </v-list-item>


        <!-- <p class="font-weight-bold fs-22 mb-0">Annual Income</p>
        <p class="subtitle-2 txt-666">
          View Income details and update Annual Income.
          Check income details and revise annual income.



        </p> -->
      </div>
      <v-divider></v-divider>
      <v-card class="py-md-6 px-md-3 mb-6 mt-6" elevation="0" :outlined="$vuetify.breakpoint.smAndDown ? false : true"
        width="100%">
        <!-- {{accountstatus}} {{accountstatus != 'client_close'}} -->
        <v-row no-glutters>
          <v-col cols="12" sm="6">
            <v-text-field oncopy="alert('Copying forbidden!');return false" readonly :disabled="yearlyIncomeDisable"
              class="mb-3" :loading="profileLoader" append-icon="" v-model="profileData.ANNUAL_INCOME" hide-details
              label="YEARLY INCOME RANGE">
              <template #append>
                <div v-if="accountstatus != 'client_close'">
                <v-btn v-if="!yearlyIncomeDisable" @click="mobileEdit('income')" class=" rounded" style="background-color:#000" elevation="0"  text small>
                  <!-- <img src="@/assets/pen.svg" alt="pen"> --> <span  style="color:#fff;font-size:10px">Declare</span> 
                </v-btn></div>
                <div v-if="accountstatus == 'client_close'">
                  <v-tooltip color="black" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn disabled class="rounded"  elevation="0" small>
                          Declare
                        </v-btn>
                      </span>
                    </template>
                    <span>Your trading account is closed</span>
                  </v-tooltip>

                </div>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field id="mobileedit" oncopy="alert('Copying forbidden!');return false" class="mb-3" readonly
              :loading="profileLoader" v-model="profileData.GrossAnnualIncomeDate" append-icon="" hide-details
              label="LAST UPDATED DATE"></v-text-field>
          </v-col>
        </v-row>

        <v-card rounded="lg" v-if="mobStatus.income_status == 'e-signed completed'" color="#FCEFD4" elevation="0"
          class="mt-4">
          <div class="px-2 py-1">
            <p style="
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
              Your Income Change request for is in process
              <!-- <span style="color: #0037B7;cursor: pointer;"> Click here esign</span> -->
            </p>
          </div>
        </v-card>
      </v-card>
    </div>
    <v-overlay :value="blurLoader" class="rounded-0" style="z-index:2000">
      <div class="text-center">
        <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
      </div>
    </v-overlay>
    <v-snackbar class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" transition="slide-x-reverse-transition"
      v-model="snackbar" :timeout="4000" :value="true" color="warning" text-color="white">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      Session <strong>{{ txt }}</strong> Kindly Login again.
      <v-icon @click="snackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <v-snackbar style="z-index: 9999" class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white"
      transition="slide-x-reverse-transition" v-model="errorSnackbar" :timeout="4000" :value="true" color="error">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      {{ errtext }}
      <v-icon @click="errorSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <v-snackbar class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white"
      transition="slide-x-reverse-transition" v-model="successSnackbar" :timeout="4000" :value="true" color="#43A833">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      {{ Succtext }}
      <v-icon @click="successSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <v-dialog v-model="editdialog"
      :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : 'scale-transition'" persistent
      :fullscreen="$vuetify.breakpoint.xsOnly" :max-width="addNewSeg ? '500' : '420'" min-width="390">
      <v-card class="pb-4 rounded-lg px-md-4">
        <v-snackbar style="z-index: 9999" class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white"
          transition="slide-x-reverse-transition" v-model="errorSnackbar" :timeout="4000" :value="true" color="error">
          <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
          {{ errtext }}
          <v-icon @click="errorSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
        </v-snackbar>
        <div v-if="annualIncomechange">
          <v-card-title style="font-size:16px">
            Income change request <v-spacer></v-spacer> <v-btn height="40px" icon @click="closeBtn">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-form ref="formbanka">


            <v-card-text class="py-0">
              <span class="subheading" style="font-weight: 499">Select Income per annum</span>

              <v-chip-group v-model="selectionas" :change="incomeproof()" active-class="black text--accent-4" mandatory
                column>

                <v-chip :outlined="selectionas != 0" :color="selectionas == 0 ? '#000' : '#666666'"
                  :text-color="selectionas == 0 ? '#fff' : '#666666'">Below 1L</v-chip>
                <v-chip :outlined="selectionas != 1" :color="selectionas == 1 ? '#000' : '#666666'"
                  :text-color="selectionas == 1 ? '#fff' : '#666666'">1L to 5L</v-chip>
                <v-chip :outlined="selectionas != 2" :color="selectionas == 2 ? '#000' : '#666666'"
                  :text-color="selectionas == 2 ? '#fff' : '#666666'">5L to 10L</v-chip>
                <v-chip :outlined="selectionas != 3" :color="selectionas == 3 ? '#000' : '#666666'"
                  :text-color="selectionas == 3 ? '#fff' : '#666666'">10L to 25L</v-chip>
                <v-chip :outlined="selectionas != 4" :color="selectionas == 4 ? '#000' : '#666666'"
                  :text-color="selectionas == 4 ? '#fff' : '#666666'">Above 25L</v-chip>

              </v-chip-group>
 <!-- {{selectionas == 0}} -->
              <p v-if="(selectionas == 0) && (curren.length == 0 || derivat.length == 0  || commoditi.length == 0 ) " style="color:red">The derivatives segment has been activated for your account. However, you cannot declare an annual income below one lakh for this segment.</p>


              <!-- <p class="caption">I hereby declare that details furnished above are true and correct to the best of my knowledge. </p> -->
              <v-form v-if="proofField" ref="incomeupdate">
                <v-col class=" incomeProf px-0 py-4" cols="12">

                  <!-- <h3 class="pb-2 ">Income proof document</h3> -->
                  <p class="mb-1 pa-0" style="font-weight: 499">Document type <span style="color:red">*</span></p>

                  <!-- <v-select :items="typesOfimg" :rules="nomineeField" class="foutninput mb-3 pa-0" placeholder="Select Document type"
                    v-model="typeProof" hide-details></v-select> -->
                  <v-card @click="BankProofselect = 'bs'" style="border: 1px solid #DDD !important;" color="transparent"
                    class="pa-4 elevation-0 rounded-lg  mb-4">
                    <v-row no-glutters>
                      <v-col cols="9" sm="10">
                        <p class="font-weight-medium fs-18 mb-0">Bank Statement <v-chip color="#66BB6A" text-color="#fff"
                            class="rounded-md px-1 ml-2" x-small label>Recommended</v-chip></p>
                        <!-- <p class="txt-666 subtitle-2 font-weight-regular mb-0" style="line-height: 16px;">Of
                                        last 3 months ({{ trem }} to {{ currm }})</p> -->
                      </v-col>
                      <v-col cols="3" sm="2">
                        <v-radio-group class="radio-b " hide-details v-model="BankProofselect" column>
                          <v-radio @click="BankProofselect = 'bs'" label="" color="#000000" value="bs"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-card @click="BankProofselect = 'pc'" style="border: 1px solid #DDD !important;" color="transparent"
                    class="pa-4 elevation-0 rounded-lg  mb-4">
                    <v-row no-glutters>
                      <v-col cols="9" sm="10">
                        <p class="font-weight-medium fs-18 mb-0">Latest ITR Copy
                        </p>
                        <!-- <p class="txt-666 subtitle-2 font-weight-regular mb-0" style="line-height: 16px;">Of
                                        last 6 months ({{ sixm }} to {{ currm }})</p> -->
                      </v-col>
                      <v-col cols="3" sm="2">
                        <v-radio-group class="radio-b" hide-details v-model="BankProofselect" column>
                          <v-radio @click="BankProofselect = 'pc'" label="" color="#000000" value="pc"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card>


                  <v-card @click="BankProofselect = 'bp'" style="border: 1px solid #DDD !important;" color="transparent"
                    class="pa-4 elevation-0 rounded-lg  mb-4">
                    <v-row no-glutters>
                      <v-col cols="9" sm="10">
                        <p class="font-weight-medium fs-18 mb-0">Latest 6 months salary slip</p>
                        <!-- <p class="txt-666 subtitle-2 font-weight-regular mb-0" style="line-height: 16px;">Of the
                                        most recent tax year (FY{{ curry - 1 }}-{{ curry }})</p> -->
                      </v-col>
                      <v-col cols="3" sm="2">
                        <v-radio-group class="radio-b" hide-details v-model="BankProofselect" column>
                          <v-radio @click="BankProofselect = 'bp'" label="" color="#000000" value="bp"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>

                  </v-card>

                  <v-card @click="BankProofselect = 'dp'" style="border: 1px solid #DDD !important;" color="transparent"
                    class="pa-4 elevation-0 rounded-lg mb-4">
                    <v-row no-glutters>
                      <v-col cols="9" sm="10">
                        <p class="font-weight-medium fs-18 mb-0">DP holding statement as on date</p>
                        <!-- <p class="txt-666 subtitle-2 font-weight-regular mb-0" style="line-height: 16px;">Of the
                                        most recent tax year (FY{{ curry - 1 }}-{{ curry }})</p> -->
                      </v-col>
                      <v-col cols="3" sm="2">
                        <v-radio-group class="radio-b" hide-details v-model="BankProofselect" column>
                          <v-radio @click="BankProofselect = 'dp'" label="" color="#000000" value="dp"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-card @click="BankProofselect = 'nc'" style="border: 1px solid #DDD !important;" color="transparent"
                    class="pa-4 elevation-0 rounded-lg mb-4">
                    <v-row no-glutters>
                      <v-col cols="9" sm="10">
                        <p class="font-weight-medium fs-18 mb-0">Net worth Certificate</p>
                        <!-- <p class="txt-666 subtitle-2 font-weight-regular mb-0" style="line-height: 16px;">Of the
                                        most recent tax year (FY{{ curry - 1 }}-{{ curry }})</p> -->
                      </v-col>
                      <v-col cols="3" sm="2">
                        <v-radio-group class="radio-b" hide-details v-model="BankProofselect" column>
                          <v-radio @click="BankProofselect = 'nc'" label="" color="#000000" value="nc"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card @click="BankProofselect = 'sif'" style="border: 1px solid #DDD !important;" color="transparent"
                    class="pa-4 elevation-0 rounded-lg mb-4">
                    <v-row no-glutters>
                      <v-col cols="9" sm="10">
                        <p class="font-weight-medium fs-18 mb-0">Copy of Form 16 in case of salary income</p>
                        <!-- <p class="txt-666 subtitle-2 font-weight-regular mb-0" style="line-height: 16px;">Of the
                                        most recent tax year (FY{{ curry - 1 }}-{{ curry }})</p> -->
                      </v-col>
                      <v-col cols="3" sm="2">
                        <v-radio-group class="radio-b" hide-details v-model="BankProofselect" column>
                          <v-radio @click="BankProofselect = 'sif'" label="" color="#000000" value="sif"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card>
                  <p class="ma-0 pa-0" v-if="proofField" style="font-weight: 499">Upload proof <span
                      style="color:red">*</span></p>

                  <!-- <v-file-input v-if="proofField" label="Upload proof" class="foutninput pa-0" v-model="imgType"
                        accept="application/pdf" hide-details @change="passHide()" :rules="nomineeField"
                        prepend-icon="" prepend-inner-icon="mdi-paperclip"></v-file-input> -->

                  <v-card color="rgba(246, 247, 247, 0.5)" @ondragover="onChange"
                    :class="dragging ? 'dropAreaDragging' : ''" @dragenter="dragging = true" @dragend="dragging = false"
                    @dragleave="dragging = false" class="
                                  fileupload
                                  elevation-0
                                  rounded-lg
                                  text-center
                                  pa-2
                                " style="border: 1px dashed #d0d3d6">
                    <div class="innerfilup">
                      <!-- <img class="mb-2" src="@/assets/file drop.svg" width="48px" /> -->
                      <p class="font-weight-bold fs-18 mb-1" style="color: #0037B7  !important;">Click here to
                        upload</p>
                      <p class="mb-0 txt-666  font-weight-regular" style="line-height: 18px;">
                        You can drop your Bank proof here to be uploaded
                      </p>
                    </div>
                    <input type="file" id="items" accept=".pdf" name="items[]" required multiple @change="onChange" />

                    <!-- </div> -->
                  </v-card>
                  <div v-if="panFile">
                    <p><span class="txt-666" style="font-weight: 499">File names</span> : <b>{{ panimagedata.name }}</b>
                    </p>
                    <!-- <div class="text-right mb-2"> -->
                    <!-- <v-card width="100%" class="elevation-0 mb-16 mb-md-0" style="transform: scaleX(-1) !important">
                                <iframe style="border: none; border-radius: 8px;" :src="panimage" width="100%"
                                    height="240"></iframe>
                            </v-card> -->
                    <!-- </div> -->

                  </div>
                  <div>
                    <p class="ma-0 pa-0" style="font-weight: 499" v-if="passwordField">Password <span
                        style="color:red">*</span></p>
                    <v-text-field hide-details v-if="passwordField" :rules="nomineeField" v-model="pdfPassword"
                      :append-icon="passwordeye ? 'mdi-eye' : 'mdi-eye-off'" :type="passwordeye ? 'text' : 'password'"
                      placeholder="PDF password" class="input-group--focused foutninput ma-0"
                      @click:append="passwordeye = !passwordeye"></v-text-field>
                    <p class="incorrect" v-if="incorrectPass" :class="passwordField == true ? 'py-0' : ''">Incorrect
                      Password</p>
                  </div>
                </v-col>
              </v-form>
            </v-card-text>
            <!-- <p>{{ ifscBankInfo.BANK }}</p> -->
            <v-card-actions class="pt-0">
              <!-- <v-btn elevation="0"
            color="black white--text px-8 "
            rounded
              @click="editdialog = false"
            >
              Close
            </v-btn> -->
              <v-btn height="40px" :disabled="(selectionas == 0) && (curren.length == 0 || derivat.length == 0  || commoditi.length == 0 )" elevation="0" v-if="paswordverification" color="black white--text" rounded block
                :loading="bankLoader" @click="paswordverificationBtn">
                <span class="text-none px-8">Submit</span>
              </v-btn>
            </v-card-actions>
          </v-form>
        </div>
        <div v-if="annualIncomeverifyotp">
          <v-form ref="annualotpformm">
            <v-card-title style="font-size:16px">
              Verify Your OTP
              <v-spacer></v-spacer> <v-btn icon @click="closeBtn">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p class="ma-0 pa-0">Mobile number </p>

              <v-text-field class="pa-0" readonly hide-spin-buttons placeholder="Mobile number"
                v-model="profileData.MOBILE_NO"></v-text-field>
              <p class="ma-0 pa-0">Enter 4 digit OTP sent to mobile no. <span style="color:red">*</span>
              </p>

              <v-text-field class="pa-0" hide-spin-buttons required type="number" pattern="\d*" maxlength="4"
                :rules="otpRules" placeholder="00-00" v-model="annualuserOtp"></v-text-field>
              <!-- <p class="ma-0 pa-0">OTP</p>
                                <v-otp-input
    length="4" plain style="height:40px"
  ></v-otp-input> -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn elevation="0"
            color="black white--text px-8 "
            rounded
              @click="editdialog = false"
            >
              Close
            </v-btn> -->
              <v-btn height="40px" elevation="0" color="black white--text " rounded block :loading="mobileLoader"
                @click="annualotpVerifyClicked">
                <span class="text-none px-8">Submit</span>
              </v-btn>
            </v-card-actions>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>


<script src="https://app.digio.in/sdk/v10/digio.js"></script>

<script>
const script = document.createElement("script");
script.src = "https://app.digio.in/sdk/v10/digio.js";
document.body.appendChild(script);
import api from "@/Api.js";

import axios from "axios";
export default {
  // name: "BarChart",
  components: {},
  data() {
    return {
      xsOnly: null,
      smAndDown: null,
      txt: "",
      accountNum: "",
      upiDisabled: false,
      verifyBtn: true,
      upiLoader: false,
      errtext: "",
      errorSnackbar: false,
      snackbar: false,
      checkClientVPA: [],
      verfy: true,
      // items: [],
      isHidden: true,
      client_code: null,
      name: null,
      email: null,
      mobile: null,
      acc_no: null,
      ifsc: null,
      loading: false,
      selection: 0,
      url_data: null,
      oid: null,
      amount: null,
      valid: true,
      vpa: null,
      attrs: null,
      company: null,
      company_code: [],
      amountRules: [
        (v) => !!v || "Enter Amount",
        (v) => (v && v >= 10) || "Should be above 10",
      ],
      upiRules: [
        (v) => !!v || "Enter UPI ID",
        (v) => /.+@.+/.test(v) || "Invalid UPI ID",
      ],
      submit_this_val: null,
      bankDetails: [],
      // snackbar: false,
      clietData: "",
      togg: false,
      selectionAtom: 0,
      profileData: [],
      colatral: "",
      message: "Hai da Goopal",
      secretKey: "asdfghjklzxcvbnm",
      dataToEncrypt: "Sensitive information you want to encrypt.",
      token: "",
      publicIP: "",
      statusChk: [],
      Succtext: "",
      successSnackbar: false,
      profileLoader: true,
      BankData: [],
      submitBtndisable: true,
      equty: [],
      commoditi: [],
      derivat: [],
      editdialog: false,

      //asdkjbnasdl
      chha: false,
      nifity: true,
      widthdat: [50, 50, 50, 50, 80],
      chip1: true,
      chip2: true,
      chip3: true,
      chip4: true,
      linkUrl: "#",
      zebu: "https://zebuetrade.com/",
      toggleMenu: true,
      drawer: false,
      selectedItem: 0,
      tab: 0,
      mobileNumber: "",
      cardTitle: "",
      dependency: ["Self", "Spouse", "child Dependent", "parents Dependent"],
      dependencyValue: "Self",
      number: true,
      otpvalid: "",
      otpValue: "",
      dependentName: "",
      headerProps: {
        class: "custom-header",
      },
      subTitle: "",
      mobileVerification: false,
      emailVerification: false,
      emailid: "",
      emailvalid: true,
      cardLoader: false,
      addressVerification: false,
      newNomineepercent: "",
      newNomineeAddress: "",
      newNomineeDob: "",
      newNomineeRelation: "",
      newNomineeName: "",
      nomineePop: false,
      personalDetail: false,
      activePicker: null,
      date: null,
      datee: null,
      datento: null,
      dateento: null,
      datenthre: null,
      dateenthre: null,

      menuu: false,
      menunto: false,
      menuunto: false,

      menunthre: false,
      menuunthre: false,
      menu: false,
      newNomineeProof: "",
      relationNominee: [
        "Mother",
        "Father",
        "Brother",
        "Sister",
        "Daughter",
        "Wife",
        "Husband",
        "Son",
        "Grand-Son",
        "Grand-Father",
        "Grand-Daughter",
        "Grand-Mother",
      ],
      newNomineeCity: "",
      newNomineePincode: "",
      newNomineeState: "",
      newNomineeCountry: "",
      pinLoader: false,
      nomineeForm: false,
      yesorno: "no",
      nomineeYes: false,
      nomineeMobile: "",
      nomineeEmail: "",
      checkbox: true,
      newNomineePan: "",

      nomineeMobilento: "",
      nomineeEmailnto: "",
      checkboxnto: true,
      newNomineePannto: "",

      nomineeMobilenthre: "",
      nomineeEmailnthre: "",
      checkboxnthre: true,
      newNomineePannthre: "",
      newNomineeNamenthre: "",
      newNomineeRelationnthre: "",

      optionOfupdate: "",
      radioBtn: false,
      nomineesubmitBtn: false,
      submissionText: "",
      filedegio: "",
      esignBtn: false,
      newNomineeGuardian: "",
      newNomineeGuardiannto: "",
      newNomineeGuardiannthre: "",

      belowAge: false,
      belowAgento: false,
      belowAgenthre: false,

      newNomineeGuardianRelation: "",
      newNomineeGuardianRelationnto: "",
      newNomineeGuardianRelationnthre: "",
      newNomineegurdianmobile: "",
      newNomineegurdianmobilento: "",
      newNomineegurdianmobilenthre: "",

      checkboxTow: false,
      checkboxThree: false,

      newNomineeNameto: "",
      newNomineeRelationto: "",

      newNomineepercentone: 100,
      newNomineepercentnto: 1,
      newNomineepercentnthre: 1,
      max: 100,
      secnompercentmax: 0,
      thernompercentmax: 0,
      hashpan: "",

      newNomineeGuardianEmail: "",
      newNomineeGuardianAddress: "",
      newNomineeGuardianPincode: "",
      newNomineeGuardianState: "",
      newNomineeGuardianCity: "",
      newNomineeGuardianCountry: "",

      newNomineeGuardianEmailnto: "",
      newNomineeGuardianAddressnto: "",
      newNomineeGuardianPincodento: "",
      newNomineeGuardianStatento: "",
      newNomineeGuardianCitynto: "",
      newNomineeGuardianCountrynto: "",

      newNomineeGuardianEmailnthre: "",
      newNomineeGuardianAddressnthre: "",
      newNomineeGuardianPincodenthre: "",
      newNomineeGuardianStatenthre: "",
      newNomineeGuardianCitynthre: "",
      newNomineeGuardianCountrynthre: "",

      nominee2: false,
      newNomineeName2: "",
      newNomineeRelation2: "",
      newNomineeDob2: "",
      newNomineepercent2: "",

      nominee3: false,
      newNomineeName3: "",
      newNomineeRelation3: "",
      newNomineeDob3: "",
      newNomineepercent3: "",

      usernewmobile: "",
      newmobilerules: [
        (v) => !v || v.length <= 10 || "Enter a valid mobile number",
        (v) => !!v || "Mobile is required",
      ],
      requestotp: true,
      verifyotp: false,
      userOtp: "",
      editType: "",
      mobStatus: [],
      mobileDisable: false,
      usernewemail: "",
      requestotpemail: false,
      useremailOtp: "",
      verifyotpemail: false,
      usernewAdress: "",
      adresschange: false,
      proofTypes: "",
      prooftype: ["Aadhar card", "Passport", "VoterID", "Driving licence"],
      emailDisable: false,

      adressproffdoc: null,
      manual: false,
      addressDisable: false,
      blurLoader: false,
      emailLoader: false,
      addressLoader: false,
      mobileLoader: false,
      userEmailadress: "",
      stateAddress: "",
      countryAddress: "",
      distAddress: "",
      pincodeAddress: "",

      // BANK INIT
      bankproffdoc: null,
      bankAccountnumber: "",
      bankIfscCode: "",
      bankchange: false,

      ifscBankInfo: [],
      // setDefault:'',
      existingBanks: [],
      defaultCheckbox: false,
      confirmation: false,
      deletebank: [],
      bankLoader: false,
      annualIncomechange: false,
      selectionas: null,
      proofField: false,
      incorrectPass: false,
      passwordField: false,
      typesOfimg: [
        "Latest 6 months Bank Statement",
        "Latest ITR Copy",
        "Latest 6 months salary slip",
        "DP holding statement as on date",
        "Net worth Certificate",
        "Copy of Form 16 in case of salary income",
      ],
      imgType: null,
      typeProof: "",
      pdfPassword: "",
      passwordeye: false,
      paswordverification: true,
      annualuserOtp: "",
      annualIncomeverifyotp: false,
      items: ["Below 1L", "1L to 5L", "5L to 10L", "10L to 25L", "Above 25L"],
      itemSelected: "",
      yearlyIncomeDisable: false,
      segments: false,
      nsecash: false,
      bsecash: false,
      mfbse: false,
      bsefno: false,
      nsefno: false,
      cdnse: false,
      cdbse: false,
      // cdbse:false,
      bsecom: false,
      nsecom: false,
      icex: false,
      mcx: false,
      segOpt: false,
      addNewSeg: false,
      activateSeg: false,
      tok: "",

      nsecashDisable: false,
      bsecashDisable: false,
      mfDisable: false,

      bsefnoDisable: false,
      nsefnoDisable: false,
      cdnseDisable: false,
      cdbseDisable: false,

      bsecomDisable: false,
      nsecomDisable: false,
      icexDisable: false,
      mcxDisable: false,

      activateSegBtn: false,
      addsegtype: "",
      newSegements: [],
      segmentbankproffdoc: null,
      inactiveSegments: [],
      existingSegments: [],
      allSegments: [
        "BSE_CASH",
        "MF_BSE",
        "NSE_CASH",
        "BSE_FNO",
        "NSE_FNO",
        "CD_NSE",
        "CD_BSE",
        "BSE_COM",
        "NSE_COM",
        "ICEX",
        "MCX",
      ],
      newSegments: [],
      newSegmentSelected: [],
      bankproffdocument: false,
      addsegtypeNew: "",
      mfnse: "",
      nseslbm: "",
      deactivateConfirmation: false,
      aadharstate: "",
      aadharcode: "",
      banklogo: "https://rekycbe.mynt.in/autho",
      reasons: [
        "High brokerage and charges",
        "Monthly maintenance charges",
        "Need better margins",
        "Problem with payment and withdrawal",
        "Need better order placement options",
        "App crashes",
        "Issues with back office portals",
        "Problem with customer service",
        "App is complicated to understand",
        "Faced losses",
        "No time to focus on trading",
      ],
      closureReason: "",
      deactivateForm: true,
      deactivateCanceled: false,
      itemsaaass: [
        { text: "My Account" },
        {
          text: "Profile Details",
          icon: "",
          link: "/accountpage",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Bank Accounts Linked",
          icon: "",
          link: "/bankaccount",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Set Auto Pay",
          icon: "",
          link: "/autopay",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Margin against stocks",
          icon: "",
          link: "/noticationsettings",
          mdiicon: "mdi-chevron-right",
        },
        { text: "Manage Funds", link: "secufund" },
        {
          text: "Securities Funds ",
          icon: "",
          link: "managefund",
          mdiicon: "mdi-chevron-right",
        },
      ],
      actid: "",
      susertoken: "",
      noms: [
        { txt: "Yes, add nominee", val: "yes" },
        { txt: "No, i will do it later", val: "no" },
      ],
      bankprooftype: [
        { txt: "Saving Account", val: "Saving Account" },
        { txt: "Current Account", val: "Current Account" },
      ],
      bankproofTypes: "Saving Account",
      file: null,
      dragging: false,
      panimagedata: [],
      panimage: null,
      panFile: "",
      BankProofselect: "bs",
      customLabel: "Nominee address is same as my address",
      panel: 0,
      cardloader: false,
      dissBtn: true,
      dissBtn2: true,
      dissBtn3: true,
      activetab: "tab1",
      nosegmentsPop: false,
      newFamilyRelation: "",
      memberId: "",
      memberMobile: "",
      memberPan: "",
      validFamily: "",
      familyloader: false,
      familySubmission: false,
      endtxt: '',
      familyRequestData: '',
      familystatusloading: false,
      familyrequest: false,
      selectTableitem: [],
      mobileMember: '',
      disabled: false,
      readonly: false,
      accountstatus:""
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menunto(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuunto(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menunthre(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuunthre(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    passHide() {
      // let file = e.target.files[0] || e.dataTransfer.files[0]
      //           this.panFile = file;

      var axiosThis = this;
      this.passwordField = false;
      const FormData = require("form-data");
      let data = new FormData();
      data.append("client_id", this.client_code);
      data.append("proff_file", this.panFile);
      data.append("checktype", "income");

      let config = {
        method: "post",
        url: api.api_url + "/pdf_lock",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          // console.log(response.data);
          if (response.data["password required"] == "True") {
            axiosThis.passwordField = true;
            axiosThis.passwordRequired = true;
            // axiosThis.paswordverification = true
          } else {
            axiosThis.passwordRequired = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchMobileStatus() {
      var axiosThis = this;
      let data = JSON.stringify({
        client_id: this.profileData.CLIENT_ID,
      });

      let config = {
        method: "post",
        url: api.api_url + "/add_mob_email_stat",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "application/json",
        },
        data: data,
      };
      // console.log("jhasdgajb",this.client_code,this.tok)
      axios
        .request(config)
        .then((response) => {
          // console.log(response.data);
          if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "clientid not exist") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }

          axiosThis.blurLoader = false;
          axiosThis.mobStatus = response.data;
          if (
            axiosThis.mobStatus.mobile_status == "e-signed pending" ||
            axiosThis.mobStatus.address_status == "e-signed pending" ||
            axiosThis.mobStatus.email_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.mobileDisable = true;
            axiosThis.emailDisable = true;
            axiosThis.addressDisable = true;
          }
          if (
            axiosThis.mobStatus.address_status == "e-signed completed" ||
            axiosThis.mobStatus.address_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.addressDisable = true;
          }
          if (
            axiosThis.mobStatus.mobile_status == "e-signed completed" ||
            axiosThis.mobStatus.mobile_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.mobileDisable = true;
          }
          if (
            axiosThis.mobStatus.email_status == "e-signed completed" ||
            axiosThis.mobStatus.email_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.emailDisable = true;
          }
          if (
            axiosThis.mobStatus.income_status == "e-signed completed" ||
            axiosThis.mobStatus.income_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.yearlyIncomeDisable = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    samemembercheck() {
      if (this.memberId == this.client_code) {
        this.memberId = ''
        this.errorSnackbar = true;
        this.errtext = "Not allowed";
      }
    },
    onChange(e) {
      let file = e.target.files[0] || e.dataTransfer.files[0];
      // console.log(file["type"]);
      if (
        file["type"] == "image/jpeg" ||
        file["type"] == "image/jpg" ||
        file["type"] == "image/png" ||
        file["type"] == "application/pdf"
      ) {
        this.panFile = file;
        this.panimage = window.URL.createObjectURL(this.panFile);
        this.panimagedata["name"] = this.panFile.name;
        this.panimagedata["size"] = (this.panFile.size / 1048576).toFixed(1);
        this.passHide();
      } else {
        this.cardloader = false;
        this.toasttext = "Only png, jpg, jpeg image formats are supported.";
        this.snackbar = true;
      }
    },
    handleFiles(files) {
      // Handle the selected files here
      // console.log(files);
    },
    openFileInput() {
      // console.log("click input",this.$refs.fileInput.$refs.input.click())
      // Programmatically trigger the click event of the hidden input
      this.$refs.fileInput.$refs.input.click();
    },
    goSso() {
      // if( data)
      // window.location.assign(`http://192.168.5.152:8082/?url=${window.location.href}`)

      window.location.assign(
        `https://desk.mynt.in/?url=${window.location.href}`
      );
    },
    annualincomeSubmit() {
      this.itemSelected = this.items[this.selectionas];

      const FormData = require("form-data");
      let data = new FormData();
      // data.append("proff", this.bankproffdoc);
      // data.append("bank_exists", JSON.stringify(this.existingBanks));
      data.append("client_id", this.profileData.CLIENT_ID);
      data.append("client_email", this.profileData.CLIENT_ID_MAIL);
      data.append("client_name", this.profileData.CLIENT_NAME);
      data.append("ext_income_range", this.profileData.ANNUAL_INCOME);
      data.append("cur_income_range", this.itemSelected);
      data.append("proff", this.selectionas == 4 ? "YES" : "NO");
      data.append("dp_code", this.profileData.CLIENT_DP_CODE);
      data.append("proff_type", this.typeProof);
      data.append("proff_file", this.panFile);
      data.append("password", this.pdfPassword);
      data.append("password_reqiured", "");
      let config = {
        method: "post",
        url: api.api_url + "/income",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      let axiosThis = this;
      // console.log("pppp",this.existingBanks)
      axios
        .request(config)
        .then((response) => {
          // axiosThis.blurLoader = false
          // if()
          if (response.data.msg == "success") {
            axiosThis.successSnackbar = true;
            axiosThis.Succtext = "Submited sucessfully";
          } else {
            axiosThis.errorSnackbar = true;
            axiosThis.errtext = response.data.msg;
          }
          // console.log(response.data);
        })
        .catch((error) => {
          axiosThis.blurLoader = false;
          console.log(error);
        });
    },
    annualotpVerifyClicked() {
      var axiosThis = this;
      var valid = this.$refs.annualotpformm.validate();
      if (valid == true) {
        let data = JSON.stringify({
          mobile_number: this.profileData.MOBILE_NO,
          client_id: this.client_code,
          mobile_otp: this.annualuserOtp,
        });

        let config = {
          method: "post",

          url: api.api_url + "/income_otp_ver",
          headers: {
            Authorization: this.tok,
            clientid: this.profileData.CLIENT_ID,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            // console.log(JSON.stringify(response.data),axiosThis.selectionas,axiosThis.typeProof,axiosThis.imgType,axiosThis.pdfPassword);
            // if(response.data.msg == "otp not valid") {
            //   axiosThis.annualIncomechange = false
            //   axiosThis.annualIncomeverifyotp = true
            // }
            if (response.data.msg == "otp valid") {
              axiosThis.annualincomeSubmit();
              axiosThis.editdialog = false;
              setTimeout(function () {
           
              window.location.reload();
            }, 2000);
              // axiosThis.annualIncomechange = false
              // axiosThis.annualIncomeverifyotp = false
            } else {
              (axiosThis.annualuserOtp = ""), (axiosThis.errorSnackbar = true);
              axiosThis.errtext = response.data.msg;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    annualincomeOtpReq() {
      var axiosThis = this;
      let data = JSON.stringify({
        mobile_number: this.profileData.MOBILE_NO,
        client_id: this.client_code,
        client_name: this.name,
      });

      let config = {
        method: "post",

        url: api.api_url + "/income_otp_req",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          if (response.data.msg == "otp send") {
            axiosThis.annualIncomechange = false;
            axiosThis.annualIncomeverifyotp = true;
            
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },


    usercopytest() {
      // console.log(this.usernewemail.toUpperCase(),this.profileData.CLIENT_ID_MAIL.toUpperCase())
      if (
        this.usernewemail.toUpperCase() ==
        this.profileData.CLIENT_ID_MAIL.toUpperCase()
      ) {
        this.usernewemail = "";
        this.errorSnackbar = true;
        this.errtext = "Enter your new email id";
      }
    },    connectDigio() {
      var stateid = "signup" + Math.random().toString(36).substring(7);
      // window.location.href =
      //   "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=A73859D2&state=" +
      //   stateid +
      //   "";
      // for local
      window.location.href =
        "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=A987F208&state=" +
        stateid +
        "";
    },


    selectOptionBtn() {
      // console.log("cjsjsakcnsioun", this.yesorno);
      if (this.yesorno == "yes") {
        this.nomineeForm = true;
      } else if (this.yesorno == "no") {
        this.nomineeForm = false;
      }
    },

    otpButtonClicked() {
      this.tok = localStorage.getItem("usession");

      var axiosThis = this;
      if (this.currentClick == "mobile") {
        var valid = this.$refs.formm.validate();
        if (valid == true) {
          // console.log("josi");
          this.mobileLoader = true;
          // this.blurLoader = true

          var data = JSON.stringify({
            pres_mobile_no: this.usernewmobile,
            client_id: this.client_code,
            mobile_otp: this.userOtp,
            prev_mobile_no: this.profileData.MOBILE_NO,
            client_name: this.name,
            client_email: this.profileData.CLIENT_ID_MAIL.toUpperCase(),
            dp_code: this.profileData.CLIENT_DP_CODE,
          });
          // var enc = this.encryptionFunction(dall);
          // console.log("status payload", enc, dall);
          // let data = JSON.stringify({
          //   string: enc,
          // });

          let config = {
            method: "post",
            // url: api.api_url+"/mob_otp_ver",
            url: api.api_url + "/mob_otp_ver",

            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              axiosThis.mobileLoader = false;

              // var res = axiosThis.decryptionFunction(response.data.str);
              // console.warn(response);
              if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Invalid";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              var responceReq = response.data;
              // console.log(response.data.message);
              if (responceReq.msg == "otp valid") {
                axiosThis.requestotp = true;
                axiosThis.verifyotp = false;
                axiosThis.successSnackbar = true;
                axiosThis.mobileLoader = false;
                axiosThis.blurLoader = true;

                const date = new Date();
                const offset =
                  date.getTimezoneOffset() == 0
                    ? 0
                    : -1 * date.getTimezoneOffset();

                let normalized = new Date(date.getTime() + offset * 60000);
                let indiaTime = new Date(
                  normalized.toLocaleString("en-US", {
                    timeZone: "Asia/Calcutta",
                  })
                );

                // console.log("Time in India::", indiaTime.toISOString());
                var dateAndTime = indiaTime.toISOString();

                let data = JSON.stringify({
                  pres_mobile_no: axiosThis.usernewmobile,
                  client_id: axiosThis.client_code,
                  prev_mobile_no: axiosThis.profileData.MOBILE_NO,
                  client_name: axiosThis.name,
                  client_email:
                    axiosThis.profileData.CLIENT_ID_MAIL.toUpperCase(),
                  dp_code: axiosThis.profileData.CLIENT_DP_CODE,
                  date_time: dateAndTime,
                });

                let config = {
                  method: "post",

                  url: api.api_url + "/file_write_mob",
                  headers: {
                    Authorization: axiosThis.tok,
                    clientid: axiosThis.profileData.CLIENT_ID,
                    "Content-Type": "application/json",
                  },
                  data: data,
                };

                axios
                  .request(config)
                  .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    // axiosThis.digioEsign(responceReq.fileid, responceReq.mailid)
                    var responceReq = response.data;
                    axiosThis.digioEsign(
                      responceReq.fileid,
                      responceReq.mailid,
                      responceReq.session
                    );
                  })
                  .catch((error) => {
                    console.log(error);
                  });

                axiosThis.Succtext = "OTP Verified";
                axiosThis.editdialog = false;
                axiosThis.usernewmobile = "";
                axiosThis.userOtp = "";
              } else {
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = "otp not valid";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      if (this.currentClick == "email") {
        //// console.log("email otp verify", this.$refs.emailform);
        var emailvalid = this.$refs.formemailveri.validate();
        if (emailvalid == true) {
          // this.blurLoader = true

          // console.log("josi");
          let data = JSON.stringify({
            client_id: this.client_code,
            present_email: this.userEmailadress.toUpperCase(),
            emailotp: this.useremailOtp,
          });

          let config = {
            method: "post",
            url: api.api_url + "/mail_otpverify",
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Invalid";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              // console.log(response.data.msg);
              if (response.data.msg == "otp valid") {
                axiosThis.blurLoader = true;

                axiosThis.successSnackbar = true;

                axiosThis.verifyotp = false;
                axiosThis.requestotp = true;
                axiosThis.userOtp = "";
                axiosThis.usernewmobile = "";
                (axiosThis.usernewemail = ""),
                  (axiosThis.requestotpemail = false),
                  (axiosThis.useremailOtp = ""),
                  (axiosThis.verifyotpemail = false);
                axiosThis.Succtext = "OTP Verfified Sucessfully";
                // axiosThis.connectDigio()

                const date = new Date();
                const offset =
                  date.getTimezoneOffset() == 0
                    ? 0
                    : -1 * date.getTimezoneOffset();

                let normalized = new Date(date.getTime() + offset * 60000);
                let indiaTime = new Date(
                  normalized.toLocaleString("en-US", {
                    timeZone: "Asia/Calcutta",
                  })
                );

                // console.log("Time in India::", indiaTime.toISOString());
                var dateAndTime = indiaTime.toISOString();

                let data = JSON.stringify({
                  client_id: axiosThis.client_code,
                  client_email:
                    axiosThis.profileData.CLIENT_ID_MAIL.toUpperCase(),
                  dp_code: axiosThis.profileData.CLIENT_DP_CODE,
                  date_time: dateAndTime,
                  present_email: axiosThis.userEmailadress.toUpperCase(),
                  previous_email:
                    axiosThis.profileData.CLIENT_ID_MAIL.toUpperCase(),
                  client_name: axiosThis.name,
                });
                axiosThis.blurLoader = true;

                let config = {
                  method: "post",

                  url: api.api_url + "/file_write_email",
                  headers: {
                    Authorization: axiosThis.tok,
                    clientid: axiosThis.profileData.CLIENT_ID,
                    "Content-Type": "application/json",
                  },
                  data: data,
                };

                axios
                  .request(config)
                  .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    var responceReq = response.data;
                    axiosThis.digioEsign(
                      responceReq.fileid,
                      responceReq.mailid,
                      responceReq.session
                    );
                  })
                  .catch((error) => {
                    axiosThis.blurLoader = false;

                    console.log(error);
                  });

                axiosThis.editdialog = false;
              } else {
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = "Invalid OTP";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      // console.log("submit clik");
    },
    sendButtonClicked() {
      this.tok = localStorage.getItem("usession");

      var axiosThis = this;
      if (this.currentClick == "mobile") {
        var emailvalid = this.$refs.form.validate();
        if (emailvalid == true) {
          this.mobileLoader = true;

          this.cardLoader = true;
          // console.log("josi");
          var dall = JSON.stringify({
            prev_mobile_no: this.profileData.MOBILE_NO,
            pres_mobile_no: this.usernewmobile,
            client_id: this.client_code,
            client_name: this.name,
            client_email: this.profileData.CLIENT_ID_MAIL.toUpperCase(),
          });
          // var enc = this.encryptionFunction(dall);
          // console.log("status payload", dall);
          // let data = JSON.stringify({
          //   string: enc,
          // });
          let data = dall;

          let config = {
            method: "post",
            url: api.api_url + "/mob_otp_req",
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Invalid";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              // var res = axiosThis.decryptionFunction(response.data.str);
              // console.warn(response);
              axiosThis.mobileLoader = false;

              var responceReq = response.data;
              axiosThis.cardLoader = false;
              // console.log(JSON.stringify(response.data));
              if (responceReq.msg == "otp sent") {
                axiosThis.requestotp = false;
                axiosThis.verifyotp = true;

                axiosThis.Succtext = "OTP Sent";
                axiosThis.successSnackbar = true;
              } else if (responceReq.msg == "already this mobile number used") {
                axiosThis.usernewmobile = "";
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = "Entered Number was already available";
              }
            })
            .catch((error) => {
              axiosThis.cardLoader = false;
              console.log(error);
            });
        }
      }
      if (this.currentClick == "email") {
        var valid = this.$refs.formemailreq.validate();
        if (valid == true) {
          this.emailLoader = true;
          this.cardLoader = true;
          // console.log("email");
          this.userEmailadress = this.usernewemail;
          let data = JSON.stringify({
            client_id: this.client_code,
            previous_email: this.profileData.CLIENT_ID_MAIL,
            present_email: this.usernewemail.toUpperCase(),
            client_name: this.name,
          });

          let config = {
            method: "post",
            url: api.api_url + "/mail_otpsend",
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Invalid";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              // axiosThis.cardLoader = false;
              axiosThis.emailLoader = false;

              // console.log(JSON.stringify(response.data));
              if (response.data.msg == "success send mail") {
                axiosThis.requestotpemail = false;
                axiosThis.verifyotpemail = true;
              } else if (response.data.msg == "Mobile already exists") {
                axiosThis.mobileNumber = "";
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = "Entered Number was already available";
              } else {
                axiosThis.mobileNumber = "";
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = response.data.msg;
              }
            })
            .catch((error) => {
              axiosThis.cardLoader = false;
              console.log(error);
            });
        }
      }
    },
    digilockerAdressSubmit() {
      var axiosThis = this;

      // let params = (new URL(document.location)).searchParams;
      let token = this.aadharcode;
      let state = this.aadharstate;

      // var url_data = this.$route.params;
      // console.log(token,"poiuytrewqqqqqqasdfghjkllkmnbvcxz");

      // var addressvalid = this.$refs.formadress.validate();
      if (token && state) {
        this.editType = "address_change";
        const FormData = require("form-data");
        let data = new FormData();
        data.append("file", "");
        data.append("proff", "");
        data.append("cur_address", "");
        data.append("ext_address", this.profileData.CL_RESI_ADD1);
        data.append("dp_code", this.profileData.CLIENT_DP_CODE);
        data.append("client_id", this.client_code);
        data.append("client_name", this.name);
        data.append(
          "client_email",
          this.profileData.CLIENT_ID_MAIL.toUpperCase()
        );
        data.append("aadhar_address", "");
        data.append("adr_manual", "aadhar");
        data.append("code", token);
        data.append("state", state);

        // let config = {
        //   method: 'post',
        //   // 
        //   url: api.api_url+'/adr_chn',
        //   headers: {
        //   },
        //   data : data
        // };

        // axios.request(config)
        axios
          .post(api.api_url + "/adr_chn", data, {
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            axiosThis.adresschange = false;
            axiosThis.editdialog = false;
            if (response.data.fileid) {
              axiosThis.digioEsign(
                response.data.fileid,
                response.data.mailid,
                response.data.session
              );
            } else {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = "Error in Server, please try again later";
            }
            axiosThis.$router.replace("/");
            // window.location.assign(window.location.href.split('?')[0])

            // const query = this.$route.query;
            //   delete query.code;
            //   delete query.state;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    paswordverificationBtn() {
      // this.annualincomeOtpReq()
      this.tok = localStorage.getItem("usession");
      if (this.passwordRequired == true) {
        var axiosThis = this;
        const FormData = require("form-data");
        let data = new FormData();
        data.append("client_id", this.client_code);
        data.append("password", this.pdfPassword);
        data.append("checktype", "income");

        data.append("proff_file", this.panFile);

        let config = {
          method: "post",
          url: api.api_url + "/pdf_password_check",
          headers: {
            Authorization: this.tok,
            clientid: this.profileData.CLIENT_ID,
            "Content-Type": "multipart/form-data",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            if (response.data["password required"] == "In-Correct") {
              axiosThis.pdfPassword = "";
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = "Incorrect Password";

              axiosThis.passwordField = true;
              axiosThis.paswordverification = true;
            } else {
              axiosThis.annualincomeOtpReq();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.panFile && this.selectionas == 4) {
        this.annualincomeOtpReq();
      } else if (!this.panFile && this.selectionas !== 4) {
        this.annualincomeOtpReq();
      } else {
        this.errorSnackbar = true;
        this.errtext = "Upload proof";
      }
    },
    checkSame() {
      if (this.profileData.MOBILE_NO == this.usernewmobile) {
        this.usernewmobile = "";
        this.errorSnackbar = true;
        this.errtext = "Enter your new Mobile number";
      }
    },

    incomeproof() {
      if (this.selectionas == 4) {
        this.proofField = true;
      } else {
        this.proofField = false;
        this.passwordField = false;
        this.incorrectPass = false;
      }
    },
    mobileEdit(data) {
      this.tok = localStorage.getItem("usession");
      if (data == "income") {
        this.currentClick = "income";
        this.editType = "income_change";
        this.adressOptions = false;
        this.manual = false;
        this.requestotp = false;
        this.requestotpemail = false;
        this.editdialog = true;

        this.adresschange = false;
        this.bankchange = false;
        this.segments = false;
        this.annualIncomechange = true;
        this.nosegmentsPop = false;
        this.annualIncomeverifyotp = false;
        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Would you like to change adress ";
        if (this.profileData.ANNUAL_INCOME == "Less Then One Lakhs") {
          this.selectionas = 0;
          // console.log("responce",0);
        }
        if (this.profileData.ANNUAL_INCOME == "One To Five Lakhs") {
          this.selectionas = 1;
          // console.log("responce",1);
        }
        if (this.profileData.ANNUAL_INCOME == "Five To Ten Lakhs") {
          // console.log("responce",2);
          this.selectionas = 2;
        }
        if (this.profileData.ANNUAL_INCOME == "Ten To TwentyFive Lakhs") {
          // console.log("responce",3);
          this.selectionas = 3;
        }
        if (
          this.profileData.ANNUAL_INCOME == "TwentyFive Lakhs To Fifty Lakhs" ||
          this.profileData.ANNUAL_INCOME == "Above Five Crore" ||
          this.profileData.ANNUAL_INCOME == "TwentyFive Lakhs To One Crore"
        ) {
          // console.log("responce",4);
          this.selectionas = 4;
        }
      }

      // console.log("edit mobile");
    },
    valu(val) {
      // console.log(val, "val");
      const validat = val;
      if (validat == false) {
        this.createChartsred();
      }
    },
    encryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      var derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      var iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      var test = CryptoJS.AES.encrypt(payload, derived_key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString();
      return test;
    },
    decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      // console.log('Decrypted Data:', decryptedData);
      return decryptedData;
    },
    getAvailBal() {
      var axiosThis = this;
      

     
          axiosThis.blurLoader = false;
          axiosThis.profileLoader = false;
          // console.log(response)
         
   
              axiosThis.equty = [];
              axiosThis.derivat = [];
              axiosThis.commoditi = [];
              axiosThis.curren = []

              // console.log(response.data.str)
              var res = axiosThis.decryptionFunction(localStorage.getItem("profile_data"));
              // console.warn(JSON.parse(res));
              var resp = JSON.parse(res);
              axiosThis.profileData = resp.client_data;
              axiosThis.hashpan = axiosThis.profileData.PAN_NO.slice(-3);
              axiosThis.BankData = resp.bank_data;

              axiosThis.name = axiosThis.profileData.CLIENT_NAME;
              localStorage.setItem('clinet_name', axiosThis.name)

              axiosThis.client_code = axiosThis.profileData.CLIENT_ID;

              if (axiosThis.profileData.Nominee_Name) {
                // axiosThis.multinominee = resp.
                axiosThis.nomineeYes = true;
                axiosThis.newNomineeName = axiosThis.profileData.Nominee_Name;
                axiosThis.newNomineeRelation =
                  axiosThis.profileData.Nominee_Relation;
                axiosThis.newNomineeDob = axiosThis.profileData.Nominee_DOB;
                // axiosThis.newNomineepercent = axiosThis.profileData.Nominee_Name
              }

              // else {
              //   axiosThis.nomineeYes = false

              // }
              axiosThis.profileData.CL_RESI_ADD1 =
                axiosThis.profileData.CL_RESI_ADD1 +
                " " +
                axiosThis.profileData.CL_RESI_ADD2 +
                " " +
                axiosThis.profileData.CL_RESI_ADD3;
                for (var i = 0; i < axiosThis.profileData.segments_data.length; i++) {
  // if (
  //   (axiosThis.profileData.segments_data[i].COMPANY_CODE == "BSE_CASH" && 
  //     (axiosThis.profileData.segments_data[i].ACTIVE_INACTIVE === 'I' ||
  //     axiosThis.profileData.segments_data[i].Exchange_ACTIVE_INACTIVE === 'NOT OPEN')) || 
  //   (axiosThis.profileData.segments_data[i].COMPANY_CODE == "NSE_CASH" && 
  //     (axiosThis.profileData.segments_data[i].ACTIVE_INACTIVE === 'I' ||
  //     axiosThis.profileData.segments_data[i].Exchange_ACTIVE_INACTIVE === 'NOT OPEN'))
  // ) {
  //   axiosThis.equty.push('Equity data');
  // }

  if (
    (axiosThis.profileData.segments_data[i].COMPANY_CODE == "NSE_FNO" && 
      (axiosThis.profileData.segments_data[i].ACTIVE_INACTIVE === 'I' ||
      axiosThis.profileData.segments_data[i].Exchange_ACTIVE_INACTIVE === 'NOT OPEN')) || 
    (axiosThis.profileData.segments_data[i].COMPANY_CODE == "BSE_FNO" && 
      (axiosThis.profileData.segments_data[i].ACTIVE_INACTIVE === 'I' ||
      axiosThis.profileData.segments_data[i].Exchange_ACTIVE_INACTIVE === 'NOT OPEN'))
  ) {
    axiosThis.derivat.push("F&odata");
  }

  if (
    (axiosThis.profileData.segments_data[i].COMPANY_CODE == "BSE_COM" && 
      (axiosThis.profileData.segments_data[i].ACTIVE_INACTIVE === 'I' ||
      axiosThis.profileData.segments_data[i].Exchange_ACTIVE_INACTIVE === 'NOT OPEN')) || 
    (axiosThis.profileData.segments_data[i].COMPANY_CODE == "NSE_COM" && 
      (axiosThis.profileData.segments_data[i].ACTIVE_INACTIVE === 'I' ||
      axiosThis.profileData.segments_data[i].Exchange_ACTIVE_INACTIVE === 'NOT OPEN')) || 
    (axiosThis.profileData.segments_data[i].COMPANY_CODE == "MCX" && 
      (axiosThis.profileData.segments_data[i].ACTIVE_INACTIVE === 'I' ||
      axiosThis.profileData.segments_data[i].Exchange_ACTIVE_INACTIVE === 'NOT OPEN'))
  ) {
    axiosThis.commoditi.push('Commdtiydata');
  }

  if (
    (axiosThis.profileData.segments_data[i].COMPANY_CODE == "CD_BSE" && 
      (axiosThis.profileData.segments_data[i].ACTIVE_INACTIVE === 'I' ||
      axiosThis.profileData.segments_data[i].Exchange_ACTIVE_INACTIVE === 'NOT OPEN')) || 
    (axiosThis.profileData.segments_data[i].COMPANY_CODE == "CD_NSE" && 
      (axiosThis.profileData.segments_data[i].ACTIVE_INACTIVE === 'I' ||
      axiosThis.profileData.segments_data[i].Exchange_ACTIVE_INACTIVE === 'NOT OPEN'))
  ) {
    axiosThis.curren.push('Currencydata');
  }
}

              // console.log(axiosThis.derivat);
              // axiosThis.payout.ledger_amount = axiosThis.payout.ledger_amount.toFixed(2)
              // axiosThis.payout.withdraw_amount = axiosThis.payout.withdraw_amount.toFixed(2)

              // axiosThis.cashledg = axiosThis.payout.ledger_amount
            
          
          axiosThis.fetchMobileStatus()
          axiosThis.digilockerAdressSubmit();
       
    },
    chipp() {
      this.chha = !this.chha;
    },
    getCardColor(index) {
      // Custom logic to determine the card color based on the index or other conditions
      if (index % 2 === 0) {
        return "#ECF9EF"; // Example: Blue color for even index cards
      } else {
        return "#FAEAEA"; // Example: Green color for odd index cards
      }
    },
    closeBtn() {
      this.editdialog = false;
      this.verifyotp = false;
      this.requestotp = true;
      this.userOtp = "";
      this.usernewmobile = "";
      (this.usernewemail = ""),
        (this.requestotpemail = false),
        (this.useremailOtp = ""),
        (this.verifyotpemail = false),
        (this.stateAddress = ""),
        (this.countryAddress = ""),
        (this.distAddress = ""),
        (this.pincodeAddress = "");
      (this.usernewAdress = ""),
        (this.adressproffdoc = null),
        (this.bankIfscCode = ""),
        (this.bankAccountnumber = ""),
        (this.bankproofTypes = ""),
        (this.bankproffdoc = null),
        (this.defaultCheckbox = false);
    },
  },
  computed: {
    orderbookHeaders() {
      return [
        { text: "Member ID", value: "memberid", sortable: false },
        { text: "Datetime", value: "req_link_time" },
        { text: "Status", value: "status", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    mobileNumberRules() {
      return [(value) => /^\d{10}$/.test(value) || "Enter a valid number"];
    },
    otpRules() {
      return [
        (value) => !!value || "OTP Required",
        (value) => /^\d{4}$/.test(value) || "Must be exactly 4 digits",
      ];
    },
    nomineeField() {
      return [(value) => !!value || "Required field"];
    },
    emailNumberRules() {
      return [
        (value) => !!value || "email id is required",
        (value) => /.+@.+\..+/.test(value) || "Email must be valid",
      ];
    },
    depedselectRules() {
      return [(value) => !!value || "Dependent name is required"];
    },
  },
  mounted() {
    this.blurLoader = true;
    let params = new URL(document.location).searchParams;
    // var axiosThis = this;
    var cod = params.get("code");
    var stat = params.get("state");
    var vendorAcc = params.get("sAccountId");
    var vendorToken = params.get("sToken");
    // console.log("jiji", vendorAcc, vendorToken);
    this.accountstatus = localStorage.getItem("clientStatus")

    if (cod && stat) {
      this.aadharstate = stat;
      this.aadharcode = cod;
    }
    // let decoded = decodeURIComponent(window.location.search);
    var actid = params.get("uid");
    var token = params.get("token");
    this.susertoken = localStorage.getItem("usession");
    // console.log(this.susertoken,actid,window.location.search)
    this.actid = localStorage.getItem("userid");
    if (typeof actid == "string" && typeof token == "string") {
      localStorage.setItem("usession", token);
      localStorage.setItem("userid", actid);
      this.susertoken = localStorage.getItem("usession");
      this.actid = localStorage.getItem("userid");
      window.location.assign(window.location.href.split("?")[0]);
    }
    if (vendorAcc == null && vendorToken == null) {
      // console.warn("nully")
      if (this.susertoken && this.actid) {
        var id = this.actid;
        this.tok = this.susertoken;
        // console.log("ifififififififif", this.tok);
        this.getAvailBal(id);
      } else {
        this.goSso();
      }
    }
  },
};
const $ = document.querySelector.bind(document);
const $$ = document.querySelectorAll.bind(document);

let activeLink = null;

window.addEventListener("scroll", () => {
  $$(".section").forEach((section) => {
    const { y } = section.getBoundingClientRect();

    if (y <= 0) {
      if (activeLink != null) {
        activeLink.classList.remove("active");
      }

      activeLink = $(`.nav-link[href~="#${section.id}"]`);
      activeLink.classList.add("active");
    }
  });
});
</script>
