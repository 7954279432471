<template>
  <div class="ma-lg-10 ml-lg-10 ma-md-10 ml-md-10">
    <v-snackbar class="snakbar-sty rounded-pill" transition="slide-x-reverse-transition" v-model="snackbar"
      timeout="6000" :value="true" :color="snackcolor" top right outlined absolute text-color="white">
      <v-icon class="mr-2" :color="snackcolor">mdi-alert-outline</v-icon>
      {{ mesg }}
      <v-icon @click="snackbar = false" class="float-right" :color="snackcolor">mdi-close-circle</v-icon>
    </v-snackbar>
    <div class="px-md-3">

      <v-list-item class="pl-0 pl-md-1 ">
            <v-list-item-content>
                <v-list-item-title class="text-start"
                    style="color: #000000;font-size: 20px;font-weight:559;line-height:24px;letter-spacing:-1px">
                  Form Download</v-list-item-title>
                <v-list-item-subtitle class="text-start pt-1"
                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px"> You can see all forms related to your trading account, and if necessary, you can download them.
                </v-list-item-subtitle>
            </v-list-item-content>
       </v-list-item>
      <!-- <p class="font-weight-bold fs-22 mb-0"> Downloads</p>
      <p class="subtitle-2 txt-666">
        These segments are enabled for your account
        You can see all forms related to your trading account, and if necessary, you can download them.

      </p> -->
    </div>
<v-divider></v-divider>
    <v-card outlined elevation="0" class="pa-4 mt-6">
      <!-- <v-row>
            <v-col cols="6">
                <p class="title">Refer your friends to Zebu</p>
    <span class="subtitle-2">Get 20% of brokerage fees for trades made<br>
    by your friends! in everybe the change?<br>
    You're at the right place.</span>
            </v-col>
            <v-col align="end"  cols="6">
                <img  height="120px" width="120px" src="https://zebuetrade.com/img/main-bg.c976c4a3.svg">
            </v-col>
        </v-row>
        <p class="headline mb-0 mt-4 font-weight-bold">Create your referral link, to easy share</p>
        <span style="color: grey;" class="subtitle-2 mt-2">Enter your client ID below to generate the refferal link. <br> You can get your client ID from your dashboard.</span>
    <div v-if="firstref">
        <v-text-field class="mt-3" prepend-inner-icon="mdi-account-outline" v-model="referlink" append-icon oninput="this.value = this.value.toUpperCase()" dense outlined style=" font-size: 12px;width: 350px;" 
                        solo  flat hide-details text placeholder="Enter Client code" >
        <template v-slot:append>
            <v-btn :disabled="referlink == ''" :loading="refload" icon @click="getref">
    <v-icon> mdi-arrow-right-thin</v-icon>
            </v-btn>
    
        </template>
</v-text-field>
</div>
<div v-if="secondref">
  <div class="d-flex">
    <div> <v-text-field class="mt-3" readonly prepend-inner-icon="mdi-account-outline" v-model="finallink" append-icon
        dense outlined style=" font-size: 12px;width: 350px;" solo flat hide-details text
        placeholder="Enter Client code">
        <template v-slot:append>
            <v-btn :loading="refload" icon @click="copyref">
    <v-icon> mdi-content-copy</v-icon>
            </v-btn>
    
        </template>
      </v-text-field></div> <v-btn @click="closeback()" class="mt-3" size="18" icon><v-icon>mdi-close</v-icon></v-btn>
  </div>
  <p class="mt-2" style="color: blue;">hare your referral link with friends and family to earn 20% of their brokerage
    costs from trades.</p>

</div>
<a href="https://example.com" target="_blank">Click here</a> -->

      <!-- <v-divider class="mb-3"></v-divider> -->
      <v-progress-circular class="ml-14 mt-14" v-if="proloader" :size="50" color="primary"
        indeterminate></v-progress-circular>
      <p v-if="this.ekydocdown != ''" class="text-uppercase font-weight-regular ls-1 lh-38">KYC Forms</p>

      <div v-if="this.ekydocdown != ''">
        <v-row no-glutters>
          <v-col cols="12" md="6" xl="4">
            <v-card width="100%" class="box-s1 px-md-2 py-1 sec-two-sty1 rounded-xl">
              <v-list-item class="pr-0">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold fs-18">Trading and DP KYC</v-list-item-title>
                  <!-- <p class="mt-1 mb-0 font-weight-light">{{ t.desc }}</p> -->
                </v-list-item-content>
                <v-btn @click="Keyforndownnn()" target="_blank" rel="noopener noreferrer" text plain
                  color="#0037B7"><span class="font-weight-bold text-none fs-16">Download</span></v-btn>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <!-- <div v-else>
        <v-row no-glutters>
                <v-col cols="12" md="6" xl="4">
                    <v-card  width="100%" class="box-s1 px-md-2 py-1 sec-two-sty1 rounded-xl"
                        >
                        <v-list-item class="pr-0">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold fs-18">No Data Found</v-list-item-title>
                              
                            </v-list-item-content>
                           
                        </v-list-item>
                    </v-card>
                </v-col>
            </v-row>
          </div> -->




      <!-- <v-card v-if="this.ekydocdown != ''" width="250px"
              elevation="0"
              outlined
              class="d-flex rounded-lg pa-1"
            >
              <span class="mb-0 mt-1 ml-2 subtitle-1" style=" text-transform: capitalize;">Account Opening Form</span>
              <v-btn @click="Keyforndownnn()" class="ml-3 ml-auto" icon>
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </v-card> -->


      <!-- </v-col> -->
      <v-divider v-if="this.ekydocdown != ''" class="mt-3 mb-3"></v-divider>
      <p class="text-uppercase font-weight-regular ls-1 lh-38">Modification Forms</p>

      <template v-if="!Object.values(doenfulldata).some(value => value !== '')">
        <v-row no-glutters>
          <v-col cols="12" md="6" xl="4">
            <v-card width="100%" class="box-s1 px-md-2 py-1 sec-two-sty1 rounded-xl">
              <v-list-item class="pr-0">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold fs-18">No Data Found</v-list-item-title>
                  <!-- <p class="mt-1 mb-0 font-weight-light">{{ t.desc }}</p> -->
                </v-list-item-content>

              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <div v-else>
        <!-- 
        <v-row no-glutters>
          <template v-for="(key, index) in doenfulldata">
                <v-col :key="index" v-if="doenfulldata[key] != ''" cols="12" md="6" xl="4">
                    <v-card data-aos="flip-up" width="100%" class="box-s1 px-md-2 py-1 sec-two-sty1 rounded-xl"
                       >
                        <v-list-item class="pr-0">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold fs-18" style=" text-transform: capitalize;">{{ key }}</v-list-item-title>
                             
                            </v-list-item-content>
                            <v-btn @click="openLink(doenfulldata[key])" target="_blank" rel="noopener noreferrer" text plain
                                color="#0037B7"><span class="font-weight-bold text-none fs-16">Download</span></v-btn>
                        </v-list-item>
                    </v-card>
                </v-col>
              </template>
            </v-row> -->
        <v-row>
          <template v-for="(item, index) in doenfulldata">
            <!-- Check if any value in the item is not empty -->
            <v-col :key="'card-' + index" v-if="Object.values(item).some(value => value !== '')" cols="12" md="6"
              xl="4">
              <v-card data-aos="flip-up" width="100%" class="box-s1 px-md-2 py-1 sec-two-sty1 rounded-xl">
                <v-list-item class="pr-0">
                  <v-list-item-content>
                    <!-- Find the first non-empty key -->
                    <v-list-item-title class="font-weight-bold fs-18" style="text-transform: capitalize;">
                      {{ Object.keys(item).find(key => item[key] !== '') }}
                    </v-list-item-title>
           
                    <!-- <template v-for="(value, key) in item">
                      <div v-if="value !== '' && key !== Object.keys(item)[0]" :key="'value-' + key">{{ value }}</div>
                    </template> --> 
                  </v-list-item-content>
                  <v-btn v-if="Object.keys(item).find(key => item[key] !== '')"
                    @click="openLink(item[Object.keys(item).find(key => item[key] !== '')])" target="_blank"
                    rel="noopener noreferrer" text plain color="#0037B7">
                    <span class="font-weight-bold text-none fs-16">Download</span>
                  </v-btn>
                  <p v-if="Object.keys(item).find(key => item[key] === '')">no dtaa</p>
                </v-list-item>
              </v-card>
            </v-col>
          </template>
          
        </v-row>


        <!-- 
<v-row no-gutters>
    <template v-for="(item, index) in doenfulldata">
     
        <v-col :key="'card-' + index" v-if="Object.values(item).some(value => value !== '')" cols="12" md="6" xl="4">
            <v-card data-aos="flip-up" width="100%" class="box-s1 px-md-2 py-1 sec-two-sty1 rounded-xl">
                <v-list-item class="pr-0">
                    <v-list-item-content>
                        
                        <v-list-item-title class="font-weight-bold fs-18" style="text-transform: capitalize;">{{ Object.keys(item)[0] }}</v-list-item-title>
                       
                        <template v-for="(value, key) in item">
                            <div v-if="value !== '' && key !== Object.keys(item)[0]" :key="'value-' + key">{{ value }}</div>
                        </template>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
        </v-col>
    </template>
</v-row> -->


        <!-- 
            <v-row no-glutters>
              <div v-for="(value, index) in values" :key="index">

          <template v-for="(content, key) in doenfulldata">
                <v-col v-if="content" :key="content.projectId" cols="12" md="6" xl="4">
                    <v-card data-aos="flip-up" width="100%" class="box-s1 px-md-2 py-1 sec-two-sty1 rounded-xl"
                       >
                        <v-list-item class="pr-0">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold fs-18" style=" text-transform: capitalize;">{{ key }}: {{ content }}</v-list-item-title>
                             
                            </v-list-item-content>
                        
                        </v-list-item>
                    </v-card>
                </v-col>
              </template>
            </div>
            </v-row> -->

        <!-- <v-card v-for="item in doenfulldata" :key="item.__uid">
      <v-card-text>
        <v-list two-line>
          <v-list-item v-for="(value, key) in Object.keys(item)" :key="key">
            <v-list-item-content>
              <v-list-item-title>{{ key }}</v-list-item-title>
              <v-list-item-subtitle>{{ value[1] }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card> -->
        <!-- <div v-for="(value, index) in values" :key="index">
  <template v-for="(content, key) in value">
    <div v-if="content">
      {{ key }}: {{ content }}
    </div>
  </template>
</div> -->






        <v-row>
          <!-- <template v-for="(key, index) in Object.keys(doenfulldata)">
        <v-col cols="3" :key="index" v-if="doenfulldata[key] != ''">
          <div v-if="doenfulldata[key] != ''">
            <v-card 
              elevation="0"
              outlined
              class="d-flex rounded-lg pa-1"
            >
              <span class="mb-0 mt-1 ml-2 subtitle-1" style=" text-transform: capitalize;">{{ key }}</span>
              <v-btn @click="openLink(doenfulldata[key])" class="ml-3 ml-auto" icon>
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </v-card>
          </div>
        </v-col>
      </template> -->
        </v-row>

      </div>


    </v-card>
  </div>
</template>
<script>
import api from "@/Api.js";

export default {
  data() {
    return {
      referlink: '',
      refload: false,
      mesg: '',
      snackbar: false,
      snackcolor: '#000',
      finallink: '',
      firstref: true,
      secondref: false,
      doenfulldata: [],
      proloader: true,
      ekydocdown: '',
    }
  },
  created() {
    // Add a unique identifier if not present
    this.doenfulldata.forEach(item => {
      if (!item.__uid) {
        item.__uid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      }
    });
  },
  methods: {

    openLink(link) {
      // console.log('satartt');

      // console.log(link);
      const fullLink = 'https://rekycbe.mynt.in' + link;
      window.open(fullLink, '_blank');
    },
    Keyforndownnn() {
      const fullLink = 'https://ekycbe.mynt.in' + this.ekydocdown;
      window.open(fullLink, '_blank');
    },

    downloaddata() {
      this.proloader = true
      this.doenfulldata = []
      const axios = require('axios');
      let data = JSON.stringify({
        client_id: localStorage.getItem('userid')
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: api.api_url + '/pdfdownload',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      var axiosThis = this
      axios.request(config)
        .then((response) => {
          axiosThis.proloader = false

          // console.log(JSON.stringify(response.data));
          if (response.data.stat == 'ok') {
            axiosThis.doenfulldata = response.data.values
          } else {
            axiosThis.proloader = false

            axiosThis.snackbar = true
            axiosThis.snackcolor = 'error'
            axiosThis.mesg = response.data
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.proloader = false

          axiosThis.snackbar = true
          axiosThis.snackcolor = 'error'
          axiosThis.mesg = error
        });

    },
    ekyfiledown() {
      const axios = require('axios');
      let data = JSON.stringify({
        mobile_number: this.clientmobnu
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://ekycbe.mynt.in/dd/ekyc_form_download',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      var axiosThis = this
      axios.request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          if (response.data.stat == 'ok') {
            if (response.data.values) {
              axiosThis.ekydocdown = response.data.values
            }

          }
          // else{
          //   axiosThis.proloader = false

          //   axiosThis.snackbar = true
          // axiosThis.snackcolor = 'error'
          // axiosThis.mesg = response.data
          // }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.proloader = false

          axiosThis.snackbar = true
          axiosThis.snackcolor = 'error'
          axiosThis.mesg = error
        });

    },

    encryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      var derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      var iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      var test = CryptoJS.AES.encrypt(payload, derived_key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString();
      return test;
    },
    decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      // console.log('Decrypted Data:', decryptedData);
      return decryptedData;
    },
    getAvailBal() {
      var axiosThis = this;

      // console.log(response.data.str)
      var res = axiosThis.decryptionFunction(localStorage.getItem("profile_data"));
      // console.warn(JSON.parse(res));
      var resp = JSON.parse(res);
      axiosThis.profileData = resp.client_data;


      axiosThis.clientmobnu = axiosThis.profileData.MOBILE_NO;

      axiosThis.ekyfiledown()

    },


  },
  mounted() {
    this.getAvailBal()
    this.downloaddata()
  }
}
</script>