<template>
  <v-app>
    <div class="d-none">
      <form
        name="frmpledge"
        method="post"
        action="https://api.cdslindia.com/APIServices/pledgeapi/pledgesetup"
      >
        <input id="dps" type="text" name="dpid" value="dpid" />
        <input id="plg" type="text" name="pledgedtls" value="pledgedtls" />
        <input id="req" type="text" name="reqid" value="svb" />
        <input id="ver" type="hidden" name="version" value="1.0" />
        <button ref="myBtn" type="submit">submit</button>
      </form>
    </div>

    <v-container class="pa-0 pa-md-3 pr-md-0 pb-0">
      <div class="pa-md-4">
        <v-snackbar
          class="snakbar-sty rounded-pill"
          transition="slide-x-reverse-transition"
          v-model="snackbar"
          timeout="4000"
          :value="true"
          :color="snackcolor"
          top
          right
          outlined
          absolute
          text-color="white"
        >
          <v-icon class="mr-2" :color="snackcolor">mdi-alert-outline</v-icon>
          {{ toasttext }}
          <v-icon
            @click="snackbar = false"
            class="float-right"
            :color="snackcolor"
            >mdi-close-circle</v-icon
          >
        </v-snackbar>
        <div class="pt-6 pa-4">
          <v-toolbar
            class="mb-3 toolbar-contantd px-md-3"
            elevation="0"
            color="white"
          >
            <v-list-item class="pl-5 pl-md-1">
              <v-list-item-content>
                <v-list-item-title
                  class="text-start"
                  style="
                    color: #000000;
                    font-size: 20px;
                    font-weight: 559;
                    line-height: 24px;
                    letter-spacing: -1px;
                  "
                >
                  Pledge & Unpledge
                </v-list-item-title>
                <v-list-item-subtitle
                  class="text-start pt-1"
                  style="
                    color: #666666;
                    font-size: 12px;
                    font-weight: 469;
                    line-height: 14px;
                  "
                  >Stocks held by you in your various accounts
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-spacer></v-spacer>
            <v-list class="d-none d-md-flex">
              <v-list-item style="min-height: 0px">
                <v-list-item-content class="">
                  <v-list-item-title
                    class="text-start"
                    style="
                      color: #000000;
                      font-size: 16px;
                      font-weight: 559;
                      line-height: 16px;
                    "
                  >
                    {{ alldata.length }}
                    Companies</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-text-field
              v-if="
                filter == 'All' ||
                filter == 'Free Stocks' ||
                filter == 'Pledged Stocks' ||
                filter == 'Unapproved'
              "
              prepend-inner-icon="mdi-magnify"
              v-model="datasearchall"
              class="d-none d-md-flex tophundraedmutual searchfundlabel"
              style="min-width: 180px; font-size: 12px"
              background-color="#F1F3F8"
              label="Search"
              solo
              rounded
              flat
              dense
              hide-details
            >
            </v-text-field>
            <v-text-field
              v-else-if="histtab == 0"
              prepend-inner-icon="mdi-magnify"
              v-model="historydata"
              class="d-none d-md-flex tophundraedmutual searchfundlabel"
              style="min-width: 180px; font-size: 12px"
              background-color="#F1F3F8"
              label="Search"
              solo
              rounded
              flat
              dense
              hide-details
            >
            </v-text-field>
            <v-text-field
              v-else-if="histtab == 1"
              prepend-inner-icon="mdi-magnify"
              v-model="unpledgehis"
              class="d-none d-md-flex tophundraedmutual searchfundlabel"
              style="min-width: 180px; font-size: 12px"
              background-color="#F1F3F8"
              label="Search"
              solo
              rounded
              flat
              dense
              hide-details
            >
            </v-text-field>

            <v-select
              :items="['All', 'Free Stocks', 'Pledged Stocks', 'Unapproved', 'History']"
              @change="setFilter()"
              repend-inner-icon="mdi-magnify"
              v-model="filter"
              append-icon="mdi-chevron-down"
              class="d-none d-md-flex tophundraedmutual ml-3 searchfundlabel"
              style="min-width: 160px; font-size: 12px"
              background-color="#F1F3F8"
              solo
              rounded
              flat
              dense
              hide-details
            ></v-select>
          </v-toolbar>

          <v-divider></v-divider>
          <v-row class="pa-6 d-none d-md-flex">
            <v-col>
              <v-card class="pl-3 py-1" outlined height="90px" width="">
                <p
                  class="pt-1 mb-3"
                  style="
                    font-weight: 450;
                    font-size: 16px;
                    color: #000000;
                    line-height: 16px;
                    letter-spacing: -0.4px;
                  "
                >
                  Total value
                </p>
                <v-divider
                  class="mt-2"
                  style="border: solid px #000000"
                  width="30px"
                  color="#000000"
                ></v-divider>
                <div class="display-flex row pt-6 pl-2">
                  <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                    <p
                      class="mb-0"
                      style="
                        color: #000;
                        font-size: 13px;
                        font-weight: 469;
                        line-height: 12px;
                        letter-spacing: 0.9px;
                      "
                    >
                      {{
                        holdsdata.stocks_value ? holdsdata.stocks_value : "0.00"
                      }}
                    </p>
                  </v-card>
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="pl-3 py-1" outlined height="90px" width="">
                <p
                  class="pt-1 mb-3"
                  style="
                    font-weight: 450;
                    font-size: 16px;
                    color: #000000;
                    line-height: 16px;
                    letter-spacing: -0.4px;
                  "
                >
                Est Mrg / Avaiable Mrg
                </p>
                <v-divider
                  class="mt-2"
                  style="border: solid px #000000"
                  width="30px"
                  color="#000000"
                ></v-divider>
                <div class="display-flex row pt-6 pl-2">
                  <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                    <p
                      class="mb-0"
                      style="
                        color: #000;
                        font-size: 13px;
                        font-weight: 469;
                        line-height: 12px;
                        letter-spacing: 0.9px;
                      "
                    >
                      {{
                        holdsdata.margin_total_available
                          ? holdsdata.margin_total_available
                          : "0.00"
                      }}
                      / 
                      <span class="pt-2">
                        {{
                          holdsdata.est_total_available
                            ? holdsdata.est_total_available
                            : "0.00"
                        }}
                      </span
                      >
                    </p>
                  </v-card>
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="pl-3 py-1" outlined height="90px" width="">
                <p
                  class="pt-1 mb-3"
                  style="
                    font-weight: 450;
                    font-size: 16px;
                    color: #000000;
                    line-height: 16px;
                    letter-spacing: -0.4px;
                  "
                >
                 Cash Equivalent / Non Cash
                </p>
                <v-divider
                  class="mt-2"
                  style="border: solid px #000000"
                  width="30px"
                  color="#000000"
                ></v-divider>
                <div class="display-flex row pt-6 pl-2">
                  <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                    <p
                      class="mb-0"
                      style="
                        color: #000;
                        font-size: 13px;
                        font-weight: 469;
                        line-height: 12px;
                        letter-spacing: 0.9px;
                      "
                    >
                      {{ cashquiet ? cashquiet : "0.00" }} / {{noncashquiet ? noncashquiet : "0.00"}}
                    </p>
                  </v-card>
                </div>
              </v-card>
            </v-col>

            <v-col>
              <v-card class="pl-3 py-1" outlined height="90px" width="">
                <p
                  class="pt-1 mb-3"
                  style="
                    font-weight: 450;
                    font-size: 16px;
                    color: #000000;
                    line-height: 16px;
                    letter-spacing: -0.4px;
                  "
                >
                Total / Pledged  / Non-approved 
                </p>
                <v-divider
                  class="mt-2"
                  style="border: solid px #000000"
                  width="30px"
                  color="#000000"
                ></v-divider>
                <div class="display-flex row pt-6 pl-2">
                  <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                    <p
                      class="mb-0"
                      style="
                        color: #000;
                        font-size: 13px;
                        font-weight: 469;
                        line-height: 12px;
                        letter-spacing: 0.9px;
                      "
                    >
                    {{ holdsdata.no_of_stocks ? holdsdata.no_of_stocks : 0 }} / {{totalpledgedlen ? totalpledgedlen : 0}} / {{
                        holdsdata.no_of_non_approved_stocks
                          ? holdsdata.no_of_non_approved_stocks
                          : 0
                      }}
                    </p>
                  </v-card>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-card
            color="#eeeeee"
            outlined
            class="d-md-none elevation-0 rounded-lg pa-6 mt-3 my-6"
          >
            <div class="text-center pa-4">
              <p
                class="caption"
                style="
                  color: #696969;
                  letter-spacing: 0.16em;
                  text-transform: uppercase;
                "
              >
                Total value
              </p>
              <p
                class="ctnbtl headline mb-0"
                style="line-height: 28px; color: #505359"
              >
                {{ holdsdata.stocks_value ? holdsdata.stocks_value : "0.00" }}
              </p>
            </div>
            <v-card outlined class="elevation-0 rounded-lg pa-4 pb-0">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="5">
                      <p
                        class="caption"
                        style="
                          color: #696969;
                          letter-spacing: 0.16em;
                          text-transform: uppercase;
                        "
                      >
                      Est Mrg / Avaiable Mrg
                      </p>
                    </v-col>
                    <v-col cols="7" class="text-right">
                      <p
                        class="mb-0"
                        style="
                          color: #000;
                          font-size: 13px;
                          font-weight: 469;
                          line-height: 12px;
                          letter-spacing: 0.9px;
                        "
                      >
                        {{
                          holdsdata.margin_total_available
                            ? holdsdata.margin_total_available
                            : "0.00"
                        }}
                        /
                        {{
                          holdsdata.est_total_available
                            ? holdsdata.est_total_available
                            : "0.00"
                        }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="5">
                      <p
                        class="caption"
                        style="
                          color: #696969;
                          letter-spacing: 0.16em;
                          text-transform: uppercase;
                        "
                      >
                      Cash Equivalent
                      </p>
                    </v-col>
                    <v-col cols="7" class="text-right">
                      <p
                        class="mb-0"
                        style="
                          color: #000;
                          font-size: 13px;
                          font-weight: 469;
                          line-height: 12px;
                          letter-spacing: 0.9px;
                        "
                      >
                        {{
                          cashquiet ? cashquiet : 0
                        }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="5">
                      <p
                        class="caption"
                        style="
                          color: #696969;
                          letter-spacing: 0.16em;
                          text-transform: uppercase;
                        "
                      >
                      Non Cash
                      </p>
                    </v-col>
                    <v-col cols="7" class="text-right">
                      <p
                        class="mb-0"
                        style="
                          color: #000;
                          font-size: 13px;
                          font-weight: 469;
                          line-height: 12px;
                          letter-spacing: 0.9px;
                        "
                      >
                        {{
                          noncashquiet ? noncashquiet : 0
                        }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="5">
                      <p
                        class="caption"
                        style="
                          color: #696969;
                          letter-spacing: 0.16em;
                          text-transform: uppercase;
                        "
                      >
                        Total stocks
                      </p>
                    </v-col>
                    <v-col cols="7" class="text-right">
                      <p
                        class="mb-0"
                        style="
                          color: #000;
                          font-size: 13px;
                          font-weight: 469;
                          line-height: 12px;
                          letter-spacing: 0.9px;
                        "
                      >
                        {{
                          holdsdata.no_of_stocks ? holdsdata.no_of_stocks : 0
                        }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="5">
                      <p
                        class="caption"
                        style="
                          color: #696969;
                          letter-spacing: 0.16em;
                          text-transform: uppercase;
                        "
                      >
                        Pledged stocks
                      </p>
                    </v-col>
                    <v-col cols="7" class="text-right">
                      <p
                        class="mb-0"
                        style="
                          color: #000;
                          font-size: 13px;
                          font-weight: 469;
                          line-height: 12px;
                          letter-spacing: 0.9px;
                        "
                      >
                        {{
                         totalpledgedlen ? totalpledgedlen : 0
                        }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="5">
                      <p
                        class="caption"
                        style="
                          color: #696969;
                          letter-spacing: 0.16em;
                          text-transform: uppercase;
                        "
                      >
                        Non-approved stocks
                      </p>
                    </v-col>
                    <v-col cols="7" class="text-right">
                      <p
                        class="mb-0"
                        style="
                          color: #000;
                          font-size: 13px;
                          font-weight: 469;
                          line-height: 12px;
                          letter-spacing: 0.9px;
                        "
                      >
                        {{
                          holdsdata.no_of_non_approved_stocks
                            ? holdsdata.no_of_non_approved_stocks
                            : 0
                        }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-card>

          <v-card
            class="d-md-none pb-2"
            style="
              position: fixed !important;
              bottom: 0 !important;
              z-index: 6 !important;
              background-color: white !important;
              width: 100% !important;
              right: 0px !important;
            "
            v-if="sndplgdata.length > 0 || sndunplgdata.length > 0"
            width="100%"
          >
            <p class="mb-0 subtitle-1" style="text-align: center">
              You've Chosen The Scrip For Margin
              {{
                sndplgdata.length > 0
                  ? "Pledge"
                  : sndunplgdata.length > 0
                  ? "Unpledge"
                  : ""
              }}
            </p>
            <v-row>
              <v-col cols="6" class="d-flex flex-row-reverse">
                <v-btn
                  block
                  class="text-none elevation-0"
                  :disabled="tableloader"
                  rounded
                  color="black"
                  outlined
                  dark
                  style="font-size: 12px"
                  @click="cancleAll()"
                >
                  Cancel
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  block
                  class="text-none elevation-0"
                  rounded
                  color="black"
                  dark
                  style="font-size: 12px"
                  @click="confirm()"
                  :loading="deploybtn"
                  :disabled="tableloader"
                >
                  Sumbit Request
                  <!-- {{
                      sndplgdata.length > 0
                      ? "Pledge"
                      : sndunplgdata.length > 0
                        ? "Unpledge"
                        : ""
                    }} -->
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-row class="d-md-none mb-4">
            <v-col cols="12">
              <v-select
                :items="['All', 'Free Stocks', 'Pledged Stocks', 'Unapproved', 'History']"
                @change="setFilter()"
                repend-inner-icon="mdi-magnify"
                v-model="filter"
                append-icon="mdi-chevron-down"
                class="tophundraedmutual searchfundlabel"
                style="font-size: 12px"
                background-color="#F1F3F8"
                solo
                rounded
                flat
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-if="
                  filter == 'All' ||
                  filter == 'Free Stocks' ||
                  filter == 'Pledged Stocks' ||
                  filter == 'Unapproved'
                "
                prepend-inner-icon="mdi-magnify"
                v-model="datasearchall"
                class="tophundraedmutual searchfundlabel"
                style="font-size: 12px"
                background-color="#F1F3F8"
                label="Search"
                solo
                rounded
                flat
                dense
                hide-details
              >
              </v-text-field>
              <v-text-field
                v-else-if="histtab == 0"
                prepend-inner-icon="mdi-magnify"
                v-model="datasearchall1"
                class="tophundraedmutual searchfundlabel"
                style="font-size: 12px"
                background-color="#F1F3F8"
                label="Search"
                solo
                rounded
                flat
                dense
                hide-details
              >
              </v-text-field>
              <v-text-field
                v-else-if="histtab == 1"
                prepend-inner-icon="mdi-magnify"
                v-model="datasearchall2"
                class="tophundraedmutual searchfundlabel"
                style="font-size: 12px"
                background-color="#F1F3F8"
                label="Search"
                solo
                rounded
                flat
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>

          <div class="d-none d-md-block" v-if="filter == 'History'">
            <v-tabs v-model="histtab" color="black">
              <v-tab>Pledge History</v-tab>
              <v-tab>UnPledge History</v-tab>
              <v-tab-item>
                <div>
                  <v-data-table
                    :loading="pledgeload"
                    :search="historydata"
                    fixed-header
                    disable-sort
                    style="border: 1px solid #efeef3"
                    class="elevation-0 rounded-lg"
                    :headers="cdslheder"
                    :items="cdsldata"
                    height="335px"
                    single-expand
                    show-expand
                    :expanded.sync="expanded1"
                    item-key="dat_tim"
                  >
                    <template v-slot:[`item.status`]="{ item }">
                      <span v-if="item.status == 'requested'"
                        ><v-chip
                          label
                          small
                          style="
                            border: 1px solid #ffb038;
                            background-color: #f6f6c5;
                          "
                          ><span style="color: #ffb038">Requested</span></v-chip
                        ></span
                      >
                      <span v-else-if="item.status == 'completed'"
                        ><v-chip
                          label
                          small
                          style="border: 1px solid #c1e7ba; background: #ecf8f1"
                          ><span style="color: #43a833">Completed</span></v-chip
                        ></span
                      >

                      <span v-else
                        ><v-chip
                          label
                          small
                          style="border: 1px solid #c1e7ba; background: #ecf8f1"
                          ><span style="color: #43a833">{{
                            item.status
                          }}</span></v-chip
                        ></span
                      >
                    </template>
                    <template v-slot:no-data>
                      <v-col class="mx-auto mt-16">
                        <img
                          alt=""
                          class="shrink"
                          src="../../../assets/nodata.svg"
                          width="15%"
                          height=""
                        />
                        <p class="font-weight-bold black--text">
                          No data available
                        </p>
                      </v-col>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length" class="pl-0 pr-0">
                        <div v-if="item.status == 'completed'">
                          <v-data-table
                            dense
                            :headers="headersreqfin"
                            :items="item.newsata"
                            fixed-header
                            disable-sort
                            :items-per-page="10"
                            class="elevation-1"
                          >
                            <template v-slot:[`item.status`]="{ item }">
                              <v-tooltip color="black" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <span
                                    v-bind="attrs"
                                    v-on="on"
                                    v-if="item.status == '1'"
                                    ><v-chip
                                      label
                                      small
                                      style="
                                        border: 1px solid #ffcdcd;
                                        background-color: #fcf3f3;
                                      "
                                      ><span style="color: #e66258"
                                        >Rejected</span
                                      ></v-chip
                                    ></span
                                  >
                                  <span
                                    v-bind="attrs"
                                    v-on="on"
                                    v-else-if="item.status == '0'"
                                    ><v-chip
                                      label
                                      small
                                      style="
                                        border: 1px solid #c1e7ba;
                                        background: #ecf8f1;
                                      "
                                      ><span style="color: #43a833"
                                        >Success</span
                                      ></v-chip
                                    ></span
                                  >

                                  <span v-bind="attrs" v-on="on" v-else
                                    ><v-chip
                                      label
                                      small
                                      style="
                                        border: 1px solid #c1e7ba;
                                        background: #ecf8f1;
                                      "
                                      ><span style="color: #43a833">{{
                                        item.status
                                      }}</span></v-chip
                                    ></span
                                  >
                                </template>
                                <span>{{ item.reserrmsg }}</span>
                              </v-tooltip>
                            </template>
                          </v-data-table>
                        </div>
                        <div v-else>
                          <v-data-table
                            dense
                            :headers="headersreqfin"
                            :items="item.final"
                            fixed-header
                            disable-sort
                            :items-per-page="5"
                            class="elevation-1"
                          >
                            <template v-slot:[`item.status`]="{ item }">
                              <span v-if="item.status == '1'"
                                ><v-chip
                                  label
                                  small
                                  style="
                                    border: 1px solid #ffcdcd;
                                    background-color: #fcf3f3;
                                  "
                                  ><span style="color: #e66258"
                                    >Rejected</span
                                  ></v-chip
                                >
                              </span>

                              <span v-else-if="item.status == '0'"
                                ><v-chip
                                  label
                                  small
                                  style="
                                    border: 1px solid #c1e7ba;
                                    background: #ecf8f1;
                                  "
                                  ><span style="color: #43a833"
                                    >Success</span
                                  ></v-chip
                                ></span
                              >

                              <span v-else
                                ><v-chip
                                  label
                                  small
                                  style="
                                    border: 1px solid #c1e7ba;
                                    background: #ecf8f1;
                                  "
                                  ><span style="color: #43a833">{{
                                    item.status
                                  }}</span></v-chip
                                ></span
                              >
                            </template>
                          </v-data-table>
                        </div>
                      </td>
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div>
                  <v-data-table
                    :loading="unpladgeload"
                    :search="unpledgehis"
                    fixed-header
                    disable-sort
                    style="border: 1px solid #efeef3"
                    class="elevation-0 rounded-lg"
                    :headers="unpledge"
                    :items="unpledgehistory"
                    height="335px"
                  >
                    <template v-slot:[`item.status`]="{ item }">
                      <div v-if="item.status == 'Approved'">
                        <v-chip
                          label
                          small
                          style="border: 1px solid #c1e7ba; background: #ecf8f1"
                          ><span style="color: #43a833">{{
                            item.status
                          }}</span></v-chip
                        >
                      </div>
                      <div v-else>
                        <v-chip
                          label
                          small
                          style="
                            border: 1px solid #ffcdcd;
                            background-color: #fcf3f3;
                          "
                          ><span style="color: #e66258">{{
                            item.status
                          }}</span></v-chip
                        >
                      </div>
                    </template>
                    <template v-slot:no-data>
                      <v-col class="mx-auto mt-16">
                        <img
                          alt=""
                          class="shrink"
                          src="../../../assets/nodata.svg"
                          width="15%"
                          height=""
                        />
                        <p class="font-weight-bold black--text">
                          No data available
                        </p>
                      </v-col>
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item>
            </v-tabs>
          </div>

          <div v-else>
            <v-data-table
              mobile-breakpoint
              :loading="tableloader"
              :items-per-page="-1"
              :headers="alldatahead"
              :items="tabledata"
              height="400px"
              filterdata
              notapp
              fixed-header
              :search="datasearchall"
              disable-sort
              hide-default-footer
              style="border: 1px solid #efeef3"
              class="d-none d-md-block elevation-0 rounded-lg"
            >
              <template v-slot:[`item.NSE_SYMBOL`]="{ item }">
                <span>
                  {{ item.NSE_SYMBOL }}
                  <!-- 
              <span v-for="(j, l) in item.cash_eq_coll" :key="l">
                <v-chip color="indigo"   elevation="0" class="pa-1 mr-1 "
              x-small v-if="j === 'True'"><span style="color:white" class="caption font-weight-light">Cash EQ </span>     </v-chip>
              {{ l === 'cd_cash_eq' ? 'Currencies' : l === 'com_cash_eq' ? 'Commodities' : l === 'fo_cash_eq' ? 'F & O' : '' }} 
              </div>
            </span> -->
          
            <v-chip   v-if="item.COLQTY != '0.000' && Object.keys(item.cash_eq_coll).length > 0 "
                          small
                          :style="{background : setExch(item.cash_eq_coll) == true ? '#216DC2' : 'teal' }"  elevation="0" class="pa-2 ml-2 mr-1 "
             ><span  :style="{color: setExch(item.cash_eq_coll) == true ? '#ffffff' : '#ffffff' }" class="captionml-2 font-weight-light">  {{setExch(item.cash_eq_coll) == true ? 'Cash' : "Non Cash"}} </span>     </v-chip>
            
        
                </span>

                <div v-if="item.Status == 'Not_ok'">
                  <v-chip x-small color="#FCF3F3" class="pa-1">
                    <span style="color: #ff1717; font-size: 10px"
                      >Non - Approved</span
                    >
                  </v-chip>
                </div>
              </template>
              <template v-slot:[`item.NET`]="{ item }">
                <span class="secondtext">
                  {{ item.NET ? parseInt(item.NET) : "0" }}
                  <span v-if="item.NET_QTY_AFTR_LIMITS != 'nan'">
                    /
                    {{
                      item.NET_QTY_AFTR_LIMITS
                        ? parseInt(item.NET_QTY_AFTR_LIMITS)
                        : "0"
                    }}
                  </span></span
                >
              </template>

              <template v-slot:[`item.pleged`]="{ item }">
                <v-chip
                  v-if="
                    filter != 'Unpledge' &&
                    item.Status != 'Not_ok' &&
                    item.plege_qty > 0
                  "
                  :disabled="sndunplgdata.length > 0"
                  class="pa-1"
                  small
                  label
                  color="#EBF2FF"
                  style="background-color: #fff8e1; border: 1px solid #ffc107"
                >
                  <span class="ml-2" style="color: #ffc107"
                    >{{ item.plege_qty ? item.plege_qty : 0 }}/
                    {{ item.unpl ? item.unpl : 0 }}</span
                  >
                  <v-icon
                    @click="setPledge(item, 'p')"
                    class="ml-2"
                    size="12"
                    color="#FFC107"
                    >mdi-pencil</v-icon
                  >
                  <v-icon
                    @click="retPledge(item, 'p')"
                    class="ml-2"
                    size="12"
                    color="#FFC107"
                    >mdi-close</v-icon
                  >
                </v-chip>

                <v-chip
                  @click="setPledge(item, 'p')"
                  :disabled="sndunplgdata.length > 0 || item.initiated != '0'"
                  v-else-if="
                    filter != 'Unpledge' &&
                    Math.trunc(item.ch) != '0' &&
                    item.Status != 'Not_ok' &&
                    item.unpl > 0
                  "
                  class="pa-1"
                  small
                  label
                  color="#EBF2FF"
                  style="background-color: #e3e9f6; border: 1px solid #2f6ad9"
                >
                  <span class="ml-2" style="color: #2f6ad9">
                    {{ item.unpl ? item.unpl : 0 }}</span
                  >
                  <span
                    class="ml-2"
                    style="color: #2f6ad9"
                    v-if="item.initiated != '0'"
                  >
                    / {{ item.initiated }}</span
                  >
                  <v-icon class="ml-2" size="12" color="#2f6ad9"
                    >mdi-plus</v-icon
                  >
                </v-chip>
              </template>
              <!-- <template v-slot:[`item.unpleged`]="{ item }">
                            <v-chip v-if="filter != 'pledge' && item.Status != 'Not_ok' && item.un_plege_qty > 0"
                                class="pa-1" small label color="#EBF2FF" style="
                        background-color: #FBE9E7;
                        border: 1px solid #e66258;
                      ">
                                <span class="ml-2" style="color: #e66258">
                                    {{
                                        item.un_plege_qty ? item.un_plege_qty : 0
                                    }}/{{
    item.COLQTY ? item.COLQTY : 0
  }}</span>
                                <v-icon @click="setPledge(item, 'u')" class="ml-2" size="12"
                                    color="#e66258">mdi-pencil</v-icon>
                                <v-icon @click="retPledge(item, 'u')" class="ml-2" size="12"
                                    color="#e66258">mdi-close</v-icon>
                            </v-chip>
                        </template> -->
              <!-- <template v-slot:[`item.unpleged`]="{ item }">
                            <v-chip @click="retPledge(item, 'u')"
                                v-if="filter != 'pledge' && item.Status != 'Not_ok' && item.un_plege_qty > 0" class="pa-1"
                                small label color="#EBF2FF" style="
                        background-color: #FBE9E7;
                        border: 1px solid #FF5722;
                      ">
                                <span class="ml-2" style="color: #FF5722">
                                    {{
                                        item.un_plege_qty ? item.un_plege_qty : 0
                                    }}</span>
                                <v-icon class="ml-2" size="12" color="#FF5722">mdi-close</v-icon>
                            </v-chip>
                        </template> -->
              <!-- ========================================================================================================================================== -->
              <template v-slot:[`item.COLQTY`]="{ item }">
                <div v-if="Number(item.COLQTY) > 0">
                  <span class="secondtext"
                    >{{ Number(item.COLQTY).toFixed(0) }}
                  </span>
                  shares pledged
                  <v-btn
                    :disabled="sndplgdata.length > 0"
                    v-if="filter != 'Pledge' && item.un_plege_qty < 1"
                    @click="setPledge(item, 'u')"
                    small
                    dense
                    class="text-none text-weight-bold px-0"
                    color="#e66258"
                    text
                    >Unpledge</v-btn
                  >
                  <v-chip
                    :disabled="sndplgdata.length > 0"
                    v-if="filter != 'pledge' && item.un_plege_qty > 0"
                    class="pa-1"
                    small
                    label
                    color="#EBF2FF"
                    style="background-color: #fbe9e7; border: 1px solid #e66258"
                  >
                    <span class="ml-2" style="color: #e66258">
                      {{ item.un_plege_qty ? item.un_plege_qty : 0 }}/{{
                        item.COLQTY ? item.COLQTY : 0
                      }}</span
                    >
                    <v-icon
                      @click="setPledge(item, 'u')"
                      class="ml-2"
                      size="12"
                      color="#e66258"
                      >mdi-pencil</v-icon
                    >
                    <v-icon
                      @click="retPledge(item, 'u')"
                      class="ml-2"
                      size="12"
                      color="#e66258"
                      >mdi-close</v-icon
                    >
                  </v-chip>
                </div>
              </template>

              <!-- ========================================================================================================================================== -->

              <!-- <template v-slot:[`item.Haircut`]="{ item }">
                            <span class="secondtext">
                                {{ Math.trunc(item.Haircut) }} %</span>
                        </template> -->
              <template v-slot:[`item.est_percentage`]="{ item }">
                <span class="secondtext">
                  {{ Math.trunc(item.est_percentage) }} %</span
                >
              </template>

              <template v-slot:[`item.estimated`]="{ item }">
                <span class="secondtext">
                  {{
                    Number(item.margin) > 0 && Number(item.estimated) > 0
                      ? `${Number(item.margin).toFixed(2)} / ${Number(
                          item.estimated
                        ).toFixed(2)}`
                      : Number(item.margin) > 0
                      ? `${Number(item.margin).toFixed(2)} (Mrg)`
                      : `${Number(item.estimated).toFixed(2)} (Est)`
                  }}</span
                >
              </template>
              <template v-slot:[`item.AMOUNT`]="{ item }">
                <span class="secondtext">
                  {{ Number(item.AMOUNT).toFixed(2) }}</span
                >
              </template>
              <template v-slot:no-data>
                <v-col class="mx-auto mt-16">
                  <img
                    alt=""
                    class="shrink"
                    src="../../../assets/nodata.svg"
                    width="15%"
                    height=""
                  />
                  <p class="font-weight-bold black--text">No data available</p>
                </v-col>
              </template>
            </v-data-table>
            <!-- <p>{{ ststs }} decryptedData</p> -->
          </div>
          <div class="d-md-none">
            <!-- <div v-for="(p, i) in tabledata" :key="l">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Two-line item</v-list-item-title>
                                <v-list-item-subtitle>Secondary text</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item> -->

            <div
              v-if="
                filter == 'All' ||
                filter == 'Free Stocks' ||
                filter == 'Pledged Stocks' ||
                filter == 'Unapproved'
              "
            >
              <v-card
                v-if="tabledata.length == 0"
                width="100%"
                class="text-center elevation-0"
                ><v-col class="mx-auto">
                  <img
                    alt=""
                    class="shrink"
                    src="../../../assets/nodata.svg"
                    width="35%"
                    height=""
                  />
                  <p class="font-weight-bold black--text">No data available</p>
                </v-col>
              </v-card>
              <div v-else>
                <v-card
                  v-for="(p, i) in MobSearchFilterItem"
                  :key="i"
                  style="border-top: 5px #f1f3f8 solid"
                  class="elevation-0"
                  width="100%"
                >
                  <v-toolbar
                    class="toolbar-contantd px-0"
                    dense
                    elevation="0"
                    color="white"
                  >
                    <p
                      class="text-start pa-2 mb-0"
                      style="color: #000000; font-size: 15px; font-weight: 459"
                    >
                      {{ p.NSE_SYMBOL }} |
                      <span class="caption grey--text">Qty : </span>
                      {{ p.NET ? parseInt(p.NET) : "0" }} 
                      <span v-if="p.NET_QTY_AFTR_LIMITS != 'nan'">
                    /
                    {{
                      p.NET_QTY_AFTR_LIMITS
                        ? parseInt(p.NET_QTY_AFTR_LIMITS)
                        : "0"
                    }}

<v-chip   v-if="p.COLQTY != '0.000'"
                          small
                          :style="{background : setExch(p.cash_eq_coll) == true ? '#216DC2' : 'teal' }"  elevation="0" class="pa-2 ml-2 mr-1 "
             ><span  :style="{color: setExch(p.cash_eq_coll) == true ? '#ffffff' : '#ffffff' }" class="captionml-2 font-weight-light">  {{setExch(p.cash_eq_coll) == true ? 'Cash' : "Non Cash"}} </span>     </v-chip>


                  </span>
                    </p>
                    <v-spacer></v-spacer>
                    <p
                      class="text-start pa-2 mb-0"
                      style="color: #000000; font-size: 15px; font-weight: 459"
                    >
                      <span class="caption grey--text">Value : </span>
                      {{ Number(p.AMOUNT).toFixed(2) }}
                    </p>
                  </v-toolbar>
                  <v-divider class="mb-2"></v-divider>
                  <v-row class="px-2">
                    <v-col cols="5">
                      <!-- <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Total Qty : 
                                        </p> -->
                      <!-- <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Value : 
                                        </p> -->
                      <p
                        class="mb-1 body-2 font-weight-regular"
                        style="color: #666666"
                      >
                        Qty to pledge :
                      </p>
                      <p
                        class="mb-1 body-2 font-weight-regular"
                        style="color: #666666"
                      >
                        Mrg / Est :
                      </p>
                      <p
                        class="mb-1 body-2 font-weight-regular"
                        style="color: #666666"
                      >
                        Est % :
                      </p>
                      <!-- <p class="mb-3 body-2 font-weight-regular" style="color: #666666">
                          Pledged shares :
                        </p> -->
                    </v-col>
                    <v-col cols="7" class="text-end">
                      <!-- <p class="mb-1 body-2 font-weight-regular" style="color: #666666;"><span
                                                class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ p.NET ?
                                                    parseInt(p.NET) : '0'
                                                }}</span>
                                        </p> -->
                      <!-- <p class="mb-1 body-2 font-weight-regular" style="color: #666666;"><span
                                                class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                    Number(p.AMOUNT).toFixed(2) }}</span>
                                        </p> -->
                      <p
                        class="mb-1 body-2 font-weight-regular"
                        style="color: #666666"
                      >
                        <v-chip
                          v-if="
                            filter != 'Unpledge' &&
                            p.Status != 'Not_ok' &&
                            p.plege_qty > 0
                          "
                          :disabled="sndunplgdata.length > 0"
                          class="pa-1"
                          small
                          label
                          color="#EBF2FF"
                          style="
                            background-color: #fff8e1;
                            border: 1px solid #ffc107;
                          "
                        >
                          <span class="ml-2" style="color: #ffc107"
                            >{{ p.plege_qty ? p.plege_qty : 0 }}/
                            {{ p.unpl ? p.unpl : 0 }}</span
                          >
                          <v-icon
                            @click="setPledge(p, 'p')"
                            class="ml-2"
                            size="12"
                            color="#FFC107"
                            >mdi-pencil</v-icon
                          >
                          <v-icon
                            @click="retPledge(p, 'p')"
                            class="ml-2"
                            size="12"
                            color="#FFC107"
                            >mdi-close</v-icon
                          >
                        </v-chip>

                        <v-chip
                          @click="setPledge(p, 'p')"
                          :disabled="
                            sndunplgdata.length > 0 || p.initiated != '0'
                          "
                          v-else-if="
                            filter != 'Unpledge' &&
                            Math.trunc(p.ch) != '0' &&
                            p.Status != 'Not_ok' &&
                            p.unpl > 0
                          "
                          class="pa-1"
                          small
                          label
                          color="#EBF2FF"
                          style="
                            background-color: #e3e9f6;
                            border: 1px solid #2f6ad9;
                          "
                        >
                          <span class="ml-2" style="color: #2f6ad9">
                            {{ p.unpl ? p.unpl : 0 }}</span
                          >
                          <span
                            class="ml-2"
                            style="color: #2f6ad9"
                            v-if="p.initiated != '0'"
                          >
                            / {{ p.initiated }}</span
                          >
                          <v-icon class="ml-2" size="12" color="#2f6ad9"
                            >mdi-plus</v-icon
                          >
                        </v-chip>

                        <span v-else>-</span>
                      </p>
                      <p
                        class="mb-1 body-2 font-weight-regular"
                        style="color: #666666"
                      >
                        <span
                          class="mb-0 body-2 font-weight-regular"
                          style="color: #000000"
                          >{{
                            Number(p.margin) > 0 && Number(p.estimated) > 0
                              ? `${Number(p.margin).toFixed(2)} / ${Number(
                                  p.estimated
                                ).toFixed(2)}`
                              : Number(p.margin) > 0
                              ? `${Number(p.margin).toFixed(2)} (Mrg)`
                              : `${Number(p.estimated).toFixed(2)} (Est)`
                          }}
                        </span>
                      </p>
                      <p
                        class="mb-1 body-2 font-weight-regular"
                        style="color: #666666"
                      >
                        <span
                          class="mb-0 body-2 font-weight-regular"
                          style="color: #000000"
                          >{{ Math.trunc(p.est_percentage) }}%</span
                        >
                      </p>
                      <p
                        v-if="Number(p.COLQTY) > 0"
                        class="mb-3 body-2 font-weight-regular"
                        style="color: #666666"
                      >
                        <span class="secondtext"
                          >{{ Number(p.COLQTY).toFixed(0) }}
                        </span>
                        shares pledged
                        <v-btn
                          :disabled="sndplgdata.length > 0"
                          v-if="filter != 'Pledge' && p.un_plege_qty < 1"
                          @click="setPledge(p, 'u')"
                          small
                          dense
                          class="text-none text-weight-bold px-0"
                          color="#e66258"
                          text
                          >Unpledge</v-btn
                        >
                        <v-chip
                          :disabled="sndplgdata.length > 0"
                          v-if="filter != 'pledge' && p.un_plege_qty > 0"
                          class="pa-1"
                          small
                          label
                          color="#EBF2FF"
                          style="
                            background-color: #fbe9e7;
                            border: 1px solid #e66258;
                          "
                        >
                          <span class="ml-2" style="color: #e66258">
                            {{ p.un_plege_qty ? p.un_plege_qty : 0 }}/{{
                              p.COLQTY ? p.COLQTY : 0
                            }}</span
                          >
                          <v-icon
                            @click="setPledge(p, 'u')"
                            class="ml-2"
                            size="12"
                            color="#e66258"
                            >mdi-pencil</v-icon
                          >
                          <v-icon
                            @click="retPledge(p, 'u')"
                            class="ml-2"
                            size="12"
                            color="#e66258"
                            >mdi-close</v-icon
                          >
                        </v-chip>
                      </p>
                      <p
                        v-else
                        class="mb-3 body-2 font-weight-regular"
                        style="color: #666666"
                      >
                        <span>-</span>
                      </p>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </div>

            <div v-else-if="filter == 'History'">
              <v-tabs fixed-tabs v-model="histtab" color="black">
                <v-tab>Pledge History</v-tab>
                <v-tab>UnPledge History</v-tab>
                <v-tab-item>
                  <div v-if="cdsldata.length == 0">
                    <v-card width="100%" class="text-center elevation-0"
                      ><v-col class="mx-auto">
                        <img
                          alt=""
                          class="shrink"
                          src="../../../assets/nodata.svg"
                          width="35%"
                          height=""
                        />
                        <p class="font-weight-bold black--text">
                          No data available
                        </p>
                      </v-col>
                    </v-card>
                  </div>
                  <div v-else>
                    <v-expansion-panels inset style="z-index: 0">
                      <v-expansion-panel
                        v-for="(item, i) in MobSearchFilterItem1"
                        :key="i"
                      >
                        <v-expansion-panel-header
                          >{{ item.reqid }} |
                          <span>{{ item.dat_tim }}</span>
                          <span v-if="item.status == 'requested'"
                            ><v-chip
                              label
                              small
                              style="
                                border: 1px solid #ffb038;
                                background-color: #f6f6c5;
                              "
                              ><span style="color: #ffb038"
                                >Requested</span
                              ></v-chip
                            ></span
                          >
                          <span v-else-if="item.status == 'completed'"
                            ><v-chip
                              label
                              small
                              style="
                                border: 1px solid #c1e7ba;
                                background: #ecf8f1;
                              "
                              ><span style="color: #43a833"
                                >Completed</span
                              ></v-chip
                            ></span
                          >

                          <span v-else
                            ><v-chip
                              label
                              small
                              style="
                                border: 1px solid #c1e7ba;
                                background: #ecf8f1;
                              "
                              ><span style="color: #43a833">{{
                                item.status
                              }}</span></v-chip
                            ></span
                          >
                        </v-expansion-panel-header>
                        <div v-if="item.status == 'completed'">
                          <v-expansion-panel-content>
                            <v-card
                              v-for="iitem in item.newsata"
                              :key="iitem.index"
                              style="border-top: 5px #f1f3f8 solid"
                              class="elevation-0 px-4 pt-2"
                              width="100%"
                            >
                              <span class="font-weight-bold">
                                {{ iitem.isin }}
                              </span>
                              <v-divider class="mb-2 mt-2"></v-divider>
                              <v-row class="px-2">
                                <v-col>
                                  <p
                                    class="mb-1 body-2 font-weight-regular"
                                    style="color: #666666"
                                  >
                                    Script
                                  </p>
                                  <p
                                    class="mb-1 body-2 font-weight-regular"
                                    style="color: #666666"
                                  >
                                    Script Sequence
                                  </p>
                                  <p
                                    class="mb-1 body-2 font-weight-regular"
                                    style="color: #666666"
                                  >
                                    Qty:
                                    <span
                                      class="mb-0 body-2 font-weight-regular"
                                      style="color: #000000"
                                      >{{ iitem.BUY_QTY }}</span
                                    >
                                  </p>
                                  <p
                                    class="mb-3 body-2 font-weight-regular"
                                    style="color: #666666"
                                  >
                                    Status
                                  </p>
                                </v-col>
                                <v-col class="text-end">
                                  <p
                                    class="mb-0 body-2 font-weight-regular"
                                    style="color: #666666"
                                  >
                                    {{ iitem.script }}
                                  </p>
                                  <p
                                    class="mb-0 body-2 font-weight-regular"
                                    style="color: #666666"
                                  >
                                    {{ iitem.isinreqid }}
                                  </p>
                                  <p
                                    class="mb-0 body-2 font-weight-regular"
                                    style="color: #666666"
                                  >
                                    {{ iitem.quantity }}
                                  </p>
                                  <div>
                                    <v-tooltip color="black" bottom>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <span
                                          v-bind="attrs"
                                          v-on="on"
                                          v-if="iitem.status == '1'"
                                          ><v-chip
                                            label
                                            small
                                            style="
                                              border: 1px solid #ffcdcd;
                                              background-color: #fcf3f3;
                                            "
                                            ><span style="color: #e66258"
                                              >Rejected</span
                                            ></v-chip
                                          ></span
                                        >
                                        <span
                                          v-bind="attrs"
                                          v-on="on"
                                          v-else-if="iitem.status == '0'"
                                          ><v-chip
                                            label
                                            small
                                            style="
                                              border: 1px solid #c1e7ba;
                                              background: #ecf8f1;
                                            "
                                            ><span style="color: #43a833"
                                              >Success</span
                                            ></v-chip
                                          ></span
                                        >

                                        <span v-bind="attrs" v-on="on" v-else
                                          ><v-chip
                                            label
                                            small
                                            style="
                                              border: 1px solid #c1e7ba;
                                              background: #ecf8f1;
                                            "
                                            ><span style="color: #43a833">{{
                                              iitem.status
                                            }}</span></v-chip
                                          ></span
                                        >
                                      </template>
                                      <span>{{ iitem.reserrmsg }}</span>
                                    </v-tooltip>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-expansion-panel-content>
                        </div>
                        <div v-else>
                          <v-expansion-panel-content>
                            <v-card
                              v-for="iitem in item.final"
                              :key="iitem.index"
                              style="border-top: 5px #f1f3f8 solid"
                              class="elevation-0 px-4 pt-2"
                              width="100%"
                            >
                              <span class="font-weight-bold">
                                {{ iitem.isin }}
                              </span>
                              <v-divider class="mb-2 mt-2"></v-divider>
                              <v-row class="px-2">
                                <v-col>
                                  <p
                                    class="mb-1 body-2 font-weight-regular"
                                    style="color: #666666"
                                  >
                                    Script
                                  </p>
                                  <p
                                    class="mb-1 body-2 font-weight-regular"
                                    style="color: #666666"
                                  >
                                    Script Sequence
                                  </p>
                                  <p
                                    class="mb-1 body-2 font-weight-regular"
                                    style="color: #666666"
                                  >
                                    Qty:
                                    <span
                                      class="mb-0 body-2 font-weight-regular"
                                      style="color: #000000"
                                      >{{ iitem.BUY_QTY }}</span
                                    >
                                  </p>
                                  <p
                                    class="mb-3 body-2 font-weight-regular"
                                    style="color: #666666"
                                  >
                                    Segment
                                  </p>
                                  <p
                                    class="mb-3 body-2 font-weight-regular"
                                    style="color: #666666"
                                  >
                                    Status
                                  </p>
                                </v-col>
                                <v-col class="text-end">
                                  <p
                                    class="mb-0 body-2 font-weight-regular"
                                    style="color: #666666"
                                  >
                                    {{ iitem.script }}
                                  </p>
                                  <p
                                    class="mb-0 body-2 font-weight-regular"
                                    style="color: #666666"
                                  >
                                    {{ iitem.isinreqid }}
                                  </p>
                                  <p
                                    class="mb-0 body-2 font-weight-regular"
                                    style="color: #666666"
                                  >
                                    {{ iitem.quantity }}
                                  </p>
                                  <p
                                    class="mb-0 body-2 font-weight-regular"
                                    style="color: #666666"
                                  >
                                    {{ iitem.segments }}
                                  </p>
                                  <div>
                                    <v-tooltip color="black" bottom>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <span
                                          v-bind="attrs"
                                          v-on="on"
                                          v-if="iitem.status == '1'"
                                          ><v-chip
                                            label
                                            small
                                            style="
                                              border: 1px solid #ffcdcd;
                                              background-color: #fcf3f3;
                                            "
                                            ><span style="color: #e66258"
                                              >Rejected</span
                                            ></v-chip
                                          ></span
                                        >
                                        <span
                                          v-bind="attrs"
                                          v-on="on"
                                          v-else-if="iitem.status == '0'"
                                          ><v-chip
                                            label
                                            small
                                            style="
                                              border: 1px solid #c1e7ba;
                                              background: #ecf8f1;
                                            "
                                            ><span style="color: #43a833"
                                              >Success</span
                                            ></v-chip
                                          ></span
                                        >

                                        <span v-bind="attrs" v-on="on" v-else
                                          ><v-chip
                                            label
                                            small
                                            style="
                                              border: 1px solid #c1e7ba;
                                              background: #ecf8f1;
                                            "
                                            ><span style="color: #43a833">{{
                                              iitem.status
                                            }}</span></v-chip
                                          ></span
                                        >
                                      </template>
                                      <span>{{ iitem.reserrmsg }}</span>
                                    </v-tooltip>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-expansion-panel-content>
                        </div>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </v-tab-item>
                <v-tab-item>
                  <div v-if="unpledgehistory.length == 0">
                    <v-card width="100%" class="text-center elevation-0"
                      ><v-col class="mx-auto">
                        <img
                          alt=""
                          class="shrink"
                          src="../../../assets/nodata.svg"
                          width="35%"
                          height=""
                        />
                        <p class="font-weight-bold black--text">
                          No data available
                        </p>
                      </v-col>
                    </v-card>
                  </div>
                  <div v-else>
                    <v-card
                      v-for="(p, i) in MobSearchFilterItem2"
                      :key="i"
                      style="border-top: 5px #f1f3f8 solid"
                      class="elevation-0"
                      width="100%"
                    >
                      <v-toolbar
                        class="toolbar-contantd px-0"
                        dense
                        elevation="0"
                        color="white"
                      >
                        <p
                          class="text-start pa-2 mb-0"
                          style="
                            color: #000000;
                            font-size: 15px;
                            font-weight: 459;
                          "
                        >
                          {{ p.script }} |
                          <span class="caption grey--text">Qty : </span>
                          {{ p.un_plege_qty }}
                        </p>
                        <v-spacer></v-spacer>
                        <div class="d-flex">
                          <p
                            class="mb-0 mt-1 mr-2"
                            style="color: #666666; font-size: 14px"
                          >
                            Status
                          </p>
                          <div
                            style="color: #000000; font-size: 14px"
                            class="mb-0 ml-2 ml-auto mt-1"
                          >
                            <div v-if="p.status == 'Approved'">
                              <v-chip
                                label
                                small
                                style="
                                  border: 1px solid #c1e7ba;
                                  background: #ecf8f1;
                                "
                                ><span style="color: #43a833">{{
                                  p.status
                                }}</span></v-chip
                              >
                            </div>
                            <div v-if="p.status == 'Rejected'">
                              <v-tooltip bottom color="black">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    label
                                    small
                                    style="
                                      border: 1px solid #ffcdcd;
                                      background-color: #fcf3f3;
                                    "
                                    ><span style="color: #e66258">{{
                                      p.status
                                    }}</span></v-chip
                                  >
                                </template>
                                <span> {{ p.reason }}</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </div>
                      </v-toolbar>
                      <v-divider class="mb-2"></v-divider>
                      <v-row class="px-2">
                        <v-col cols="5">
                          <!--              
                  <p
                    class="mb-1 body-2 font-weight-regular"
                    style="color: #666666"
                  >
                   Boid :
                  </p> -->
                          <p
                            class="mb-1 body-2 font-weight-regular"
                            style="color: #666666"
                          >
                            ISIN :
                          </p>
                          <p
                            class="mb-1 body-2 font-weight-regular"
                            style="color: #666666"
                          >
                            Requested date & time :
                          </p>
                          <p
                            class="mb-3 body-2 font-weight-regular"
                            style="color: #666666"
                          >
                            Approved date & time
                          </p>
                        </v-col>
                        <v-col cols="7" class="text-end">
                          <!-- <p class="mb-1 body-2 font-weight-regular" style="color: #666666;"><span
                                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ p.NET ?
                                                parseInt(p.NET) : '0'
                                            }}</span>
                                    </p> -->
                          <!-- <p class="mb-1 body-2 font-weight-regular" style="color: #666666;"><span
                                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                Number(p.AMOUNT).toFixed(2) }}</span>
                                    </p> -->
                          <!-- <p
                    class="mb-1 body-2 font-weight-regular"
                    style="color: #666666"
                  >
                  {{ p.BOID }}
                  </p> -->
                          <p
                            class="mb-1 body-2 font-weight-regular"
                            style="color: #666666"
                          >
                            <span
                              class="mb-1 body-2 font-weight-regular"
                              style="color: #666666"
                              >{{ p.ISIN }}
                            </span>
                          </p>
                          <p
                            class="mb-1 body-2 font-weight-regular"
                            style="color: #666666"
                          >
                            <span
                              class="mb-1 body-2 font-weight-regular"
                              style="color: #666666"
                              >{{ p.req_dat_time }}</span
                            >
                          </p>
                          <p
                            class="mb-1 body-2 font-weight-regular"
                            style="color: #666666"
                          >
                            <span class="secondtext"
                              >{{ p.app_dat_time }}
                            </span>
                          </p>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </v-tab-item>
              </v-tabs>
            </div>

            <v-progress-linear
              v-if="tableloader"
              indeterminate
            ></v-progress-linear>

            <!-- </div> -->
          </div>
          <!-- <v-footer class="ma-3" fixed padless> -->
          <v-card
            style="
              position: fixed !important;
              bottom: 0 !important;
              z-index: 6 !important;
              background-color: white !important;
              width: 100% !important;
              right: 0px !important;
            "
            v-if="sndplgdata.length > 0 || sndunplgdata.length > 0"
            width="100%"
            outlined
            class="rounded-lg pa-3 d-none d-md-flex"
          >
            <v-row>
              <v-col cols="6">
                <p class="mb-0 subtitle-1 font-weight-semibold">
                  You've Chosen The
                  <span class="font-weight-bold">{{
                    sndplgdata.length || sndunplgdata.length
                  }}</span>
                  Scrip For Margin
                  {{
                    sndplgdata.length > 0
                      ? "Pledge"
                      : sndunplgdata.length > 0
                      ? "Unpledge"
                      : ""
                  }}
                </p>
                <!-- <p class="mt-1 mb-0 caption grey--text">
                    Total No Of Stocks Selected :
                    <span class="black--text caption">
                      {{ sndplgdata.length || sndunplgdata.length }}</span>
                  </p> -->
                <!-- <p>delete {{ filteredData.length }}</p> -->

                <!-- <p>Add stock : {{  dellthhh.length - sndplgdata.length }}</p> -->
              </v-col>
              <v-col cols="6" class="d-flex flex-row-reverse">
                <v-btn
                  class="ml-3 text-none elevation-0"
                  rounded
                  color="black"
                  dark
                  style="font-size: 12px"
                  @click="confirm()"
                  :disabled="tableloader"
                >
                  Sumbit Request
                  <!-- {{
                      sndplgdata.length > 0
                      ? "Pledge"
                      : sndunplgdata.length > 0
                        ? "Unpledge"
                        : ""
                    }} -->
                </v-btn>
                <v-btn
                  class="ml-3 text-none elevation-0"
                  :disabled="tableloader"
                  rounded
                  color="black"
                  outlined
                  dark
                  style="font-size: 12px"
                  @click="cancleAll()"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <!-- </v-footer> -->
        </div>
        <v-dialog persistent v-model="showdialog" max-width="400">
          <v-card outlined class="pa-4 rounded-lg" elevation="0">
            <v-toolbar
              class="mb-1 toolbar-contant"
              elevation="0"
              dense
              color="white"
            >
              <p
                class="mb-0"
                style="
                  color: #000;
                  font-size: 18px;
                  font-weight: 559;
                  line-height: 100%;
                  letter-spacing: -0.36px;
                "
              >
                {{ doshowdata.sets == "p" ? "Pledge" : "Unpledge" }}
                Details
              </p>
              <v-btn
                class="ml-auto"
                :disable="deploybtn"
                icon
                @click="(showdialog = false), (doshowdata = [])"
                ><v-icon>mdi-close-circle-outline</v-icon></v-btn
              >
            </v-toolbar>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Symbol : </v-list-item-subtitle>
                <!-- <v-list-item-subtitle>Value : </v-list-item-subtitle> -->
                <v-list-item-subtitle
                  >Margine / Estimation :
                </v-list-item-subtitle>
                <!-- <v-list-item-subtitle>Estimation % : </v-list-item-subtitle> -->
                <v-list-item-subtitle>Total Qty : </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content class="font-weight-bold">
                <v-list-item-title>{{
                  doshowdata.NSE_SYMBOL
                }}</v-list-item-title>
                <!-- <v-list-item-title>{{ Number(doshowdata.AMOUNT).toFixed(2) }}</v-list-item-title> -->
                <v-list-item-title
                  >{{ Number(doshowdata.estimated).toFixed(2) }} ({{
                    doshowdata.est_percentage
                  }}
                  %)</v-list-item-title
                >
                <!-- <v-list-item-title>{{ doshowdata.est_percentage }} %</v-list-item-title> -->
                <v-list-item-title>{{
                  parseInt(doshowdata.NET)
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-row class="px-4 mb-3">
              <!-- <v-col cols="5">
                                <v-list-item-subtitle class="mb-1">Total Qty</v-list-item-subtitle>
                                <v-text-field background-color="#ECEFF1" disabled type="number"
                                    :value="parseInt(doshowdata.NET)" hide-spin-buttons flat solo hide-details>
                                </v-text-field>
                            </v-col> -->
              <v-col cols="12">
                <v-list-item-subtitle class="mb-1"
                  >{{ doshowdata.sets == "p" ? "Pledge" : "Unpledge" }} Qty up
                  to
                  <b>{{
                    doshowdata.sets == "p" ? doshowdata.unpl : doshowdata.COLQTY
                  }}</b></v-list-item-subtitle
                >
                <!-- <p>test{{  doshowdata.sets == 'p' ? typeof doshowdata.unpl : typeof doshowdata.COLQTY}} test{{  doshowdata.sets == 'p' ?  doshowdata.unpl :  doshowdata.COLQTY}}</p> -->
                <v-text-field
                  background-color="#F1F3F8"
                  @keyup.enter="setBfodeploy()"
                  :placeholder="
                    doshowdata.sets == 'p' ? doshowdata.unpl : doshowdata.COLQTY
                  "
                  type="number"
                  :max="
                    doshowdata.sets == 'p' ? doshowdata.unpl : doshowdata.COLQTY
                  "
                  min="0"
                  v-model="enterval"
                  flat
                  solo
                  hide-details
                >
                </v-text-field>
                <!-- <b>{{
                    doshowdata.sets == "p" ? doshowdata.eligible_segments : ""
                  }}</b> -->

                <div v-if="doshowdata.eligible_segments != ''">
                  <v-card
                    v-if="doshowdata.sets == 'p'"
                    class="pa-2 mb-2 mt-3 rounded-lg"
                    elevation="0"
                    style="background-color: #fcefd4; border: 1px solid #fae3b4"
                  >
                    <div>
                      <p
                        class="mb-0 ml-auto"
                        style="color: #000; font-size: 10px"
                      >
                        <span class="mb-0" style="color: #000; font-size: 10px"
                          >Note:</span
                        >

                        Please ensure that you submit separate pledge requests
                        for MTF and other segments (FO, CD, and Commodities).
                        Combining pledges for MTF and other segments is not
                        permitted. However, combining pledges for FO, CD, and
                        Commodities segments is allowed.
                      </p>
                      <!-- <p class="mb-0 caption ml-auto" style="color: #000">
                      Thank you for choosing Zebu.
                    </p> -->
                    </div>
                  </v-card>

                  <div v-if="doshowdata.sets == 'p'">
                    <span class="caption"
                      >Which segment do you want to pledge the stocks</span
                    >
                  </div>
                  <v-select
                    @change="segmentmtf"
                    class="mt-1"
                    v-if="doshowdata.sets == 'p'"
                    background-color="#F1F3F8"
                    label="Select Segment"
                    item-disabled="show"
                    item-text="txt"
                    :items="newsss"
                    min="0"
                    v-model="segment_select"
                    hide-spin-buttons
                    hide-details
                    flat
                    solo
                    :rules="[(v) => !!v || 'Segment is Requied']"
                  >
                    <template v-slot:item="{ item, attrs, on }">
                      <v-list-item v-on="on" v-bind="attrs">
                        <!-- <v-list-item-content>
                                <v-list-item-title class="font-weight-medium">{{ item.MandateId }} <span
                                    class="float-right">{{
                                      Number(item.Amount).toFixed(2) }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle class="caption">reg date : {{ item.RegnDate }}
                                </v-list-item-subtitle>
                              </v-list-item-content> -->
                        <div v-if="item.show == true">
                          {{ item.txt }}
                          <v-chip
                            x-small
                            color="#FCF3F3"
                            class="pa-1"
                            v-if="
                              item.txt == 'Margin Trading Facility' &&
                              item.mess != 'not app'
                            "
                          >
                            <span style="color: #ff1717; font-size: 10px"
                              >MTF not enabled</span
                            >
                          </v-chip>

                          <v-chip
                            x-small
                            color="#FCF3F3"
                            class="pa-1"
                            v-if="
                              item.txt != 'Margin Trading Facility' &&
                              item.mess != 'not app'
                            "
                          >
                            <span style="color: #ff1717; font-size: 10px"
                              >Your is segments inactive</span
                            >
                          </v-chip>
                        </div>

                        <div v-else>
                          {{ item.txt }}
                        </div>
                        <div></div>
                      </v-list-item>
                    </template>
                  </v-select>
                </div>
                <div v-else>
                  <span v-if="doshowdata.sets == 'p'" class="caption red--text"
                    >This stock is not an eligible segment, so you cannot pledge
                    it.</span
                  >
                </div>
              </v-col>
              <div v-if="datat == 'Saturday' || datat == 'Sunday'">
                <v-card
                  class="pa-3 mb-2 mt-3 rounded-lg"
                  elevation="0"
                  style="background-color: #fcefd4; border: 1px solid #fae3b4"
                >
                  <div>
                    <p class="mb-0 ml-auto caption" style="color: #000">
                      <span class="mb-0 caption" style="color: #000"
                        >Note:</span
                      >
                      {{ doshowdata.sets == "p" ? "Pledge" : "Unpledge" }}
                      requests process on exchange working days, submissions on
                      weekends or exchange holidays are handled the next working
                      day.
                    </p>
                    <!-- <p class="mb-0 caption ml-auto" style="color: #000">
                      Thank you for choosing Zebu.
                    </p> -->
                  </div>
                </v-card>
              </div>

              <v-col cols="12" class="pb-0">
                <v-btn
                  @click="setBfodeploy()"
                  :loading="deploybtn"
                  rounded
                  block
                  height="48px"
                  :disabled="
                    enterval == 0 ||
                    (doshowdata.sets == 'p' ? segment_select == '' : false) ||
                    (doshowdata.sets == 'p'
                      ? enterval > doshowdata.unpl
                      : enterval > doshowdata.COLQTY) ||
                    deploybtn
                  "
                  class="text-none white--text font-weight-bold"
                  color="#000"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="showdeldialog" max-width="400">
          <v-card outlined class="pa-4 rounded-lg" elevation="0">
            <v-toolbar
              class="mb-1 toolbar-contant"
              elevation="0"
              dense
              color="white"
            >
              <p
                class="mb-0"
                style="
                  color: #000;
                  font-size: 18px;
                  font-weight: 559;
                  line-height: 100%;
                  letter-spacing: -0.36px;
                "
              >
                Delete {{ doshowdata.sets == "p" ? "Pledge" : "Unpledge" }}
                request
              </p>
              <v-btn
                class="ml-auto"
                :disable="deploybtn"
                icon
                @click="(showdeldialog = false), (doshowdata = [])"
                ><v-icon>mdi-close-circle-outline</v-icon></v-btn
              >
            </v-toolbar>
            <!-- <div v-for="(card, index) in alldata" :key="index">
                <div v-if="card.reqplege == 'yes'">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>Symbol : </v-list-item-subtitle>
                  
                    </v-list-item-content>
                    <v-list-item-content class="font-weight-bold">
                      <v-list-item-title>{{ card.NSE_SYMBOL }}</v-list-item-title>
                     
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div> -->

            <v-row class="px-4 mb-3">
              <!-- <v-col cols="3">
                                <v-list-item-subtitle class="mb-1">Total Qty</v-list-item-subtitle>
                                <v-text-field background-color="#F1F3F8" disabled type="number"
                                    :value="parseInt(doshowdata.NET)" hide-spin-buttons flat solo hide-details>
                                </v-text-field>
                            </v-col>
                            <v-col cols="4" class="pl-0">
                                <v-list-item-subtitle class="mb-1">{{ doshowdata.sets == 'u' ? 'Pledged Qty' :
                                    'Qty to Pledge' }}</v-list-item-subtitle>
                                <v-text-field background-color="#F1F3F8" disabled type="number"
                                    :value="parseInt(doshowdata.sets == 'u' ? doshowdata.COLQTY : doshowdata.unpl)"
                                    hide-spin-buttons flat solo hide-details>
                                </v-text-field>
                            </v-col> -->
              <v-col cols="12">
                <v-list-item-subtitle class="mb-1">{{
                  doshowdata.sets == "u" ? "Unpledge Qty" : "Pledge Qty"
                }}</v-list-item-subtitle>
                <v-text-field
                  background-color="#F1F3F8"
                  disabled
                  min="0"
                  :value="
                    parseInt(
                      doshowdata.sets == 'u'
                        ? doshowdata.un_plege_qty
                        : doshowdata.plege_qty
                    )
                  "
                  hide-spin-buttons
                  flat
                  solo
                  hide-details
                >
                </v-text-field>
              </v-col>
              <!-- <v-col cols="4" class="pl-0" v-if="doshowdata.sets == 'p'">
                                <v-list-item-subtitle class="mb-1">Qty to Pledge</v-list-item-subtitle>
                                <v-text-field background-color="#F1F3F8" readonly min="0" :value="parseInt(doshowdata.unpl)"
                                    hide-spin-buttons flat solo hide-details>
                                </v-text-field>
                            </v-col>
                            <v-col cols="5" class="pl-0" v-if="doshowdata.sets == 'p'">
                                <v-list-item-subtitle class="mb-1">Pledge Qty</v-list-item-subtitle>
                                <v-text-field background-color="#F1F3F8" readonly min="0" :value="parseInt(doshowdata.plege_qty)"
                                    hide-spin-buttons flat solo hide-details>
                                </v-text-field>
                            </v-col> -->

              <v-col cols="12" class="pb-0">
                <v-btn
                  @click="setBfodeldeploy()"
                  :loading="deploybtn"
                  rounded
                  block
                  height="48px"
                  :disabled="deploybtn"
                  class="text-none white--text font-weight-bold"
                  color="#000"
                  >Delete this request</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>

        <v-dialog persistent v-model="pledgefinal" max-width="400">
          <v-card outlined class="pa-4 rounded-lg" elevation="0">
            <v-toolbar
              class="mb-1 toolbar-contant"
              elevation="0"
              dense
              color="white"
            >
              <p
                class="mb-0"
                style="
                  color: #000;
                  font-size: 18px;
                  font-weight: 559;
                  line-height: 100%;
                  letter-spacing: -0.36px;
                "
              >
                Pledge Details
              </p>
              <!-- <p v-if="filteredData.length > 0">Pledge Delete Request</p> -->
              <v-btn class="ml-auto" icon @click="pledgefinal = false"
                ><v-icon>mdi-close-circle-outline</v-icon></v-btn
              >
            </v-toolbar>
            <!-- <p class="ml-4" style="
                    color: #000;
                    font-size: 18px;
                    font-weight: 559;
                    line-height: 100%;
                    letter-spacing: -0.36px;
                  " v-if="filteredData.length == ''">Pledge Delete Request</p> -->
            <div v-for="(card, index) in alldata" :key="index">
              <div v-if="card.reqplege == 'yes'">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Symbol : </v-list-item-subtitle>
                    <!-- <v-list-item-subtitle>Value : </v-list-item-subtitle> -->
                    <!-- <v-list-item-subtitle>Margine / Estimation : </v-list-item-subtitle> -->
                    <!-- <v-list-item-subtitle>Estimation % : </v-list-item-subtitle> -->
                    <v-list-item-subtitle v-if="card.plege_qty != ''"
                      >Total Qty :
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="card.plege_qty != ''"
                      >Segment :
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content class="font-weight-bold">
                    <v-list-item-title
                      >{{ card.NSE_SYMBOL }}
                      <span
                        v-if="card.plege_qty == ''"
                        class="ml-2 caption red--text"
                        >Remove</span
                      >
                    </v-list-item-title>
                    <!-- <v-list-item-title>{{ Number(card.estimated).toFixed(2) }} ({{
                                    card.est_percentage }} %)</v-list-item-title> -->
                    <v-list-item-title v-if="card.plege_qty != ''">{{
                      card.plege_qty
                    }}</v-list-item-title>
                    <v-list-item-title v-if="card.plege_qty != ''">{{
                      card.segenene
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </div>

            <p style="color: #ff1717; font-size: 13px">{{ erroettt }}</p>

            <!-- <v-form ref="form" v-model="validd" lazy-validation> -->
            <!-- <v-row class="px-4">
              <v-col cols="12">
             

                <div>
                  <span v-if="filteredData.length > 0 && uniqueItems != ''" class="caption">Which segment do you want to
                    pledge the stocks</span>
                  <div>
                    <div>
                      <v-select class="mt-1" v-if="filteredData.length > 0" background-color="#F1F3F8"
                        label="Select Segment" :items="uniqueItems" min="0" v-model="segment_select" hide-spin-buttons
                        hide-details flat solo :rules="[(v) => !!v || 'Segment is Requied']">
                      </v-select>
                    </div>

                    <div v-else>

                      <span class=" caption red--text">All segments inactive. Click here to <span class="caption"><a
                            style="text-decoration: none" href="https://profile.mynt.in/segment">activate </a></span> the
                        segments, then only you can pledge your stocks.</span>

                    </div>
                  </div>




                </div>

                
              </v-col>
            </v-row> -->

            <v-row>
              <v-col cols="6">
                <v-btn
                  @click="pledgefinal = false"
                  rounded
                  block
                  height="48px"
                  class="text-none black--text font-weight-bold"
                  outlined
                  color="#000"
                  >Cancel</v-btn
                >
              </v-col>
              <v-col cols="6" class="pb-0">
                <v-btn
                  @click="setDeploy(sndplgdata.length > 0 ? 'p' : '')"
                  :loading="deploybtn"
                  rounded
                  block
                  height="48px"
                  class="text-none white--text font-weight-bold"
                  color="#000"
                  >Submit Request</v-btn
                >
              </v-col>
            </v-row>
            <!-- </v-form> -->
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="loader"
          fullscreen
          elevation="0"
          persistent
          class="loader-overlay mx-auto text-center"
        >
          <v-card
            max-width="100%"
            class="loader-overlay mx-auto text-center"
            style="background-color: #999999"
            elevation="0"
          >
            <div class="loader-overlay mx-auto text-center">
              <div class="loader-overlay mx-auto text-center">
                <v-progress-circular
                  :size="70"
                  v-model="loader"
                  indeterminate
                  color="primary"
                  class="mt-14"
                ></v-progress-circular>
                <div>
                  <p class="title mt-4 ml-3" style="color: white">
                    Request To CDSL Please Wait a Sec...
                  </p>
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <!-- <v-overlay persistent v-model="loader1">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay> -->
      </div>
    </v-container>
  </v-app>
</template>
  
<script>
import axios from "axios";
// import { pledgeurl } from "../apiUrl";

import { pledgeurlapi } from "../../../Api.js";
export default {
  components: {},
  data() {
    return {
      // loader1:true,
      loader: false,
      totalvaluelt: "",
      alldatahead: [
        { text: "Symbol", value: "NSE_SYMBOL", width: "" },
        { text: "Qty to pledge", value: "pleged", width: "8%", align: "end" },
        { text: "Total Qty", value: "NET", width: "12%", align: "end" },
        { text: "Value", value: "AMOUNT", width: "12%", align: "end" },
        { text: "Mrg / Est", value: "estimated", align: "end", width: "15%" },
        { text: "Est %", value: "est_percentage", align: "end", width: "8%" },
        // { text: "Net Qty After Limit", value: "NET_QTY_AFTR_LIMITS", align: "end", width: "8%" },
        // { text: "Net Value After Limit", value: "NET_VALUE_AFTR_LIMITS", align: "end", width: "" },

        { text: "Pledged shares", value: "COLQTY", width: "20%", align: "end" },

        // { text: "", value: "textfield", width: "7%" },
        // { text: "pledged", value: "pleged", width: "4%", align: "start" },
        // { text: "", value: "Unpleddge", width: "3%", align: "end" },
        // { text: "Haircut", value: "Haircut", width: "12%", align: "end" },
        // { text: "Unpledged", value: "unpleged", width: "4%", align: "end" },
        // { text: "Pledge", value: "Unpleddge", width: "15%" },
        // { text: "UnPledge", value: "COLQTY", width: "15%" },
        // { text: "", value: "entertextus", align: "end" },
      ],
      selectsegfordisbale: "",
      cdslheder: [
        { text: "Client Id", value: "uccid" },
        { text: "Request Id", value: "reqid" },
        // { text: "BOID", value: "client_bo_id" },
        { text: "CDSL Requested Date & Time", value: "dat_tim" },
        { text: "CDSl Responce Date & Time", value: "cdsl_req_time" },
        { text: "Status", value: "status" },

        { text: "", value: "data-table-expand" },
      ],
      headersreqfin: [
        { text: "ISIN", value: "isin" },
        { text: "Script", value: "script" },
        { text: "ISIN Request Id", value: "isinresid" },
        { text: "Quantity", value: "quantity" },
        { text: "Segments", value: "segments" },
        { text: "Status", value: "status" },
        { text: "Reason", value: "errormsg" },
      ],
      unpledge: [
        { text: "Clientid", value: "clientid" },
        { text: "ISIN", value: "ISIN" },
        // { text: "BOID", value: "BOID" },
        { text: "Script", value: "script" },
        { text: "Unpledge qty", value: "un_plege_qty" },
        { text: "Requested date & time", value: "req_dat_time" },
        { text: "Approved date & time", value: "app_dat_time" },
        { text: "Status", value: "status" },
        { text: "Reason", value: "reason" },
      ],

      tableloader: false,
      alldata: [],
      tabledata: [],
      datasearchall: "",
      datasearchall1: "",
      datasearchall2: "",

      showdialog: false,
      showdeldialog: false,
      doshowdata: [],
      enterval: 0,
      holdsdata: [],
      deploybtn: false,
      filter: "All",
      snackbar: false,
      snackcolor: "#2a2a2a",
      toasttext: "",
      sndplgdata: [],
      sndunplgdata: [],
      deleteisins: [],
      pledgefinal: false,
      segment_select: "",
      // valid: true,
      filteredData: [],
      stocklen: [],
      dellenn: [],
      validd: true,
      // dellenn:[],
      arrlen: "",
      datat: "",
      expanded: [],
      singleExpand: false,
      expanded1: [],
      cdsldata: [],
      historydata: "",
      unpledgehistory: [],
      unpledgehis: "",
      histtab: "",
      pledgeload: true,
      unpladgeload: true,
      segment: [],
      segmentchoosee: [],
      uniqueItems: [],
      newsss: [],

      selectsgne: [],
      erroettt: "",
      shownn: "",
      sumtotal: "",
      cashquiet:"",
      noncashquiet:"",
      totalpledgedlen:"",
    };
  },

  methods: {
    goSso() {
      window.location.assign(
        `https://desk.mynt.in/?url=${window.location.href}`
      );
    },
    cancleAll() {
      this.sndplgdata = [];
      this.sndunplgdata = [];
      // this.alldata = this.holdsdata.data;
      // this.tabledata = this.alldata;
      this.getpledgeHold();
      window.location.reload();
    },

    segmentmtf() {
      // this.selectsgne = []
      let vars = { ISIN: this.doshowdata.ISIN, exch: this.segment_select };
      var index = this.selectsgne.findIndex((x) => x.ISIN == vars.ISIN);

      if (index > 0) {
        this.selectsgne[index].exch = this.segment_select;
      } else {
        this.selectsgne.push(vars);
      }
      console.log("neww", this.selectsgne);
      console.log("neww111", this.newsss);
    },

    getpledgeHold() {
      this.tableloader = true;
      this.tabledata = [];
      this.alldata = [];
      this.totalvaluelt = "";
      this.cashquiet = ''
      this.noncashquiet = ""
      this.totalpledgedlen = ""
      let data = JSON.stringify({
        clientid: localStorage.getItem("userid"),
      });
      let config = {
        method: "post",
        url: `${pledgeurlapi}PledgeHoldings`,
        headers: {
          "Content-Type": "application/json",
          clientid: localStorage.getItem("userid"),
          Authorization: localStorage.getItem("usession"),
        },
        data: data,
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          if (
            response.data.emsg == "Authorization Required" ||
            response.data.emsg == "token expired" ||
            response.data.emsg == "invalid token"
          ) {
            axiosThis.goSso();
          } else if (response.data && response.data.data) {
            let data = response.data.data;
            for (let t = 0; t < data.length; t++) {
              data[t]["unpl"] = parseInt(
                Number(data[t].Unpleddge) > 0
                  ? Number(data[t].Unpleddge)
                  : 0 + Number(data[t].NSOHQTY) > 0
                  ? Number(data[t].NSOHQTY)
                  : 0 + Number(data[t].SOHQTY) > 0
                  ? Number(data[t].SOHQTY)
                  : 0
              );
              data[t]["COLQTY"] = parseInt(
                Number(data[t].COLQTY) > 0 ? Number(data[t].COLQTY) : 0
              );

              // data[t]['unpl'] = parseInt((Number(data[t].Unpleddge) > 0 ? Number(data[t].Unpleddge) : 0 + Number(data[t].NSOHQTY) > 0 ? Number(data[t].NSOHQTY) : 0 + Number(data[t].SOHQTY) > 0 ? Number(data[t].SOHQTY) : 0) - (Number(data[t].plege_qty) > 0 ? Number(data[t].plege_qty) : 0));
              // data[t]['COLQTY'] = parseInt((Number(data[t].COLQTY) > 0 ? Number(data[t].COLQTY) : 0) - (Number(data[t].un_plege_qty) > 0 ? Number(data[t].un_plege_qty) : 0));
            }

            axiosThis.totalpledgedlen = data.filter(item => item.COLQTY != '0.000').length;
            axiosThis.alldata = data;
            axiosThis.setFilter();
            axiosThis.holdsdata = response.data;
            axiosThis.holdsdata["data"] = data;
// =================================================cash and non cash cal=============================================
axiosThis.sumtotal = data.reduce((acc, item) => {
  const netValueStr = item.NET_VALUE_AFTR_LIMITS;
  const cashEqColl = item.cash_eq_coll;

  if (netValueStr.toLowerCase() === "nan" || Object.keys(cashEqColl).length === 0) return acc; 

  const num = parseFloat(netValueStr);
  if (isNaN(num)) {
    console.log(`Skipping invalid number: ${netValueStr}`);
    return acc;
  }
  return acc + num;
}, 0);
// console.log("this.sumtotal", axiosThis.sumtotal);


axiosThis.cashquiet = data.reduce((sum, item) => {
  const netValueStr = item.NET_VALUE_AFTR_LIMITS;
  const cashEqColl = item.cash_eq_coll;

  if (netValueStr.toLowerCase() === "nan" || Object.keys(cashEqColl).length === 0) return sum; 
  
  const allTrue = Object.values(cashEqColl).every(value => value === "True");
  const netValue = parseFloat(netValueStr);
  if (isNaN(netValue)) {
    console.log(`Skipping invalid number for cashquiet: ${netValueStr}`);
    return sum;
  }
  return allTrue ? sum + netValue : sum;
}, 0).toFixed(2);
// console.log("this.cashquiet", axiosThis.cashquiet);

axiosThis.noncashquiet = data.reduce((sum, item) => {
  const netValueStr = item.NET_VALUE_AFTR_LIMITS;
  const cashEqColl = item.cash_eq_coll;

  if (netValueStr.toLowerCase() === "nan" || Object.keys(cashEqColl).length === 0) return sum;
  
  const allFalse = Object.values(cashEqColl).every(value => value === "False");
  const netValue = parseFloat(netValueStr);
  if (isNaN(netValue)) {
    console.log(`Skipping invalid number for noncashquiet: ${netValueStr}`);
    return sum;
  }
  return allFalse ? sum + netValue : sum;
}, 0).toFixed(2);



// console.log("this.noncashquiet", axiosThis.noncashquiet);
// =================================================cash and non cash cal=============================================

            // axiosThis.toasttext = "";
          } else {
            axiosThis.snackbar = true;
            axiosThis.snackcolor = "warning";
            axiosThis.toasttext = "no data found";
          }
          axiosThis.tableloader = false;
        })
        .catch((error) => {
          console.log(error);
          axiosThis.tableloader = false;
          axiosThis.snackbar = true;
          axiosThis.snackcolor = "error";
          axiosThis.toasttext = error;
        });
    },
    setFilter() {
      this.tabledata = [];
      // this.cdsldata = [];
      // this.
      if (this.filter == "All") {
        this.tabledata = this.alldata;
      } else if (this.filter == "History") {
        this.historypledge();
        this.unpledgehitory();
      } else {
        for (let r = 0; r < this.alldata.length; r++) {
          if (
            this.filter == "Free Stocks" &&
            this.alldata[r].ch != "0" &&
            this.alldata[r].Status != "Not_ok" &&
            (this.alldata[r].unpl > 0 || this.alldata[r].plege_qty > 0)
          ) {
            this.tabledata.push(this.alldata[r]);
          } else if (
            this.filter == "Pledged Stocks" &&
            Number(this.alldata[r].COLQTY) > 0 &&
            this.alldata[r].Status != "Not_ok"
          ) {
            this.tabledata.push(this.alldata[r]);
          } else if (
            this.filter == "Unapproved" &&
            this.alldata[r].Status == "Not_ok"
          ) {
            this.tabledata.push(this.alldata[r]);
          }
        }
      }

      // axiosThis.alldata = data;
    },
    setExch(item) {
      var cashseg =
        item &&
        Object.values(item).length > 0 &&
        Object.values(item).includes("True")
          ? true
          : false;

      return cashseg;
    },
    setPledge(i, v) {
      // this.showdialog = true;

      this.doshowdata = i;
      this.doshowdata["sets"] = v;
      this.enterval =
        this.doshowdata.sets == "p"
          ? this.doshowdata.unpl
          : this.doshowdata.COLQTY;

      var today = new Date();
      var day = today.getDay();
      this.daylist = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      this.datat = this.daylist[day];
      this.showdialog = true;

      // console.log(this.doshowdata.eligible_segments);
      var segemetel = [];
      this.newsss = [];
      // commonElements = [];
      // var shownn = ""

      for (let a = 0; a < this.doshowdata.eligible_segments.length; a++) {
        let inx = this.uniqueItems.includes(
          this.doshowdata.eligible_segments[a]
        );

        segemetel.push({
          txt: this.doshowdata.eligible_segments[a],
          show: inx == true ? false : true,
          mess: inx == true ? "not app" : true,
        });
      }

      var index = segemetel.findIndex(
        (x) => x.txt == "Margin Trading Facility"
      );
      if (this.selectsgne) {
        for (let d = 0; d < this.selectsgne.length; d++) {
          if (
            (this.selectsgne[d].exch == "Futures And Options" ||
              this.selectsgne[d].exch == "Commodities" ||
              this.selectsgne[d].exch == "Currencies") &&
            index >= 0
          ) {
            for (let s = 0; s < segemetel.length; s++) {
              if (index >= 0) {
                segemetel[index].show = true;
                segemetel[index].mess = "not app";
              } else {
                segemetel[s].show = false;
              }
            }
          } else if (this.selectsgne[d].exch == "Margin Trading Facility") {
            for (let s = 0; s < segemetel.length; s++) {
              if (segemetel[s].txt == "Margin Trading Facility") {
                segemetel[s].show = false;
                segemetel[s].mess = "not app";
              } else {
                segemetel[s].show = true;
              }
            }
          }

          // else if(indext >=0) {
          //   segemetel[0].show = true;
          //   segemetel[1].show = true;
          //   segemetel[2].show = true;
          // }
        }
        // segemetel
      }

      this.newsss = segemetel;
      // console.log('Common Elements:', segemetel);
      // console.log('this.newsss', this.newsss);

      // console.log('typeoff:', typeof segemetel);
      //       const propertyNames = Object.values(segemetel);

      // console.log('12334',propertyNames);

      //       console.log("dattt", this.doshowdata);

      // console.log("1111",this.enterval);
    },
    getDeploylist() {
      this.sndplgdata = this.alldata.filter((e) => e.reqplege == "yes");
      this.sndunplgdata = this.alldata.filter((e) => e.requnplege == "yes");
      for (let i = 0; i < this.alldata.length; i++) {
        if (this.alldata[i].reqplege == "yes") {
          this.dellenn.push(this.alldata[i].reqplege);
        }
      }
    },

    setBfodeploy() {
      if (
        this.enterval != 0 &&
        ((this.doshowdata.sets == "p" &&
          this.enterval <= this.doshowdata.unpl &&
          this.segment_select != "") ||
          (this.doshowdata.sets == "u" &&
            this.enterval <= this.doshowdata.COLQTY))
      ) {
        let x = this.alldata.findIndex(
          ({ ISIN }) => ISIN === this.doshowdata.ISIN
        );

        if (this.doshowdata.sets == "p") {
          this.alldata[x]["plege_qty"] = this.enterval;
          this.alldata[x]["segenene"] = this.segment_select;
          this.alldata[x]["reqplege"] = "yes";
          // console.log("enterdataaa", this.alldata[x]);

          if (this.alldata[x]["reqplege"] == "yes") {
            this.stocklen.push(this.alldata[x]);
          }
        } else {
          this.alldata[x]["un_plege_qty"] = this.enterval;
          this.alldata[x]["requnplege"] = "yes";
        }
        //     this.filteredData = this.sndplgdata.filter(alldata => alldata.plege_qty > 0);

        // console.log("qwtyu",this.filteredData);
        // axiosThis.getpledgeHold();
        this.showdialog = false;
        this.doshowdata = [];
        this.enterval = null;
        this.segment_select = "";
        this.toasttext = "data updated";
        this.snackcolor = "#2a2a2a";
        this.snackbar = true;
        this.getDeploylist();
      }
    },

    confirm() {
      if (this.sndplgdata.length > 0) {
        this.pledgefinal = true;
        this.filteredData = this.sndplgdata.filter(
          (alldata) => alldata.plege_qty > 0
        );
        this.lendel = this.sndplgdata.filter(
          (alldata) => alldata.plege_qty != "yes"
        );
        // console.log("eeee", this.lendel);
        // console.log("ttytttt", this.filteredData.length);
      } else if (this.sndunplgdata.length > 0) {
        this.setDeploy(this.sndunplgdata.length > 0 ? "u" : "");
      }
    },

    setDeploy(v) {
      // this.$refs.form.setDeploy(v)
      this.deploybtn = true;
      this.deleteisins = [];
      let data = {
        uccid: this.holdsdata.CLIENTCODE,
        client_bo_id: this.holdsdata.BOID,
        CLIENT_NAME: this.holdsdata.CLIENT_NAME,
        // segments: this.segment_select,
        unpledge_req_data: this.newDataArray,
      };
      let data1 = {
        clientid: this.holdsdata.CLIENTCODE,
        boid: this.holdsdata.BOID,
        clientname: this.holdsdata.CLIENT_NAME,
        // segments: this.segment_select,
        unpledge_req_data: this.newDataArray,
      };
      if (v == "p") {
        data["pledgeReq"] = [];
        for (let p = 0; p < this.sndplgdata.length; p++) {
          if (this.sndplgdata[p].plege_qty > 0) {
            data["pledgeReq"].push({
              segments: this.sndplgdata[p].segenene,
              symbol: this.sndplgdata[p].NSE_SYMBOL,
              isin: this.sndplgdata[p].ISIN,
              // NET: this.sndplgdata[p].NET,
              value: `${this.sndplgdata[p].SCRIP_VALUE}`,
              quantity: `${
                this.sndplgdata[p].plege_qty > 0
                  ? this.sndplgdata[p].plege_qty
                  : 0
              }`,
            });
          }
          let v =
            this.sndplgdata[p].plege_qty > 0 ? this.sndplgdata[p].plege_qty : 0;
          if (v == 0) {
            this.deleteisins.push(this.sndplgdata[p].ISIN);
            this.pledgefinal = false;
            this.loader = true;
            this.$emit("pledgeloader");
          }
        }
      } else {
        data1["unpledge_req_data"] = [];
        for (let p = 0; p < this.sndunplgdata.length; p++) {
          data1["unpledge_req_data"].push({
            NSE_SYMBOL: this.sndunplgdata[p].NSE_SYMBOL,
            ISIN: this.sndunplgdata[p].ISIN,
            NET: this.sndunplgdata[p].NET,
            COLQTY: `${this.sndunplgdata[p].COLQTY}`,
            unplege_qty: `${
              this.sndunplgdata[p].un_plege_qty > 0
                ? this.sndunplgdata[p].un_plege_qty
                : 0
            }`,
          });
          let v =
            this.sndunplgdata[p].un_plege_qty > 0
              ? this.sndunplgdata[p].un_plege_qty
              : 0;
          if (v == 0) {
            this.deleteisins.push(this.sndunplgdata[p].ISIN);
          }
        }
      }

      // data[this.doshowdata.sets == 'p' ? 'pledge_req_data' : 'unpledge_req_data'] = [{
      //     "NSE_SYMBOL": this.doshowdata.NSE_SYMBOL,
      //     "ISIN": this.doshowdata.ISIN,
      //     "NET": this.doshowdata.NET,
      //     "COLQTY": `${this.doshowdata.COLQTY}`,
      //     'plege_qty': `${this.enterval}`
      // }]
      // data[0][this.doshowdata.sets == 'p' ? 'plege_qty' : 'unplege_qty'] = `${this.enterval}`
      if (
        this.deleteisins &&
        this.deleteisins.length > 0 &&
        data.pledgeReq &&
        data.pledgeReq.length == 0
      ) {
        // console.log("if");
        this.setDeledeploy(v);
      }

      //  else if (this.deleteisins.length > 0 && data1 && data1.unpledge_req_data.length == 0) {
      //     console.log("else if");
      //     this.setDeledeploy(v);
      //   }
      else {
        let datas = JSON.stringify(v == "p" ? data : data1);

        let config = {
          method: "post",
          url: `${pledgeurlapi}${v == "p" ? "response" : "unpledge_requested"}`,
          headers: {
            "Content-Type": "application/json",
            clientid: localStorage.getItem("userid"),
            Authorization: localStorage.getItem("usession"),
          },
          data: datas,
        };
        var axiosThis = this;
        axios
          .request(config)
          .then((response) => {
            // console.log("oo  start", axiosThis.respdata);

            let data = JSON.parse(JSON.stringify(response.data));
            if (v == "p") {
              axiosThis.respdata = Object.assign([], data);

              document.getElementById("dps").value = data.dpid;
              document.getElementById("plg").value = data.pledgedtls;
              document.getElementById("req").value = data.reqid;
              document.getElementById("ver").value = data.version;
              axiosThis.$refs.myBtn.click();
            }

            // cllid = "";
            // snboid = "";
            // this.newDataArray = "";
            if (
              response.data.emsg == "Authorization Required" ||
              response.data.emsg == "token expired" ||
              response.data.emsg == "invalid token"
            ) {
              axiosThis.goSso();
            } else if (response.data.msg == "data updated successfully") {
              // axiosThis.getpledgeHold();

              if (axiosThis.deleteisins.length > 0) {
                axiosThis.setDeledeploy(v);
              } else {
                axiosThis.pledgefinal = false;
                // axiosThis.loader = true;
                // axiosThis.$emit("pledgeloader");

                axiosThis.getpledgeHold();
                axiosThis.showdialog = false;
                axiosThis.doshowdata = [];
                axiosThis.enterval = null;
                axiosThis.sndplgdata = [];
                axiosThis.sndunplgdata = [];
                axiosThis.segment_select = "";
              }

              // console.log("popop", axiosThis.deleteisins.length);
              // axiosThis.showdialog = false;
              // axiosThis.doshowdata = [];
              // axiosThis.enterval = null;
              axiosThis.toasttext =
                response.data.msg == "data updated successfully"
                  ? "UnPledged successfully"
                  : response.data;
              // console.log("snnnn", axiosThis.toasttext);
              // axiosThis.loader = false

              axiosThis.snackcolor = "success";
              // axiosThis.sndplgdata = [];
              // axiosThis.sndunplgdata = [];
            } else if (response.data.dpid != "") {
              // axiosThis.getpledgeHold();
              if (axiosThis.deleteisins.length > 0) {
                axiosThis.setDeledeploy(v);
                // console.log("00000000");
              } else {
                axiosThis.pledgefinal = false;
                axiosThis.loader = true;
                axiosThis.$emit("pledgeloader");

                // axiosThis.getpledgeHold();
                axiosThis.showdialog = false;
                axiosThis.doshowdata = [];
                axiosThis.enterval = null;
                axiosThis.sndplgdata = [];
                axiosThis.sndunplgdata = [];
                axiosThis.segment_select = "";
              }

              // console.log("popop", axiosThis.deleteisins.length);
              // axiosThis.showdialog = false;
              // axiosThis.doshowdata = [];
              // axiosThis.enterval = null;
              axiosThis.toasttext =
                response.data.dpid != "" ? "Request To dsl" : response.data;
              // console.log("snnnn", axiosThis.toasttext);
              axiosThis.snackcolor = "success";
              // axiosThis.sndplgdata = [];
              // axiosThis.sndunplgdata = [];
            } else {
              axiosThis.toasttext = response.data.msg
                ? response.data.msg
                : response.data;
              axiosThis.snackcolor = "error";
            }

            axiosThis.deploybtn = false;
            axiosThis.snackbar = true;
            axiosThis.erroettt = response.data.emsg;
          })

          .catch((error) => {
            console.log(error);
            axiosThis.deploybtn = false;
            axiosThis.snackbar = true;
            axiosThis.snackcolor = "error";
            axiosThis.toasttext = error;
          });
      }
    },
    retPledge(i, v) {
      this.showdeldialog = true;
      this.doshowdata = i;
      this.doshowdata["sets"] = v;
    },
    setBfodeldeploy() {
      let x = this.alldata.findIndex(
        ({ ISIN }) => ISIN === this.doshowdata.ISIN
      );

      if (this.doshowdata.sets == "p") {
        let a = this.sndplgdata.findIndex(
          ({ ISIN }) => ISIN === this.doshowdata.ISIN
        );

        // console.log("1222", this.sndplgdata);
        if (a >= 0 && this.sndplgdata[a].ISIN == this.doshowdata.ISIN) {
          this.alldata[x]["reqplege"] = "yes";
          this.alldata[x]["plege_qty"] = "";
          // console.log("deee", this.sndplgdata[a].ISIN);
          // this.dellenttt.push(this.sndplgdata[a].ISIN);
        } else {
          this.alldata[x]["reqplege"] =
            this.alldata[x]["plege_qty"] > 0 ? "yes" : "";
          this.alldata[x]["plege_qty"] = "";
        }
      } else {
        let b = this.sndunplgdata.findIndex(
          ({ ISIN }) => ISIN === this.doshowdata.ISIN
        );
        if (b >= 0 && this.sndunplgdata[b].ISIN == this.doshowdata.ISIN) {
          this.alldata[x]["requnplege"] = "yes";
          this.alldata[x]["un_plege_qty"] = "";
        } else {
          this.alldata[x]["requnplege"] =
            this.alldata[x]["un_plege_qty"] > 0 ? "yes" : "";
          this.alldata[x]["un_plege_qty"] = "";
        }
      }
      // axiosThis.getpledgeHold();
      // for(let i = 0; i < this.sndplgdata.length; i++){
      //   if(this.sndplgdata[i].reqplege == "yes" && this.sndplgdata[i].plege_qty != ''){
      //     this.leeenteh.push(this.sndplgdata[i].reqplege == "yes" && this.sndplgdata[i].plege_qty != '')
      //     console.log("qwertyu[]",this.leeenteh);
      //   }
      // }

      this.showdeldialog = false;
      this.doshowdata = [];
      this.enterval = null;
      this.toasttext = "data removed successfully";
      this.snackcolor = "success";
      this.snackbar = true;
      this.getDeploylist();
    },
    setDeledeploy(v) {
      let data = JSON.stringify({
        clientid: localStorage.getItem("userid"),
        ISIN: this.deleteisins,
      });

      let config = {
        method: "post",
        url: `${pledgeurlapi}${v == "p" ? "del_pledge" : "del_unpledge"}`,
        headers: {
          "Content-Type": "application/json",
          clientid: localStorage.getItem("userid"),
          Authorization: localStorage.getItem("usession"),
        },
        data: data,
      };
      var axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          axiosThis.loader = false;
          if (
            response.data.emsg == "Authorization Required" ||
            response.data.emsg == "token expired" ||
            response.data.emsg == "invalid token"
          ) {
            axiosThis.goSso();
          } else if (
            response.data.msg == "request deleted" ||
            response.data.msg == "pledged successfully"
          ) {
            axiosThis.segment_select = "";
            axiosThis.doshowdata = [];
            axiosThis.enterval = null;
            axiosThis.sndplgdata = [];
            axiosThis.sndunplgdata = [];
            axiosThis.deleteisins = [];
            axiosThis.getpledgeHold();
            axiosThis.pledgefinal = false;
            axiosThis.dellthhh = "";
            axiosThis.toasttext = response.data.msg
              ? response.data.msg
              : response.data;
            axiosThis.snackcolor = "success";
          } else {
            axiosThis.toasttext = response.data.msg
              ? response.data.msg
              : response.data;
            axiosThis.snackcolor = "error";
          }
          axiosThis.deploybtn = false;
          axiosThis.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setUnpledge() {
      // this.showdialog = true;
      // this.doshowdata = i;
    },

    historypledge() {
      this.pledgeload = true;
      this.cdsldata = [];
      const axios = require("axios");
      let data = JSON.stringify({
        clientid: localStorage.getItem("userid"),
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${pledgeurlapi}history_data`,
        // url:"http://192.168.5.92:9000/history_data",

        headers: {
          "Content-Type": "application/json",
          clientid: localStorage.getItem("userid"),
        },
        data: data,
      };
      var axiosThis = this;

      axios
        .request(config)
        .then((response) => {
          if (response.data.data) {
            axiosThis.pledgeload = false;
            axiosThis.cdsldata = response.data.data;
            for (let f = 0; f < axiosThis.cdsldata.length; f++) {
              if (axiosThis.cdsldata[f].final.pledgeresdtls) {
                axiosThis.cdsldata[f]["newsata"] =
                  axiosThis.cdsldata[
                    f
                  ].final.pledgeresdtls.pledgeresdtls.isinresdtls;

                axiosThis.cdsldata[f].newsata[0]["reserrmsg"] =
                  axiosThis.cdsldata[
                    f
                  ].final.pledgeresdtls.pledgeresdtls.reserrmsg;
              } else {
                axiosThis.cdsldata[f]["newsatafinal"] =
                  axiosThis.cdsldata[f].final;
              }
            }
          } else if (response.data.emasg == "no data") {
            axiosThis.pledgeload = false;

            axiosThis.snackbar = true;
            axiosThis.snackcolor = "warning";
            axiosThis.toasttext = "Pledge History no data found";
          } else {
            axiosThis.pledgeload = false;

            axiosThis.snackbar = true;
            axiosThis.snackcolor = "warning";
            axiosThis.toasttext = response.data;
          }
        })
        .catch((error) => {
          axiosThis.pledgeload = false;

          console.log(error);
        });
    },
    unpledgehitory() {
      this.unpledgehistory = [];
      const axios = require("axios");
      let data = JSON.stringify({
        clientid: localStorage.getItem("userid"),
        date: "",
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${pledgeurlapi}unpledge_history`,
        // url: "http://192.168.5.92:9000/unpledge_history",

        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      var axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          if (response.data.data) {
            axiosThis.unpladgeload = false;
            axiosThis.unpledgehistory = response.data.data;
          } else if (response.data.msg == "no data found") {
            axiosThis.unpladgeload = false;

            axiosThis.snackbar = true;
            axiosThis.snackcolor = "warning";
            axiosThis.toasttext = "UnPledge History no data found";
          } else {
            axiosThis.unpladgeload = false;

            axiosThis.snackbar = true;
            axiosThis.snackcolor = "warning";
            axiosThis.toasttext = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.unpladgeload = false;
        });
    },
    segmentadd() {
      this.segment = [];
      const axios = require("axios");
      var dall = JSON.stringify({
        client_code: localStorage.getItem("userid"),
      });
      var enc = this.encryptionFunction(dall);
      let data = JSON.stringify({
        code: enc,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://fundapi.mynt.in/api/client_check",
        headers: {
          Authorization: localStorage.getItem("usession"),
          clientid: localStorage.getItem("userid"),
          "Content-Type": "application/json",
        },
        data: data,
      };
      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          // axiosThis.decseg = response.data.str
          // axiosThis.decryptionFunction()
          // console.log(JSON.stringify(response.data));
          var CryptoJS = require("crypto-js");

          const payload = response.data.str;
          const derived_key = CryptoJS.enc.Base64.parse(
            btoa("N#j2L^8pq9Fb$d@1")
          );
          const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
          const encryptedData = payload;
          const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
            iv,
            mode: CryptoJS.mode.CBC,
          });
          const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

          // console.log("11111", decryptedData);
          let responsee = decryptedData;
          let obj = JSON.parse(responsee);
          // console.log("Common Data:", obj.company_code);
          for (let a = 0; a < obj.company_code.length; a++) {
            let neeww = obj.company_code[a];
            axiosThis.segment = neeww;
            if (this.segment == "NSE_FNO" || this.segment == "BSE_FNO") {
              this.segmentchoosee.push("Futures And Options");
            }
            if (this.segment == "CD_NSE" || this.segment == "CD_BSE") {
              this.segmentchoosee.push("Currencies");
            }
            if (this.segment == "MCX") {
              this.segmentchoosee.push("Commodities");
            }
            // if (this.segment == 'BSE_CASH' || this.segment == 'NSE_CASH') {
            //   this.segmentchoosee.push("Equity");
            // }
            // console.log("ararar", neeww);
          }
          // console.log("segment", this.segmentchoosee);

          if (
            localStorage.getItem("mtf1") == "Y" ||
            localStorage.getItem("mtf2") == "Y"
          ) {
            this.segmentchoosee.push("Margin Trading Facility");
          }

          this.uniqueItems = [...new Set(this.segmentchoosee)];

          // console.log("Common Data:", this.uniqueItems);

          return this.uniqueItems;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    encryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      var derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1"));
      var iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      var test = CryptoJS.AES.encrypt(payload, derived_key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString();
      return test;
    },

    //   decryptionFunction(decseg) {
    //     var CryptoJS = require("crypto-js");

    //     const payload = decseg;
    //     const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1"));
    //     const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
    //     const encryptedData = payload;
    //     const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
    //       iv,
    //       mode: CryptoJS.mode.CBC,
    //     });
    //   const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
    //  console.log("111",decseg);
    //     return decryptedData;
    //   },

    segmentchoose() {
      //  else if (
      //     this.segment == "NSE_FNO" &&
      //     this.segment == "BSE_FNO" &&
      //     this.segment == "CD_NSE" &&
      //     this.segment == "CD_BSE"
      //   ) {
      //     return ["Futures And Options", "Currencies"];
      //   }
      //   else if (
      //     this.segment == "NSE_FNO" ||
      //     this.segment == "BSE_FNO"
      //   ) {
      //     return ["Futures And Options"];
      //   }
    },
  },
  computed: {
    validr() {
      return this.segment_select !== "";
    },
    MobSearchFilterItem() {
      return this.tabledata.filter((post) => {
        return post.NSE_SYMBOL.toLowerCase().includes(
          this.datasearchall.toLowerCase()
        );
      });
    },
    MobSearchFilterItem1() {
      return this.cdsldata.filter((post) => {
        return post.reqid
          .toLowerCase()
          .includes(this.datasearchall1.toLowerCase());
      });
    },
    MobSearchFilterItem2() {
      return this.unpledgehistory.filter((post) => {
        return post.script
          .toLowerCase()
          .includes(this.datasearchall2.toLowerCase());
      });
    },
  },
  mounted() {
    this.getpledgeHold();
    this.segmentadd();
    // this.testttt();

    // this.decryptionFunction();
    // console.log('wwwqqqqq');
    // (this.idname = localStorage.getItem("userid")),
    // console.log('www',this.idname);
    // this.historypledge();
    // this.unpledgehitory();
  },
};
</script>
  
<style>
.snakbar-sty {
  z-index: 6 !important;
  position: fixed !important;
}

.toolbar-contantd .v-toolbar__content {
  padding: 0 !important;
}
</style>