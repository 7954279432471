<template>
  <div id="app" class="ma-lg-10 ml-lg-10 ma-md-10 ml-md-10">
    <div class="mx-4 scroll-container">
      <v-snackbar class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" transition="slide-x-reverse-transition"
      v-model="snackbar" :timeout="4000" :value="true" color="warning" text-color="white">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      Session <strong>{{ txt }}</strong> Kindly Login again.
      <v-icon @click="snackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
      <!-- <div class="py-md-7  d-block d-md-none "></div> -->
      <div class="px-md-3">
        <v-list-item class="pl-0 pl-md-1 ">
            <v-list-item-content>
                <v-list-item-title class="text-start"
                    style="color: #000000;font-size: 20px;font-weight:559;line-height:24px;letter-spacing:-1px">
                    Bank Accounts Linked</v-list-item-title>
                <v-list-item-subtitle class="text-start pt-1"
                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">View bank details and add new banks.
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        
        <!-- <p class="font-weight-bold fs-22 mb-0">Bank Accounts Linked</p>
        <p class="subtitle-2 txt-666">
          View bank details and add new banks.
        </p> -->
      </div>
      <v-divider></v-divider>
      <v-card class="py-md-6 px-md-3 px-0 mt-6 mb-6" :outlined="$vuetify.breakpoint.smAndDown ? false : true" elevation="0"
        width="100%">
        <p class="subtitle-1 txt-666 font-weight-medium">
          {{ BankData.length > 1 ? BankData.length : "" }} Account
          Linked
        </p>
        <!-- {{BankData}} -->
        <v-list-item v-for="bank in BankData" class="pl-0 d-inline-block d-md-none" :key="bank.Account_Code">
          <v-row no-gutters>
            <v-col cols="7">
              <span style="display:flex">
                <img v-if="banklogo != 'n'" width="30px" height="30px" :src="`${banklogo}/banklogo?bank=${bank.IFSC_Code.slice(
                  0,
                  4
                ).toLowerCase()}&type=svg`" class="mr-4 mt-3 pa-1" />
                <v-list-item-avatar v-else color="#fff" size="40">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M5 9.00002V17M9.5 9.00002V17M14.5 9.00002V17M19 9.00002V17M3 18.6L3 19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7952 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7952 20.7951 20.6422 20.891 20.454C21 20.2401 21 19.9601 21 19.4V18.6C21 18.04 21 17.7599 20.891 17.546C20.7951 17.3579 20.6422 17.2049 20.454 17.109C20.2401 17 19.9601 17 19.4 17H4.6C4.03995 17 3.75992 17 3.54601 17.109C3.35785 17.2049 3.20487 17.3579 3.10899 17.546C3 17.7599 3 18.04 3 18.6ZM11.6529 3.07715L4.25291 4.7216C3.80585 4.82094 3.58232 4.87062 3.41546 4.99082C3.26829 5.09685 3.15273 5.24092 3.08115 5.40759C3 5.59654 3 5.82553 3 6.28349L3 7.40002C3 7.96007 3 8.2401 3.10899 8.45401C3.20487 8.64217 3.35785 8.79515 3.54601 8.89103C3.75992 9.00002 4.03995 9.00002 4.6 9.00002H19.4C19.9601 9.00002 20.2401 9.00002 20.454 8.89103C20.6422 8.79515 20.7951 8.64217 20.891 8.45401C21 8.2401 21 7.96007 21 7.40002V6.2835C21 5.82553 21 5.59655 20.9188 5.40759C20.8473 5.24092 20.7317 5.09685 20.5845 4.99082C20.4177 4.87062 20.1942 4.82094 19.7471 4.7216L12.3471 3.07715C12.2176 3.04837 12.1528 3.03398 12.0874 3.02824C12.0292 3.02314 11.9708 3.02314 11.9126 3.02824C11.8472 3.03398 11.7824 3.04837 11.6529 3.07715Z"
                      stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold fs-14">{{ bank.Bank_Name }}
                    <!-- <v-chip color="#EBEFFA" v-if="bank.Default_Ac == 'Yes'" x-small class="ml-3"
                      style="border-radius: 4px !important">
                      <span style="font-size: 9px" class="primary--text">PRIMARY</span>
                    </v-chip> -->

                  </v-list-item-title>
                  <v-list-item-subtitle class="fs-12">A/C No: {{ bank.Bank_AcNo }}</v-list-item-subtitle>
                <div>  <v-chip color="#EBEFFA" v-if="bank.Default_Ac == 'Yes'" x-small class=""
                      style="border-radius: 4px !important">
                      <span style="font-size: 9px" class="primary--text">PRIMARY</span>
                    </v-chip></div>
                </v-list-item-content>
              </span>

              <v-list-item-content class="ml-11">
                <v-list-item-subtitle class="fs-12">IFSC CODE</v-list-item-subtitle>
                <v-list-item-title class="font-weight-medium fs-14">{{
                  bank.IFSC_Code
                }}</v-list-item-title>

              </v-list-item-content>

              <v-list-item-content class="ml-11">
                <v-list-item-subtitle class="fs-12">A/C TYPE</v-list-item-subtitle>
                <v-list-item-title class="font-weight-medium fs-14">{{
                  bank.BANK_ACCTYPE
                }}</v-list-item-title>
              </v-list-item-content>


            


            </v-col>
            <v-col cols="1" class="d-flex">
              <!-- <v-list-item-content > -->
                <div v-if="accountstatus != 'client_close'">

               <v-btn class="ml-2 mt-2 mr-2" @click="editbank(bank)" v-if="
                    mobStatus.bank_status != 'e-signed pending' &&
                    mobStatus.bank_status != 'e-signed completed'
                    "  style="background-color:#000" elevation="0" :disabled="accountstatus == 'client_close'" text small>
                  <!-- <img src="@/assets/pen.svg" alt="pen"> --> <span  style="color:#fff;font-size:10px">Edit</span> </v-btn>
                </div>
                <v-btn :disabled="accountstatus == 'client_close'" @click="deletebankk(bank)" v-if="bank.Default_Ac != 'Yes' &&
                    mobStatus.bank_status != 'e-signed pending' &&
                    mobStatus.bank_status != 'e-signed completed'
                    " icon>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="24" height="24">
                    <path fill="currentColor" fill-rule="evenodd"
                      d="M11.5 6a.5.5 0 0 0-.5.5V8h6V6.5a.5.5 0 0 0-.5-.5h-5zM18 8V6.5c0-.83-.67-1.5-1.5-1.5h-5c-.83 0-1.5.67-1.5 1.5V8H5.5a.5.5 0 0 0 0 1H7v12.5A2.5 2.5 0 0 0 9.5 24h9a2.5 2.5 0 0 0 2.5-2.5V9h1.5a.5.5 0 0 0 0-1H18zm2 1H8v12.5c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V9zm-8.5 3c.28 0 .5.22.5.5v7a.5.5 0 0 1-1 0v-7c0-.28.22-.5.5-.5zm5.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z">
                    </path>
                  </svg>
                </v-btn>
                <v-btn v-else plain disabled icon></v-btn>
              <!-- </v-list-item-content> -->
            </v-col>
            <v-col class="pl-3 ">
              <div>
              <v-chip color="#EBEFFA" v-if="bank.Default_Ac == 'No' &&
                mobStatus.bank_status != 'e-signed pending' &&
                mobStatus.bank_status != 'e-signed completed'" :disabled="accountstatus == 'client_close'" x-small @click="setasPrimary(bank)" class="pl-4 mt-3  ml-5"
                style="border-radius: 4px !important;cursor: pointer;">
                <span style="font-size: 9px" class="black--text">SET AS PRIMARY</span>
              </v-chip>
            </div>
            </v-col>
          </v-row>
        </v-list-item>
        <v-list v-for="bank in BankData" :key="bank.Bank_AcNo" class="pl-0 d-none d-md-inline  ">
          <v-list-item class='px-2'>
            <v-row>
              <v-col cols="2" md="1" lg="1" class="pa-0">
                <img v-if="banklogo != 'n'" width="32px" :src="`${banklogo}/banklogo?bank=${bank.IFSC_Code.slice(
                  0,
                  4
                ).toLowerCase()}&type=svg`" class="ml-lg-4 mx-md-2 mt-3 pa-1" />

                <v-list-item-avatar v-else color="#fff" size="40" class="">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M5 9.00002V17M9.5 9.00002V17M14.5 9.00002V17M19 9.00002V17M3 18.6L3 19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7952 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7952 20.7951 20.6422 20.891 20.454C21 20.2401 21 19.9601 21 19.4V18.6C21 18.04 21 17.7599 20.891 17.546C20.7951 17.3579 20.6422 17.2049 20.454 17.109C20.2401 17 19.9601 17 19.4 17H4.6C4.03995 17 3.75992 17 3.54601 17.109C3.35785 17.2049 3.20487 17.3579 3.10899 17.546C3 17.7599 3 18.04 3 18.6ZM11.6529 3.07715L4.25291 4.7216C3.80585 4.82094 3.58232 4.87062 3.41546 4.99082C3.26829 5.09685 3.15273 5.24092 3.08115 5.40759C3 5.59654 3 5.82553 3 6.28349L3 7.40002C3 7.96007 3 8.2401 3.10899 8.45401C3.20487 8.64217 3.35785 8.79515 3.54601 8.89103C3.75992 9.00002 4.03995 9.00002 4.6 9.00002H19.4C19.9601 9.00002 20.2401 9.00002 20.454 8.89103C20.6422 8.79515 20.7951 8.64217 20.891 8.45401C21 8.2401 21 7.96007 21 7.40002V6.2835C21 5.82553 21 5.59655 20.9188 5.40759C20.8473 5.24092 20.7317 5.09685 20.5845 4.99082C20.4177 4.87062 20.1942 4.82094 19.7471 4.7216L12.3471 3.07715C12.2176 3.04837 12.1528 3.03398 12.0874 3.02824C12.0292 3.02314 11.9708 3.02314 11.9126 3.02824C11.8472 3.03398 11.7824 3.04837 11.6529 3.07715Z"
                      stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </v-list-item-avatar>
              </v-col>
              <v-col md="4" lg="4" class="pa-0">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold fs-14">{{ bank.Bank_Name }}
                    <v-chip color="#EBEFFA" v-if="bank.Default_Ac == 'Yes'" x-small class="ml-3"
                      style="border-radius: 4px !important">
                      <span style="font-size: 9px" class="primary--text">PRIMARY</span>
                    </v-chip>

                  </v-list-item-title>
                  <v-list-item-subtitle class="fs-12">A/C No: {{ bank.Bank_AcNo }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-col>
              <v-col md="2" lg="2" class='pa-0'>
                <v-list-item-content>
                  <v-list-item-subtitle class="fs-12">IFSC CODE</v-list-item-subtitle>
                  <v-list-item-title class="font-weight-medium fs-14">{{
                    bank.IFSC_Code
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-col md="1" lg="1" class="pa-0">
                <v-list-item-content>
                  <v-list-item-subtitle class="fs-12">A/C TYPE</v-list-item-subtitle>
                  <v-list-item-title class="font-weight-medium fs-14">{{
                    bank.BANK_ACCTYPE
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-col>
      




              <v-col lg="1" md="1" class="px-0 d-flex">
                <div v-if="accountstatus != 'client_close'">

               <v-btn class="ml-2 mt-2 mr-2" @click="editbank(bank)"  v-if="
                    mobStatus.bank_status != 'e-signed pending' &&
                    mobStatus.bank_status != 'e-signed completed' 
                    " style="background-color:#000" elevation="0" :disabled="accountstatus == 'client_close'" text small>
                  <!-- <img src="@/assets/pen.svg" alt="pen"> --> <span  style="color:#fff;font-size:10px">Edit</span> </v-btn>
                </div>
                <v-btn :disabled="accountstatus == 'client_close'" class=" " @click="deletebankk(bank)" v-if="bank.Default_Ac != 'Yes' &&
                    mobStatus.bank_status != 'e-signed pending' &&
                    mobStatus.bank_status != 'e-signed completed'
                    " icon>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="24" height="24">
                    <path fill="currentColor" fill-rule="evenodd"
                      d="M11.5 6a.5.5 0 0 0-.5.5V8h6V6.5a.5.5 0 0 0-.5-.5h-5zM18 8V6.5c0-.83-.67-1.5-1.5-1.5h-5c-.83 0-1.5.67-1.5 1.5V8H5.5a.5.5 0 0 0 0 1H7v12.5A2.5 2.5 0 0 0 9.5 24h9a2.5 2.5 0 0 0 2.5-2.5V9h1.5a.5.5 0 0 0 0-1H18zm2 1H8v12.5c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V9zm-8.5 3c.28 0 .5.22.5.5v7a.5.5 0 0 1-1 0v-7c0-.28.22-.5.5-.5zm5.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z">
                    </path>
                  </svg>
                </v-btn>
                <v-btn v-else plain disabled icon></v-btn>
              </v-col>
              <v-col md="2">
                <v-chip  color="#EBEFFA" v-if="bank.Default_Ac == 'No' &&
                  mobStatus.bank_status != 'e-signed pending' &&
                  mobStatus.bank_status != 'e-signed completed'" :disabled="accountstatus == 'client_close'" x-small @click="setasPrimary(bank)" class=" ml-2 mt-3"
                  style="border-radius: 4px !important;cursor: pointer;">
                  <span style="font-size: 9px" class="black--text">SET AS PRIMARY</span>
                </v-chip>
              </v-col>
            </v-row>

          </v-list-item>
        </v-list>
        <v-card rounded="lg" v-if="mobStatus.bank_status == 'e-signed pending'" color="#FCEFD4" elevation="0"
          class="mt-4">
          <div class="px-2 py-1">
            <p style="
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
              Your Bank Change request is not yet Completed,<span style="color: #0037b7; cursor: pointer" @click="
                (editType = 'bank_change'),
                digioEsign(
                  mobStatus.bank_file_id,
                  mobStatus.bank_client_email.toLowerCase(),
                  mobStatus.bank_session
                )
                ">
                Click here esign</span>
              <span @click="editType = 'bank_change', fileide = mobStatus.bank_file_id, canconfirmation = true"
                class="mt-1 mr-2" style="color:red;cursor: pointer;float:right">Cancel request</span>
            </p>
          </div>
        </v-card>
        <v-dialog v-model="canconfirmation" persistent max-width="380">
          <v-card class="rounded-lg px-2 px-md-2">
            <v-card-title class="pr-2">Cancel request? <v-spacer></v-spacer>
              <v-btn icon @click="canconfirmation = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-card-title>
            <v-card-text>
              Are you sure you want to cancel your <b>"{{ this.editType }}"</b> request?
            </v-card-text>
            <v-card-actions class="pb-4">


              <!-- <v-btn
                height="40px"
                block
                color="red darken-1 text-none"
                text
                @click="deleteBankapi"
              >
                Delete
              </v-btn> -->
              <v-btn elevation="0" color="black white--text" rounded block @click="rejectEsign">
                <span class="text-none ">Proceed</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card rounded="lg" v-if="mobStatus.bank_status == 'e-signed completed'" color="#FCEFD4" elevation="0"
          class="mt-4">
          <div class="px-2 py-1">
            <p style="
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
              Your Bank Change request is in process
              <!-- <span style="color: #0037B7;cursor: pointer;"> Click here esign</span> -->
            </p>
          </div>
        </v-card>
      </v-card>
      <div v-if="accountstatus != 'client_close'">
      <v-btn :disabled="emailLoader || BankData.length == 5 || accountstatus == 'client_close'" v-if="mobStatus.bank_status != 'e-signed pending' &&
        mobStatus.bank_status != 'e-signed completed'
        " :block="$vuetify.breakpoint.xsOnly ? true : false" elevation="0" color="black white--text" rounded
        :loading="emailLoader" @click="mobileEdit('addbank')"><span class="text-none px-8">+ Add Bank Account</span>
      </v-btn>
    </div>
    <div v-if="accountstatus == 'client_close'">
                  <v-tooltip color="black" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn disabled class="text-none"  elevation="0" color="black white--text" rounded>
                          + Add Bank Account
                        </v-btn>
                      </span>
                    </template>
                    <span>Your trading account is closed</span>
                  </v-tooltip>

                </div>
      <p class="subtitle-2 txt-666 mt-4">
        *As per the regulation, you can have up-to 5 bank a/c linked to
        trading a/c
      </p>
    </div>
    <v-overlay :value="blurLoader" class="rounded-0" style="z-index:2000">
      <div class="text-center">
        <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
      </div>
    </v-overlay>
    <v-dialog v-model="confirmation" persistent max-width="380">
      <v-card class="rounded-lg px-2 px-md-4">
        <v-card-title> Delete Bank? </v-card-title>
        <v-card-text>
          Are you sure you want to delete
          <b>"{{ deletebank.Bank_Name }} ({{ deletebank.Bank_AcNo }})"?</b>
          <br />You can't undo this action.</v-card-text>
        <v-card-actions class="pb-4">
          <v-row no-gutters>
            <v-col>
              <v-btn height="40px" block color="grey darken-1 text-none" text rounded @click="confirmation = false">
                Cancel
              </v-btn>

            </v-col>
            <v-col class="pl-3">
              <!-- <v-btn
                height="40px"
                block
                color="red darken-1 text-none"
                text
                @click="deleteBankapi"
              >
                Delete
              </v-btn> -->
              <v-btn elevation="0" color="black white--text" rounded block @click="deleteBankapi">
                <span class="text-none ">Delete</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="setasPrimaryconfirmation" persistent max-width="380">
      <v-card class="rounded-lg px-2 px-md-4">
        <v-card-title> Set your Bank as Primary? </v-card-title>
        <v-card-text>
          Are you sure you want to Set this bank as primary
          <b>"{{ primaryAcc.Bank_Name }} ({{ primaryAcc.Bank_AcNo }})"?</b>
          <br />You can't undo this action.</v-card-text>
        <v-card-actions class="pb-4">
          <v-row no-gutters>
            <v-col>
              <v-btn block color="grey darken-1 text-none" rounded text @click="setasPrimaryconfirmation = false">
                Cancel
              </v-btn>

            </v-col>
            <v-col class="pl-3">
              <v-btn elevation="0" color="black white--text" rounded @click="setPrimaryBankapi">
                <span class="text-none ">Set as Primary</span>
              </v-btn>
              <!-- <v-btn
                height="40px"
                block
                color=" darken-1 text-none"
                text
                @click="setPrimaryBankapi"
              >
                Set as Primary
              </v-btn> -->
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editdialog"
      :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : 'scale-transition'" persistent
      :fullscreen="$vuetify.breakpoint.xsOnly" :max-width="addNewSeg ? '500' : '420'" min-width="390">
      <v-card class="pb-4 rounded-lg px-md-4">
        <v-snackbar  class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white"
          transition="slide-x-reverse-transition" v-model="errorSnackbar" :timeout="4000" :value="true" color="error">
          <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
          {{ errtext }} {{toasttext}}
          <v-icon @click="errorSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
        </v-snackbar>
        <!-- <v-snackbar class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" transition="slide-x-reverse-transition"
      v-model="snackbar" :timeout="4000" :value="true" color="warning" text-color="white">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      Session <strong>{{ txt }}</strong> Kindly Login again.
      <v-icon @click="snackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar> -->

        <div v-if="bankchange">
          <v-card-title style="font-size: 16px">
            Bank change request <v-spacer></v-spacer>
            <v-btn icon @click="closeBtn">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-form ref="formbank">
            <v-card-text class="pb-0 pt-0">
              <v-chip-group class="pb-4" column>
                <v-chip @click="bankproofTypes = tag.val" :color="bankproofTypes == tag.val ? '#000' : '#666666'"
                  :text-color="bankproofTypes == tag.val ? '#fff' : '#666666'" v-for="(tag, l) in bankprooftype"
                  :outlined="bankproofTypes != tag.val" :key="l">
                  {{ tag.txt }}
                </v-chip>
              </v-chip-group>
              <p class="ma-0 pa-0" style="font-weight: 499">
                Bank A/C No <span style="color: red">*</span>
              </p>

              <v-text-field class="pa-0" required type="number" @keyup="sameBankcheck" hide-spin-buttons
                :rules="nomineeField" placeholder="Enter Bank A/C No" v-model="bankAccountnumber"></v-text-field>

              <p class="ma-0 pa-0" style="font-weight: 499">
                IFSC CODE <span style="color: red">*</span>
              </p>
              <v-text-field class="pa-0" :rules="prooffield" @keyup="ifscFetch(bankIfscCode)" placeholder="Enter IFSC Code"
                v-model="bankIfscCode"></v-text-field>
               <!-- {{ ifscBankInfo ? ifscBankInfo : '--' }}

               {{ ifscBankInfo.BANK ? ifscBankInfo.BANK : '' }} -->
              <div v-if="ifscBankInfo.BANK === null">
              <p class="ma-0 pa-0" style="font-weight: 499">
                Not fetch your bank. so please enter it <span style="color: red">*</span>
              </p>
            
              <v-text-field class="pa-0" v-model="manulabankname" :rules="prooffield"  placeholder="Bank Name"
               ></v-text-field>
               
              </div>
              <p class="ma-0 pa-0" style="font-weight: 499">
                Proof Type <span style="color: red">*</span>
              </p>
              <v-select class="pa-0"  :rules="nomineeField" v-model="proofvalue"
                              :items="relationNominee" placeholder="Proof type">
                              <!-- <template #prepend-inner>
                                <div style="width: 30px">
                                  <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/family.svg" />
                                </div>
                              </template> -->
              </v-select>
              <!-- PINCODE FOR MANUAL ADDRESS -->

              <!-- <p class="ma-0 pa-0">Account type <span style="color:red">*</span></p>
              <v-select class="pa-0" :items="bankprooftype" :rules="nomineeField" v-model="bankproofTypes"
                placeholder="Select Account type"></v-select> -->

              <p class="ma-0 pa-0" style="font-weight: 499">
                Bank Proof <span style="color: red">*</span>
              </p>
              <!-- <v-file-input  class="pa-0 custom-input" prepend-icon="" prepend-inner-icon="mdi-paperclip" :rules="nomineeField" v-model="bankproffdoc" accept=".pdf" placeholder="Upload Bank Proof">
              
              </v-file-input> -->
              <!-- Your custom trigger element -->
              <!-- <v-btn >Select a file</v-btn> -->

              <v-card color="rgba(246, 247, 247, 0.5)" @ondragover="onChange" :class="dragging ? 'dropAreaDragging' : ''"
                @dragenter="dragging = true" @dragend="dragging = false" @dragleave="dragging = false"
                class="fileupload elevation-0 rounded-lg text-center pa-2" style="border: 1px dashed #d0d3d6">
                <!-- <v-card class="pa-3" @click="openFileInput()"> -->
                <!-- <div align="center"> -->
                <!-- <p style="font-weight:499;font-size:15px;color:#0037B7"  class="ma-0"> Click here to upload</p> -->
                <!-- <p>You can drop your last 6 months Bank statement here to be uploaded</p> -->
                <div class="innerfilup">
                  <!-- <img class="mb-2" src="@/assets/file drop.svg" width="48px" /> -->
                  <p class="font-weight-bold fs-18 mb-1" style="color: #0037b7 !important;margin-bottom: 0.1em;">
                    Click here to upload
                  </p>
                  <p class="mb-0 txt-666 font-weight-regular" style="line-height: 18px">
                    You can drop your Bank proof here to be uploaded PDF file format Only Accepted
                  </p>
                </div>
                <input type="file" id="items" accept=".pdf" name="items[]" required multiple @change="onChange" />

                <!-- </div> -->
              </v-card>
              <div v-if="panFile">
                <p>
                  <span class="txt-666">File names</span> :
                  <b>{{ panimagedata.name }}</b>
                </p>
                <div class="text-right mb-2">
                  <!-- <v-card width="100%" class="elevation-0 mb-16 mb-md-0" style="transform: scaleX(-1) !important">
                                <iframe style="border: none; border-radius: 8px;" :src="panimage" width="100%"
                                    height="240"></iframe>
                            </v-card> -->
                </div>
              </div>
              <!-- Hidden default v-file-input -->

              <!-- <v-file-input v-model="file" ref="fileInput" style="display: none;"></v-file-input> -->

              <div v-if="passwordField">
                <p class="ma-0 pa-0" style="font-weight: 499">
                  Password <span style="color: red">*</span>
                </p>
                <v-text-field class="pa-0" placeholder="Pdf Password" v-model="protedpdfpass"></v-text-field>
              </div>
              <v-checkbox class="mt-0 py-3 " :on-icon="'mdi-checkbox-marked-circle'"
                :off-icon="'mdi-checkbox-blank-circle-outline'" hide-details v-model="defaultCheckbox"
                label="Set as primary"></v-checkbox>
            </v-card-text>
            <v-card-text v-if="ifscBankInfo.BRANCH" class="py-0">
              <h4>{{ ifscBankInfo.BANK }}</h4>
              <p class="ma-0 pa-0" style="font-size: 12px">
                {{ ifscBankInfo.BRANCH }}, {{ ifscBankInfo.CITY }},{{
                  ifscBankInfo.STATE
                }}
                | {{ ifscBankInfo.MICR }}
              </p>
            </v-card-text>
            <!-- <p>{{ ifscBankInfo.BANK }}</p> -->

            <v-card-actions class="pb-0">
              <!-- <v-btn elevation="0"
            color="black white--text px-8 "
            rounded
              @click="editdialog = false"
            >
              Close
            </v-btn> -->
              <!-- <p>6666666666666666666666</p> -->              <v-btn :disabled="passwordField == true ? protedpdfpass == '' : emtyvall = false" height="40px"
                elevation="0" color="black white--text" rounded block :loading="bankLoader" @click="addBankapi">
                <span class="text-none px-8">Submit</span>
              </v-btn>
            </v-card-actions>
          </v-form>
        </div>


      </v-card>
    </v-dialog>



    <v-dialog v-model="bankeditdialog"
      :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : 'scale-transition'" persistent
      :fullscreen="$vuetify.breakpoint.xsOnly" :max-width="addNewSeg ? '500' : '420'" min-width="390">
      <v-card class="pb-4 rounded-lg px-md-4">
        <v-snackbar style="z-index: 9999" class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white"
          transition="slide-x-reverse-transition" v-model="errorSnackbar" :timeout="4000" :value="true" color="error">
          <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
          {{ errtext }}
          <v-icon @click="errorSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
        </v-snackbar>

        <div v-if="bankchange">
          <v-card-title style="font-size: 16px">
             Edit Your Bank Details Here <v-spacer></v-spacer>
            <v-btn icon @click="bankeditdialog = false,closeBtn()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-form ref="formbank">
            <v-card-text class="pb-0 pt-0">
              <v-chip-group class="pb-4" column>
                <v-chip @click="bankproofTypes = tag.val" :color="bankproofTypes == tag.val ? '#000' : '#666666'"
                  :text-color="bankproofTypes == tag.val ? '#fff' : '#666666'" v-for="(tag, l) in bankprooftype"
                  :outlined="bankproofTypes != tag.val" :key="l">
                  {{ tag.txt }}
                </v-chip>
              </v-chip-group>
              <p class="ma-0 pa-0" style="font-weight: 499">
                Bank A/C No <span style="color: red">*</span>
              </p>

              <v-text-field readonly disabled class="pa-0" required type="number"  hide-spin-buttons
                :rules="nomineeField" placeholder="Enter Bank A/C No" v-model="bankAccountnumber"></v-text-field>

              <p class="ma-0 pa-0" style="font-weight: 499">
                IFSC CODE <span style="color: red">*</span>
              </p>
              <v-text-field class="pa-0" :rules="IfscRules" @keyup="ifscFetch(bankIfscCode)" placeholder="Enter IFSC Code"
                v-model="bankIfscCode"></v-text-field>

              <!-- PINCODE FOR MANUAL ADDRESS -->

              <!-- <p class="ma-0 pa-0">Account type <span style="color:red">*</span></p>
              <v-select class="pa-0" :items="bankprooftype" :rules="nomineeField" v-model="bankproofTypes"
                placeholder="Select Account type"></v-select> -->

              <p class="ma-0 pa-0" style="font-weight: 499">
                Bank Proof <span style="color: red">*</span>
              </p>
              <!-- <v-file-input  class="pa-0 custom-input" prepend-icon="" prepend-inner-icon="mdi-paperclip" :rules="nomineeField" v-model="bankproffdoc" accept=".pdf" placeholder="Upload Bank Proof">
              
              </v-file-input> -->
              <!-- Your custom trigger element -->
              <!-- <v-btn >Select a file</v-btn> -->

              <v-card color="rgba(246, 247, 247, 0.5)" @ondragover="onChange" :class="dragging ? 'dropAreaDragging' : ''"
                @dragenter="dragging = true" @dragend="dragging = false" @dragleave="dragging = false"
                class="fileupload elevation-0 rounded-lg text-center pa-2" style="border: 1px dashed #d0d3d6">
                <!-- <v-card class="pa-3" @click="openFileInput()"> -->
                <!-- <div align="center"> -->
                <!-- <p style="font-weight:499;font-size:15px;color:#0037B7"  class="ma-0"> Click here to upload</p> -->
                <!-- <p>You can drop your last 6 months Bank statement here to be uploaded</p> -->
                <div class="innerfilup">
                  <!-- <img class="mb-2" src="@/assets/file drop.svg" width="48px" /> -->
                  <p class="font-weight-bold fs-18 mb-1" style="color: #0037b7 !important;margin-bottom: 0.1em;">
                    Click here to upload
                  </p>
                  <p class="mb-0 txt-666 font-weight-regular" style="line-height: 18px">
                    You can drop your Bank proof here to be uploaded PDF file format Only Accepted
                  </p>
                </div>
                <input type="file" id="items" accept=".pdf" name="items[]" required multiple @change="onChange" />

                <!-- </div> -->
              </v-card>
              <div v-if="panFile">
                <p>
                  <span class="txt-666">File names</span> :
                  <b>{{ panimagedata.name }}</b>
                </p>
                <div class="text-right mb-2">
                  <!-- <v-card width="100%" class="elevation-0 mb-16 mb-md-0" style="transform: scaleX(-1) !important">
                                <iframe style="border: none; border-radius: 8px;" :src="panimage" width="100%"
                                    height="240"></iframe>
                            </v-card> -->
                </div>
              </div>
              <!-- Hidden default v-file-input -->

              <!-- <v-file-input v-model="file" ref="fileInput" style="display: none;"></v-file-input> -->

              <div v-if="passwordField">
                <p class="ma-0 pa-0" style="font-weight: 499">
                  Password <span style="color: red">*</span>
                </p>
                <v-text-field class="pa-0" placeholder="Pdf Password" v-model="protedpdfpass"></v-text-field>
              </div>
              <v-checkbox  :readonly="BankData.length == 1" class="mt-0 py-3 " :on-icon="'mdi-checkbox-marked-circle'"
                :off-icon="'mdi-checkbox-blank-circle-outline'" hide-details v-model="defaultCheckbox"
                label="Set as primary"></v-checkbox>
            </v-card-text>
            <v-card-text v-if="ifscBankInfo.BRANCH" class="py-0">
              <h4>{{ ifscBankInfo.BANK }}</h4>
              <p class="ma-0 pa-0" style="font-size: 12px">
                {{ ifscBankInfo.BRANCH }}, {{ ifscBankInfo.CITY }},{{
                  ifscBankInfo.STATE
                }}
                | {{ ifscBankInfo.MICR }}
              </p>
            </v-card-text>
            <!-- <p>{{ ifscBankInfo.BANK }}</p> -->

            <v-card-actions class="pb-0">
              <!-- <v-btn elevation="0"
            color="black white--text px-8 "
            rounded
              @click="editdialog = false"
            >
              Close
            </v-btn> -->
              <!-- <p>6666666666666666666666</p> -->              <v-btn :disabled="passwordField == true ? protedpdfpass == '' : emtyvall = false" height="40px"
                elevation="0" color="black white--text" rounded block :loading="bankLoader" @click="editbankaddBankapi()">
                <span class="text-none px-8">Submit</span>
              </v-btn>
            </v-card-actions>
          </v-form>
        </div>


      </v-card>
    </v-dialog>



















  </div>
</template>
<style lang="scss" scoped>

.v-btn:not(.v-btn--round).v-size--small {
 height: 0px; 
    min-width: 0px;
    /* padding: 0 12.4444444444px; */
    padding-left: 12px;
    padding-right: 12px;

    padding-top:10px;
    padding-bottom:10px;

}
.innerfilup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pl-3.col {
    margin-left: 20px;
}

.fileupload {
  position: relative;
  text-align: center;
  border: 1px solid #ebeef0 !important;
}

.fileupload input {
  cursor: pointer !important;
  width: 100% !important;
  height: 80px !important;
  opacity: 0 !important;
}
</style>

<script src="https://app.digio.in/sdk/v10/digio.js"></script>

<script>
const script = document.createElement("script");
script.src = "https://app.digio.in/sdk/v10/digio.js";
document.body.appendChild(script);
import api from "@/Api.js";

import axios from "axios";
export default {
  // name: "BarChart",
  components: {},
  data() {
    return {
      xsOnly: null,
      smAndDown: null,
      txt: "",
      accountNum: "",
      upiDisabled: false,
      verifyBtn: true,
      upiLoader: false,
      errtext: "",
      errorSnackbar: false,
      snackbar: false,
      checkClientVPA: [],
      verfy: true,
      // items: [],
      isHidden: true,
      client_code: null,
      name: null,
      email: null,
      mobile: null,
      acc_no: null,
      ifsc: null,
      loading: false,
      selection: 0,
      url_data: null,
      oid: null,
      amount: null,
      valid: true,
      vpa: null,
      attrs: null,
      company: null,
      company_code: [],
      amountRules: [
        (v) => !!v || "Enter Amount",
        (v) => (v && v >= 10) || "Should be above 10",
      ],
      upiRules: [
        (v) => !!v || "Enter UPI ID",
        (v) => /.+@.+/.test(v) || "Invalid UPI ID",
      ],
      submit_this_val: null,
      bankDetails: [],
      // snackbar: false,
      clietData: "",
      togg: false,
      selectionAtom: 0,
      profileData: [],
      colatral: "",
      message: "Hai da Goopal",
      secretKey: "asdfghjklzxcvbnm",
      dataToEncrypt: "Sensitive information you want to encrypt.",
      token: "",
      publicIP: "",
      statusChk: [],
      Succtext: "",
      successSnackbar: false,
      profileLoader: true,
      BankData: [],
      submitBtndisable: true,
      equty: [],
      commoditi: [],
      derivat: [],
      editdialog: false,

      //asdkjbnasdl
      chha: false,
      nifity: true,
      widthdat: [50, 50, 50, 50, 80],
      chip1: true,
      chip2: true,
      chip3: true,
      chip4: true,
      linkUrl: "#",
      zebu: "https://zebuetrade.com/",
      toggleMenu: true,
      drawer: false,
      selectedItem: 0,
      tab: 0,
      mobileNumber: "",
      cardTitle: "",
      dependency: ["Self", "Spouse", "child Dependent", "parents Dependent"],
      dependencyValue: "Self",
      number: true,
      otpvalid: "",
      otpValue: "",
      dependentName: "",
      headerProps: {
        class: "custom-header",
      },
      subTitle: "",
      mobileVerification: false,
      emailVerification: false,
      emailid: "",
      emailvalid: true,
      cardLoader: false,
      addressVerification: false,
      newNomineepercent: "",
      newNomineeAddress: "",
      newNomineeDob: "",
      newNomineeRelation: "",
      newNomineeName: "",
      nomineePop: false,
      personalDetail: false,
      activePicker: null,
      date: null,
      datee: null,
      datento: null,
      dateento: null,
      datenthre: null,
      dateenthre: null,

      menuu: false,
      menunto: false,
      menuunto: false,

      menunthre: false,
      menuunthre: false,
      menu: false,
      newNomineeProof: "",
      relationNominee: [
        "Passbook",
        "Latest Statement",
        "Cancelled Cheque",
        
      ],
      newNomineeCity: "",
      newNomineePincode: "",
      newNomineeState: "",
      newNomineeCountry: "",
      pinLoader: false,
      nomineeForm: false,
      yesorno: "no",
      nomineeYes: false,
      nomineeMobile: "",
      nomineeEmail: "",
      checkbox: true,
      newNomineePan: "",

      nomineeMobilento: "",
      nomineeEmailnto: "",
      checkboxnto: true,
      newNomineePannto: "",

      nomineeMobilenthre: "",
      nomineeEmailnthre: "",
      checkboxnthre: true,
      newNomineePannthre: "",
      newNomineeNamenthre: "",
      newNomineeRelationnthre: "",

      optionOfupdate: "",
      radioBtn: false,
      nomineesubmitBtn: false,
      submissionText: "",
      filedegio: "",
      esignBtn: false,
      proofvalue :'',
      newNomineeGuardian: "",
      newNomineeGuardiannto: "",
      newNomineeGuardiannthre: "",

      belowAge: false,
      belowAgento: false,
      belowAgenthre: false,

      newNomineeGuardianRelation: "",
      newNomineeGuardianRelationnto: "",
      newNomineeGuardianRelationnthre: "",
      newNomineegurdianmobile: "",
      newNomineegurdianmobilento: "",
      newNomineegurdianmobilenthre: "",

      checkboxTow: false,
      checkboxThree: false,

      newNomineeNameto: "",
      newNomineeRelationto: "",

      newNomineepercentone: 100,
      newNomineepercentnto: 1,
      newNomineepercentnthre: 1,
      max: 100,
      secnompercentmax: 0,
      thernompercentmax: 0,
      hashpan: "",

      newNomineeGuardianEmail: "",
      newNomineeGuardianAddress: "",
      newNomineeGuardianPincode: "",
      newNomineeGuardianState: "",
      newNomineeGuardianCity: "",
      newNomineeGuardianCountry: "",

      newNomineeGuardianEmailnto: "",
      newNomineeGuardianAddressnto: "",
      newNomineeGuardianPincodento: "",
      newNomineeGuardianStatento: "",
      newNomineeGuardianCitynto: "",
      newNomineeGuardianCountrynto: "",

      newNomineeGuardianEmailnthre: "",
      newNomineeGuardianAddressnthre: "",
      newNomineeGuardianPincodenthre: "",
      newNomineeGuardianStatenthre: "",
      newNomineeGuardianCitynthre: "",
      newNomineeGuardianCountrynthre: "",

      nominee2: false,
      newNomineeName2: "",
      newNomineeRelation2: "",
      newNomineeDob2: "",
      newNomineepercent2: "",

      nominee3: false,
      newNomineeName3: "",
      newNomineeRelation3: "",
      newNomineeDob3: "",
      newNomineepercent3: "",

      usernewmobile: "",
      newmobilerules: [
        (v) => !v || v.length <= 10 || "Enter a valid mobile number",
        (v) => !!v || "Mobile is required",
      ],
      requestotp: true,
      verifyotp: false,
      userOtp: "",
      editType: "bank_change",
      mobStatus: [],
      mobileDisable: false,
      usernewemail: "",
      requestotpemail: false,
      useremailOtp: "",
      verifyotpemail: false,
      usernewAdress: "",
      adresschange: false,
      proofTypes: "",
      prooftype: ["Aadhar card", "Passport", "VoterID", "Driving licence"],
      emailDisable: false,
      IfscRules: [
        (v) => !!v || "IFSC is required",
        (v) => /^\w{11}$/.test(v) || "Must be exactly 11 digits",
      ],

      adressproffdoc: null,
      manual: false,
      addressDisable: false,
      blurLoader: false,
      emailLoader: false,
      addressLoader: false,
      mobileLoader: false,
      userEmailadress: "",
      stateAddress: "",
      countryAddress: "",
      distAddress: "",
      pincodeAddress: "",

      // BANK INIT
      bankproffdoc: null,
      bankAccountnumber: "",
      bankIfscCode: "",
      bankchange: false,

      ifscBankInfo: [],
      // setDefault:'',
      existingBanks: [],
      defaultCheckbox: false,
      confirmation: false,
      canconfirmation: false,

      deletebank: [],
      bankLoader: false,
      annualIncomechange: false,
      selectionas: null,
      proofField: false,
      incorrectPass: false,
      passwordField: false,
      typesOfimg: [
        "Latest 6 months Bank Statement",
        "Latest ITR Copy",
        "Latest 6 months salary slip",
        "DP holding statement as on date",
        "Net worth Certificate",
        "Copy of Form 16 in case of salary income",
      ],
      imgType: null,
      typeProof: "",
      pdfPassword: "",
      passwordeye: false,
      paswordverification: true,
      annualuserOtp: "",
      annualIncomeverifyotp: false,
      items: ["Below 1L", "1L to 5L", "5L to 10L", "10L to 25L", "Above 25L"],
      itemSelected: "",
      yearlyIncomeDisable: false,
      segments: false,
      nsecash: false,
      bsecash: false,
      mfbse: false,
      bsefno: false,
      nsefno: false,
      cdnse: false,
      cdbse: false,
      // cdbse:false,
      bsecom: false,
      nsecom: false,
      icex: false,
      mcx: false,
      segOpt: false,
      addNewSeg: false,
      activateSeg: false,
      tok: "",

      nsecashDisable: false,
      bsecashDisable: false,
      mfDisable: false,

      bsefnoDisable: false,
      nsefnoDisable: false,
      cdnseDisable: false,
      cdbseDisable: false,

      bsecomDisable: false,
      nsecomDisable: false,
      icexDisable: false,
      mcxDisable: false,

      activateSegBtn: false,
      addsegtype: "",
      newSegements: [],
      segmentbankproffdoc: null,
      inactiveSegments: [],
      existingSegments: [],
      allSegments: [
        "BSE_CASH",
        "MF_BSE",
        "NSE_CASH",
        "BSE_FNO",
        "NSE_FNO",
        "CD_NSE",
        "CD_BSE",
        "BSE_COM",
        "NSE_COM",
        "ICEX",
        "MCX",
      ],
      newSegments: [],
      newSegmentSelected: [],
      bankproffdocument: false,
      addsegtypeNew: "",
      mfnse: "",
      nseslbm: "",
      deactivateConfirmation: false,
      aadharstate: "",
      aadharcode: "",
      banklogo: "https://rekycbe.mynt.in/autho",
      reasons: [
        "High brokerage and charges",
        "Monthly maintenance charges",
        "Need better margins",
        "Problem with payment and withdrawal",
        "Need better order placement options",
        "App crashes",
        "Issues with back office portals",
        "Problem with customer service",
        "App is complicated to understand",
        "Faced losses",
        "No time to focus on trading",
      ],
      closureReason: "",
      deactivateForm: true,
      deactivateCanceled: false,
      itemsaaass: [
        { text: "My Account" },
        {
          text: "Profile Details",
          icon: "",
          link: "/accountpage",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Bank Accounts Linked",
          icon: "",
          link: "/bankaccount",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Set Auto Pay",
          icon: "",
          link: "/autopay",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Margin against stocks",
          icon: "",
          link: "/noticationsettings",
          mdiicon: "mdi-chevron-right",
        },
        { text: "Manage Funds", link: "secufund" },
        {
          text: "Securities Funds ",
          icon: "",
          link: "managefund",
          mdiicon: "mdi-chevron-right",
          accountstatus:""
        },
      ],
      actid: "",
      susertoken: "",
      noms: [
        { txt: "Yes, add nominee", val: "yes" },
        { txt: "No, i will do it later", val: "no" },
      ],
      bankprooftype: [
        { txt: "Saving Account", val: "Saving Account" },
        { txt: "Current Account", val: "Current Account" },
      ],
      bankproofTypes: "Saving Account",
      file: null,
      dragging: false,
      panimagedata: [],
      panimage: null,
      panFile: "",
      BankProofselect: "bs",
      customLabel: "Nominee address is same as my address",
      panel: 0,
      cardloader: false,
      dissBtn: true,
      dissBtn2: true,
      dissBtn3: true,
      activetab: "tab1",
      nosegmentsPop: false,
      newFamilyRelation: "",
      memberId: "",
      memberMobile: "",
      memberPan: "",
      validFamily: "",
      familyloader: false,
      familySubmission: false,
      endtxt: '',
      familyRequestData: '',
      familystatusloading: false,
      familyrequest: false,
      selectTableitem: [],
      mobileMember: '',
      disabled: false,
      readonly: false,
      setasPrimaryconfirmation: false,
      primaryAcc: '',
      fileide: '',
      fileddata: '',
      protedpdfpass: "",
      emtyvall: null,
      count: 0,
      bankeditdialog:false,
      bankstname:'',
      manulabankname:'',
      toasttext:""
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menunto(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuunto(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menunthre(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuunthre(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    rejectEsign() {
      let data = JSON.stringify({
        "client_id": this.profileData.CLIENT_ID,
        "file_id": this.fileide,
        "type": "bank_change"
      });

      let config = {
        method: 'post',
        url: api.api_url + '/manual_cancel_request',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          this.canconfirmation = false
          // console.log(JSON.stringify(response.data));
          if (response.data.msg == 'canceled successfully') {
            this.Succtext = 'Canceled successfully'
            this.successSnackbar = true
            this.fetchMobileStatus()
            location.reload();
          } else {
            this.errtext = response.data.msg
            this.errorSnackbar = true
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    samemembercheck() {
      if (this.memberId == this.client_code) {
        this.memberId = ''
        this.errorSnackbar = true;
        this.errtext = "Not allowed";
      }
    },
    setasPrimary(aa) {
      // console.log('set as primary',aa)
      this.setasPrimaryconfirmation = true

      this.primaryAcc = aa
      for (var i = 0; i < this.BankData.length; i++) {
        this.existingBanks.push({
          acc_no: this.BankData[i].Bank_AcNo,
          ifsc_no: this.BankData[i].IFSC_Code,
        });
      }
    },
    setPrimaryBankapi() {
      const FormData = require("form-data");
      let data = new FormData();
      var axiosThis = this
      data.append("proff", this.panFile);
      data.append("bank_exists", JSON.stringify(this.existingBanks));

      let config = {
        method: "post",
        url:
          api.api_url +
          "/bank?mob=" +
          this.profileData.MOBILE_NO +
          "&bankName=" +
          this.primaryAcc.Bank_Name +
          "&ifsc=" +
          this.primaryAcc.IFSC_Code +
          "&accountNo=" +
          this.primaryAcc.Bank_AcNo +
          "&branch=" + "" +
          "&bank_account_type=" +
          this.primaryAcc.BANK_ACCTYPE +
          "&client_name=" +
          this.name +
          "&option=modify&client_id=" +
          this.client_code +
          "&set_defalut=" + "yes" +
          "&dp_code=" +
          this.profileData.CLIENT_DP_CODE +
          "&micr=" +
          this.primaryAcc.Micr_code +
          "&client_email=" +
          this.profileData.CLIENT_ID_MAIL.toUpperCase(),

        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      // console.log("pppp",this.existingBanks)
      axios
        .request(config)
        .then((response) => {
          axiosThis.blurLoader = false;

          // console.log(response.data);
          if (response.data.msg) {
            axiosThis.errorSnackbar = true;
            axiosThis.errtext = response.data.msg + " " + "Dont Refresh this page" + " " + "Please Try again in 3 attempts";
          } else {
            axiosThis.digioEsign(
              response.data.fileid,
              response.data.mailid,
              response.data.session
            );
            axiosThis.setasPrimaryconfirmation = false
          }
        })
        .catch((error) => {
          axiosThis.blurLoader = false;
          console.log(error);
        });
    },

    onChange(e) {
      let file = e.target.files[0] || e.dataTransfer.files[0];
      // console.log(file["type"]);
      if (
        file["type"] == "application/pdf"
      ) {
        this.panFile = file;
        this.panimage = window.URL.createObjectURL(this.panFile);
        this.panimagedata["name"] = this.panFile.name;
        this.panimagedata["size"] = (this.panFile.size / 1048576).toFixed(1);
        this.passHide();
      } else {
        this.cardloader = false;
        this.errtext = "Only pdf formats are supported.";
        // this.snackbar = true;
        this.errorSnackbar = true
      }
    },
    goSso() {
      // if( data)
      // window.location.assign(`http://192.168.5.152:8082/?url=${window.location.href}`)

      window.location.assign(
        `https://desk.mynt.in/?url=${window.location.href}`
      );
    },
    deletebankk(bank) {
      this.deletebank = bank;
      this.confirmation = true;
    },
    sameBankcheck() {
      for (var i = 0; i < this.BankData.length; i++) {
        if (this.bankAccountnumber == this.BankData[i].Bank_AcNo) {
          this.bankAccountnumber = "";
          this.errorSnackbar = true;
          this.errtext = "Enter your new Account number";
        }
      }
    },
    usercopytest() {
      // console.log(this.usernewemail.toUpperCase(),this.profileData.CLIENT_ID_MAIL.toUpperCase())
      if (
        this.usernewemail.toUpperCase() ==
        this.profileData.CLIENT_ID_MAIL.toUpperCase()
      ) {
        this.usernewemail = "";
        this.errorSnackbar = true;
        this.errtext = "Enter your new email id";
      }
    },
    addBankapi() {
      this.incrementCounter()
      this.existingBanks = [];
      var valid = this.$refs.formbank.validate();
      var axiosThis = this;
      // console.log("qqqqqqqq",valid == true && this.ifscBankInfo.BANK && this.panFile && this.proofvalue)
      // console.log("qqqqqqqq",valid == true, this.ifscBankInfo.BANK, this.panFile , this.proofvalue)
      // console.log("qqqqqqqq5", this.ifscBankInfo)
      // console.log("qqqqqqqq5",this.panFile )
      // console.log("qqqqqqqq5" , this.proofvalue)
      // console.log("ffff",this.proofvalue)
      // console.log(this.ifscBankInfo.BANK , this.panFile, 'akjadsfkj')
      if (valid == true && (this.ifscBankInfo.BANK || this.manulabankname) && this.panFile && this.proofvalue) {
        this.blurLoader = true;

        for (var i = 0; i < this.BankData.length; i++) {
          this.existingBanks.push({
            acc_no: this.BankData[i].Bank_AcNo,
            ifsc_no: this.BankData[i].IFSC_Code,
          });
        }
        // console.warn( this.existingBanks,"s]]]]]]]]]]]]]]]]")

        if (this.defaultCheckbox) {
          var setdef = "yes";
        } else {
          setdef = "no";
        }
        const FormData = require("form-data");
        let data = new FormData();
        data.append("proff", this.panFile);
        data.append("bank_exists", JSON.stringify(this.existingBanks));

        let config = {
          method: "post",
          url:
            api.api_url +
            "/bank?mob=" +
            this.profileData.MOBILE_NO +
            "&bankName=" +
            (this.ifscBankInfo.BANK || this.manulabankname) +
            "&ifsc=" +
            this.ifscBankInfo.IFSC +
            "&accountNo=" +
            this.bankAccountnumber +
            "&proff_type=" + 
            this.proofvalue +
            "&branch=" +
            this.ifscBankInfo.BRANCH +
            "&bank_account_type=" +
            this.bankproofTypes +
            "&client_name=" +
            this.name +
            "&option=add&client_id=" +
            this.client_code +
            "&set_defalut=" +
            setdef +
            "&dp_code=" +
            this.profileData.CLIENT_DP_CODE +
            "&micr=" +
            this.ifscBankInfo.MICR +
            "&client_email=" +
            this.profileData.CLIENT_ID_MAIL.toUpperCase() +
            "&password_required=" + (this.fileddata == 'False' ? 'NO' : 'YES')
            + "&password=" + (this.fileddata == 'False' ? '' : this.protedpdfpass) 
            +"&count="+localStorage.getItem('count')
            ,


          headers: {
            Authorization: this.tok,
            clientid: this.profileData.CLIENT_ID,
            "Content-Type": "multipart/form-data",
          },
          data: data,
        };
        // console.log("pppp",this.existingBanks)
        axios
          .request(config)
          .then((response) => {
            axiosThis.blurLoader = false;

            // console.log(response.data);
            if (response.data.msg) {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = response.data.msg +" "+  "Dont Refresh this page" + " " + "Please Try again in 3 attempts";
            } else {
              axiosThis.digioEsign(
                response.data.fileid,
                response.data.mailid,
                response.data.session
              );
            }
          })
          .catch((error) => {
            axiosThis.blurLoader = false;
            console.log(error);
          });
      }else if(this.ifscBankInfo.BANK == null){
        (this.errorSnackbar = true), (this.errtext = "Not fetch your bank name so please enter it ")
      } 
       else {
        (this.errorSnackbar = true), (this.errtext = "Please make sure all required fields are filled.");
      }
    },


    editbankaddBankapi() {
      this.incrementCounter()
      this.existingBanks = [];
      var valid = this.$refs.formbank.validate();
      var axiosThis = this;
      // console.log(this.ifscBankInfo.BANK , this.panFile, 'akjadsfkj')
      if (valid == true && this.ifscBankInfo.BANK && this.panFile) {
        this.blurLoader = true;

        for (var i = 0; i < this.BankData.length; i++) {
          this.existingBanks.push({
            acc_no: this.BankData[i].Bank_AcNo,
            ifsc_no: this.BankData[i].IFSC_Code,
          });
        }
        // console.warn( this.existingBanks,"s]]]]]]]]]]]]]]]]")

        if (this.defaultCheckbox) {
          var setdef = "yes";
        } else {
          setdef = "no";
        }
        const FormData = require("form-data");
        let data = new FormData();
        data.append("proff", this.panFile);
        data.append("bank_exists", JSON.stringify(this.existingBanks));

        let config = {
          method: "post",
          url:
            api.api_url +
            "/bank?mob=" +
            this.profileData.MOBILE_NO +
            "&bankName=" +
            this.ifscBankInfo.BANK +
            "&ifsc=" +
            this.ifscBankInfo.IFSC +
            "&accountNo=" +
            this.bankAccountnumber +
            "&branch=" +
            this.ifscBankInfo.BRANCH +
            "&bank_account_type=" +
            this.bankproofTypes +
            "&client_name=" +
            this.name +
            "&option=modify&client_id=" +
            this.client_code +
            "&set_defalut=" +
            setdef +
            "&dp_code=" +
            this.profileData.CLIENT_DP_CODE +
            "&micr=" +
            this.ifscBankInfo.MICR +
            "&client_email=" +
            this.profileData.CLIENT_ID_MAIL.toUpperCase() +
            "&password_required=" + (this.fileddata == 'False' ? 'NO' : 'YES')
            + "&password=" + (this.fileddata == 'False' ? '' : this.protedpdfpass) 
           
            ,


          headers: {
            Authorization: this.tok,
            clientid: this.profileData.CLIENT_ID,
            "Content-Type": "multipart/form-data",
          },
          data: data,
        };
        // console.log("pppp",this.existingBanks)
        axios
          .request(config)
          .then((response) => {
            axiosThis.blurLoader = false;

            // console.log(response.data);
            if (response.data.msg) {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = response.data.msg +" "+  "Dont Refresh this page" + " " + "Please Try again in 3 attempts";
            } else {
              axiosThis.digioEsign(
                response.data.fileid,
                response.data.mailid,
                response.data.session
              );
            }
          })
          .catch((error) => {
            axiosThis.blurLoader = false;
            console.log(error);
          });
      } else {
        (this.errorSnackbar = true), (this.errtext = "Please make sure all required fields are filled.");
      }
    },
















    editbank(bank){
console.log("ghch",bank)
this.bankeditdialog = true
// this.ifscFetch(ifsc)
this.bankchange = true;

        this.bankproofTypes = bank.BANK_ACCTYPE == 'Saving' ? "Saving Account" : "Current Account";
        

this.bankAccountnumber = bank.Bank_AcNo,
this.bankIfscCode = bank.IFSC_Code
this.defaultCheckbox = bank.Default_Ac == 'Yes' ? true : false
this.ifscFetch(this.bankIfscCode);
    },

    incrementCounter() {
      // Increment the count and store it in local storage as a string
      this.count++;
      localStorage.setItem('count', String(this.count));
    },
    getCountFromLocalStorage() {
      // Retrieve the count from local storage
      const count = localStorage.getItem('count');
      if (count !== null) {
        // Convert the count from a string to a number and set it to the data property
        this.count = parseInt(count);
      }
    },


    deleteBankapi() {
      var deletion = this.deletebank;
      this.blurLoader = true;
      this.existingBanks = [];
      // console.log(deletion)
      // var valid = this.$refs.formbank.validate();
      var axiosThis = this;
      // if(valid == true) {
      for (var i = 0; i < this.BankData.length; i++) {
        this.existingBanks.push({
          acc_no: this.BankData[i].Bank_AcNo,
          ifsc_no: this.BankData[i].IFSC_Code,
        });
      }
      // console.warn( this.existingBanks,"s]]]]]]]]]]]]]]]]")

      // if(this.defaultCheckbox) {
      //   var setdef = "yes"
      // } else {
      //    setdef = "no"
      // }
      const FormData = require("form-data");
      let data = new FormData();
      data.append("proff", this.bankproffdoc);
      data.append("bank_exists", JSON.stringify(this.existingBanks));

      let config = {
        method: "post",
        url:
          api.api_url +
          "/bank?mob=" +
          this.profileData.MOBILE_NO +
          "&bankName=" +
          deletion.Bank_Name +
          "&ifsc=" +
          deletion.IFSC_Code +
          "&accountNo=" +
          deletion.Bank_AcNo +
          "&branch=" +
          "" +
          "&bank_account_type=" +
          deletion.BANK_ACCTYPE +
          "&client_name=" +
          this.profileData.CLIENT_NAME +
          "&option=delete&client_id=" +
          this.client_code +
          "&set_defalut=" +
          "no" +
          "&dp_code=" +
          this.profileData.CLIENT_DP_CODE +
          "&micr=" +
          deletion.Micr_code +
          "&client_email=" +
          this.profileData.CLIENT_ID_MAIL,
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          axiosThis.blurLoader = false;
          axiosThis.confirmation = false;
          // console.log(response.data);
          if (response.data.msg) {
            axiosThis.errorSnackbar = true;
            axiosThis.errtext = response.data.msg +" " + "Dont Refresh this page" + " " + "Please Try again in 3 attempts";
          } else {
            axiosThis.digioEsign(
              response.data.fileid,
              response.data.mailid,
              response.data.session
            );
          }
        })
        .catch((error) => {
          axiosThis.blurLoader = false;
          console.log(error);
        });
      // }
    },
    connectDigio() {
      var stateid = "signup" + Math.random().toString(36).substring(7);
      // window.location.href =
      //   "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=A73859D2&state=" +
      //   stateid +
      //   "";
      // for local
      window.location.href =
        "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=A987F208&state=" +
        stateid +
        "";
    },
    pincodeFetch() {
      this.pinLoader = true;
      var axiosThis = this;
      // console.warn("hh");
      if (this.newNomineePincode.length >= 6) {
        let config = {
          method: "get",
          url: "https://api.postalpincode.in/pincode/" + this.newNomineePincode,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeState = resp.State;
            axiosThis.newNomineeCountry = resp.Country;
            axiosThis.newNomineeCity = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }
      if (this.pincodeAddress.length >= 6) {
        let config = {
          method: "get",
          url: "https://api.postalpincode.in/pincode/" + this.pincodeAddress,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.stateAddress = resp.State;
            axiosThis.countryAddress = resp.Country;
            axiosThis.distAddress = resp.District;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }

      if (this.newNomineeGuardianPincode.length >= 6) {
        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" +
            this.newNomineeGuardianPincode,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeGuardianState = resp.State;
            axiosThis.newNomineeGuardianCountry = resp.Country;
            axiosThis.newNomineeGuardianCity = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }

      if (this.newNomineeGuardianPincodento.length >= 6) {
        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" +
            this.newNomineeGuardianPincodento,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeGuardianStatento = resp.State;
            axiosThis.newNomineeGuardianCountrynto = resp.Country;
            axiosThis.newNomineeGuardianCitynto = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }

      if (this.newNomineeGuardianPincodenthre.length >= 6) {
        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" +
            this.newNomineeGuardianPincodenthre,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeGuardianStatenthre = resp.State;
            axiosThis.newNomineeGuardianCountrynthre = resp.Country;
            axiosThis.newNomineeGuardianCitynthre = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }

      if (this.newNomineePincodento.length >= 6) {
        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" + this.newNomineePincodento,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeStatento = resp.State;
            axiosThis.newNomineeCountrynto = resp.Country;
            axiosThis.newNomineeCitynto = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }
      if (this.newNomineePincodenthre.length >= 6) {
        let config = {
          method: "get",
          url:
            "https://api.postalpincode.in/pincode/" +
            this.newNomineePincodenthre,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.pinLoader = false;

            var resp = response.data[0].PostOffice[0];
            axiosThis.newNomineeStatenthre = resp.State;
            axiosThis.newNomineeCountrynthre = resp.Country;
            axiosThis.newNomineeCitynthre = resp.Division;

            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            axiosThis.pinLoader = false;

            console.log(error);
          });
      }
    },
    digioEsign(file, email, session) {
      var axo = this;
      this.blurLoader = true;
      axo.editdialog = false;
      axo.bankeditdialog = false
      // console.log(file, email,session)
      // localStorage.setItem('fileid', this.digioFile)
      var options = {
        environment: "production",
        callback: function (t) {
          // axo.statusCheckNominee(axo.client_code);
          // console.log(t);
          // console.log(axo.stata);
          axo.blurLoader = false;

          if (axo.stata != undefined) {
            if (axo.stata.app_status == "e-signed pending") {
              axo.radioBtn = true;
              axo.nomineesubmitBtn = true;
            } else {
              axo.radioBtn = false;
              axo.nomineesubmitBtn = false;
            }
          } else {
            axo.radioBtn = false;
            axo.nomineesubmitBtn = false;
          }

          if (t.message == "Signing cancelled") {
            // if(axo.editType == 'mobile_change') {
            axo.filedownloadDatabase("failure", file);
            axo.fetchMobileStatus();

            // }
            // if(axo.editType == 'email_change') {
            // axo.filedownloadDatabase("failure", file);
            // axo.fetchMobileStatus()

            // }
            // if(axo.editType == 'address_change') {
            // axo.filedownloadDatabase("failure", file);
            // axo.fetchMobileStatus()
            // }
          } else if (t.message == "Signed Successfully") {
            // axo.filedownloadDatabase("success", axo.filedegio?axo.filedegio:axo.stata.file_id);
            // if(axo.editType == 'mobile_change') {
            axo.filedownloadDatabase("success", file);
            // }
            // if(axo.editType == 'email_change') {
            // axo.filedownloadDatabase("success", file);
            // }
            // if(axo.editType == 'address_change') {
            // axo.filedownloadDatabase("success", file);
            // }
          }

          // if (t["hasOwnProperty"]("error_code")) {
          //   // document.getElementById("loading").style.display='none';
          //   //// console.log('Error Digio')
          //   //// console.log(t.message)
          // } else {
          //   //document.getElementById("result").innerHTML="API Mandate Authentication Successful"
          //   //successDigio();
          //   //// console.log(this.step, this.ekycStatus);
          //   // setTimeout(function () {
          //   //code goes here
          //   // window.location.reload();
          //   // }, 5200);
          // }
        },
      };

      var digio = new Digio(options);
      digio.init(); // Call init on user action (eg.button press and proceed to asynchronous upload)
      // digio.submit(this.digioFile, this.digioMail,);
      // console.log(file,email,session)
      digio.submit(file, email, session);
      // digio.submit("DID230911100935062BG8PAZ3INK43XM", "mageshjack002@gmail.com","GWT2309111009374289K3NVXB6RHUEDO");

      // console.log(digio)
    },
    filedownloadDatabase(stat, fileid) {
      let data = JSON.stringify({
        client_id: this.client_code,
        file_id: fileid,
        response: stat,
        type: this.editType,
      });

      let config = {
        method: "post",

        // url: 'http://192.168.5.121:5555/filedownload',

        url: api.api_url + "/filedownload",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "application/json",
        },
        data: data,
      };
      let axiosThis = this;

      axios
        .request(config)
        .then((response) => {
          if (response.data.emsg == "token expired") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Expired";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();
              window.location.reload();
            }, 2000);
          }
          if (response.data.emsg == "invalid token") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Invalid";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();

              window.location.reload();
            }, 2000);
          }
          // console.log(JSON.stringify(response.data));
          window.location.reload();

        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchMobileStatus() {
      var axiosThis = this;
      let data = JSON.stringify({
        client_id: this.profileData.CLIENT_ID,
      });

      let config = {
        method: "post",
        url: api.api_url + "/add_mob_email_stat",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "application/json",
        },
        data: data,
      };
      // console.log("jhasdgajb",this.client_code,this.tok)
      axios
        .request(config)
        .then((response) => {
          if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "clientid not exist") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }


          // console.log(response.data);
          axiosThis.blurLoader = false;
          axiosThis.mobStatus = response.data;
          if (
            axiosThis.mobStatus.mobile_status == "e-signed pending" ||
            axiosThis.mobStatus.address_status == "e-signed pending" ||
            axiosThis.mobStatus.email_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.mobileDisable = true;
            axiosThis.emailDisable = true;
            axiosThis.addressDisable = true;
          }
          if (
            axiosThis.mobStatus.address_status == "e-signed completed" ||
            axiosThis.mobStatus.address_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.addressDisable = true;
          }
          if (
            axiosThis.mobStatus.mobile_status == "e-signed completed" ||
            axiosThis.mobStatus.mobile_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.mobileDisable = true;
          }
          if (
            axiosThis.mobStatus.email_status == "e-signed completed" ||
            axiosThis.mobStatus.email_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.emailDisable = true;
          }
          if (
            axiosThis.mobStatus.income_status == "e-signed completed" ||
            axiosThis.mobStatus.income_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.yearlyIncomeDisable = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendButtonClicked() {
      this.tok = localStorage.getItem("usession");

      var axiosThis = this;
      if (this.currentClick == "mobile") {
        var emailvalid = this.$refs.form.validate();
        if (emailvalid == true) {
          this.mobileLoader = true;

          this.cardLoader = true;
          // console.log("josi");
          var dall = JSON.stringify({
            prev_mobile_no: this.profileData.MOBILE_NO,
            pres_mobile_no: this.usernewmobile,
            client_id: this.client_code,
            client_name: this.name,
            client_email: this.profileData.CLIENT_ID_MAIL.toUpperCase(),
          });
          // var enc = this.encryptionFunction(dall);
          // console.log("status payload", dall);
          // let data = JSON.stringify({
          //   string: enc,
          // });
          let data = dall;

          let config = {
            method: "post",
            url: api.api_url + "/mob_otp_req",
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Invalid";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              // var res = axiosThis.decryptionFunction(response.data.str);
              // console.warn(response);
              axiosThis.mobileLoader = false;

              var responceReq = response.data;
              axiosThis.cardLoader = false;
              // console.log(JSON.stringify(response.data));
              if (responceReq.msg == "otp sent") {
                axiosThis.requestotp = false;
                axiosThis.verifyotp = true;

                axiosThis.Succtext = "OTP Sent";
                axiosThis.successSnackbar = true;
              } else if (responceReq.msg == "already this mobile number used") {
                axiosThis.usernewmobile = "";
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = "Entered Number was already available";
              }
            })
            .catch((error) => {
              axiosThis.cardLoader = false;
              console.log(error);
            });
        }
      }
      if (this.currentClick == "email") {
        var valid = this.$refs.formemailreq.validate();
        if (valid == true) {
          this.emailLoader = true;
          this.cardLoader = true;
          // console.log("email");
          this.userEmailadress = this.usernewemail;
          let data = JSON.stringify({
            client_id: this.client_code,
            previous_email: this.profileData.CLIENT_ID_MAIL,
            present_email: this.usernewemail.toUpperCase(),
            client_name: this.name,
          });

          let config = {
            method: "post",
            url: api.api_url + "/mail_otpsend",
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Invalid";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              // axiosThis.cardLoader = false;
              axiosThis.emailLoader = false;

              // console.log(JSON.stringify(response.data));
              if (response.data.msg == "success send mail") {
                axiosThis.requestotpemail = false;
                axiosThis.verifyotpemail = true;
              } else if (response.data.msg == "Mobile already exists") {
                axiosThis.mobileNumber = "";
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = "Entered Number was already available";
              } else {
                axiosThis.mobileNumber = "";
                axiosThis.errorSnackbar = true;
                axiosThis.errtext = response.data.msg;
              }
            })
            .catch((error) => {
              axiosThis.cardLoader = false;
              console.log(error);
            });
        }
      }
    },

    checkSame() {
      if (this.profileData.MOBILE_NO == this.usernewmobile) {
        this.usernewmobile = "";
        this.errorSnackbar = true;
        this.errtext = "Enter your new Mobile number";
      }
    },
    ifscFetch(ifsc) {
      var axiosThis = this;
      this.bankIfscCode = this.bankIfscCode.toUpperCase();
      if (ifsc.indexOf(" ") >= 0) {
        // console.log("contains spaces",this.bankIfscCode);
        this.bankIfscCode = ifsc.replace(/\s+/g, "");
      }
      if (this.bankIfscCode.length >= 11) {
        let config = {
          method: "get",
          url: "https://ifsc.razorpay.com/" + ifsc,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            // console.log(response.data);
            axiosThis.ifscBankInfo = response.data;
            axiosThis.bankstname = response.data.BANK;
             if(this.ifscBankInfo.BANK == null){
        (this.errorSnackbar = true), (this.errtext = "Not fetch your bank name so please enter it Manualy")
      } 

          })
          .catch((error) => {
            axiosThis.ifscBankInfo = [];
            console.log(error);
          });
      }
    },
    incomeproof() {
      if (this.selectionas == 4) {
        this.proofField = true;
      } else {
        this.proofField = false;
        this.passwordField = false;
        this.incorrectPass = false;
      }
    },
    passHide() {
      // let file = e.target.files[0] || e.dataTransfer.files[0]
      //           this.panFile = file;

      var axiosThis = this;
      this.passwordField = false;
      const FormData = require("form-data");
      let data = new FormData();
      data.append("client_id", this.client_code);
      data.append("proff_file", this.panFile);
      data.append("checktype", 'bank');


      let config = {
        method: "post",
        url: api.api_url + "/pdf_lock",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          // checktype:"bank",
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          // console.log(response.data);
          axiosThis.fileddata = response.data["password required"]
          // console.log('axxxx', axiosThis.fileddata);
          if (response.data["password required"] == "True") {
            axiosThis.passwordField = true;
            axiosThis.passwordRequired = true;
            // axiosThis.paswordverification = true
          } else {
            axiosThis.passwordRequired = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    mobileEdit(data) {
      this.tok = localStorage.getItem("usession");

      if (data == "mobile") {
        this.currentClick = "mobile";
        this.editType = "mobile_change";
        // this.cardTitle = 'Mobile Number Verification'
        this.subTitle = "Enter your new mobile number";

        this.bankchange = false;
        this.editdialog = true;

        this.annualIncomechange = false;
        this.mobileLoader = false;

        this.nosegmentsPop = false;
        this.segments = false;

        this.adresschange = false;
        // this.addressVerification = false;
      }
      if (data == "email") {
        this.currentClick = "email";
        this.editType = "email_change";
        this.requestotpemail = true;
        this.requestotp = false;
        this.editdialog = true;

        this.nosegmentsPop = false;
        this.bankchange = false;
        this.annualIncomechange = false;
        this.annualIncomeverifyotp = false;
        this.segments = false;

        // this.addressVerification = false;
        this.adresschange = false;
        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Enter your new email number";
      }
      if (data == "address") {
        this.currentClick = "address";
        this.editType = "address_change";
        this.adressOptions = true;
        this.manual = false;
        this.nosegmentsPop = false;
        this.editdialog = true;

        this.requestotp = false;
        this.requestotpemail = false;
        this.adresschange = true;
        this.bankchange = false;
        this.annualIncomechange = false;
        this.annualIncomeverifyotp = false;
        this.segments = false;

        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Would you like to change adressd ";
      }
      if (data == "addbank") {
        this.currentClick = "addbank";
        this.editType = "bank_change";
        this.adressOptions = false;
        this.manual = false;
        this.editdialog = true;

        this.requestotp = false;
        this.nosegmentsPop = false;
        this.requestotpemail = false;
        this.adresschange = false;
        this.segments = false;
        this.annualIncomechange = false;
        this.bankproofTypes = "Saving Account";
        this.bankchange = true;
        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Would you like to change adressd ";
      }
      if (data == "income") {
        this.currentClick = "income";
        this.editType = "income_change";
        this.adressOptions = false;
        this.manual = false;
        this.requestotp = false;
        this.requestotpemail = false;
        this.editdialog = true;

        this.adresschange = false;
        this.bankchange = false;
        this.segments = false;
        this.annualIncomechange = true;
        this.nosegmentsPop = false;
        this.annualIncomeverifyotp = false;
        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Would you like to change adress ";
        if (this.profileData.ANNUAL_INCOME == "Less Then One Lakhs") {
          this.selectionas = 0;
          // console.log("responce",0);
        }
        if (this.profileData.ANNUAL_INCOME == "One To Five Lakhs") {
          this.selectionas = 1;
          // console.log("responce",1);
        }
        if (this.profileData.ANNUAL_INCOME == "Five To Ten Lakhs") {
          // console.log("responce",2);
          this.selectionas = 2;
        }
        if (this.profileData.ANNUAL_INCOME == "Ten To TwentyFive Lakhs") {
          // console.log("responce",3);
          this.selectionas = 3;
        }
        if (
          this.profileData.ANNUAL_INCOME == "TwentyFive Lakhs To Fifty Lakhs" ||
          this.profileData.ANNUAL_INCOME == "Above Five Crore" ||
          this.profileData.ANNUAL_INCOME == "TwentyFive Lakhs To One Crore"
        ) {
          // console.log("responce",4);
          this.selectionas = 4;
        }
      }
      if (data == "segment") {
        this.inactiveSegments = [];
        this.existingSegments = [];
        this.currentClick = "Segment";
        this.editType = "Segment_change";
        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Would you like to activate segment";
        for (var a = 0; a < this.equty.length; a++) {
          if (this.equty[a].COMPANY_CODE == "BSE_CASH") {
            if (this.equty[a].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.equty[a].COMPANY_CODE);
              this.bsecash = true;
              this.bsecashDisable = true;
            } else if (this.equty[a].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.equty[a].COMPANY_CODE);
            }
            // console.log(this.equty[a],"bse")
          }
          if (this.equty[a].COMPANY_CODE == "MF_BSE") {
            if (this.equty[a].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.equty[a].COMPANY_CODE);

              this.mfbse = true;
              this.mfDisable = true;
            } else if (this.equty[a].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.equty[a].COMPANY_CODE);
            }
            // console.log(this.equty[a],"mf")
          }
          if (this.equty[a].COMPANY_CODE == "NSE_CASH") {
            if (this.equty[a].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.equty[a].COMPANY_CODE);

              this.nsecash = true;
              this.nsecashDisable = true;
              // console.log(this.equty[a],"nse")
            } else if (this.equty[a].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.equty[a].COMPANY_CODE);
            }
          }

          // }
        }
        for (var b = 0; b < this.derivat.length; b++) {
          if (this.derivat[b].COMPANY_CODE == "BSE_FNO") {
            if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.derivat[b].COMPANY_CODE);

              this.bsefno = true;
              this.bsefnoDisable = true;
            } else if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.derivat[b].COMPANY_CODE);
            }
          }
          if (this.derivat[b].COMPANY_CODE == "NSE_FNO") {
            if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.derivat[b].COMPANY_CODE);

              this.nsefno = true;
              this.nsefnoDisable = true;
            } else if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.derivat[b].COMPANY_CODE);
            }
          }
          if (this.derivat[b].COMPANY_CODE == "CD_NSE") {
            if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.derivat[b].COMPANY_CODE);

              this.cdnse = true;
              this.cdnseDisable = true;
            } else if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.derivat[b].COMPANY_CODE);
            }
          }
          if (this.derivat[b].COMPANY_CODE == "CD_BSE") {
            if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.derivat[b].COMPANY_CODE);

              this.cdbse = true;
              this.cdbseDisable = true;
            } else if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.derivat[b].COMPANY_CODE);
            }
          }
          // }
        }

        for (var c = 0; c < this.commoditi.length; c++) {
          if (this.commoditi[c].COMPANY_CODE == "BSE_COM") {
            if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.commoditi[c].COMPANY_CODE);

              this.bsecom = true;
              this.bsecomDisable = true;
              // console.log(this.commoditi[c],"bse")
            } else if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.commoditi[c].COMPANY_CODE);
            }
          }
          if (this.commoditi[c].COMPANY_CODE == "NSE_COM") {
            if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.commoditi[c].COMPANY_CODE);

              this.nsecom = true;
              this.nsecomDisable = true;
              // console.log(this.commoditi[c],"mf")
            } else if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.commoditi[c].COMPANY_CODE);
            }
          }
          if (this.commoditi[c].COMPANY_CODE == "ICEX") {
            if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.commoditi[c].COMPANY_CODE);

              this.icex = true;
              this.icexDisable = true;
              // console.log(this.commoditi[a],"nse")
            } else if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.commoditi[c].COMPANY_CODE);
            }
          }
          if (this.commoditi[c].COMPANY_CODE == "MCX") {
            if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.commoditi[c].COMPANY_CODE);

              this.mcx = true;
              this.mcxDisable = true;
              // console.log(this.commoditi[c],"nse")
            } else if (this.commoditi[c].Exchange_ACTIVE_INACTIVE == "I") {
              this.inactiveSegments.push(this.commoditi[c].COMPANY_CODE);
            }
          }
          // }
        }
        var array1;
        var array2;

        // for (var i = 0;i<this.allSegments.length;i++) {
        let arr = this.allSegments;
        // let remove = arr.filter((id) => id !== this.existingSegments[i].COMPANY_CODE)
        array1 = arr.filter((item) => !this.existingSegments.includes(item));
        array2 = array1.filter((item) => !this.inactiveSegments.includes(item));

        this.newSegments = array2;
        // console.log(array1, this.existingSegments.length);
        if (array2.length == 11) {
          this.nosegmentsPop = true;
        } else {
          this.editdialog = true;
          this.adressOptions = false;
          this.manual = false;
          this.requestotp = false;
          this.requestotpemail = false;
          this.adresschange = false;
          this.bankchange = false;
          this.annualIncomechange = false;
          this.segments = true;
          this.annualIncomeverifyotp = false;
          this.segOpt = false;
          this.addNewSeg = true;
          this.activateSeg = false;
          this.nosegmentsPop = false;
        }
        // [1,2,3,4,6]
        // }
        // console.log(this.inactiveSegments,"......",this.existingSegments,this.allSegments)
      }
      // console.log("edit mobile");
    },
    valu(val) {
      // console.log(val, "val");
      const validat = val;
      if (validat == false) {
        this.createChartsred();
      }
    },
    encryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      var derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      var iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      var test = CryptoJS.AES.encrypt(payload, derived_key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString();
      return test;
    },
    decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      // console.log('Decrypted Data:', decryptedData);
      return decryptedData;
    },

    getAvailBal() {
      var axiosThis = this;
    
  
          axiosThis.blurLoader = false;
          axiosThis.profileLoader = false;
          // console.log(response)
        
           
              axiosThis.equty = [];
              axiosThis.derivat = [];
              axiosThis.commoditi = [];

              // console.log(response.data.str)
              var res = axiosThis.decryptionFunction(localStorage.getItem("profile_data"));
              // console.warn(JSON.parse(res));
              var resp = JSON.parse(res);
              axiosThis.profileData = resp.client_data;
              axiosThis.hashpan = axiosThis.profileData.PAN_NO.slice(-3);
              axiosThis.BankData = resp.bank_data;

              axiosThis.name = axiosThis.profileData.CLIENT_NAME;
              // console.log(axiosThis.name)
              localStorage.setItem('client_name', axiosThis.name)
              //   if(typeof axiosThis.name == 'string'){
              // }
              // axiosThis.$router.push("/");


              axiosThis.client_code = axiosThis.profileData.CLIENT_ID;

              if (axiosThis.profileData.Nominee_Name) {
                // axiosThis.multinominee = resp.
                axiosThis.nomineeYes = true;
                axiosThis.newNomineeName = axiosThis.profileData.Nominee_Name;
                axiosThis.newNomineeRelation = axiosThis.profileData.Nominee_Relation;
                axiosThis.newNomineeDob = axiosThis.profileData.Nominee_DOB;
                // axiosThis.newNomineepercent = axiosThis.profileData.Nominee_Name
              }
              axiosThis.fetchMobileStatus();
              // axiosThis.kraImageCheck()

              // else {
              //   axiosThis.nomineeYes = false

              // }
              axiosThis.profileData.CL_RESI_ADD1 =
                axiosThis.profileData.CL_RESI_ADD1 +
                " " +
                axiosThis.profileData.CL_RESI_ADD2 +
                " " +
                axiosThis.profileData.CL_RESI_ADD3;
              for (
                var i = 0;
                i < axiosThis.profileData.segments_data.length;
                i++
              ) {
                // console.log(axiosThis.profileData.segments_data[i].COMPANY_CODE);
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "BSE_CASH" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "MF_NSE" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "NSE_CASH" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "MF_BSE"
                ) {
                  axiosThis.equty.push(axiosThis.profileData.segments_data[i]);
                }
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "NSE_FNO" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "BSE_FNO" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "CD_BSE" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "CD_NSE"
                ) {
                  axiosThis.derivat.push(
                    axiosThis.profileData.segments_data[i]
                  );
                }
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "BSE_COM" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "NSE_COM" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "MCX" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE == "ICEX"
                ) {
                  axiosThis.commoditi.push(
                    axiosThis.profileData.segments_data[i]
                  );
                }
              }
              // console.log(axiosThis.derivat);
              // axiosThis.payout.ledger_amount = axiosThis.payout.ledger_amount.toFixed(2)
              // axiosThis.payout.withdraw_amount = axiosThis.payout.withdraw_amount.toFixed(2)

              // axiosThis.cashledg = axiosThis.payout.ledger_amount
            
          
   
          // axiosThis.digilockerAdressSubmit();


    },
    chipp() {
      this.chha = !this.chha;
    },
    getCardColor(index) {
      // Custom logic to determine the card color based on the index or other conditions
      if (index % 2 === 0) {
        return "#ECF9EF"; // Example: Blue color for even index cards
      } else {
        return "#FAEAEA"; // Example: Green color for odd index cards
      }
    },
    closeBtn() {
      this.editdialog = false;
      this.verifyotp = false;
      this.requestotp = true;
      this.userOtp = "";
      this.usernewmobile = "";
      (this.usernewemail = ""),
        (this.requestotpemail = false),
        (this.useremailOtp = ""),
        (this.verifyotpemail = false),
        (this.stateAddress = ""),
        (this.countryAddress = ""),
        (this.distAddress = ""),
        (this.pincodeAddress = "");
      (this.usernewAdress = ""),
        (this.adressproffdoc = null),
        (this.bankIfscCode = ""),
        (this.bankAccountnumber = ""),
        (this.bankproofTypes = ""),
        (this.bankproffdoc = null),
        (this.defaultCheckbox = false);
        this.ifscBankInfo = {};
        this.panFile = null;
  this.panimage = '';
  this.panimagedata = { name: '', size: '' };
  this.ifscBankInfo.BANK = ""
    },
  },
  computed: {
    orderbookHeaders() {
      return [
        { text: "Member ID", value: "memberid", sortable: false },
        { text: "Datetime", value: "req_link_time" },
        { text: "Status", value: "status", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    mobileNumberRules() {
      return [(value) => /^\d{10}$/.test(value) || "Enter a valid number"];
    },
    otpRules() {
      return [
        (value) => !!value || "OTP Required",
        (value) => /^\d{4}$/.test(value) || "Must be exactly 4 digits",
      ];
    },
    nomineeField() {
      return [(value) => !!value || "Required field"];
    },
    prooffield() {
      return [(value) => !!value || "Required field"];
    },
    emailNumberRules() {
      return [
        (value) => !!value || "email id is required",
        (value) => /.+@.+\..+/.test(value) || "Email must be valid",
      ];
    },
    depedselectRules() {
      return [(value) => !!value || "Dependent name is required"];
    },
  },
  mounted() {
    this.blurLoader = true;
    let params = new URL(document.location).searchParams;
    // var axiosThis = this;/
    var cod = params.get("code");
    var stat = params.get("state");
    var vendorAcc = params.get("sAccountId");
    var vendorToken = params.get("sToken");
    this.accountstatus = localStorage.getItem("clientStatus")

    // console.log("jiji", vendorAcc, vendorToken);

    if (cod && stat) {
      this.aadharstate = stat;
      this.aadharcode = cod;
    }
    // let decoded = decodeURIComponent(window.location.search);
    var actid = params.get("uid");
    var token = params.get("token");
    this.susertoken = localStorage.getItem("usession");
    // console.log(this.susertoken,actid,window.location.search)
    this.actid = localStorage.getItem("userid");
    if (typeof actid == "string" && typeof token == "string") {
      localStorage.setItem("usession", token);
      localStorage.setItem("userid", actid);
      this.susertoken = localStorage.getItem("usession");
      this.actid = localStorage.getItem("userid");
      window.location.assign(window.location.href.split("?")[0]);
    }
    if (vendorAcc == null && vendorToken == null) {
      // console.warn("nully")
      if (this.susertoken && this.actid) {
        var id = this.actid;
        this.tok = this.susertoken;
        // console.log("ifififififififif", this.tok);
        this.getAvailBal(id);
      } else {
        this.goSso();
      }
    }
    this.getCountFromLocalStorage();
  },
};
const $ = document.querySelector.bind(document);
const $$ = document.querySelectorAll.bind(document);

let activeLink = null;

window.addEventListener("scroll", () => {
  $$(".section").forEach((section) => {
    const { y } = section.getBoundingClientRect();

    if (y <= 0) {
      if (activeLink != null) {
        activeLink.classList.remove("active");
      }

      activeLink = $(`.nav-link[href~="#${section.id}"]`);
      activeLink.classList.add("active");
    }
  });
});
</script>