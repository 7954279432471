<template>
  <v-container>
    <div class="borderlinehide">
      <!-- Mobile -->
      <div class="mblappbar d-md-none mb-8">
        <!-- {{ appbarhide }} -->
        <v-app-bar class="appbar elevation-1" absolute width="100%" permanent color="white">
          <v-row class="d-flex">
            <v-col class="d-flex justify-start">
              <img src="@/assets/zebulogo.svg" style="max-width: 80px" alt="zebulogo" />
            </v-col>
            <v-tooltip v-if="accountstatus == 'client_close'"   bottom color="black">
                                                        <template v-slot:activator="{ on, attrs }">
                                                    <v-icon style="cursor: pointer" class="mr-2 mt-2" color="red"   v-bind="attrs"
                                                    v-on="on" size="18">mdi-alert</v-icon>
                                                </template>
                                                <span>Your trading account is closed.</span>
                                                </v-tooltip>
            <v-menu offset-y>
              <template v-slot:activator="{on, attrs}">
                <a v-bind="attrs" v-on="on" icon class="menu-app-btn mt-6 pl-0 pr-0 ml-0 mr-0">
                  <v-icon color="#666666" size="21">mdi-headset</v-icon>
                </a>
              </template>
              <v-card class="apps-menu-card pt-3 pa-3 elevation-0 overflow-y-auto overflow-x-hidden" width="340px" height="280px">
                <p class="font-weight-semibold fs-22 mb-0">Can we help you?</p>

                <p class="mt-1 mb-0" style="color: #666666"><v-icon color="#666666" size="17">mdi-phone</v-icon><span class="body-2 ml-3">(+91) 93 8010 8010</span></p>
                <p class="mb-0 mt-2" style="color: #666666"><img width="17px" height="15px" src="https://zebuetrade.com/img/send-message.5b687289.svg" /><span class="body-1 ml-3">assist@zebuetrade.com</span></p>
                <p class="font-weight-bold mb-0 mt-2" style="color: #666666">Investor grievance: <span class="body-1 font-weight-semibold" style="color: #666666">grievance@zebuetrade.com</span></p>
                <v-divider class="mb-3 mt-3"></v-divider>
                <p class="font-weight-bold mb-0" style="color: #666666">Follow as</p>

                <div class=" ">
                  <v-btn v-for="(m, n) in footermediabtn" :key="n" small color="#E5EBEC" fab :href="m.link" target="_blank" rel="noopener noreferrer" class="pa-1 elevation-0 mr-2">
                    <img :src="m.image" width="60%" :alt="m.image" />
                  </v-btn>
                </div>

                <!-- <v-row no-glutters>
                        <v-col cols="12" class="py-0 px-0">
                            <v-card class="elevation-0 rounded-0 pt-3" color="#EBEEF0"></v-card>
                        </v-col>
                    </v-row> -->
              </v-card>
            </v-menu>
            <v-app-bar-nav-icon class="mt-3" color="#797E8A" @click.stop="drawer = !drawer"> </v-app-bar-nav-icon>
            <v-menu open-on-hover left :offset-x="offset"> </v-menu>
          </v-row>
        </v-app-bar>
        <v-navigation-drawer class="white" v-model="drawer" height="100%" app>
          <v-list nav link class="pa-0 pl-1">
            <v-list-item-group v-model="mblslcItm" color="#0037B7">
              <v-list-item>
                <v-list-item-avatar color="#DDD" size="40">
                  <span class="text-uppercase font-weight-bold title">{{ singlename }}</span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold black--text subtitle-2 text-capitalize">{{ cname }}</v-list-item-title>
                  <v-list-item-subtitle class="txt-666 fs-12 text-uppercase"
                    >CLIENT CODE <span class="black--text font-weight-bold">{{ actid }}</span></v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider class="" style="border: 1px solid #eff2f5 !important"></v-divider>
             
              <v-list-group :value="false" no-action aria-hidden="false" class="ml-1" append-icon="mdi-menu-down"  @click="(lsticonone = !lsticonone), (lsticontwo = false), (lsticontre = false), (lsticonfro = false)" color="#0037B7">
                      <template v-slot:activator>
                        <v-list-item-avatar tile size="22">
                          <!-- <img src="@/assets/Portfolio.svg" alt="Portfolio icon"> -->
                          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M13.1298 11.3554C13.1299 11.2267 13.0975 11.0999 13.0353 10.9857C12.9731 10.8715 12.883 10.7733 12.7725 10.6994C11.2594 9.76479 9.4792 9.30184 7.67817 9.37462C5.87715 9.30184 4.09693 9.76479 2.58385 10.6994C2.47337 10.7733 2.38321 10.8715 2.32101 10.9857C2.25881 11.0999 2.22641 11.2267 2.22656 11.3554V13.3746H13.1298V11.3554Z"
                              stroke="black"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.67767 6.975C9.29889 6.975 10.6132 5.7214 10.6132 4.175C10.6132 2.6286 9.29889 1.375 7.67767 1.375C6.05645 1.375 4.74219 2.6286 4.74219 4.175C4.74219 5.7214 6.05645 6.975 7.67767 6.975Z"
                              stroke="black"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </v-list-item-avatar>
                        <v-list-item-title class="body-2 font-weight-bold">Profile
                          <v-tooltip v-if="accountstatus == 'client_close'" bottom color="black">
                                                        <template v-slot:activator="{ on, attrs }">
                                                    <v-icon color="red"   v-bind="attrs"
                                                    v-on="on" size="16">mdi-alert</v-icon>
                                                </template>
                                                <span>Your trading account is closed.</span>
                                                </v-tooltip>

                        </v-list-item-title>
                         
                      </template>
                      <div class="mt-1">

                        <v-list-item dense class="pl-3 fw-500" link style="" to="/profile">
                            <v-list-item-title color="#666"
                                class=" caption font-weight-medium">Personal
                                Info</v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 "
                            style="border:1px solid #EFF2F5 !important;"></v-divider>

                        <v-list-item dense class="pl-3 fw-500" link style="" to="/bank">
                            <v-list-item-title color="#666"
                                class=" caption font-weight-medium">Bank</v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 "
                            style="border:1px solid #EFF2F5 !important;"></v-divider>
                        <v-list-item dense class="pl-3 fw-500" link style="" to="/deposltory">
                            <v-list-item-title color="#666"
                                class=" caption font-weight-medium">Demat</v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 "
                            style="border:1px solid #EFF2F5 !important;"></v-divider>

                        <v-list-item dense class="pl-3 fw-500" link style="" to="/segment">
                            <v-list-item-title color="#666" class=" caption font-weight-medium">
                                Trading
                                Preference</v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 mt-n1"
                            style="border:1px solid #EFF2F5 !important;"></v-divider>
                        <v-list-item dense class="pl-3 fw-500" link style="" to="/mtf">
                            <v-list-item-title color="#666" class=" caption font-weight-medium">
                                Margin
                                Trading Facility (MTF)
                            </v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 "
                            style="border:1px solid #EFF2F5 !important;"></v-divider>


                        <v-list-item dense class="pl-3 fw-500" link style="" to="/annualincome">
                            <v-list-item-title color="#666"
                                class=" caption font-weight-medium">Annual
                                Income</v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 "
                            style="border:1px solid #EFF2F5 !important;"></v-divider>

                        <v-list-item dense class="pl-3 fw-500" link style="" to="/nominee">
                            <v-list-item-title color="#666"
                                class=" caption font-weight-medium">Nominee
                            </v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 "
                            style="border:1px solid #EFF2F5 !important;"></v-divider>
                            <v-list-item dense class="pl-3 fw-500" link style="" to="/family">
                            <v-list-item-title color="#666"
                                class=" caption font-weight-medium">Family Account
                            </v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 "
                            style="border:1px solid #EFF2F5 !important;"></v-divider>
                        <v-list-item dense class="pl-3 fw-500" link style="" to="/closure">
                            <v-list-item-title color="#666" class=" caption font-weight-medium">
                                Closure
                            </v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 "
                            style="border:1px solid #EFF2F5 !important;"></v-divider>
                        <v-list-item dense class="pl-3 fw-500" link style="" to="/formdownload">
                            <v-list-item-title color="#666"
                                class=" caption font-weight-medium">Form Download 
                            </v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 "
                            style="border:1px solid #EFF2F5 !important;"></v-divider>

                      </div>
                    </v-list-group>
                    <v-list-group :value="false" no-action aria-hidden="false" append-icon="mdi-menu-down" class="ml-1" @click="(lsticonone = !lsticonone), (lsticontwo = false), (lsticontre = false), (lsticonfro = false)" color="#0037B7">
                      <template v-slot:activator>
                        <v-list-item-avatar tile size="22">
                          <!-- <img src="@/assets/Portfolio.svg" alt="Portfolio icon"> -->
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                              d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M16 13H8M16 17H8M10 9H8M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2Z"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </v-list-item-avatar>
                        <v-list-item-title class="body-2 font-weight-bold">Reports</v-list-item-title>
                      </template>
                      <div class="mt-1">
                        <!-- <v-list-item dense class="pl-3 fw-500" link to="/dashboard" style="">
                                                    <v-list-item-title color="#666"
                                                        class=" caption font-weight-medium">DashBoard</v-list-item-title><v-icon>
                                                        mdi-chevron-right </v-icon>
                                                </v-list-item>
                                                <v-divider class="mx-3 mt-n1"
                                                    style="border:1px solid #EFF2F5 !important;"></v-divider> -->
                        <v-list-item dense class="pl-3 fw-500" link to="/ledger" style=""> <v-list-item-title color="#666" class="caption font-weight-medium">Ledger</v-list-item-title><v-icon> mdi-chevron-right </v-icon> </v-list-item>
                        <v-divider class="mx-3 mt-n1" style="border: 1px solid #eff2f5 !important"></v-divider>

                        <v-list-item dense class="pl-3 fw-500" link to="/holding" style=""> <v-list-item-title class="font-weight-medium caption">Holdings</v-list-item-title><v-icon> mdi-chevron-right </v-icon> </v-list-item>
                        <v-divider class="mx-3 mt-n1" style="border: 1px solid #eff2f5 !important"></v-divider>
                        <v-list-item dense class="pl-3 fw-500" link to="/positions" style=""> <v-list-item-title class="font-weight-medium caption">Positions - (Beta)</v-list-item-title><v-icon> mdi-chevron-right </v-icon> </v-list-item>
                        <v-divider class="mx-3 mt-n1" style="border: 1px solid #eff2f5 !important"></v-divider>

                        <v-list-item dense class="pl-3 fw-500" link to="/pnl" style=""> <v-list-item-title class="font-weight-medium caption"> Profit & Loss</v-list-item-title><v-icon> mdi-chevron-right </v-icon> </v-list-item>
                        <v-divider class="mx-3 mt-n1" style="border: 1px solid #eff2f5 !important"></v-divider>
                        <v-list-item dense class="pl-3 fw-500" link to="/calenderpnl" style=""> <v-list-item-title class="font-weight-medium caption">Calender P&L (Beta)</v-list-item-title><v-icon> mdi-chevron-right </v-icon> </v-list-item>
                        <v-divider class="mx-3 mt-n1" style="border: 1px solid #eff2f5 !important"></v-divider>
                        <v-list-item dense class="pl-3 fw-500" link to="/taxpnl" style=""> <v-list-item-title class="font-weight-medium caption">Tax P&L</v-list-item-title><v-icon> mdi-chevron-right </v-icon> </v-list-item>
                        <v-divider class="mx-3 mt-n1" style="border: 1px solid #eff2f5 !important"></v-divider>

                        <v-list-item dense class="pl-3 fw-500" link to="/tradebook" style=""> <v-list-item-title class="font-weight-medium caption">TradeBook/Contract</v-list-item-title><v-icon> mdi-chevron-right </v-icon> </v-list-item>
                        <!-- <v-divider class="mx-3 mt-n1" style="border:1px solid #EFF2F5 !important;"></v-divider>
                                                <v-list-item dense class="pl-3 fw-500" link to="/event" style="">
                                        <v-list-item-title
                                            class=" font-weight-medium caption">Events</v-list-item-title><v-icon>
                                            mdi-chevron-right </v-icon>
                                    </v-list-item> -->
                        <v-divider class="mx-3 mt-n1" style="border: 1px solid #eff2f5 !important"></v-divider>
                        <!-- <v-list-item dense class="pl-3 fw-500" link to="/eventpage" style="">
                                        <v-list-item-title
                                            class=" font-weight-medium caption">Event</v-list-item-title><v-icon>
                                            mdi-chevron-right </v-icon>
                                    </v-list-item> -->
                        <!-- <v-divider class="mx-3 mt-n1"
                                                style="border:1px solid #EFF2F5 !important;"></v-divider>

                                            <v-list-item dense class="pl-3 fw-500" link to="/tradeverify" style="">
                                                <v-list-item-title
                                                    class=" font-weight-medium caption">Verified P&L (Beta)</v-list-item-title><v-icon>
                                                    mdi-chevron-right </v-icon>
                                            </v-list-item> -->
                        <!-- <v-divider class="mx-3 mt-n1"
                                                style="border:1px solid #EFF2F5 !important;"></v-divider>
                                                <v-list-item dense class="pl-3 fw-500" link to="/corporateaction" style="">
                                                <v-list-item-title
                                                    class=" font-weight-medium caption">Corporate Actions</v-list-item-title><v-icon>mdi
                                                    mdi-chevron-right </v-icon>
                                            </v-list-item> -->
                        <!-- <v-divider class="mx-3 mt-n1"
                                                    style="border:1px solid #EFF2F5 !important;"></v-divider> -->
                        <v-list-item dense class="pl-3 fw-500" link to="/pdfdownload" style=""> <v-list-item-title class="font-weight-medium caption">PDF Download</v-list-item-title><v-icon>mdi mdi-chevron-right </v-icon> </v-list-item>
                        <!-- <v-divider class="mx-3 mt-n1"
                                        style="border:1px solid #EFF2F5 !important;"></v-divider>
                                    <v-list-item dense class="pl-3 fw-500" link to="/calender" style="">
                                        <v-list-item-title
                                            class=" font-weight-medium caption">calender</v-list-item-title><v-icon>mdi
                                            mdi-chevron-right </v-icon>
                                    </v-list-item> -->
                        <!-- <v-divider class="mx-3 mt-n1" style="border:1px solid #EFF2F5 !important;"></v-divider>
                                    <v-list-item dense class="pl-3 fw-500" link to="/DiviDends_Page" style="">
                                        <v-list-item-title class=" font-weight-medium caption">Dividends</v-list-item-title><v-icon>mdi
                                            mdi-chevron-right </v-icon>
                                    </v-list-item>
                                                <v-divider class="mx-3 mt-n1"
                                                    style="border:1px solid #EFF2F5 !important;"></v-divider> -->
                        <!-- <v-list-item dense class="pl-3 fw-500" link to="/pledge" style="">
                                                <v-list-item-title class=" font-weight-medium caption">Pledge &
                                                    Unpledge</v-list-item-title><v-icon>mdi
                                                    mdi-chevron-right </v-icon>
                                            </v-list-item>
                                            <v-divider class="mx-3 mt-n1"
                                                style="border:1px solid #EFF2F5 !important;"></v-divider> -->
                        <!-- <v-list-item dense class="pl-3 fw-500" link to="/pledge" style="">
                                                <v-list-item-title class=" font-weight-medium caption">Pledge & Unpledge
                                                    </v-list-item-title><v-icon>
                                                    mdi-chevron-right </v-icon>
                                            </v-list-item>
                                            <v-divider class="mx-3 mt-n1"
                                                style="border:1px solid #EFF2F5 !important;"></v-divider> -->

                        <!-- <v-list-item dense class="pl-3" @click="logout">
                                        <v-list-item-title
                                            class=" font-weight-medium caption">Logout</v-list-item-title><v-icon>mdi
                                            mdi-chevron-right </v-icon>
                                    </v-list-item> -->
                      </div>
                    </v-list-group>
                    <v-list-item dense class="pl-3 fw-500" link to="/tradeverify" style="">
                      <v-list-item-avatar tile size="22">
                        <!-- <img src="@/assets/Portfolio.svg" alt="Portfolio icon"> -->
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M16 13H8M16 17H8M10 9H8M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2Z"
                                                        stroke="black" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg> -->
                        <v-icon color="black">mdi mdi-check-decagram</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title class="body-2 font-weight-bold">Verified P&L </v-list-item-title>
                    </v-list-item>

                    <v-list-item dense class="pl-3 fw-500" link to="/corporateaction" style="">
                      <v-list-item-avatar tile size="22">
                        <!-- <img src="@/assets/Portfolio.svg" alt="Portfolio icon"> -->
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M16 13H8M16 17H8M10 9H8M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2Z"
                                                        stroke="black" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg> -->
                        <v-icon color="black"> mdi mdi-view-list-outline</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title class="body-2 font-weight-bold">Corporate Actions </v-list-item-title>
                    </v-list-item>
                    <v-list-item dense class="pl-3 fw-500" link to="/event" style="">
                      <v-list-item-avatar tile size="22">
                        <!-- <img src="@/assets/Portfolio.svg" alt="Portfolio icon"> -->
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none">
                                        <path
                                            d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M16 13H8M16 17H8M10 9H8M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2Z"
                                            stroke="black" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg> -->
                        <v-icon color="black">mdi mdi-calendar-multiple</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title class="body-2 font-weight-bold">CA Events </v-list-item-title>
                    </v-list-item>
                    <v-list-item dense class="pl-3 fw-500" link to="/pledge" style="">
                      <v-list-item-avatar tile size="22">
                        <!-- <img src="@/assets/Portfolio.svg" alt="Portfolio icon"> -->
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M16 13H8M16 17H8M10 9H8M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2Z"
                                                        stroke="black" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg> -->
                        <v-icon color="black">mdi mdi-hand-extended-outline</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title class="body-2 font-weight-bold">Pledge & Unpledge </v-list-item-title>
                    </v-list-item>
                    <v-list-item dense class="pl-2 fw-500" link to="/refer" style="">
                                        <v-list-item-avatar tile size="22">

                                            <v-icon color="black">mdi-party-popper</v-icon>

                                        </v-list-item-avatar>
                                        <v-list-item-title class=" body-2 font-weight-bold">Refer
                                        </v-list-item-title>
                                    </v-list-item>
            </v-list-item-group>
          </v-list>
          <template v-slot:append>
            <div class="pa-2">
              <v-btn @click="logout" style="border-radius: 4px" rounded block color="#000">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                    fill="none">
                                    <g clip-path="url(#clip0_1680_167)">
                                        <path d="M12.5068 4.79297L16.7146 9.00076L12.5068 13.2086" stroke="#fff"
                                            stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.7149 9H5.49414" stroke="#fff" stroke-width="1.2" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M8.29912 16.7137H3.39003C2.83204 16.7137 2.29691 16.4921 1.90235 16.0975C1.50779 15.703 1.28613 15.1678 1.28613 14.6098V3.38905C1.28613 2.83106 1.50779 2.29593 1.90235 1.90137C2.29691 1.50682 2.83204 1.28516 3.39003 1.28516H8.29912"
                                            stroke="#fff" stroke-width="1.6" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1680_167">
                                            <rect width="18" height="18" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg> -->
                <span class="text-none font-weight-bold pl-2 white--text">Logout</span>
              </v-btn>
            </div>
          </template>
        </v-navigation-drawer>
        <v-footer
             v-if="accountstatus == 'client_close'"
            class="pa-0"
      v-bind="localAttrs"
      :padless="padless"
    >
      <v-card
       style="background-color:#F3EAEA"
        width="100%"
        
        class="text-center pa-1"
      >
      <div v-if="accountstatus == 'client_close'" >
        <!-- <v-icon size="35" color="red">mdi-alert-outline</v-icon>  -->
      <span style="line-height: 90%;"  class="  subtitle-1 red--text">Your trading account is closed .      <a target="_blank" :href="`https://oa.mynt.in/`">Click here </a>  to reopen your account.</span>

      </div>



        <!-- <v-divider></v-divider> -->

        
      </v-card>
    </v-footer>
      </div>
      <!-- Desktop -->
      <div class="dsknavdraw d-none d-md-block">
        <v-app-bar dense width="100%" permanent class="cust-appbar px-0 px-md-2 mt-n3 overflow-hidden" style="">
          <img src="@/assets/zebulogo.svg" style="max-width: 80px" alt="zebulogo" />
          <v-spacer></v-spacer>
          <v-row class="flex-row-reverse mr-0 mr-md-4 pt-4">
            <v-list nav color="transparent">
              <v-list-item-group class="d-flex">
                <!-- :href=""https://profile.mynt.in/?uid=${actid}&token=${susertoken} -->
                <!-- <v-list-item target="_blank"
                                    :href="`https://profile.mynt.in/?uid=${actid}&token=${susertoken}`">
                                    <v-list-item-title style="color:#666;" class="font-weight-medium text-capitalize">
                                        Profile
                                    </v-list-item-title>
                                </v-list-item> -->

                <!-- <v-list-item target="_blank"
                                    :href="`https://reports-cf9df.web.app/ledger?uid=${actid}&token=${susertoken}`" class="px-4 mx-1 mb-0">
                                    <v-list-item-title style="color:#666;" class="font-weight-medium text-capitalize">
                                        Funds
                                    </v-list-item-title>
                                </v-list-item> -->
                <!-- <v-list-item style="background-color:#EBEFFA;" class="px-4 mx-1 mb-0 rounded-t-lg">
                                    <v-list-item-title style="color:#0037B7;" class="font-weight-medium text-capitalize">
                                        Reports
                                    </v-list-item-title>
                                </v-list-item> -->
              </v-list-item-group>
            </v-list>
          </v-row>
          <span v-if="accountstatus == 'client_close'" style="line-height: 90%;"  class="  subtitle-1 red--text">Your trading account is closed .      <a target="_blank" :href="`https://oa.mynt.in/`">Click here </a>  to reopen your account.</span>

          <v-menu offset-y>
            <template v-slot:activator="{on, attrs}">
              <v-btn v-bind="attrs" v-on="on" text class="menu-app-btn text-none d-none d-md-flex"> <v-icon color="#666666" size="18">mdi-headphones</v-icon><span>Need Help ?</span> </v-btn>
            </template>
            <v-card class="apps-menu-card pt-3 pa-3 elevation-0 overflow-y-auto overflow-x-hidden" width="340px" height="280px">
              <p class="font-weight-semibold fs-22 mb-0">Can we help you?</p>

              <p class="mt-1 mb-0" style="color: #666666"><v-icon color="#666666" size="17">mdi-phone</v-icon><span class="body-2 ml-3">(+91) 93 8010 8010</span></p>
              <p class="mb-0 mt-2" style="color: #666666"><img width="17px" height="15px" src="https://zebuetrade.com/img/send-message.5b687289.svg" /><span class="body-1 ml-3">assist@zebuetrade.com</span></p>
              <p class="font-weight-bold mb-0 mt-2" style="color: #666666">Investor grievance: <span class="body-1 font-weight-semibold" style="color: #666666; cursor: pointer" @click="email()">grievance@zebuetrade.com</span></p>
              <v-divider class="mb-3 mt-3"></v-divider>
              <p class="font-weight-bold mb-0" style="color: #666666">Follow as</p>

              <div class="d-none d-md-inline-flex">
                <v-btn v-for="(m, n) in footermediabtn" :key="n" small color="#E5EBEC" fab :href="m.link" target="_blank" rel="noopener noreferrer" class="pa-1 elevation-0 mr-2">
                  <img :src="m.image" width="60%" :alt="m.image" />
                </v-btn>
              </div>

              <!-- <v-row no-glutters>
                        <v-col cols="12" class="py-0 px-0">
                            <v-card class="elevation-0 rounded-0 pt-3" color="#EBEEF0"></v-card>
                        </v-col>
                    </v-row> -->
            </v-card>
          </v-menu>
          <!-- <v-btn @click="logout" icon class="d-none d-md-block">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <g clip-path="url(#clip0_1680_167)">
                                <path d="M12.5068 4.79297L16.7146 9.00076L12.5068 13.2086" stroke="#4C4C4C"
                                    stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16.7149 9H5.49414" stroke="#4C4C4C" stroke-width="1.2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path
                                    d="M8.29912 16.7137H3.39003C2.83204 16.7137 2.29691 16.4921 1.90235 16.0975C1.50779 15.703 1.28613 15.1678 1.28613 14.6098V3.38905C1.28613 2.83106 1.50779 2.29593 1.90235 1.90137C2.29691 1.50682 2.83204 1.28516 3.39003 1.28516H8.29912"
                                    stroke="#4C4C4C" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1680_167">
                                    <rect width="18" height="18" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </v-btn> -->
          <v-app-bar-nav-icon @click="drawer = true" class="#4C4C4C d-md-none"></v-app-bar-nav-icon>
        </v-app-bar>
        <v-card>
          <v-navigation-drawer class="white pt- ml-10 mt-16" app permanent>
            <v-card class="elevation-0 mx-auto mr-3" height="530px" style="border: thin solid rgba(231, 230, 230, 0.904)">
              <v-list nav link flat class="pa-0 pl-1">
                <v-list-item-group v-model="mblslcItm" color="#0037B7">
                  <v-list-item>
                    <v-list-item-avatar color="#DDD" size="40">
                      <span class="text-uppercase font-weight-bold title">{{ singlename }}</span>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold black--text subtitle-2 text-capitalize">{{ cname }}</v-list-item-title>
                      <v-list-item-subtitle class="txt-666 fs-12 text-uppercase"
                        >CLIENT CODE <span class="black--text font-weight-bold">{{ actid }}</span></v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="" style="border: 1px solid #eff2f5 !important"></v-divider>
                  <div class="" style="overflow: scroll; height: 435px">
                    <!-- {{lsticonone}} -->
                    <!-- :append-icon="lsticonone ? 'mdi-menu-up' : 'mdi-menu-down'" -->
                    <v-list-group :value="false" no-action aria-hidden="false" class="ml-1" append-icon="mdi-menu-down"  @click="(lsticonone = !lsticonone), (lsticontwo = false), (lsticontre = false), (lsticonfro = false)" color="#0037B7">
                      <template v-slot:activator>
                        <v-list-item-avatar tile size="22">
                          <!-- <img src="@/assets/Portfolio.svg" alt="Portfolio icon"> -->
                          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M13.1298 11.3554C13.1299 11.2267 13.0975 11.0999 13.0353 10.9857C12.9731 10.8715 12.883 10.7733 12.7725 10.6994C11.2594 9.76479 9.4792 9.30184 7.67817 9.37462C5.87715 9.30184 4.09693 9.76479 2.58385 10.6994C2.47337 10.7733 2.38321 10.8715 2.32101 10.9857C2.25881 11.0999 2.22641 11.2267 2.22656 11.3554V13.3746H13.1298V11.3554Z"
                              stroke="black"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.67767 6.975C9.29889 6.975 10.6132 5.7214 10.6132 4.175C10.6132 2.6286 9.29889 1.375 7.67767 1.375C6.05645 1.375 4.74219 2.6286 4.74219 4.175C4.74219 5.7214 6.05645 6.975 7.67767 6.975Z"
                              stroke="black"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </v-list-item-avatar>
                        <v-list-item-title class="body-2 font-weight-bold">Profile
                          <v-tooltip v-if="accountstatus == 'client_close'" bottom color="black">
                                                        <template v-slot:activator="{ on, attrs }">
                                                    <v-icon color="red"   v-bind="attrs"
                                                    v-on="on" size="16">mdi-alert</v-icon>
                                                </template>
                                                <span>Your trading account is closed.</span>
                                                </v-tooltip>

                        </v-list-item-title>
                         
                      </template>
                      <div class="mt-1">

                        <v-list-item dense class="pl-3 fw-500" link style="" to="/profile">
                            <v-list-item-title color="#666"
                                class=" caption font-weight-medium">Personal
                                Info</v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 "
                            style="border:1px solid #EFF2F5 !important;"></v-divider>

                        <v-list-item dense class="pl-3 fw-500" link style="" to="/bank">
                            <v-list-item-title color="#666"
                                class=" caption font-weight-medium">Bank</v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 "
                            style="border:1px solid #EFF2F5 !important;"></v-divider>
                        <v-list-item dense class="pl-3 fw-500" link style="" to="/deposltory">
                            <v-list-item-title color="#666"
                                class=" caption font-weight-medium">Demat</v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 "
                            style="border:1px solid #EFF2F5 !important;"></v-divider>

                        <v-list-item dense class="pl-3 fw-500" link style="" to="/segment">
                            <v-list-item-title color="#666" class=" caption font-weight-medium">
                                Trading
                                Preference</v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 mt-n1"
                            style="border:1px solid #EFF2F5 !important;"></v-divider>
                        <v-list-item dense class="pl-3 fw-500" link style="" to="/mtf">
                            <v-list-item-title color="#666" class=" caption font-weight-medium">
                                Margin
                                Trading Facility (MTF)
                            </v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 "
                            style="border:1px solid #EFF2F5 !important;"></v-divider>


                        <v-list-item dense class="pl-3 fw-500" link style="" to="/annualincome">
                            <v-list-item-title color="#666"
                                class=" caption font-weight-medium">Annual
                                Income</v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 "
                            style="border:1px solid #EFF2F5 !important;"></v-divider>

                        <v-list-item dense class="pl-3 fw-500" link style="" to="/nominee">
                            <v-list-item-title color="#666"
                                class=" caption font-weight-medium">Nominee
                            </v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 "
                            style="border:1px solid #EFF2F5 !important;"></v-divider>
                            <v-list-item dense class="pl-3 fw-500" link style="" to="/family">
                            <v-list-item-title color="#666"
                                class=" caption font-weight-medium">Family Account
                            </v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 "
                            style="border:1px solid #EFF2F5 !important;"></v-divider>
                        <v-list-item dense class="pl-3 fw-500" link style="" to="/closure">
                            <v-list-item-title color="#666" class=" caption font-weight-medium">
                                Closure
                            </v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 "
                            style="border:1px solid #EFF2F5 !important;"></v-divider>
                        <v-list-item dense class="pl-3 fw-500" link style="" to="/formdownload">
                            <v-list-item-title color="#666"
                                class=" caption font-weight-medium">Form Download 
                            </v-list-item-title><v-icon>
                                mdi-chevron-right </v-icon>
                        </v-list-item>
                        <v-divider class="mx-3 "
                            style="border:1px solid #EFF2F5 !important;"></v-divider>

                      </div>
                    </v-list-group>
                    <v-list-group :value="false" no-action aria-hidden="false" append-icon="mdi-menu-down" class="ml-1" @click="(lsticonone = !lsticonone), (lsticontwo = false), (lsticontre = false), (lsticonfro = false)" color="#0037B7">
                      <template v-slot:activator>
                        <v-list-item-avatar tile size="22">
                          <!-- <img src="@/assets/Portfolio.svg" alt="Portfolio icon"> -->
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                              d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M16 13H8M16 17H8M10 9H8M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2Z"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </v-list-item-avatar>
                        <v-list-item-title class="body-2 font-weight-bold">Reports</v-list-item-title>
                      </template>
                      <div class="mt-1">
                        <!-- <v-list-item dense class="pl-3 fw-500" link to="/dashboard" style="">
                                                    <v-list-item-title color="#666"
                                                        class=" caption font-weight-medium">DashBoard</v-list-item-title><v-icon>
                                                        mdi-chevron-right </v-icon>
                                                </v-list-item>
                                                <v-divider class="mx-3 mt-n1"
                                                    style="border:1px solid #EFF2F5 !important;"></v-divider> -->
                        <v-list-item dense class="pl-3 fw-500" link to="/ledger" style=""> <v-list-item-title color="#666" class="caption font-weight-medium">Ledger</v-list-item-title><v-icon> mdi-chevron-right </v-icon> </v-list-item>
                        <v-divider class="mx-3 mt-n1" style="border: 1px solid #eff2f5 !important"></v-divider>

                        <v-list-item dense class="pl-3 fw-500" link to="/holding" style=""> <v-list-item-title class="font-weight-medium caption">Holdings</v-list-item-title><v-icon> mdi-chevron-right </v-icon> </v-list-item>
                        <v-divider class="mx-3 mt-n1" style="border: 1px solid #eff2f5 !important"></v-divider>
                        <v-list-item dense class="pl-3 fw-500" link to="/positions" style=""> <v-list-item-title class="font-weight-medium caption">Positions - (Beta)</v-list-item-title><v-icon> mdi-chevron-right </v-icon> </v-list-item>
                        <v-divider class="mx-3 mt-n1" style="border: 1px solid #eff2f5 !important"></v-divider>

                        <v-list-item dense class="pl-3 fw-500" link to="/pnl" style=""> <v-list-item-title class="font-weight-medium caption"> Profit & Loss</v-list-item-title><v-icon> mdi-chevron-right </v-icon> </v-list-item>
                        <v-divider class="mx-3 mt-n1" style="border: 1px solid #eff2f5 !important"></v-divider>
                        <v-list-item dense class="pl-3 fw-500" link to="/calenderpnl" style=""> <v-list-item-title class="font-weight-medium caption">Calender P&L (Beta)</v-list-item-title><v-icon> mdi-chevron-right </v-icon> </v-list-item>
                        <v-divider class="mx-3 mt-n1" style="border: 1px solid #eff2f5 !important"></v-divider>
                        <v-list-item dense class="pl-3 fw-500" link to="/taxpnl" style=""> <v-list-item-title class="font-weight-medium caption">Tax P&L</v-list-item-title><v-icon> mdi-chevron-right </v-icon> </v-list-item>
                        <v-divider class="mx-3 mt-n1" style="border: 1px solid #eff2f5 !important"></v-divider>

                        <v-list-item dense class="pl-3 fw-500" link to="/tradebook" style=""> <v-list-item-title class="font-weight-medium caption">TradeBook/Contract</v-list-item-title><v-icon> mdi-chevron-right </v-icon> </v-list-item>
                        <!-- <v-divider class="mx-3 mt-n1" style="border:1px solid #EFF2F5 !important;"></v-divider>
                                                <v-list-item dense class="pl-3 fw-500" link to="/event" style="">
                                        <v-list-item-title
                                            class=" font-weight-medium caption">Events</v-list-item-title><v-icon>
                                            mdi-chevron-right </v-icon>
                                    </v-list-item> -->
                        <v-divider class="mx-3 mt-n1" style="border: 1px solid #eff2f5 !important"></v-divider>
                        <!-- <v-list-item dense class="pl-3 fw-500" link to="/eventpage" style="">
                                        <v-list-item-title
                                            class=" font-weight-medium caption">Event</v-list-item-title><v-icon>
                                            mdi-chevron-right </v-icon>
                                    </v-list-item> -->
                        <!-- <v-divider class="mx-3 mt-n1"
                                                style="border:1px solid #EFF2F5 !important;"></v-divider>

                                            <v-list-item dense class="pl-3 fw-500" link to="/tradeverify" style="">
                                                <v-list-item-title
                                                    class=" font-weight-medium caption">Verified P&L (Beta)</v-list-item-title><v-icon>
                                                    mdi-chevron-right </v-icon>
                                            </v-list-item> -->
                        <!-- <v-divider class="mx-3 mt-n1"
                                                style="border:1px solid #EFF2F5 !important;"></v-divider>
                                                <v-list-item dense class="pl-3 fw-500" link to="/corporateaction" style="">
                                                <v-list-item-title
                                                    class=" font-weight-medium caption">Corporate Actions</v-list-item-title><v-icon>mdi
                                                    mdi-chevron-right </v-icon>
                                            </v-list-item> -->
                        <!-- <v-divider class="mx-3 mt-n1"
                                                    style="border:1px solid #EFF2F5 !important;"></v-divider> -->
                        <v-list-item dense class="pl-3 fw-500" link to="/pdfdownload" style=""> <v-list-item-title class="font-weight-medium caption">PDF Download</v-list-item-title><v-icon>mdi mdi-chevron-right </v-icon> </v-list-item>
                        <!-- <v-divider class="mx-3 mt-n1"
                                        style="border:1px solid #EFF2F5 !important;"></v-divider>
                                    <v-list-item dense class="pl-3 fw-500" link to="/calender" style="">
                                        <v-list-item-title
                                            class=" font-weight-medium caption">calender</v-list-item-title><v-icon>mdi
                                            mdi-chevron-right </v-icon>
                                    </v-list-item> -->
                        <!-- <v-divider class="mx-3 mt-n1" style="border:1px solid #EFF2F5 !important;"></v-divider>
                                    <v-list-item dense class="pl-3 fw-500" link to="/DiviDends_Page" style="">
                                        <v-list-item-title class=" font-weight-medium caption">Dividends</v-list-item-title><v-icon>mdi
                                            mdi-chevron-right </v-icon>
                                    </v-list-item>
                                                <v-divider class="mx-3 mt-n1"
                                                    style="border:1px solid #EFF2F5 !important;"></v-divider> -->
                        <!-- <v-list-item dense class="pl-3 fw-500" link to="/pledge" style="">
                                                <v-list-item-title class=" font-weight-medium caption">Pledge &
                                                    Unpledge</v-list-item-title><v-icon>mdi
                                                    mdi-chevron-right </v-icon>
                                            </v-list-item>
                                            <v-divider class="mx-3 mt-n1"
                                                style="border:1px solid #EFF2F5 !important;"></v-divider> -->
                        <!-- <v-list-item dense class="pl-3 fw-500" link to="/pledge" style="">
                                                <v-list-item-title class=" font-weight-medium caption">Pledge & Unpledge
                                                    </v-list-item-title><v-icon>
                                                    mdi-chevron-right </v-icon>
                                            </v-list-item>
                                            <v-divider class="mx-3 mt-n1"
                                                style="border:1px solid #EFF2F5 !important;"></v-divider> -->

                        <!-- <v-list-item dense class="pl-3" @click="logout">
                                        <v-list-item-title
                                            class=" font-weight-medium caption">Logout</v-list-item-title><v-icon>mdi
                                            mdi-chevron-right </v-icon>
                                    </v-list-item> -->
                      </div>
                    </v-list-group>
                    <v-list-item dense class="pl-3 fw-500" link to="/tradeverify" style="">
                      <v-list-item-avatar tile size="22">
                        <!-- <img src="@/assets/Portfolio.svg" alt="Portfolio icon"> -->
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M16 13H8M16 17H8M10 9H8M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2Z"
                                                        stroke="black" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg> -->
                        <v-icon color="black">mdi mdi-check-decagram</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title class="body-2 font-weight-bold">Verified P&L </v-list-item-title>
                    </v-list-item>

                    <v-list-item dense class="pl-3 fw-500" link to="/corporateaction" style="">
                      <v-list-item-avatar tile size="22">
                        <!-- <img src="@/assets/Portfolio.svg" alt="Portfolio icon"> -->
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M16 13H8M16 17H8M10 9H8M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2Z"
                                                        stroke="black" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg> -->
                        <v-icon color="black"> mdi mdi-view-list-outline</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title class="body-2 font-weight-bold">Corporate Actions </v-list-item-title>
                    </v-list-item>
                    <v-list-item dense class="pl-3 fw-500" link to="/event" style="">
                      <v-list-item-avatar tile size="22">
                        <!-- <img src="@/assets/Portfolio.svg" alt="Portfolio icon"> -->
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none">
                                        <path
                                            d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M16 13H8M16 17H8M10 9H8M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2Z"
                                            stroke="black" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg> -->
                        <v-icon color="black">mdi mdi-calendar-multiple</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title class="body-2 font-weight-bold">CA Events </v-list-item-title>
                    </v-list-item>
                    <v-list-item dense class="pl-3 fw-500" link to="/pledge" style="">
                      <v-list-item-avatar tile size="22">
                        <!-- <img src="@/assets/Portfolio.svg" alt="Portfolio icon"> -->
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M16 13H8M16 17H8M10 9H8M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2Z"
                                                        stroke="black" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg> -->
                        <v-icon color="black">mdi mdi-hand-extended-outline</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title class="body-2 font-weight-bold">Pledge & Unpledge </v-list-item-title>
                    </v-list-item>
                    <v-list-item dense class="pl-2 fw-500" link to="/refer" style="">
                                        <v-list-item-avatar tile size="22">

                                            <v-icon color="black">mdi-party-popper</v-icon>

                                        </v-list-item-avatar>
                                        <v-list-item-title class=" body-2 font-weight-bold">Refer
                                        </v-list-item-title>
                                    </v-list-item>
                  </div>
                </v-list-item-group>
              </v-list>
              <div class="pa-2 ml-n2">
                <v-btn @click="logout" style="border-radius: 4px" block color="#000" class="logoutposition">
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                        fill="none">
                                        <g clip-path="url(#clip0_1680_167)">
                                            <path d="M12.5068 4.79297L16.7146 9.00076L12.5068 13.2086" stroke="#fff"
                                                stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.7149 9H5.49414" stroke="#fff" stroke-width="1.2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <path
                                                d="M8.29912 16.7137H3.39003C2.83204 16.7137 2.29691 16.4921 1.90235 16.0975C1.50779 15.703 1.28613 15.1678 1.28613 14.6098V3.38905C1.28613 2.83106 1.50779 2.29593 1.90235 1.90137C2.29691 1.50682 2.83204 1.28516 3.39003 1.28516H8.29912"
                                                stroke="#fff" stroke-width="1.6" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1680_167">
                                                <rect width="18" height="18" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg> -->
                  <span class="text-none font-weight-bold pl-2 white--text">Logout</span>
                </v-btn>
              </div>
            </v-card>
            <!-- <template v-slot:append> -->

            <!-- </template> -->
          </v-navigation-drawer>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      lsticnmblone: false,
      lsticnmbltwo: false,
      lsticnmbltre: false,
      mblslcItm: 0,
      drawer: false,
      offset: true,
      accountstatus : '',
      lsticonone: false,
      lsticontwo: false,
      lsticontre: false,
      lsticonfro: false,
      dskslcItm: 0,
      singlename: "",
      cname: "",
      actid: "",
      susertoken: "",
      appbarhide: false,
      footermediabtn: [
        {image: "https://zebuetrade.com/img/facebook.a100418e.svg", link: "https://www.facebook.com/zebuetrade/"},
        {image: "https://zebuetrade.com/img/twitter-x.c0eeae44.svg", link: "https://twitter.com/zebuetrade?lang=en"},
        // { image:  '@/assets/footer/indeed.svg'), link: 'https://in.linkedin.com/company/zebu-share-and-wealth-managements-pvt-ltd' },
        {image: "https://zebuetrade.com/img/youtube.1d9d5060.svg", link: "https://www.youtube.com/channel/UCKbEVG1fH1TwkNDe6OM-zxg"},
        {image: "https://zebuetrade.com/img/instagram.2947a276.svg", link: "https://www.instagram.com/zebu_official/"},
        {image: "https://zebuetrade.com/img/pinterest.af43ff63.svg", link: "https://in.pinterest.com/ZebuMarketing/"},
      ],
      // accountstatus:"",
        padless: false,
        variant: 'fixed',
    };
  },
  computed: {
      localAttrs () {
        const attrs = {}

        if (this.variant === 'default') {
          attrs.absolute = false
          attrs.fixed = false
        } else {
          attrs[this.variant] = true
        }
        return attrs
      },
    },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  //   created() {
  //     console.log(this.appbarhide,"appbarhide");
  //     var url = new URL(window.location.href); ''

  //     console.log(this.appbarhide,"appbarhide2");

  //   },
  methods: {
    email() {
      const emailAddress = "grievance@zebuetrade.com";
      const mailtoURL = `mailto:${emailAddress}`;
      window.location.href = mailtoURL;
    },
    logout() {
      let data = JSON.stringify({
        clientid: this.actid,
        token: this.susertoken,
      });

      let config = {
        method: "post",
        url: "https://rekycbe.mynt.in/autho/logout",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data) {
            localStorage.clear();
            location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.actid = localStorage.getItem("userid");
    this.accountstatus = localStorage.getItem("clientStatus")
    this.cname = localStorage.getItem("cname");
    this.susertoken = localStorage.getItem("usession");
    let rep = localStorage.getItem("reports");
    let pro = localStorage.getItem("profile");
    // console.log(pro);
    
    if (rep == 'true') {
      this.mblslcItm = 2
    }else if(pro == 'true'){
      this.mblslcItm = 1
    }
    this.singlename = this.cname[0];
  },
};
</script>
<style lang="scss">
.lstctntit {
  font-weight: 500 !important;
}

.ctnbtl {
  font-weight: 500 !important;
}

.trmnucolsl {
  background-color: #f9fbfc !important;
  background-size: 80% !important;
}

.v-slide-group__prev.v-slide-group__prev--disabled {
  display: none !important;
}

.v-list--nav .v-list-item:not(:last-child):not(:only-child),
.v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
  margin-bottom: 4px !important;
}

.v-data-table .v-data-table__wrapper table thead th {
  background-color: #f9fbfc !important;
  color: #878b93 !important;
  border-color: #eceef2 !important;
  font-weight: 500 !important;
}

/* .v-data-footer__select {
    display: none;
}

.v-data-footer__icons-before,
.v-data-footer__icons-after {
    display: none;
} */

.v-navigation-drawer__content::-webkit-scrollbar {
  width: 5px;
}

.v-navigation-drawer__content::-webkit-scrollbar-track {
  border-radius: 10px;
}

.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}

.v-main {
  padding-left: 256px !important;
}

@media only screen and (max-width: 959px) {
  .appbar {
    position: fixed !important;
    z-index: 1 !important;
  }

  .v-main {
    padding-left: 0px !important;
  }

  .v-menu__content {
    box-shadow: 0px 8px 16px -4px rgba(22, 34, 51, 0.08) !important;
  }
}

.v-navigation-drawer__border {
  display: none;
}

header.cust-appbar.px-0.px-md-2.mt-n3.overflow-hidden.v-sheet.theme--light.v-toolbar.v-toolbar--dense.v-app-bar.v-app-bar--is-scrolled {
  z-index: 3 !important;
}

.logoutposition {
  position: absolute;
  bottom: 10;
}

// .v-overlay__scrim {
//     background-color: transparent !important;
// }

/* .v-list--nav .v-list-item:not(:last-child):not(:only-child), .v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
    margin-bottom: -9px !important;
} */
</style>
