<template>
    <div>
        <v-app-bar height="50px" elevation="0" style="border-bottom:1px solid #acacac;background-color:white" app fixed >
      <div>
        <v-img
          src="https://in.zebull.in/img/logo.ee5ba741.svg"
          class="mt-2 mb-4"
          width="80px"
        ></v-img>
      </div>

      <v-spacer></v-spacer>
      <v-tooltip color="black" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon plain text @click="logout"
            ><v-icon class="">mdi-close-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>Go To Back Page</span>
      </v-tooltip>
    </v-app-bar>
        <v-container>
            <div>
                <div class="d-flex mt-14">

                </div>
                <!-- <p>{{ respoqq }}</p> -->
                <!-- <p>{{ respoqq }}</p>
        <p>{{ respoqq.CLIENT_NAME }}</p> -->
                <!-- <p>{{ cardrpp.resid }}</p> -->
                <div>
                    <v-card elevation="0" style="background-color: #f0fff0" outlined class="mt-3 pa-5 rounded-lg">
                        <v-row>
                            <!-- <v-col cols="12" sm="12" md="6" lg="4">
                <p class="font-weight-semibold">
                  pledgeidentifier<span class="float-right">{{
                    cardrpp.pledgeidentifier
                  }}</span>
                </p>
                <p class="font-weight-semibold">
                  remarks<span class="float-right">{{ cardrpp.remarks }}</span>
                </p>
              </v-col>
              <v-divider vertical></v-divider> -->
                            <v-col cols="12" sm="12" md="6" lg="6">
                                <p class="font-weight-semibold">
                                    Client Name<span class="float-right">{{ name }}</span>
                                </p>
                                <p class="font-weight-semibold">
                                    Client ID<span class="float-right">{{ nameid }}</span>
                                </p>
                                <p class="font-weight-semibold">
                                    BO ID<span class="mb-0 float-right">{{ biboid }}</span>
                                </p>
                                <p class="mb-0 font-weight-semibold">
                                    Request ID<span class="float-right">{{ cardrpp.reqid }}</span>
                                </p>
                                <!-- <p class="mb-0 font-weight-semibold">
                  CDSL Id<span class="mb-0 float-right">{{
                    cardrpp.resid
                  }}</span>
                </p> -->
                            </v-col>
                            <v-divider class="d-none d-md-none d-lg-flex" vertical></v-divider>

                            <v-col cols="12" sm="12" md="6" lg="6">
                                <p class="font-weight-semibold">
                                    Status
                                    <v-tooltip color="black" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on" class="float-right"
                                                v-if="cardrpp.resstatus == 1"><v-chip label small style="
                        border: 1px solid #ffcdcd;
                        background-color: #fcf3f3;
                      "><span style="color: #e66258">Rejected</span></v-chip></span>
                                            <span v-bind="attrs" v-on="on" class="float-right" v-else><v-chip label small
                                                    style="border: 1px solid #c1e7ba; background: #ecf8f1"><span
                                                        style="color: #43a833">Success</span></v-chip></span>

                                           
                                        </template>
                                        <span>{{
                                                cardrpp.reserrmsg
                                            }}</span>
                                    </v-tooltip>
                                </p>
                                <p class="font-weight-semisemibold">
                                    CDSL Requested Time<span class="float-right">{{
                                        respoqq.pledge_req_time
                                    }}</span>
                                </p>
                                <p class="font-weight-semisemibold">
                                    CDSL Response Time<span class="float-right">{{
                                        respoqq.CDSL_resp_time
                                    }}</span>
                                </p>
                                <p class="mb-0 font-weight-semibold">
                                    CDSL ID<span class="mb-0 float-right">{{
                                        cardrpp.resid
                                    }}</span>
                                </p>
                                <!-- <p class="font-weight-semibold">
                  reserrmsg<span class="float-right">{{
                    cardrpp.reserrmsg
                  }}</span>
                </p> -->
                                <!-- <p class="font-weight-semibold">
                  Requested error<span class="mb-0 float-right">{{
                    cardrpp.reserror
                  }}</span>
                </p>
                <p class="mb-0 font-weight-semibold">
                  Remarks<span class="mb-0 float-right">{{
                    cardrpp.remarks
                  }}</span>
                </p> -->
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
                <v-card elevation="0" outlined class="mt-10 rounded-lg">
                    <div class="d-flex">
                        <p class="mb-0 mt-1 pa-3">Pledge Report Details</p>
                        <v-divider vertical></v-divider>
                    </div>
                    <v-divider></v-divider>
                    <v-data-table fixed-header disable-sort :headers="Overviewheaders" :items="report" :items-per-page="7"
                        style="border: 1px solid #efeef3" class="elevation-0 rounded-lg">

                        <template v-slot:[`item.status`]="{ item }">
                            <v-tooltip color="black" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" v-if="item.status == '1'"><v-chip label small
                                            style="border: 1px solid #ffcdcd; background-color: #fcf3f3"><span
                                                style="color: #e66258">Rejected</span></v-chip></span>
                                    <span v-bind="attrs" v-on="on" v-if="item.status == '0'"><v-chip label small
                                            style="border: 1px solid #c1e7ba; background: #ecf8f1"><span
                                                style="color: #43a833">Success</span></v-chip></span>

                                </template>
                                <span v-bind="attrs" v-on="on">{{ item.errormsg }}</span>
                            </v-tooltip>
                        </template>


                    </v-data-table>
                </v-card>
            </div>
        </v-container>
    </div>
</template>
<script>
// import { pledgeurlapi } from "../apiUrl";
import { pledgeurlapi } from "../../../Api.js";

export default {
    data() {
        return {
            route: [],
            report: [],
            // resoptresp:'',
            name: "",
            nameid: "",
            biboid: "",
            cardrpp: "",

            respoqq: [],
            Overviewheaders: [
                { text: "ISIN", value: "isin" },
                { text: "Reqired Id", value: "isinreqid" },
                { text: "Res Id", value: "isinresid" },
                { text: "Quantity", value: "quantity" },
                { text: "Status", value: "status" },
                //   { text: "S Avg", value: "SALERATE" },
                //   { text: "B Amt", value: "SALEAMT" },
                //   { text: "NET Avg", value: "NETRATE" },
                //   { text: "NET Amt", value: "NETAMT" },
                // { text: "S Avg", value: "" },
            ],
        };
    },
    methods: {
        resoptresp() {
            const axios = require("axios");
            let data = JSON.stringify({
                reqid: this.route.reqid,
                // "reqid": "071020231000000000000034"
            });

            let config = {
                method: "post",
                maxBodyLength: Infinity,
                // url: "http://192.168.5.144:9000/reqid_details",
                url: `${pledgeurlapi}/reqid_details`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            let axiosThis = this;
            axios
                .request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));

                    response.data.data;
                    axiosThis.cardrpp = thiii;
                    var clname = response.data.CLIENT_NAME;
                    this.name = clname;
                    var clnameid = response.data.uccid;
                    this.nameid = clnameid;
                    var boidd = response.data.client_bo_id;
                    this.biboid = boidd;
                    //   console.log("1stttt", response.data.CDSL_resp);
                    var sencc = response.data.CDSL_resp;
                    //   console.log("2222stttt", sencc.pledgeresdtls);
                    var thiii = sencc.pledgeresdtls.pledgeresdtls;
                    var forr = thiii.isinresdtls;
                    console.log("3333333stttt", thiii.isinresdtls);
                    console.log("3333333stttt123", sencc.pledgeresdtls.pledgeresdtls);
                    console.log("444444stttt", forr);
                    console.log("1234567890stttt", thiii.pledgeidentifier);
                    axiosThis.cardrpp = thiii;
                    axiosThis.report = forr;

                    axiosThis.respoqq = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        logout() {
            window.location.assign("/pledge");
    },
    },
    mounted() {
        this.$route.query;
        var i = this.$route.query;
        console.log("hgfgfg", i);
        console.log("keyyy", i.reqid);
        this.route = i;
        this.resoptresp();
    },
};
</script>