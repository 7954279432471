<template>
  <v-app>
    <v-container class="pa-0 mt-n5 pa-sm-3 pa-md-3 pb-0">
      <div class="pt-md-4 px-6 px-sm-4 px-md-4 pb-0 d-none d-md-block">
        <v-col cols="12" class="pl-7 pb-0">
          <v-card class="mt-7 elevation-0">
            <v-toolbar class="mt-3 mb-1 px-1 toolbar-contant" elevation="0" color="white">
              <v-list class="" style="background-color: transparent">
                <v-list-item class="pt-0 px-0">
                  <v-list-item-content class="pt-0">
                    <v-list-item-title class="text-start" style="color: #000000; font-size: 20px; font-weight: 559; line-height: 24px; letter-spacing: -1px"> Positions - (Beta)</v-list-item-title>
                    <v-list-item-subtitle class="text-start pt-1" style="color: #666666; font-size: 12px; font-weight: 469; line-height: 14px">It shows only NSE position (Cash, F&O, CDS and COM) </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-spacer></v-spacer>
              <v-list class="mr-7 pt-0" style="background-color: transparent">
                <v-list-item class="pl-0 pa-0" style="min-height: 0px">
                  <v-list-item-content class="pl-2 pa-0">
                    <v-list-item-title class="text-start" style="color: #000000; font-size: 16px; font-weight: 559; line-height: 16px">
                      {{ expositiondata ? expositiondata.length : 0 }}
                      scripts</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-text-field style="max-width: 200px" v-model="search" class="tophundraedmutual mt-4 mr-2" height="36px" background-color="#F1F3F8" label="Search" dense rounded solo flat>
                <template v-slot:prepend-inner>
                  <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="18px" />
                </template>
              </v-text-field>
              <span class="fs-12"
                >Last update: <b>{{ lasttime ? lasttime : "HH:MM:SS" }}</b></span
              >
              <v-icon :disabled="exloader" class="cursor-p mb-auto mt-3 ml-2" @click="(exloader = true), getAllposition()" color="maintext" size="24">mdi-reload</v-icon>
            </v-toolbar>
            <v-divider class="mb-3"></v-divider>
            <div class="display-flex row pa-8">
              <v-row>
                <v-col cols="4">
                  <v-card class="pl-3 py-1 pr-2" outlined height="90px" width="">
                    <p class="pt-1 mb-3" style="font-weight: 450; font-size: 16px; color: #000000; line-height: 16px; letter-spacing: -0.4px">Profit/Loss</p>
                    <v-divider class="mt-2" style="border: solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" :class="statposition.cpnl > 0 ? 'green--text' : statposition.cpnl < 0 ? 'red--text' : 'grey--text'" style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px">
                          {{ statposition.pnl ? statposition.pnl : "0.00" }} <span class="fs-10">{{ statposition.cpnl ? statposition.cpnl : "0.00" }}%</span>
                        </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <!-- <v-col cols="2">
                  <v-card class="pl-3 py-1 pr-2" outlined height="90px" width="">
                    <p class="pt-1 mb-3" style="font-weight: 450; font-size: 16px; color: #000000; line-height: 16px; letter-spacing: -0.4px">Trade value</p>
                    <v-divider class="mt-2" style="border: solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px">
                          {{ statposition.tradeval ? statposition.tradeval : "0.00" }}
                        </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col> -->
                <v-col cols="4">
                  <v-card class="pl-3 py-1 pr-2" outlined height="90px" width="">
                    <p class="pt-1 mb-3" style="font-weight: 450; font-size: 16px; color: #000000; line-height: 16px; letter-spacing: -0.4px">Open position ({{ statposition.open ? statposition.open.length : "0" }})</p>
                    <v-divider class="mt-2" style="border: solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="display-flex row pt-6 pl-2">
                      <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                        <p class="mb-0" :class="statposition.opcpnl > 0 ? 'green--text' : statposition.opcpnl < 0 ? 'red--text' : 'grey--text'" style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px">
                          {{ statposition.oppnl ? statposition.oppnl : "0.00" }} <span class="fs-10">{{ statposition.opcpnl ? statposition.opcpnl : "0.00" }}%</span>
                        </p>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="pl-3 py-1 pr-2" outlined height="90px" width="">
                    <p class="pt-1 mb-3" style="font-weight: 450; font-size: 16px; color: #000000; line-height: 16px; letter-spacing: -0.4px">Trade position</p>
                    <v-divider class="mt-2" style="border: solid px #000000" width="30px" color="#000000"></v-divider>
                    <div class="pt-0">
                      <v-chip-group row>
                        <v-chip class="mr-2 rounded-lg" color="green lighten-5" text-color="green">
                          <span class="caption"> {{ statposition.positive ? statposition.positive.length : "0" }} </span>&nbsp;Positive
                        </v-chip>
                        <v-chip height="16" class="mr-2 rounded-lg" color="red lighten-5" text-color="red">
                          <span class="caption"> {{ statposition.negative ? statposition.negative.length : "0" }} </span>&nbsp;Negative
                        </v-chip>
                        <v-chip height="16" class="rounded-lg" color="grey lighten-2" text-color="#000">
                          <span class="caption"> {{ statposition.close ? statposition.close.length : "0" }} </span>&nbsp;Closed
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </div>
      <!-- mobile -->
      <div class="d-md-none">
        <v-toolbar class="mt-6 mb-1 px-2 toolbar-contant" elevation="0" color="white">
          <v-list class="pb-0" style="background-color: transparent">
            <v-list-item class="pt-0 px-0">
              <v-list-item-content class="pt-0">
                <v-list-item-title class="text-start" style="color: #000000; font-size: 20px; font-weight: 559; line-height: 16px; letter-spacing: -1px"> Positions - (Beta) </v-list-item-title>
                <v-list-item-subtitle class="text-start pt-1" style="color: #666666; font-size: 12px; font-weight: 469; line-height: 14px">It shows only NSE position (Cash, F&O, CDS and COM) </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer></v-spacer>
          <span class="fs-12"
            >Last update: <b>{{ lasttime ? lasttime : "HH:MM:SS" }}</b></span
          >
          <v-icon :disabled="exloader" class="cursor-p mb-auto mt-3 ml-2" @click="(exloader = true), getAllposition()" color="maintext" size="24">mdi-reload</v-icon>
        </v-toolbar>
        <v-divider class="mx-2"></v-divider>
        <v-col class="pa-0 px-2" cols="12" md="12"> </v-col>
        <v-card color="#eeeeee" outlined class="elevation-0 rounded-lg pa-6 mt-3 mx-2">
          <v-card outlined class="elevation-0 rounded-lg pa-4 pb-0">
            <v-row no-gutters>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase">Profit/Loss</p>
                  </v-col>
                  <v-col cols="7">
                    <p :class="statposition.cpnl > 0 ? 'green--text' : statposition.cpnl < 0 ? 'red--text' : 'grey--text'" class="ctnbtl body-1 text-right" style="line-height: 28px">
                      {{ statposition.pnl ? statposition.pnl : "0.00" }} <span class="fs-10">{{ statposition.cpnl ? statposition.cpnl : "0.00" }}%</span>
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <!-- <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase">Trade value</p>
                  </v-col>
                  <v-col cols="7">
                    <p class="ctnbtl body-1 text-right" style="line-height: 28px">
                      {{ statposition.tradeval ? statposition.tradeval : "0.00" }}
                    </p>
                  </v-col>
                </v-row>
              </v-col> -->
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase">Profit/Loss</p>
                  </v-col>
                  <v-col cols="7">
                    <p :class="statposition.opcpnl > 0 ? 'green--text' : statposition.opcpnl < 0 ? 'red--text' : 'grey--text'" class="ctnbtl body-1 text-right" style="line-height: 28px">
                      {{ statposition.oppnl ? statposition.oppnl : "0.00" }} <span class="fs-10">{{ statposition.opcpnl ? statposition.opcpnl : "0.00" }}%</span>
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase">Trade position</p>
                  </v-col>
                  <v-col cols="7" class="text-right">
                    <!-- <v-chip-group row> -->
                    <v-chip x-small height="16" class="mt-0 mr-2 rounded-lg" color="green lighten-5" text-color="green">
                      <span class="caption"> {{ statposition.positive ? statposition.positive.length : "0" }} </span>&nbsp;Positive
                    </v-chip>
                    <v-chip x-small height="16" class="mt-0 mr-2 rounded-lg" color="red lighten-5" text-color="red">
                      <span class="caption"> {{ statposition.negative ? statposition.negative.length : "0" }} </span>&nbsp;Negative
                    </v-chip>
                    <v-chip x-small height="16" class="mt-0 rounded-lg" color="grey lighten-2" text-color="#000">
                      <span class="caption"> {{ statposition.close ? statposition.close.length : "0" }} </span>&nbsp;Closed
                    </v-chip>
                    <!-- </v-chip-group> -->
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
        <v-divider class="mt-4 mb-2"></v-divider>
        <div class="d-flex row mb-2">
          <p class="text-start mb-0 mt-6 ml-2 px-4" style="color: #696969; font-size: 12px; font-weight: 500; line-height: 16px">
            No of positions
            <span class="rounded-lg" style="color: #000000">({{ expositiondata ? expositiondata.length : 0 }})</span>
          </p>
        </div>

        <v-divider class="mb-2"></v-divider>
        <v-row>
          <v-col cols="12">
            <v-text-field style="width: 95%" v-model="search" class="tophundraedmutual mt-4 mx-4" height="24px" background-color="#F1F3F8" label="Search companies" solo rounded flat>
              <template v-slot:prepend-inner>
                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="16px" />
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
      </div>
      <div class="px-0 px-md-4 pb-0">
        <v-col cols="12" class="px-0 pl-md-7 py-0">
          <v-data-table
            must-sort
            item-key="token"
            :sort-by="['way']"
            :sort-desc="[true]"
            :loading="exloader"
            height="466px"
            :search="search"
            hide-default-footer
            fixed-header
            :items-per-page="-1"
            :headers="expositionheader"
            :items="expositiondata"
            style="border: 1px solid #efeef3"
            class="elevation-0 rounded-lg"
          >
            <template v-slot:body="{items, headers}">
              <tbody v-if="expositiondata && expositiondata.length > 0">
                <tr :style="`background-color:${item.way != 'open' ? '#F1F3F8' : ''};`" v-for="(item, o) in items" :key="o">
                  <td>
                    <p class="font-weight-bold black--text mb-0 ws-p">
                      {{ item.tsym ? item.tsym : "" }}
                      <span class="ml-1 grey--text fs-10">{{ item.exch ? item.exch : "" }}</span>
                    </p>
                  </td>

                  <td>
                    <v-chip
                      small
                      :color="item.netqty > 0 ? 'green lighten-5' : item.netqty < 0 ? 'red lighten-5' : item.way != 'open' ? '#fff' : '#F1F3F8'"
                      :text-color="item.netqty > 0 ? 'green' : item.netqty < 0 ? 'red' : '#666666'"
                      :style="`border: 1px solid ${item.netqty > 0 ? '#C1E7BA' : item.netqty < 0 ? '#FFCDCD' : '#DDD'}; border-radius: 5px; padding: 10px 8px !important;`"
                    >
                      <span class="font-weight-medium fs-12">{{ item.netqty > 0 ? `+${item.netqty}` : item.netqty < 0 ? `${item.netqty}` : "0" }}</span>
                    </v-chip>
                  </td>
                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium black--text mb-0">{{ item.avgprc ? Math.abs(item.avgprc).toFixed(2) : "0.00" }}</p>
                  </td>
                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium black--text mb-0">
                      <span> {{ Number(item.ltp) ? Number(item.ltp).toFixed(2) : "0.00" }}</span>
                    </p>
                  </td>
                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium mb-0" :class="item.rpnl > 0 ? 'green--text' : item.rpnl < 0 ? 'red--text' : 'grey--text'">
                      <span>{{ item.rpnl ? item.rpnl.toFixed(2) : "0.00" }}</span>
                    </p>
                  </td>
                  <td>
                    <v-chip small :color="item.way != 'open' ? '#fff' : '#F1F3F8'" text-color="#666666" style="border: 1px solid var(--outline); border-radius: 5px; padding: 10px 8px !important">
                      <span class="font-weight-medium fs-12">{{ item.BuyQuantity ? item.BuyQuantity : "0" }}</span>
                    </v-chip>
                  </td>
                  <td>
                    <v-chip small :color="item.way != 'open' ? '#fff' : '#F1F3F8'" text-color="#666666" style="border: 1px solid var(--outline); border-radius: 5px; padding: 10px 8px !important">
                      <span class="font-weight-medium fs-12">{{ item.SellQuantity ? item.SellQuantity : "0" }}</span>
                    </v-chip>
                  </td>

                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium black--text mb-0">{{ item.BuyPrice ? item.BuyPrice.toFixed(2) : "0.00" }}</p>
                  </td>

                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium black--text mb-0">{{ item.BuyValue ? item.BuyValue.toFixed(2) : "0.00" }}</p>
                  </td>

                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium black--text mb-0">{{ item.SellPrice ? item.SellPrice.toFixed(2) : "0.00" }}</p>
                  </td>

                  <td class="text-right font-weight-medium">
                    <p class="font-weight-medium black--text mb-0">{{ item.SellValue ? item.SellValue.toFixed(2) : "0.00" }}</p>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <td :colspan="headers.length" class="text-center">
                  <div class="mx-auto py-16 mt-16">
                    <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                    <h4 class="txt-999 font-weight-regular caption">There is no trading data here yet!</h4>
                  </div>
                </td>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </div>
    </v-container>
  </v-app>
</template>
<script>
import {beapi} from "../../../Api.js";
import axios from "axios";
export default {
  data() {
    return {
      profiledetails: [],
      headerval: [],
      search: null,

      exloader: true,
      expositiondata: [],
      statposition: [],
      lasttime: null,
    };
  },
  computed: {
    expositionheader() {
      return [
        {text: "Instrument", value: "tsym", sortable: false},
        {text: "Qty", value: "netqty", sortable: false},
        {text: "Avg Price", value: "avg", sortable: false, class: "ws-p", align: "right"},
        {text: "LTP", value: "ltp", sortable: false, align: "right"},
        {text: "P&L", value: "rpnl", sortable: false, class: "ws-p", align: "right"},
        {text: "Buy Qty", value: "BuyQuantity", sortable: false, class: "ws-p"},
        {text: "Sell Qty", value: "SellQuantity", sortable: false, class: "ws-p"},
        {text: "Buy Avg", value: "BuyPrice", sortable: false, class: "ws-p", align: "right"},
        {text: "Buy Amt", value: "BuyValue", sortable: false, class: "ws-p", align: "right"},
        {text: "Sell Avg", value: "SellPrice", sortable: false, class: "ws-p", align: "right"},
        {text: "Sell Amt", value: "SellValue", sortable: false, class: "ws-p", align: "right"},
        {text: "", value: "idx", class: " d-none"},
      ];
    },
  },
  methods: {
    decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      return decryptedData;
    },

    async getAllposition() {
      let data = JSON.stringify({
        client_id: localStorage.getItem("userid"),
      });

      let config = {
        method: "post",
        url: beapi + "GetPosition",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      var axiosthis = this;
      await axios
        .request(config)
        .then((response) => {
          let data = response.data;
          if (data && data.length > 0) {
            for (let q = 0; q < data.length; q++) {
              data[q]["idx"] = q;

              if (data[q].netqty == 0) {
                data[q]["way"] = "close";
                data[q]["disabled"] = null;
              } else {
                data[q]["way"] = "open";
                data[q]["disabled"] = false;
              }

              data[q]["val"] = (data[q].NetAvgPrc * Math.abs(data[q].netqty)).toFixed(2);
              data[q]["avgprc"] = data[q].NetAvgPrc;
            }
            axiosthis.expositiondata = [...data];
            axiosthis.statposition["positive"] = data.filter((x) => x.netqty > 0);
            axiosthis.statposition["negative"] = data.filter((x) => x.netqty < 0);

            axiosthis.statposition["close"] = data.filter((x) => x.netqty == 0);
            axiosthis.statposition["open"] = data.filter((x) => x.netqty != 0);

            let tradeval = data.reduce((acc, o) => acc + (Number(o.val) > 0 ? Number(o.val) : 0), 0);
            // axiosthis.statposition["tradeval"] = tradeval > 0 || tradeval < 0 ? this.differentView(Math.abs(tradeval)) : 0;

            let oppnl = axiosthis.statposition["open"].reduce((acc, o) => acc + (Number(o.rpnl) ? Number(o.rpnl) : 0), 0);
            axiosthis.statposition["oppnl"] = oppnl > 0 || oppnl < 0 ? this.differentView(Number(oppnl).toFixed(2)) : 0;
            axiosthis.statposition["opcpnl"] = Number(oppnl > 0 || oppnl < 0 ? ((this.statposition["open"].reduce((acc, o) => acc + (Number(o.rpnl) ? Number(o.rpnl) : 0), 0) / this.statposition["open"].reduce((acc, o) => acc + (Number(o.val) > 0 ? Number(o.val) : 0), 0)) * 100).toFixed(2) : 0);

            let pnl = data.reduce((acc, o) => acc + (Number(o.rpnl) ? Number(o.rpnl) : 0), 0);
            axiosthis.statposition["pnl"] = pnl > 0 || (pnl < 0 && tradeval > 0) || tradeval < 0 ? this.differentView(Number(pnl).toFixed(2)) : 0;
            axiosthis.statposition["cpnl"] = Number((pnl > 0 || pnl < 0) && (tradeval > 0 || tradeval < 0) ? ((pnl / tradeval) * 100).toFixed(2) : 0);
          } else if (data.length == 0) {
            axiosthis.expositiondata = [];
          }
          axiosthis.exloader = false;
        })
        .catch(() => {
          axiosthis.exloader = false;
        });
      this.lasttime = new Date().toLocaleTimeString("en-US");
    },
    differentView(view) {
      return view && Number(view) ? Number(view).toFixed(2) : "0.00";
    },
  },
  mounted() {
    var res = this.decryptionFunction(localStorage.getItem("profile_data"));
    var resp = JSON.parse(res);
    this.profiledetails = resp.client_data;
    this.exloader = true;
    this.getAllposition();
    setInterval(() => {
      this.getAllposition();
    }, 6000);
  },
};
</script>

<style>
.fs-10 {
  font-size: 10px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.ws-p {
  white-space: pre !important;
}
.toolbar .v-toolbar__content {
  padding: 0 !important;
}
</style>
