<template>
  <v-app>
    <div>
      <router-view />
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    //
  }),
};
</script>

<style>
@media only screen and (min-width: 1424px) {
  body {
    zoom: 114% !important;
  }
}
@media only screen and (min-width: 1524px) {
  body {
    zoom: 124% !important;
  }
}
@media only screen and (min-width: 1624px) {
  body {
    zoom: 128% !important;
  }
}
@media only screen and (min-width: 1724px) {
  body {
    zoom: 132% !important;
  }
}
@media only screen and (min-width: 1824px) {
  body {
    zoom: 140% !important;
  }
}
</style>
