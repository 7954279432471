<template>
    <v-app>
        <v-container class="pa-0 pa-sm-3 pa-md-3 mt-n5">
            <v-alert v-if="alert" dense outlined type="error" style="position: fixed; top: 8 ; right: 0 ; z-index: 3">
        <strong>{{ msg }}</strong>
      </v-alert>
            <div class="pt-16 pa-6 pa-sm-4 pa-md-4 d-none d-md-block">
                <!-- <v-switch x-small v-model="$vuetify.theme.dark" class="mt-n1 mr-2"></v-switch> -->
                <v-col cols="12" class="pl-7">
                    <v-card class="mt-7 elevation-0">
                        <v-toolbar class="mt-3 mb-1 px-1 toolbar-contant" elevation="0" color="white">
                            <v-list class="mr-2" style="background-color: transparent;">
                                <v-list-item class="px-0 pt-0">
                                    <v-list-item-content class=" pt-0">
                                        <v-list-item-title class="text-start"
                                            style="color: #000000;font-size: 20px;font-weight:559;line-height:19px;letter-spacing:-1px">
                                            Calender </v-list-item-title>

                                        <v-list-item-subtitle class="text-start pt-1"
                                            style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Calender for data of profit and loss
                                        </v-list-item-subtitle>
                                       
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-spacer></v-spacer>
                            <v-select label="Filled" :items="filterr" @change="filterseg()" dense
                                rounded
                            
                                        solo flat
                                v-model="filterdata_unpl" placeholder="filter"  background-color="#F1F3F8" hide-details
                                class="mr-2 mt-n3 fildrop" style="max-width: 140px; font-size: 21px">

                                <template v-slot:selection="{ item }">
                                <span class="font-weight-medium caption">{{ item }}</span>
                                </template>
                            </v-select>
                            <v-menu class="" v-model="menuForDatePicker" left  :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field style="max-width: 205px;" class="mt-4 mr-2" solo rounded flat
                                        v-model="fromshow" label="From date" dense color="black" background-color="#F1F3F8"
                                        prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-card class="pa-2">
                                    <div>
                                        <v-date-picker range :min="fromdate[0]" :max="maxval" color="black" v-model="fromdate" @input="datemaxvalue('click')"></v-date-picker>
                                        <!-- <v-date-picker  range color="black" v-model="fromdate" @change="getCalenderData()"></v-date-picker> -->
                                    </div>
                                    <!-- <p v-if="fromdate != '' && fromdate != [] && highlightyearsValue != 'cur'" class="ml-2 caption mb-1 "><span class="red--text">*</span>Your are in {{Number(maxval.split("-")[0]) - 1}}-{{maxval.split("-")[0]}} Financial year 
                                        <v-tooltip  color="black" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                           <span @click="reset"
                                             class="caption blue--text font-weight-bold"
                                        
                                            style="cursor: pointer"
                                            v-bind="attrs"
                                            v-on="on">
                                            Change
                                           </span>
                                        </template>
                                        <span>Click here to move the date forward for Financial year</span>
                                        </v-tooltip>Fsort
                                    </p> -->
                                    <p  v-if="fromdate != '' && fromdate != [] && highlightyearsValue != 'cur'" class="ml-2 caption mb-1 "><span class="red--text">*</span>Your are in {{Number(maxval.split("-")[0]) - 1}}-{{maxval.split("-")[0]}} Financial year 
                                        <v-tooltip  color="black" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                           <span @click="reset"
                                             class="caption blue--text font-weight-bold"
                                        
                                            style="cursor: pointer"
                                            v-bind="attrs"
                                            v-on="on">
                                            Change
                                           </span>
                                        </template>
                                        <span>Click here to move the date forward for Financial year</span>
                                        </v-tooltip>
                                    </p>
                                    <v-divider></v-divider>
                                    <div class="d-flex mt-n2">
                                        <!-- <p class="mb-0 mx-1 pa-4 body-2 font-weight-black" style="cursor: pointer;" @click="gettradedataextra(7)">Last 7 days</p> -->
                                        <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="gettradedataextra(30)">Last 30 days</p> -->
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == 'cur', 'black--text': highlightyearsValue != 'cur'}"  @click="GetDataWithYearSelector(0,'cur')">Current FY</p>
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == 'lyear', 'black--text': highlightyearsValue != 'lyear'}"  @click="GetDataWithYearSelector(-1,'lyear')">Last FY</p>

                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == yearsarray[0], 'black--text': highlightyearsValue != yearsarray[0]}"  @click="GetDataWithYearSelector(-2,yearsarray[0])">{{yearsarray[0]}}</p>
                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == yearsarray[1], 'black--text': highlightyearsValue != yearsarray[1]}"  @click="GetDataWithYearSelector(-3,yearsarray[1])">{{yearsarray[1]}}</p>
                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == yearsarray[2], 'black--text': highlightyearsValue != yearsarray[2]}"  @click="GetDataWithYearSelector(-4,yearsarray[2])">{{yearsarray[2]}}</p>
                                        <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" @click="mountedfun(30)">Last </p> -->
                                    </div>
                                </v-card>
                                
                            </v-menu>
                           
                           
                            <!-- dense prepend-inner-icon="mdi-filter-variant" -->
                          
                            <!-- <v-select v-if="!downsum" :items="filterr" @change="filterseg1(filterdata_unpl1)" dense
                                v-model="filterdata_unpl1" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                                hide-details class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px">
                            </v-select> -->
                            <!-- <v-btn v-if="downsum" @click="downloadsum()" class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon
                                    size="20">mdi mdi-content-save</v-icon></v-btn>
                            <v-btn v-if="!downsum" @click="downloaddet()"
                                class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon size="20">mdi
                                    mdi-content-save</v-icon></v-btn> -->
                           
                        </v-toolbar>
                        <v-divider></v-divider>
                        <div class="display-flex row   d-none d-md-block pa-8">
                            <v-row>
                                <v-col>
                                    <v-card class=" pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Realised P&L</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p :class="RealishedPnl > 0 ? 'green--text' : RealishedPnl < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (RealishedPnl).toFixed(2) }}
                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Unrealised P&L</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p :class="UnRealishedPnl > 0 ? 'green--text' : UnRealishedPnl < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (UnRealishedPnl).toFixed(2) }}
                                                </p>
                                                
                                            </v-card>
                                        </div>
                                    </v-card>

                                </v-col>
                                <v-col>
                                    <v-card  class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Charges & taxes</p>
                                         
                                        <v-divider  class="mt-2" style="border:solid px #000000"
                                            width="30px" color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <!-- <v-skeleton-loader  max-width="300" height="10"
                                                type="card"></v-skeleton-loader> -->

                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p :class="TaxesAndCharges > 0 ? 'green--text' : TaxesAndCharges < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (TaxesAndCharges).toFixed(2) }}
                                                </p>
                                              
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <!-- {{dessertspopexpense}} -->
                            </v-row>
                        </div>
                        <!-- <v-text-field style="width: 100%;" class="tophundraedmutual d-md-none mt-4 mr-5" height="36px"
                            background-color="#F1F3F8" label="Search symbols" v-model="search" solo rounded flat dense>
                            <template v-slot:prepend-inner>
                                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px"
                                    height="18px" />
                            </template>
                        </v-text-field> -->
                        <!-- <div class="display-flex row   d-none d-md-block pa-8">
                            <v-row>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Realised P&L</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p class="mb-0"  
                                                    style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    567</p>
                                                
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Unrealised P&L</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">

                                                <p class="mb-0"  
                                                    style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    567</p>
                                            </v-card>
                                        </div>
                                    </v-card>

                                </v-col>
                                <v-col>
                                    <v-card @click="chargerdialoge = true" class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Charges & taxes</p>
                                        <div class="display-flex row pt-6 pl-2"> 
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p class="mb-0" style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    567
                                                </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col> 
                            </v-row>
                        </div> -->
                        
                        <v-card class="mt-8" outlined  style=" height : 160px;width : 100%;overflow-x: scroll">
                            <div v-if=" loader == false" class="ml-n3 mt-4">
                                <!-- <div id="cal-heatmap"></div> -->

                                <div class="ml-1" id="cal-heatmap"></div>
                                   
                             </div>
                             <!-- <div v-else-if="loader == false" class="text-center mt-4">
                                <img alt="" class="shrink" src="../../../assets/nodata.svg" width="8%" height="" />
                                <p class="mb-0 caption mt-2">No data available</p>   
                            </div> -->
                                <!-- <v-overlay v-else class="rounded-0" style="z-index:2000"> -->
                            <div v-else class="text-center mt-4">
                                <v-progress-circular :size="50" :width="4" color="#0037B7" indeterminate></v-progress-circular>
                                <p class="mb-0 caption mt-4">Getting your data please wait...</p>   
                            </div>
                                <!-- </v-overlay> -->
                        </v-card>
                    </v-card>
                    <!-- :search="search" -->
                     <!-- {{ TableData.length }} -->
                    <v-data-table ref="dataTableRef"  :loading="loader" mobile-breakpoint
                                hide-default-footer height="450px" fixed-header disable-sort :headers="HeaderFortable"
                                :items="TableData" :items-per-page="TableData.length"
                                style="border: 1px solid #EFEEF3;z-index: -1;" class="elevation-0 rounded-lg mt-8">

                               
                                <template v-slot:[`item.SCRIP_SYMBOL`]="{ item }">
                                    <span style="cursor: pointer;" @click="SymbolMatchedDate(item)" class="font-weight-bold caption">{{ item.SCRIP_SYMBOL }}</span>
                                </template>

                                <template v-slot:[`item.TRADE_DATEDt`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.TRADE_DATEDt }}</span>
                                </template>

                                <!-- <template v-slot:[`item.BAMT`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.BAMT ? (item.BAMT).toFixed(2) : 0 }}</span>
                                </template> -->

                                <template v-slot:[`item.BQTY`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.BQTY }}</span>
                                </template>

                                <template v-slot:[`item.BRATE`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.BRATE ? (item.BRATE).toFixed(2) : 0 }}</span>
                                    <p class="font-weight-regular caption mb-0">{{ item.BAMT ? (item.BAMT).toFixed(2) : 0 }}</p>
                                </template>

                                <!-- <template v-slot:[`item.SAMT`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.SAMT ? (item.SAMT).toFixed(2) : 0 }}</span>
                                </template> -->

                                <template v-slot:[`item.SQTY`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.SQTY }}</span>
                                </template>

                                <template v-slot:[`item.SRATE`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.SRATE }}</span>
                                    <p class="font-weight-regular caption mb-0">{{ item.SAMT ? (item.SAMT).toFixed(2) : 0 }}</p>

                                </template>
                                <template v-slot:[`item.Updated_NETQTY`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.Updated_NETQTY }}</span>
                                </template>
                                <template v-slot:[`item.remainingbprice`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.remainingbprice }}</span>
                                </template>

                                <template v-slot:[`item.remainingqty`]="{ item }">
                                    <span class="font-weight-regular caption">{{ item.remainingqty }}</span>
                                </template>

                                <template v-slot:[`item.unrealisedpnl`]="{ item }">
                                    <span class="font-weight-regular caption"  >{{ item.unrealisedpnl ? (item.unrealisedpnl).toFixed(2) : 0 }}</span>  
                                </template>
                                <template v-slot:[`item.realisedpnl`]="{ item }">
                                    <span class="font-weight-regular caption"  >{{ item.realisedpnl ? (item.realisedpnl).toFixed(2) : 0 }}</span>  
                                </template>
                                <template v-slot:no-data>
                                    <v-col class="mx-auto pa-15 mt-5">
                                        <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                                        <p class="font-weight-bold black--text">No data available</p>
                                    </v-col>
                                </template>
                     </v-data-table>
                     
                </v-col>
                
            </div>
            <!-- mobile -->
            <div class="d-md-none">
                <v-toolbar class="mt-6 mb-1 px-2  toolbar-contant" elevation="0" color="white">
                    <v-list class="pb-0" style="background-color: transparent;">
                        <v-list-item class=" pt-0 px-0">
                             
                            <v-list-item-content class=" pt-0">
                                <v-list-item-title class="text-start"
                                    style="color: #000000;font-size: 20px;font-weight:559;line-height:16px;letter-spacing:-1px">
                                    Profit & Loss </v-list-item-title>

                                <v-list-item-subtitle class="text-start pt-1"
                                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Profit
                                    and Loss of your trades.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-spacer></v-spacer>

                    <v-list class=" pt-0" style="background-color: transparent;">
                        <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
                        </v-list-item>
                    </v-list>


                </v-toolbar>
                <v-divider class=" mx-2"></v-divider>
                <v-card color="#eeeeee" outlined class="mx-2 mt-4 d-md-none elevation-0 rounded-lg pa-6">
                    <div class="text-center pa-4">
                        <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                            Realised P&L</p>
                            <p :class="RealishedPnl > 0 ? 'green--text' : RealishedPnl < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (RealishedPnl).toFixed(2) }}
                                                </p>
                        <!-- {{ disrealised_pnl }} -->
                    </div>
                    <v-card outlined class="elevation-0 rounded-lg pa-4 pb-0">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-row no-gutters>
                                    <v-col cols="5">
                                        <p class="caption"
                                            style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                            Unrealised P&L</p>
                                    </v-col>
                                    <v-col cols="7">
                                          <p :class="UnRealishedPnl > 0 ? 'green--text' : UnRealishedPnl < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0 text-right"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (UnRealishedPnl).toFixed(2) }}
                                                </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row no-gutters @click="chargerdialoge = true">
                                    <v-col cols="5">
                                        <p class="caption" @click="chargerdialoge = true"
                                            style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                            Charges & taxes</p>
                                    </v-col>
                                    <v-col cols="7">
                                      
                                                <p :class="TaxesAndCharges > 0 ? 'green--text' : TaxesAndCharges < 0 ? 'red--text' : 'black--text'"
                                                    class="mb-0 text-right"
                                                    style="font-size: 13px; font-weight: 469; line-height: 12px; letter-spacing: 0.9px;">
                                                    {{ (TaxesAndCharges).toFixed(2) }}
                                                </p>


                                        
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card>
                <div class="d-flex mt-4"> 
                    <v-select label="Filled" :items="filterr" @change="filterseg('mobile')" dense
                                rounded
                            
                                        solo flat
                                v-model="filterdata_unpl" placeholder="filter"  background-color="#F1F3F8" hide-details
                                class="mr-2 fildrop" style="max-width: 50%; font-size: 21px">

                                <template v-slot:selection="{ item }">
                                <span class="font-weight-medium caption">{{ item }}</span>
                                </template>
                            </v-select>
                            <v-menu class="" v-model="menuForDatePickerMobile" left :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field style="max-width: 50%;" class="  mr-2" solo rounded flat
                                        v-model="fromshow" label="From date" dense color="black" background-color="#F1F3F8"
                                        prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-card class="pa-2">
                                    <div>
                                        <v-date-picker range :min="fromdate[0]" :max="maxval" color="black" v-model="fromdate" @input="datemaxvalue('click')"></v-date-picker>
                                        <!-- <v-date-picker  range color="black" v-model="fromdate" @change="getCalenderData()"></v-date-picker> -->
                                    </div>
                                    <!-- <p v-if="fromdate != '' && fromdate != [] && highlightyearsValue != 'cur'" class="ml-2 caption mb-1 "><span class="red--text">*</span>Your are in {{Number(maxval.split("-")[0]) - 1}}-{{maxval.split("-")[0]}} Financial year 
                                        <v-tooltip  color="black" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                           <span @click="reset"
                                             class="caption blue--text font-weight-bold"
                                        
                                            style="cursor: pointer"
                                            v-bind="attrs"
                                            v-on="on">
                                            Change
                                           </span>
                                        </template>
                                        <span>Click here to move the date forward for Financial year</span>
                                        </v-tooltip>
                                    </p> -->
                                    <p  v-if="fromdate != '' && fromdate != [] && highlightyearsValue != 'cur'" class="ml-2 caption mb-1 "><span class="red--text">*</span>Your are in {{Number(maxval.split("-")[0]) - 1}}-{{maxval.split("-")[0]}} Financial year 
                                        <v-tooltip  color="black" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                           <span @click="reset"
                                             class="caption blue--text font-weight-bold"
                                        
                                            style="cursor: pointer"
                                            v-bind="attrs"
                                            v-on="on">
                                            Change
                                           </span>
                                        </template>
                                        <span>Click here to move the date forward for Financial year</span>
                                        </v-tooltip>
                                    </p>
                                    <v-divider></v-divider>
                                    <div class="d-flex mt-n2">
                                        <!-- <p class="mb-0 mx-1 pa-4 body-2 font-weight-black" style="cursor: pointer;" @click="gettradedataextra(7)">Last 7 days</p> -->
                                        <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="gettradedataextra(30)">Last 30 days</p> -->
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == 'cur', 'black--text': highlightyearsValue != 'cur'}"  @click="GetDataWithYearSelector(0,'cur')">Current FY</p>
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == 'lyear', 'black--text': highlightyearsValue != 'lyear'}"  @click="GetDataWithYearSelector(-1,'lyear')">Last FY</p>

                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == yearsarray[0], 'black--text': highlightyearsValue != yearsarray[0]}"  @click="GetDataWithYearSelector(-2,yearsarray[0])">{{yearsarray[0]}}</p>
                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == yearsarray[1], 'black--text': highlightyearsValue != yearsarray[1]}"  @click="GetDataWithYearSelector(-3,yearsarray[1])">{{yearsarray[1]}}</p>
                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyearsValue == yearsarray[2], 'black--text': highlightyearsValue != yearsarray[2]}"  @click="GetDataWithYearSelector(-4,yearsarray[2])">{{yearsarray[2]}}</p>
                                        <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" @click="mountedfun(30)">Last </p> -->
                                    </div>
                                </v-card>
                                
                            </v-menu>
                </div>
                <v-card class="" outlined  style=" height : 260px; overflow-x: scroll;">
                            <div v-if="ChartValue.length > 0 && loader == false" class="pa-5">
                                <!-- <div id="cal-heatmap"></div> -->

                                <div class="ml-1" id="cal-heatmap2"></div>
                                   
                             </div>
                             <div v-else-if="loader == false" class="text-center mt-4">
                                <img alt="" class="shrink" src="../../../assets/nodata.svg" width="8%" height="" />
                                <p class="mb-0 caption mt-2">No data available</p>   
                            </div>
                                <!-- <v-overlay v-else class="rounded-0" style="z-index:2000"> -->
                            <div v-if="loader == true  " class="text-center mt-6">
                                <v-progress-circular :size="50" :width="4" color="#0037B7" indeterminate></v-progress-circular>
                                <p class="mb-0 caption mt-4">Getting your data please wait...</p>   
                            </div>
                                <!-- </v-overlay> -->
                        </v-card>

                        <v-card v-for="item in TableData " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                    class="elevation-0  px-4 pt-2" width="100%">

                    <div class="d-flex ">
                        <span   class="font-weight-bold">

                            {{ item.SCRIP_SYMBOL }}  

                            <!-- {{ item.COMPANY_CODE }} -->
                            <!-- <span class="caption font-weight-medium">({{
                            desserts2[item.SCRIP_SYMBOL].length }})</span> -->
                            </span>
                           
                                     
                    
                    </div>
                   

                    <v-divider class="mb-2 mt-2"></v-divider>
                    <v-row class="px-2">
                        <v-col>
                            <p class="mb-1 ml-auto d-flex body-2 font-weight-regular" style="color: #666666;">Net Qty: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.Updated_NETQTY
                                    }}</span></p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Amt: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.BAMT
                                    }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.BQTY
                                    }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.BRATE
                                    }}</span>
                            </p>
                            <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Realised Pnl : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.realisedpnl
                                    }}</span>
                            </p>
                        </v-col>
                        <v-col class="text-end">
                            <p class="mb-1 ml-auto body-2 font-weight-regular" style="color: #666666;">Net Avg: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.BUY_QUANTITY
                                    }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Amt: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.SAMT
                                    }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Qty: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.SQTY
                                    }}</span>
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.SRATE
                                    }}</span>
                            </p>
                            <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Unealised Pnl : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.unrealisedpnl

                                    }}</span>
                            </p>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
        </v-container>
    </v-app>
</template>
  
<script>
import CalHeatmap from 'cal-heatmap';
import Tooltip from 'cal-heatmap/plugins/Tooltip';
import 'cal-heatmap/cal-heatmap.css';
// import { apiurl } from '../../../Api.js'
// import LegendLite from 'cal-heatmap/plugins/LegendLite';
import CalendarLabel from 'cal-heatmap/plugins/CalendarLabel';
export default {
    name: 'HeatmapComponent',
    data() {
        return {
            ChartValue : [],
            fromshow : '',
            filterdata_unpl : 'Equity',
            yearsarray : [],
            cal : null,
            highlightyearsValue : 'cur',
            fromdate : [],
            maxval : '',
            menuForDatePicker : false,
            menuForDatePickerMobile : false,
            TaxesAndCharges : 0,
            UnRealishedPnl : 0,
            RealishedPnl : 0,
            FromDateForApi : '',
            ToDateForApi : '',
            susertoken : '',
            actid : '',
            cname : '',
            minval: '',
            loader : true,
            alert : false,
            rangeColr : ['#ff6e6e'  , '#5aae61'],
            rangeValue : [-0.01,0.01],
            Nodata : false,
            msg : '',
            HeaderFortable : [
            { text: 'Script Symbol', value: 'SCRIP_SYMBOL', align: 'start', class: "headerfont",width : '250px' },
            { text: 'BQTY', value: 'BQTY' , align: 'start', class: "headerfont" },
            // { text: 'Date', value: 'TRADE_DATEDt' , align: 'start', class: "headerfont",width : '100px' },
            // { text: 'BAMT', value: 'BAMT' , align: 'start', class: "headerfont" },
            { text: 'BRATE', value: 'BRATE' , align: 'start', class: "headerfont" },
            { text: 'SQTY', value: 'SQTY' , align: 'start', class: "headerfont" },
            // { text: 'SAMT', value: 'SAMT' , align: 'start', class: "headerfont" },
            { text: 'SRATE', value: 'SRATE' , align: 'start', class: "headerfont" },
            { text: 'Net Qty', value: 'Updated_NETQTY' , align: 'start', class: "headerfont" },
            { text: 'Unrealisedpnl', value: 'unrealisedpnl' , align: 'start', class: "headerfont" },
            { text: 'Realisedpnl', value: 'realisedpnl' , align: 'start', class: "headerfont" },
            // { text: 'pnl', value: 'unrealisedpnl' , align: 'start', class: "headerfont" },
            ],
            TableData : [],
            filterr: ['Equity','Fno','Commodity','Currency'],
            SymbolDataMatched : [],
        };
    }, 
    methods : {
        SymbolMatchedDate(item){
            console.log(item);
        },
        reset(){
            this.maxval = this.resetrange
            this.fromdate = []
            this.highlightyearsValue = ''
        },
        GetDataWithYearSelector(valuevalue,item) {
            
                this.highlightyearsValue = item
                //console.log(this.highlightyearsValue);
                this.menuForDatePicker = false
                let valuedate = valuevalue
                this.loader = true 
                this.menuForDatePickerMobile = false 
                var today = new Date();
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = today.getFullYear();
                var offset = valuedate * -1;
                var yearmount = ""
                var yearnext = ""
                if (mm < 4) {
                    yearmount = yyyy - 1 - offset;
                    yearnext = yyyy - offset;
                } else {
                    yearmount = yyyy - offset;
                    yearnext = yyyy + 1 - offset;
                }

              
               
                this.fromdate = []
                this.fromdate.push(yearmount + '-' + '04' + '-' + '01');  
                this.loader = true 
                this.fromdate.push( yearnext + '-03-31') 
                this.fromshow =  '01'+ '/' + '04' + '/'  + yearmount + '_to_' + '31/03/' + yearnext 
                this.datemaxvalue()
                this.FromDateForApi = '01' + '/' + '04' + '/' + yearmount 
                this.ToDateForApi = '31/03/' + yearnext  
            
                this.DataGetApi()
        },
        filterseg(item){
            console.log( this.fromdate)
            if (item == 'mobile' && this.fromdate.length < 2) {
                this.menuForDatePickerMobile = true
                
            }else if(this.fromdate.length < 2){
                this.menuForDatePicker = true
            }else{
                this.getCalenderData()
            }
        },
        datemaxvalue(item){
            let valuefordate = this.fromdate
            //console.log(valuefordate);
        //    if (this.fromdate.length < 1) {
        //         this.fromdate = valuefordate
        //         //console.log(this.fromdate.length,this.fromdate)

        //    }else{
        //         this.fromdate = []
        //         this.fromdate = valuefordate
            // //console.log(valuefordate[0].split("-")[0])

        //    }
            
            // this.maxval = `${Number(valuefordate.split("-")[0]) + 1}-03-31`
            // //console.log(valuefordate,this.maxval,typeof(Number(valuefordate.split("-")[0])))
            // //console.log(valuefordate[0])
            // //console.log(Number(valuefordate[1]))
            // if (condition) {
                
            // }
            if (Number(valuefordate[0].split("-")[1]) > 3) {
                this.maxval = `${Number(valuefordate[0].split("-")[0]) + 1}-03-31`
              
                // //console.log(this.maxval,"huhu");
                // if (item != 'mounted') {
                //     this.todate = `${Number(valuefordate.split("-")[0]) + 1}-03-31`
                // }
                // //console.log(this.maxval,"1")
            }
            else{
                this.maxval = `${Number(valuefordate[0].split("-")[0])}-03-31`
            // //console.log(this.maxval,"2")
                // //console.log(this.maxval,"huhu");

                // if (item != 'mounted') {
                //         this.todate = `${Number(valuefordate.split("-")[0])}-03-31`
                // }
            }

            if (item == 'click' && valuefordate.length > 1) {
                this.getCalenderData()
                this.highlightyearsValue = ''
            }
        },
        getCalenderData() {
                this.Nodata = true
                this.menuForDatePicker = false  
                this.menuForDatePickerMobile = false  
                this.loader = true 
                let dateString = this.fromdate[0]
                let dateObject = new Date(dateString);
                let year = dateObject.getFullYear();
                let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
                let day = dateObject.getDate();
                let formattedDate = (day < 10 ? '0' : '') + day + '/' + (month < 10 ? '0' : '') + month + '/' + year;
                this.FromDateForApi = formattedDate 
                let dateString1 = this.fromdate[1]
                let dateObject1 = new Date(dateString1);
                let year1 = dateObject1.getFullYear();
                let month1 = dateObject1.getMonth() + 1; // Months are 0-indexed, so add 1
                let day1 = dateObject1.getDate();
                this.ToDateForApi = (day1 < 10 ? '0' : '') + day1 + '/' + (month1 < 10 ? '0' : '') + month1 + '/' + year1; 
                this.fromshow = this.FromDateForApi + '_' + 'to' + '_' + this.ToDateForApi
                this.datemaxvalue()
                this.DataGetApi()
               
        },
        chart(){ 
                const data = [  ];
                for (let i = 0; i < this.ChartValue.length; i++) {
                    data.push(this.ChartValue[i])
                }
                //console.log(data);
            // const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            this.cal = new CalHeatmap();
            this.cal.paint({
                data: { source: data, x: "date", y: "score"},
                date: { start: new Date((this.fromdate[0]).split("-")[0] + '-' + '04' +  '-01') },
                range: 12,
                scale: {
                    color: 
                    { range: this.rangeColr,     type: 'linear',interpolate: 'hsl', domain: this.rangeValue }
                //     color: function (date, value, dayjsDate) {
                //     //console.log(value, dayjsDate,date);
                //     if (value > 0) {
                //         return {
                //             type: "quantize",
                //             scheme: 'Greens',
                //             domain: [-100000, 100000]
                //         };
                //     } else {
                //         return {
                //             type: "quantize",
                //             scheme: 'Reds',
                //             domain: [-100000, 100000]
                //         };
                //     }
                // }
                },
                domain: {
                    type: 'month',
                    // label: "MM",
                    gutter: 6
                },
                subDomain: { type: 'day', radius: 2,  width: 13, 
                // label: 'DD',
                height: 13,},
                itemSelector: '#cal-heatmap',
                // itemSelector: '#cal-heatmap',
            
            },
            [
            [
            CalendarLabel,
                {
                    position: 'left',
                    key: 'left',
                    text: () => ['', 'Mon', '', 'Wed', '', 'Fri', ''],
                    textAlign: 'end',
                    width: 30,
                    padding: [0, 5, 0, 0],
                },
            ],
            // [
            //     LegendLite,
            //     {
            //     radius: 3,
            //     itemSelector: '#cal-heatmap',
            //     },
            // ],
                [
                Tooltip,
                {
                text: function (date, value, dayjsDate) {
                    console.log('valvalvalvalvalvalvalvlavla',dayjsDate ,date);
                return value == null ? "" : "Realised P&L" + value + '  '  + dayjsDate.format('LL')
          ;
        },
    },
                ],
    
            ]
        
        );
        },
        chartMobile(){ 
                const data = [  ];
                for (let i = 0; i < this.ChartValue.length; i++) {
                    data.push(this.ChartValue[i])
                }
                //console.log(data);
            // const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            this.cal = new CalHeatmap();
            this.cal.paint({
                data: { source: data, x: "date", y: "score"},
                date: { start: new Date((this.fromdate[0]).split("-")[0] + '-' + '04' +  '-01') },
                //   verticalOrientation: true,
                range: 12,
                scale: {
                    color: 
                    { range: this.rangeColr,     type: 'linear',interpolate: 'hsl', domain: this.rangeValue }
                //     color: function (date, value, dayjsDate) {
                //     //console.log(value, dayjsDate,date);
                //     if (value > 0) {
                //         return {
                //             type: "quantize",
                //             scheme: 'Greens',
                //             domain: [-100000, 100000]
                //         };
                //     } else {
                //         return {
                //             type: "quantize",
                //             scheme: 'Reds',
                //             domain: [-100000, 100000]
                //         };
                //     }
                // }
                },
                domain: {
                    type: 'month',
                    // label: "MM",
                    gutter: 6
                },
                subDomain: { type: 'day', radius: 2,  width: 25, 
                // label: 'DD',
                height: 25,},
                // itemSelector: '#cal-heatmap',
                itemSelector: '#cal-heatmap2',
            
            },
            [
            [
            CalendarLabel,
                {
                    position: 'left',
                    key: 'left',
                    text: () => ['', 'Mon', '', 'Wed', '', 'Fri', ''],
                    textAlign: 'end',
                    width: 30,
                    padding: [0, 5, 0, 0],
                },
            ],
                [
                Tooltip,
                {
                text: function (date, value, dayjsDate) {
                    console.log(value, dayjsDate);
                return value == null ? "" : "Realised P&L" + value + '  '  + dayjsDate.format('LL')
          ;
        },
    },
                ],
    
            ]
        
        );
        },
        MountedDData(){
        this.susertoken = localStorage.getItem("usession");
        this.actid = localStorage.getItem("userid");
        this.yearlist()
        this.cname = localStorage.getItem("cname"); 
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        if (mm < 4) {
            var yearmount = yyyy - 1
        } else {
            yearmount = yyyy
        }
        
        this.FromDateForApi = '01' + '/' + '04' + '/' + yearmount;
        this.fromdate.push((yearmount) + '-' + '04' + '-' +  '01')
       
        this.fromdate.push(yyyy + '-' + mm + '-' + dd) 
        this.resetrange= yyyy + '-' + mm + '-' + dd
        // this.minval = yearmount - 2 +  '-' + '04' + '-' +'01'  ;
        this.ToDateForApi = dd + '/' + mm + '/' + yyyy; 
        this.fromshow = (this.FromDateForApi) + '_' + 'to' + '_' + (this.ToDateForApi) 
        this.datemaxvalue()
        this.DataGetApi()
        },
        yearlist(){
            var today = new Date();
            let sevthdate = new Date(today);
            
            let mmsevthdate = String(sevthdate.getMonth() + 1).padStart(2, '0')
            
            let yyyysevthdate = sevthdate.getFullYear();
            if (mmsevthdate < 4) {
                var yearmount = yyyysevthdate - 1
                }else{
                    yearmount = yyyysevthdate
                }
                var startYear = yearmount - 4;
            // this.yearis = yearmount;

            for (let year = yearmount; year >= startYear; year--) {
            // let yeararraytem = []

            this.yearsarray.push(year)
            }
            this.yearsarray = this.yearsarray.slice(2, 5)
            // //console.log(this.yearsarray)
        },
        DataGetApi(){
            this.TaxesAndCharges = 0
            this.UnRealishedPnl = 0
            this.RealishedPnl = 0
            this.TableData = []
            this.ChartValue = []
            if (this.filterdata_unpl == 'Equity') {
                var SegmentValue = 'NSE_CASH,BSE_CASH'
            }else if(this.filterdata_unpl == 'Fno'){ 
                SegmentValue = 'NSE_FNO,BSE_FNO'
            }else if( this.filterdata_unpl == 'Commodity'){
                SegmentValue = 'MCX,NCDEX,NSE_COM,BSE_COM'
            }else if(this.filterdata_unpl == 'Currency'){
                SegmentValue =  'CD_NSE,CD_BSE,CD_MCX,CD_USE'
            }
            const axios = require("axios");
                let data = JSON.stringify({
                    "cc": this.actid,
                    "from": this.FromDateForApi,
                    "to": this.ToDateForApi,
                    "segment": SegmentValue
                });
                let config = {
                method: 'post',
                maxBodyLength: Infinity,
                // url: `${apiurl}/getJournal`,
                url: 'http://192.168.5.141:9003/getJournal',

                headers: {
                    'Content-Type': 'application/json'
                },
                data : data
                };
                let axiosthis = this
                axios.request(config)
                .then((response) => {
                //console.log((response.data));
                // axiosthis.ChartValue = response.data.journal
                        if (response.data != 'No Data') {
                            axiosthis.ChartValue = []
                            axiosthis.TableData = []
                            axiosthis.Nodata = false
                            axiosthis.loader = false
                            var tableData =response.data.Data
                            // axiosthis.TableData.sort((a, b) => {
                            // if (a.TRADE_DATEDt < b.TRADE_DATEDt) return -1;
                            // if (a.TRADE_DATEDt > b.TRADE_DATEDt) return 1;
                            // return 0;
                            // });
                            //console.log('tableytable' ,axiosthis.TableData )
                            var RealishedPnl = 0
                            var UnRealishedPnl = 0
                            var TaxesAndCharges = 0
                            for (let i = 0; i < tableData.length; i++) {
                                 if (tableData[i].last == true) {
                                    axiosthis.TableData.push(tableData[i])
                                 }
                            }
                            for (let i = 0; i < response.data.journal.length; i++) {
                                let dateStr = response.data.journal[i]['TRADE_DATE'];
                                let dateObj = new Date(dateStr);
                                let formattedDate = dateObj.toISOString().slice(0, 10);
                                var ProfValue = response.data.journal[i]['realisedpnl']
                                        if (ProfValue != 0) {
                                            console.log("iflaenter",ProfValue,i)
                                            let num = response.data.journal[i]['realisedpnl']
                                            
                                            ProfValue = Number(parseFloat(num).toFixed(2))
                                        }else {
                                            ProfValue = ''
                                            //console.log("elsela enter ",ProfValue)
                                        }
                                        axiosthis.ChartValue.push(
                                            {
                                                date : formattedDate,
                                                score :  ProfValue,
                                                realisedpnl : ProfValue
                                            }
                                        )
                                        //console.log(ProfValue);
                                        RealishedPnl += response.data.journal[i]['realisedpnl'];
                                        UnRealishedPnl += response.data.journal[i]['unrealisedpnl'];
                                        TaxesAndCharges += response.data.journal[i]['total_bill_net'];
                            }

                            //console.log(RealishedPnl,UnRealishedPnl,TaxesAndCharges);
                            axiosthis.RealishedPnl = RealishedPnl
                            axiosthis.UnRealishedPnl = UnRealishedPnl
                            axiosthis.TaxesAndCharges = TaxesAndCharges
                            
                            // axiosthis.chart()
                            // setTimeout(() => {
                                if (!this.cal) {
                                    
                                    //console.log('ififififif')
                                
                                        axiosthis.chart()
                                        axiosthis.chartMobile()
                                    }else{
                                        axiosthis.cal.destroy();
                                        axiosthis.chart()
                                        axiosthis.chartMobile()
                                            //console.log('elseslseelser')


                                    }
                                
                            // }, 6000);
                        }else{
                            axiosthis.ChartValue = []
                            axiosthis.loader = false
                            axiosthis.Nodata = true
                            axiosthis.TableData = []
                            axiosthis.TaxesAndCharges = 0
                            axiosthis.UnRealishedPnl = 0
                            axiosthis.UnRealishedPnl = 0
                        }
                    //console.log(axiosthis.ChartValue);

                })
                .catch((error) => {
                    console.log(error);
                    axiosthis.msg = error.message
                    axiosthis.loader = false
                    axiosthis.alert = true
                });
        },
    }, 
    mounted() { 
        this.MountedDData()
    }
};
</script>

<style lang="scss">
.basil {
    background-color: #FFFBE6 !important;
}

.basil--text {
    color: #020202 !important;
}

.v-tab {
    text-transform: none !important;
}

.v-tabs--grow>.v-tabs-bar .v-tab {
    /* flex: 1 0 auto; */
    max-width: none !important;
}
</style>