<style>
/* .chartmdl {
  position: relative;
  text-align: center;
} */

/* .chartctn {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
</style>

<template>
    <canvas id="myChart" width="100%" height="35%"></canvas>
</template>
<script>
export default {
  data() {
    return {
      /* eslint-disable */

    };
  },
  mounted() {
    const ctx = document.getElementById('myChart').getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['JAN', 'FEB', 'MAR', 'APR','MAY', 'JUN', 'JUL', 'AUG','SEP', 'OCT', 'NOV', 'DEC'],
        datasets: [{
          data: [322800.00, 489800.00, 67300.00, 92570.00,322800.00, 489800.00, 67300.00, 92570.00,322800.00, 489800.00, 67300.00, 92570.00],
          backgroundColor: [
            '#34A853',
            '#E8EBED',
            '#34A853',
            '#E8EBED','#34A853',
            '#E8EBED','#34A853',
            '#E8EBED','#34A853',
            '#E8EBED','#34A853',
            '#E8EBED',
          ],
        }]
      },
      
      options: {
        
        legend: {
          display: false,
        },
        cutoutPercentage: 75,

        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }
}
</script>