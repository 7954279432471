<template>
    <v-app>
        <v-container class="pa-0 pa-sm-3 pa-md-3 mt-n5">
            <v-alert v-if="alert" dense outlined type="error" style="position: fixed; top: 8 ; right: 0 ; z-index: 3">
                <strong>{{ msg }}</strong>
            </v-alert>
            <div class="pt-16 pa-6 pa-sm-4 pa-md-4 d-none d-md-block">

                <v-col cols="12" class="pl-7">
                    <v-card class="mt-7 elevation-0">
                        <v-toolbar class="mt-3 mb-1 px-1 toolbar-contant" elevation="0" color="white">
                            <v-list class="" style="background-color: transparent;">
                                <v-list-item class="px-0 pt-0">
                                    <v-list-item-content class=" pt-0">
                                        <v-list-item-title class="text-start"
                                            style="color: #000000;font-size: 20px;font-weight:559;line-height:19px;letter-spacing:-1px">
                                            Dividends</v-list-item-title>

                                        <v-list-item-subtitle class="text-start pt-1"
                                            style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">
                                            Track your dividend performance
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <!-- <v-spacer></v-spacer> -->
                            <!-- <v-list class="mr-7 pt-0" style="background-color: transparent;">
                                <v-list-item class="pl-0 pa-0" style="min-height: 0px;">

                                    <v-list-item-content class="pl-2 pa-0  d-none d-md-block">
                                        <v-list-item-title v-if="downsum" class="text-start"
                                            style="color: #000000;font-size: 16px;font-weight:559;line-height:16px;">
                                            {{ dessertsoriginal.length }}
                                            Symbols
                                        </v-list-item-title>
                                        <v-list-item-title v-if="!downsum" class="text-start"
                                            style="color: #000000;font-size: 16px;font-weight:559;line-height:16px;">
                                            {{ desserts23.length }}
                                            Symbols
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-menu v-if="downsum && dessertsoriginal.length > 0" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="elevation-0 rounded-pill mt-n2 mr-2" v-bind="attrs" v-on="on">
                                        <v-icon size="20">mdi mdi-download</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="(item, i) in itemsdwl" :key="i" @click="() => { }">
                                        <v-list-item-title @click="downloadsum(item.title)">{{ item.title
                                        }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-menu v-if="!downsum && desserts23.length > 0" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="elevation-0 rounded-pill mt-n2 mr-2" v-bind="attrs" v-on="on">
                                        <v-icon size="20">mdi mdi-download</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="(item, i) in itemsdwl" :key="i" @click="() => { }">
                                        <v-list-item-title @click="downloaddet(item.title)">{{ item.title
                                        }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-menu class="" v-model="menu23" :close-on-content-click="false" transition="scale-transition"
                                offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field style="max-width: 205px;" class="mt-4 mr-2" solo rounded flat
                                        v-model="fromshow" label="From date" dense color="black" background-color="#F1F3F8"
                                        prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker color="black" v-model="fromdate"></v-date-picker>
                                <v-date-picker color="black" v-model="todate" @change="gettradedata()"></v-date-picker>
                            </v-menu> -->
                            <!-- dense prepend-inner-icon="mdi-filter-variant" -->
                            <!-- <v-select v-if="downsum" :readonly="segloader" :loading="segloader" :items="filterr"
                                @change="filterseg(filterdata_unpl)" dense v-model="filterdata_unpl" placeholder="filter"
                                solo rounded flat background-color="#F1F3F8" hide-details class="mr-2 mt-n2 fildrop"
                                style="max-width: 140px; font-size: 21px; overflow: hidden;">
                            </v-select> -->
                            <!-- <v-select v-if="!downsum" :items="filterr" @change="filterseg1(filterdata_unpl1)" dense
                                v-model="filterdata_unpl1" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                                hide-details class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px">
                            </v-select> -->
                            <!-- <v-btn v-if="downsum" @click="downloadsum()" class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon
                                    size="20">mdi mdi-content-save</v-icon></v-btn>
                            <v-btn v-if="!downsum" @click="downloaddet()"
                                class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon size="20">mdi
                                    mdi-content-save</v-icon></v-btn> -->
                            <!-- <v-text-field style="width: 2%;" class="tophundraedmutual  d-none d-md-block mt-4 mr-5"
                                height="36px" background-color="#F1F3F8" label="Search" v-model="search" solo rounded text
                                flat dense>
                                <template v-slot:prepend-inner>
                                    <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px"
                                        height="18px" />
                                </template>
                            </v-text-field> -->
                        </v-toolbar>
                        <v-divider></v-divider>
                        <!-- <v-text-field style="width: 100%;" class="tophundraedmutual d-md-none mt-4 mr-5" height="36px"
                            background-color="#F1F3F8" label="Search symbols" v-model="search" solo rounded flat dense>
                            <template v-slot:prepend-inner>
                                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px"
                                    height="18px" />
                            </template>
                        </v-text-field> -->
                        <div class="display-flex row   d-none d-md-block pa-8">
                            <v-row>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            2022 dividends</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <!-- <p class="mb-0" v-if="disrealised_pnl && disrealised_pnl < 0"
                                                    style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (disrealised_pnl).toFixed(2) }} </p>
                                                <p class="mb-0" v-if="disrealised_pnl && disrealised_pnl > 0"
                                                    style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (disrealised_pnl).toFixed(2) }} </p>
                                                <p class="mb-0" v-if="disrealised_pnl && disrealised_pnl == 0"
                                                    style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (disrealised_pnl).toFixed(2) }} </p> -->
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Scrip dividends</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">

                                                <!-- <p class="mb-0" v-if="disunrealised_pnl && disunrealised_pnl < 0"
                                                    style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (disunrealised_pnl).toFixed(2) }} </p>
                                                <p class="mb-0" v-else-if="disunrealised_pnl && disunrealised_pnl > 0"
                                                    style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (disunrealised_pnl).toFixed(2) }} </p>

                                                <p class="mb-0" v-else-if="disunrealised_pnl && disunrealised_pnl == 0"
                                                    style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (disunrealised_pnl).toFixed(2) }} </p> -->
                                            </v-card>
                                        </div>
                                    </v-card>

                                </v-col>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            dividend Holdings </p>

                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <!-- <v-skeleton-loader  max-width="300" height="10"
                                                type="card"></v-skeleton-loader> -->

                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">

                                                <!-- <p class="mb-0" v-if="segval && segval < 0"
                                                    style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (segval).toFixed(2) }} </p>
                                                <p class="mb-0" v-else-if="segval && segval > 0"
                                                    style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (segval).toFixed(2) }} </p>
                                                <p class="mb-0" v-else-if="segval && segval == 0"
                                                    style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (segval).toFixed(2) }} </p> -->
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Dividend Yield %</p>

                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <!-- <v-skeleton-loader  max-width="300" height="10"
                                                type="card"></v-skeleton-loader> -->

                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">

                                                <!-- <p class="mb-0" v-if="segval && segval < 0"
                                                    style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (segval).toFixed(2) }} </p>
                                                <p class="mb-0" v-else-if="segval && segval > 0"
                                                    style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (segval).toFixed(2) }} </p>
                                                <p class="mb-0" v-else-if="segval && segval == 0"
                                                    style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (segval).toFixed(2) }} </p> -->
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="mt-2 mt-sm-0 mt-md-0">
                            <div class="mb-n4 pa-2">
                                <!-- <v-row class="pt-0">
                                    <v-col lg="4" sm="12" md="12" cols="12">
                                        <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
                                            <v-tab @click="downsum = true">
                                                P&L
                                            </v-tab>
                                            <v-tab @click="downsum = false">
                                                Tax p&l
                                            </v-tab>
                                        </v-tabs>
                                    </v-col>
                                </v-row> -->
                                <!-- {{ loader }} -->

                                <!-- {{ dialog }} -->

                                <!-- <v-tabs-items v-model="tab"> -->
                                <!-- <v-tab-item> -->
                                    
                                <v-row>
                                    <v-col>
                                        <p class="font-weight-regular body-1">Dividend Calendar</p>
                                    </v-col>
                                    <v-col >
                                        <v-tabs color="black" v-model="tabgraph">
                                            <v-tab>Graph</v-tab>
                                            <v-tab>Calendar</v-tab>
                                        </v-tabs>
                                        
                                    </v-col>
                                </v-row>
                                <v-tabs-items class=" mt-3" v-model="tabgraph">
                                            <v-tab-item>
                                               <v-row>
                                                <v-col cols="12">
                                        <Chartfor_Dividend />
                                    </v-col>
                                               </v-row>
                                            </v-tab-item>
                                            <v-tab-item>
                                                <v-row class="px-3 mt-3 mb-3">
                                    
                                    <v-col cols="12">
                                        <v-row>
                                            <v-col v-for="(item, index) in calendercard" :key="index" lg="2" md="3" sm="4"
                                                cols="4" class="pa-0">
                                                <v-card class="elevation-0"
                                                    style="height:169px; weight: 150px;border: 1px solid #EFEEF3">
                                                    <p class="subtitle-1 mb-0 ma-2"
                                                        :style="{ color: item.desclr == 'true' ? '#878B93' : '#34A853' }">
                                                        {{ item.month }}</p>
                                                    <p class="headline mb-0 ml-2">{{ item.val }}</p>
                                                    <p class="caption mb-6 ml-2 mt-n1 " style="color: #878B93">{{ item.strip
                                                    }}</p>
                                                    <v-menu top :offset-x="offset">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-card v-bind="attrs" v-on="on"
                                                                class="ma-2 elevation-0  rounded-lg"
                                                                :style="{ 'background-color': item.desclr == 'true' ? '#E8EBED' : '' }"
                                                                style="width: 150px ; height: 45px">
                                                                <p class="caption pa-2 mb-0">{{ item.des }} <v-icon
                                                                        v-if="item.desclr == 'true'" size="18"
                                                                        style="position: absolute;left: 130px;top: 25px;">mdi
                                                                        mdi-information-slab-circle</v-icon>
                                                                </p>
                                                            </v-card>
                                                        </template>

                                                        <v-card class="elevation-0  "
                                                            style="width: 370px; height: 326px; border: 1px solid #ECEEF2">
                                                            <p style="color: #505359; letter-spacing: 0.1em;"
                                                                class="mb-0 pl-4 pt-4 caption font-weight-bold">4 STRIPS</p>
                                                            <p class="mb-0 pa-4 pt-2 caption">Benefit from the
                                                                high growth of
                                                                businesses in top performing dividends
                                                            </p>
                                                            <v-data-table dense hide-default-footer fixed-header
                                                                disable-sort height="224px" :headers="headersfortooltip"
                                                                :items="tooltipdata" class="elevation-1"></v-data-table>
                                                        </v-card>
                                                    </v-menu>

                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                            </v-tab-item>
                                        </v-tabs-items>
                               
                                <v-data-table :search="search" hide-default-footer :loading="loader" height="495px"
                                    mobile-breakpoint fixed-header disable-sort :headers="Overviewheaders"
                                    :items="sortedItems" :items-per-page="itemperpage" item-key="NSE_SCRIPCODE"
                                    style="border: 1px solid #efeef3; cursor: pointer" class="elevation-0 mt-10 rounded-lg "
                                    @click:row="detailedpop">
                                    <template v-slot:no-data>
                                        <v-col class="mx-auto pa-15 mt-5">
                                            <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%"
                                                height="" />
                                            <p class="font-weight-bold black--text">No data available
                                            </p>
                                        </v-col>
                                    </template>

                                </v-data-table>
                                <v-dialog v-model="dialog" width="70%" class="elevation-0">
                                    <v-card class=" elevation-0 pt-3 pb-2" height="100%">
                                        <v-btn :ripple="false" dense class="mt-n4" text icon solo absolute top right
                                            @click="dialog = false"><v-icon size="18" color="grey">mdi
                                                mdi-close</v-icon></v-btn>
                                        <v-toolbar class=" mb-1 mt-4 toolbar-contant" elevation="0" color="white">
                                            <v-list class="" style="background-color: transparent;">
                                                <v-list-item class=" pt-0">
                                                    <v-list-item-content class=" pt-0">
                                                        <v-list-item-title class="text-start"
                                                            style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">
                                                            Detailed P & L </v-list-item-title>
                                                        <v-list-item-subtitle class="text-start pt-1"
                                                            style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">{{
                                                                scriptname }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                            <v-spacer></v-spacer>
                                            <v-list class="mr-7 pt-0" style="background-color: transparent;">
                                                <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
                                                    <v-list-item-content class="pl-2 pa-0">
                                                        <v-list-item-title
                                                            class="text-start font-weight-medium d-none d-md-block"
                                                            style="color: #000000;font-size: 14px;line-height:16px;">
                                                            <!-- {{ detailedpoparray.length }}
                                                            Trades -->
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                            <v-text-field v-model="search1" class="tophundraedmutual mt-4" height="36px"
                                                background-color="#F1F3F8" style="width: 2%;" label="Search" solo rounded
                                                flat dense>
                                                <template v-slot:prepend-inner>
                                                    <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')"
                                                        width="20px" height="18px" />
                                                </template>
                                            </v-text-field>
                                        </v-toolbar>
                                        <v-divider></v-divider>

                                        <div class=" mb-4 mx-4 my-6">

                                            <v-data-table dense :headers="Overviewheaders2" hide-default-footer
                                                :loading="loaderpopup" :search="search1" style="border: 1px solid #efeef3"
                                                class="elevation-0 rounded-lg" height="370px" fixed-header disable-sort
                                                :items="detailedpoparray.data">

                                                <template v-slot:[`item.BQTY`]="{ item }">
                                                    <span class="font-weight-regular subtitle-2"> {{ item.BQTY
                                                    }}</span>
                                                </template>
                                                <template v-slot:[`item.BRATE`]="{ item }">
                                                    <span class="font-weight-regular subtitle-2"> {{
                                                        (item.BRATE.toFixed(2))
                                                    }}</span>
                                                    <p class="font-weight-medium caption mb-0"> {{ (item.BAMT).toFixed(2) }}
                                                    </p>
                                                </template>
                                                <template v-slot:[`item.NRATE`]="{ item }">
                                                    <span class="font-weight-regular subtitle-2"> {{ item.NRATE
                                                    }}</span>
                                                    <p class="font-weight-medium caption mb-0"> {{ item.NETAMT }}
                                                    </p>
                                                </template>


                                                <template v-slot:[`item.SRATE`]="{ item }">
                                                    <span class="font-weight-regular subtitle-2"> {{
                                                        (item.SRATE).toFixed(2)
                                                    }}</span>
                                                    <p class="font-weight-medium caption mb-0"> {{ (item.SAMT).toFixed(2)
                                                    }}</p>
                                                </template>
                                                <template v-slot:[`item.SQTY`]="{ item }">
                                                    <span class="font-weight-regular subtitle-2"> {{ item.SQTY
                                                    }}</span>
                                                </template>
                                                <template v-slot:[`item.TRADE_DATE`]="{ item }">
                                                    <span class="font-weight-bold subtitle-2"> {{ item.TRADE_DATE
                                                    }}</span>
                                                </template>
                                                <template v-slot:[`item.NETAMT`]="{ item }">
                                                    <span v-if="item.NETAMT < 0" class="font-weight-regular subtitle-2"
                                                        style="color: red;">
                                                        {{ item.NETAMT }}</span>
                                                    <span v-if="item.NETAMT > 0" class="font-weight-regular subtitle-2"
                                                        style="color: green;"> {{ item.NETAMT }}</span>
                                                    <span v-if="item.NETAMT == 0" class="font-weight-regular subtitle-2"
                                                        style="color: black;"> {{ item.NETAMT }}</span>
                                                </template>
                                                <template v-slot:no-data>
                                                    <v-col class="mx-auto pa-15 mt-5">
                                                        <img alt="" class="shrink" src="../../../assets/nodata.svg"
                                                            width="15%" height="" />
                                                        <p class="font-weight-bold black--text">No data available
                                                        </p>
                                                    </v-col>
                                                </template>
                                                <template v-slot:footer>
                                                    <v-divider></v-divider>
                                                    <div class="text-end">

                                                        <span class="mr-5 mt-4"
                                                            style="color: black;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">Total
                                                            : <span v-if="finaltotal > 0" style="color: green"> {{
                                                                finaltotal.toFixed(2)
                                                            }}</span>
                                                            <span v-if="finaltotal < 0" style="color: red"> {{
                                                                finaltotal.toFixed(2)
                                                            }}</span>
                                                        </span>
                                                    </div>

                                                </template>

                                            </v-data-table>



                                        </div>
                                    </v-card>
                                </v-dialog>

                                <!-- </v-tab-item> -->
                                <!-- <v-tab-item> -->
                                <!-- <p>Tax p and l datas</p> -->
                                <!-- </v-tab-item> -->
                                <!-- </v-tabs-items> -->

                            </div>
                        </div>
                    </v-card>
                </v-col>
            </div>
            <div class="d-md-none">
                <v-toolbar class="mt-6 mb-1 px-2  toolbar-contant" elevation="0" color="white">
                    <v-list class="pb-0" style="background-color: transparent;">
                        <v-list-item class=" pt-0 px-0">
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path
                      d="M11 47H2C1.73478 47 1.48043 46.8946 1.29289 46.7071C1.10536 46.5196 1 46.2652 1 46V28C1 27.7348 1.10536 27.4804 1.29289 27.2929C1.48043 27.1054 1.73478 27 2 27H11C11.2652 27 11.5196 27.1054 11.7071 27.2929C11.8946 27.4804 12 27.7348 12 28V46C12 46.2652 11.8946 46.5196 11.7071 46.7071C11.5196 46.8946 11.2652 47 11 47Z"
                      fill="#C8C8C8" />
                    <path
                      d="M46 47H37C36.7348 47 36.4804 46.8946 36.2929 46.7071C36.1054 46.5196 36 46.2652 36 46V28C36 27.7348 36.1054 27.4804 36.2929 27.2929C36.4804 27.1054 36.7348 27 37 27H46C46.2652 27 46.5196 27.1054 46.7071 27.2929C46.8946 27.4804 47 27.7348 47 28V46C47 46.2652 46.8946 46.5196 46.7071 46.7071C46.5196 46.8946 46.2652 47 46 47Z"
                      fill="#C8C8C8" />
                    <path
                      d="M27 2V4H21V2C21 1.73478 20.8946 1.48043 20.7071 1.29289C20.5196 1.10536 20.2652 1 20 1C19.7348 1 19.4804 1.10536 19.2929 1.29289C19.1054 1.48043 19 1.73478 19 2V11H21V6H27V11H29V2C29 1.73478 28.8946 1.48043 28.7071 1.29289C28.5196 1.10536 28.2652 1 28 1C27.7348 1 27.4804 1.10536 27.2929 1.29289C27.1054 1.48043 27 1.73478 27 2Z"
                      fill="#363636" />
                    <path
                      d="M38 47H10V10C10 9.73478 10.1054 9.48043 10.2929 9.29289C10.4804 9.10536 10.7348 9 11 9H37C37.2652 9 37.5196 9.10536 37.7071 9.29289C37.8946 9.48043 38 9.73478 38 10V47Z"
                      fill="#E3E3E3" />
                    <path
                      d="M20.5 23H16.5C16.3674 23 16.2402 22.9473 16.1464 22.8536C16.0527 22.7598 16 22.6326 16 22.5V20.5C16 20.3674 16.0527 20.2402 16.1464 20.1464C16.2402 20.0527 16.3674 20 16.5 20H20.5C20.6326 20 20.7598 20.0527 20.8536 20.1464C20.9473 20.2402 21 20.3674 21 20.5V22.5C21 22.6326 20.9473 22.7598 20.8536 22.8536C20.7598 22.9473 20.6326 23 20.5 23Z"
                      fill="#43A6DD" />
                    <path
                      d="M20.5 17H16.5C16.3674 17 16.2402 16.9473 16.1464 16.8536C16.0527 16.7598 16 16.6326 16 16.5V14.5C16 14.3674 16.0527 14.2402 16.1464 14.1464C16.2402 14.0527 16.3674 14 16.5 14H20.5C20.6326 14 20.7598 14.0527 20.8536 14.1464C20.9473 14.2402 21 14.3674 21 14.5V16.5C21 16.6326 20.9473 16.7598 20.8536 16.8536C20.7598 16.9473 20.6326 17 20.5 17Z"
                      fill="#43A6DD" />
                    <path
                      d="M20.5 29H16.5C16.3674 29 16.2402 28.9473 16.1464 28.8536C16.0527 28.7598 16 28.6326 16 28.5V26.5C16 26.3674 16.0527 26.2402 16.1464 26.1464C16.2402 26.0527 16.3674 26 16.5 26H20.5C20.6326 26 20.7598 26.0527 20.8536 26.1464C20.9473 26.2402 21 26.3674 21 26.5V28.5C21 28.6326 20.9473 28.7598 20.8536 28.8536C20.7598 28.9473 20.6326 29 20.5 29Z"
                      fill="#43A6DD" />
                    <path
                      d="M20.5 35H16.5C16.3674 35 16.2402 34.9473 16.1464 34.8536C16.0527 34.7598 16 34.6326 16 34.5V32.5C16 32.3674 16.0527 32.2402 16.1464 32.1464C16.2402 32.0527 16.3674 32 16.5 32H20.5C20.6326 32 20.7598 32.0527 20.8536 32.1464C20.9473 32.2402 21 32.3674 21 32.5V34.5C21 34.6326 20.9473 34.7598 20.8536 34.8536C20.7598 34.9473 20.6326 35 20.5 35Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 23H27.5C27.3674 23 27.2402 22.9473 27.1464 22.8536C27.0527 22.7598 27 22.6326 27 22.5V20.5C27 20.3674 27.0527 20.2402 27.1464 20.1464C27.2402 20.0527 27.3674 20 27.5 20H31.5C31.6326 20 31.7598 20.0527 31.8536 20.1464C31.9473 20.2402 32 20.3674 32 20.5V22.5C32 22.6326 31.9473 22.7598 31.8536 22.8536C31.7598 22.9473 31.6326 23 31.5 23Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 17H27.5C27.3674 17 27.2402 16.9473 27.1464 16.8536C27.0527 16.7598 27 16.6326 27 16.5V14.5C27 14.3674 27.0527 14.2402 27.1464 14.1464C27.2402 14.0527 27.3674 14 27.5 14H31.5C31.6326 14 31.7598 14.0527 31.8536 14.1464C31.9473 14.2402 32 14.3674 32 14.5V16.5C32 16.6326 31.9473 16.7598 31.8536 16.8536C31.7598 16.9473 31.6326 17 31.5 17Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 29H27.5C27.3674 29 27.2402 28.9473 27.1464 28.8536C27.0527 28.7598 27 28.6326 27 28.5V26.5C27 26.3674 27.0527 26.2402 27.1464 26.1464C27.2402 26.0527 27.3674 26 27.5 26H31.5C31.6326 26 31.7598 26.0527 31.8536 26.1464C31.9473 26.2402 32 26.3674 32 26.5V28.5C32 28.6326 31.9473 28.7598 31.8536 28.8536C31.7598 28.9473 31.6326 29 31.5 29Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 35H27.5C27.3674 35 27.2402 34.9473 27.1464 34.8536C27.0527 34.7598 27 34.6326 27 34.5V32.5C27 32.3674 27.0527 32.2402 27.1464 32.1464C27.2402 32.0527 27.3674 32 27.5 32H31.5C31.6326 32 31.7598 32.0527 31.8536 32.1464C31.9473 32.2402 32 32.3674 32 32.5V34.5C32 34.6326 31.9473 34.7598 31.8536 34.8536C31.7598 34.9473 31.6326 35 31.5 35Z"
                      fill="#43A6DD" />
                    <path
                      d="M26 40H22C21.7348 40 21.4804 40.1054 21.2929 40.2929C21.1054 40.4804 21 40.7348 21 41V47H27V41C27 40.7348 26.8946 40.4804 26.7071 40.2929C26.5196 40.1054 26.2652 40 26 40Z"
                      fill="#363636" />
                  </svg> -->
                            <v-list-item-content class=" pt-0">
                                <v-list-item-title class="text-start"
                                    style="color: #000000;font-size: 20px;font-weight:559;line-height:16px;letter-spacing:-1px">
                                    Profit & Loss </v-list-item-title>

                                <v-list-item-subtitle class="text-start pt-1"
                                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Profit
                                    and Loss of your trades.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-spacer></v-spacer>

                    <v-list class=" pt-0" style="background-color: transparent;">
                        <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
                            <!-- <img alt="" class="shrink" :src="require('@/assets/zebu-stocks/savetaxfund.svg')" width="16px" -->
                            <!-- height="16px" /> -->




                        </v-list-item>
                        <!-- <v-list-item-content class="pa-0 pt-1">
                  <v-list-item-title class=" " style="color: #43A833;font-size: 12px;font-weight:559;line-height:16px;">2
                    recommended</v-list-item-title>
                </v-list-item-content> -->
                    </v-list>


                </v-toolbar>
                <v-divider class=" mx-2"></v-divider>
                <v-card color="#eeeeee" outlined class="mx-2 mt-4 d-md-none elevation-0 rounded-lg pa-6">
                    <div class="text-center pa-4">
                        <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                            Realised P&L</p>
                        <p v-if="disrealised_pnl && disrealised_pnl < 0" class="headline ctnbtltext-center"
                            style="line-height:28px; color: #E12626; ">
                            {{ (disrealised_pnl).toFixed(2) }}
                        </p>
                        <p v-else-if="disrealised_pnl && disrealised_pnl > 0" class="headline ctnbtl text-center"
                            style="line-height:28px; color: #34A853; ">
                            {{ (disrealised_pnl).toFixed(2) }}
                        </p>
                        <p v-else class="headline ctnbtl text-center" style="line-height:28px; color: #505359; ">
                            0
                        </p>
                        <!-- {{ disrealised_pnl }} -->
                    </div>
                    <v-card outlined class="elevation-0 rounded-lg pa-4 pb-0">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-row no-gutters>
                                    <v-col cols="5">
                                        <p class="caption"
                                            style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                            Unrealised P&L</p>
                                    </v-col>
                                    <v-col cols="7">
                                        <p v-if="disunrealised_pnl < 0 && disunrealised_pnl"
                                            class="ctnbtl body-1 text-right" style="line-height:28px; color: #E12626; ">
                                            {{ (disunrealised_pnl).toFixed(2) }}

                                        </p>
                                        <p v-else-if="disunrealised_pnl > 0 && disunrealised_pnl"
                                            class="ctnbtl body-1 text-right" style="line-height:28px; color: #34A853; ">
                                            {{ (disunrealised_pnl).toFixed(2) }}

                                        </p>
                                        <p v-else class="ctnbtl body-1 text-right"
                                            style="line-height:28px; color: #505359; ">
                                            0
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row no-gutters>
                                    <v-col cols="5">
                                        <p class="caption"
                                            style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                            Charges & taxes</p>
                                    </v-col>
                                    <v-col cols="7">




                                        <span v-if="segloader" class="d-flex">
                                            <v-progress-linear class="ml-auto" style="width: 30px" indeterminate
                                                color="blue"></v-progress-linear>
                                        </span>
                                        <p v-else-if="segval < 0 && segval" class="ctnbtl body-1 text-right"
                                            style="line-height:28px; color: #E12626; ">


                                            {{ (segval).toFixed(2) }}

                                        </p>
                                        <p v-else-if="segval > 0 && segval" class="ctnbtl body-1 text-right"
                                            style="line-height:28px; color: #34A853; ">
                                            {{ (segval).toFixed(2) }}

                                        </p>
                                        <p v-else class="ctnbtl body-1 text-right"
                                            style="line-height:28px; color: #505359; ">
                                            0
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheet" inset>

                        <v-sheet class="text-center" height="200px">
                            <!-- <v-btn class="mt-n6" icon text color="error" @click="sheet = !sheet">
                                <v-icon size="40">mdi mdi-close-circle</v-icon>
                            </v-btn> -->
                            <!-- {{ radios }}/ -->
                            <p class="mb-0 pt-3 title font-weight-medium">Summary P&L</p>
                            <v-card class="elevation-0 ma-8 mt-0">
                                <v-radio-group class="mt-0" v-model="radios" mandatory>
                                    <v-radio color="black" label="Download Pdf" value="Download Pdf"></v-radio>
                                    <v-radio color="black" label="Download Excel" value="Download Excel"></v-radio>
                                </v-radio-group>
                                <v-btn @click="downloadsum(radios)" class="white--text elevation-0" color="black" block
                                    rounded flat solo> {{
                                        radios }}</v-btn>
                            </v-card>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheet1" inset>

                        <v-sheet class="text-center" height="200px">
                            <!-- <v-btn class="mt-n6" icon text color="error" @click="sheet1 = !sheet1">
                                <v-icon size="40">mdi mdi-close-circle</v-icon>
                            </v-btn> -->
                            <!-- {{ radios }}/ -->
                            <p class="mb-0 pt-3 title font-weight-medium">Detailed P&L</p>

                            <v-card class="elevation-0 ma-8 mt-0">
                                <v-radio-group class="mt-0" v-model="radios1" mandatory>
                                    <v-radio color="black" label="Download Pdf" value="Download Pdf"></v-radio>
                                    <v-radio color="black" label="Download Excel" value="Download Excel"></v-radio>
                                </v-radio-group>
                                <v-btn @click="downloaddet(radios1)" class="white--text elevation-0" color="black" block
                                    rounded flat solo> {{
                                        radios1 }}</v-btn>
                            </v-card>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
                <v-divider class="mt-4 mb-2 "></v-divider>
                <div class="d-flex row">
                    <p v-if="summary" class="text-start mt-3 mb-3  ml-2 px-4 "
                        style="color: #696969;font-size: 12px;font-weight:500;line-height:16px;">
                        No of symbols
                        <span class=" pa-1 rounded-lg" style=" color: #000000;">({{ dessertsoriginal.length }})</span>
                    </p>
                    <p v-if="!summary" class="text-start mt-3 mb-3  ml-2 px-4 "
                        style="color: #696969;font-size: 12px;font-weight:500;line-height:16px;">
                        No of symbols
                        <span class=" pa-1 rounded-lg" style=" color: #000000;">({{ desserts23.length }})</span>
                    </p>
                    <v-btn v-if="summary" @click="sheet = true" class="ml-auto mr-6 " flat solo icon> <v-icon>mdi
                            mdi-download</v-icon></v-btn>
                    <v-btn v-if="!summary" @click="sheet1 = true" class="ml-auto mr-6 " flat solo icon> <v-icon>mdi
                            mdi-download</v-icon></v-btn>
                </div>
                <v-divider class="mb-2 mt-2"></v-divider>
                <v-row class="px-5 pa-0 mt-3">

                    <v-col class=" pa-0 px-2">
                        <!-- <v-text-field v-if="summary" style="width:100%;" class="tophundraedmutual  " height="36px"
                            background-color="#F1F3F8" label="Search" v-model="search" solo rounded flat dense>
                            <template v-slot:prepend-inner>
                                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px"
                                    height="18px" />
                            </template>
                        </v-text-field>
                        <v-text-field v-if="!summary" style="width:100%;" class="tophundraedmutual  " height="36px"
                            background-color="#F1F3F8" label="Search" v-model="searchde" solo rounded flat dense>
                            <template v-slot:prepend-inner>
                                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px"
                                    height="18px" />
                            </template>
                        </v-text-field> -->
                        <v-menu class="" v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                            offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field style="max-width: 88%;" class=" " solo rounded flat v-model="fromshow"
                                    label="From date" dense color="black" background-color="#F1F3F8"
                                    prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker color="black" v-model="fromdate"></v-date-picker>
                            <v-date-picker color="black" v-model="todate" @change="gettradedata()"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-select :items="filterr" :disabled="segloader" @change="filterseg(filterdata_unpl)" dense
                            v-model="filterdata_unpl" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                            hide-details class="mr-2 mt-n3 fildrop" style="max-width: 100%; font-size: 21px">
                        </v-select>
                        <!-- <v-select v-if="!summary" :items="filterr" @change="filterseg1(filterdata_unpl1)" dense
                            v-model="filterdata_unpl1" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                            hide-details class="mr-2 mt-n3 fildrop" style="max-width: 100%; font-size: 21px">
                        </v-select> -->
                    </v-col>
                </v-row>

                <v-row class="pt-0 mt-n5">
                    <v-col lg="4" sm="4" md="12" cols="12">
                        <!-- <v-tabs v-model="tab" background-color="transparent" color="basil" grow> -->
                        <!-- <v-tab>
                               
                            </v-tab> -->
                        <!-- <v-tab @click="detailed()">
                                Tax P&L
                            </v-tab> -->
                        <!-- </v-tabs> -->

                    </v-col>

                </v-row>
                <!-- {{ loader }} -->
                <v-dialog fullscreen transition="dialog-bottom-transition" v-model="dialogmobile">
                    <v-sheet class="text-center" style="height: 100vh">
                        <v-list-item-content class=" pt-5 pl-5">
                            <v-list-item-title class="text-start"
                                style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">
                                Detailed P&L</v-list-item-title>

                            <v-list-item-subtitle class="text-start pt-1"
                                style="color: #666666;font-size: 11px;font-weight:469;line-height:14px"> {{ scriptname }}
                            </v-list-item-subtitle>
                            <v-btn absolute right top icon color="black" @click="dialogmobile = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-list-item-content>
                        <v-divider></v-divider>

                        <v-card color="#eeeeee" outlined class="d-md-none elevation-0 rounded-lg pa-6 mt-3 px-3"
                            height="90%" style="overflow: scroll">
                            <div class="text-center pa-4">
                                <p class="caption"
                                    style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                    Total</p>
                                <p v-if="finaltotal < 0" class="ctnbtl headline mb-0"
                                    style="line-height:28px; color: red; ">
                                    {{ (finaltotal).toFixed(2) }}</p>
                                <p v-if="finaltotal > 0" class="ctnbtl headline mb-0"
                                    style="line-height:28px; color: green; ">
                                    {{ finaltotal }}</p>
                                <p v-if="finaltotal == 0" class="ctnbtl headline mb-0"
                                    style="line-height:28px; color: rgb(0, 0, 0); ">
                                    {{ finaltotal }}</p>
                                <p v-if="finaltotal == ''" class="ctnbtl headline mb-0" style="line-height:28px;  ">
                                    -</p>
                            </div>
                            <v-card v-for="item in detailedpoparray.data " :key="item.index"
                                style="border-top: 5px #F1F3F8 solid;" class="elevation-0  px-4" width="100%">
                                <span class="d-flex row mt-3 mb-3 px-4">
                                    <p class="  pb-0 mb-0" style="color: #000000;font-size: 15px;font-weight:459;">{{
                                        item.TRADE_DATE }}
                                    </p>
                                    <p v-if="item.NETAMT > 0" class="mb-0 ml-auto body-2 font-weight-regular"
                                        style="color: green;">
                                        {{ item.NETAMT }}</p>
                                    <p v-if="item.NETAMT < 0" class="mb-0 ml-auto body-2 font-weight-regular"
                                        style="color: red;">{{
                                            item.NETAMT }}</p>
                                    <p v-if="item.NETAMT == 0" class="mb-0 ml-auto body-2 font-weight-regular"
                                        style="color: #000000;">
                                        {{ item.NETAMT }}</p>
                                </span>

                                <v-divider class="mb-2"></v-divider>
                                <v-row class="px-2">
                                    <v-col>
                                        <p class="mb-1 body-2 font-weight-regular text-start" style="color: #666666;">Net
                                            Quantity:
                                            <span class="mb-0 body-2 font-weight-regular text-start"
                                                style="color: #000000;">{{
                                                    item.NETQTY
                                                }}</span>
                                        </p>
                                        <p class="mb-1 body-2 font-weight-regular text-start" style="color: #666666;">Buy
                                            Quantity:
                                            <span class="mb-0 body-2 font-weight-regular text-start"
                                                style="color: #000000;">{{
                                                    item.BQTY
                                                }}</span>
                                        </p>
                                        <p class="mb-3 body-2 font-weight-regular text-start" style="color: #666666;">Buy
                                            Rate :
                                            <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                item.BRATE }}</span>
                                        </p>
                                    </v-col>
                                    <v-col class="text-end">
                                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net
                                            Rate: <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                (item.NRATE).toFixed(2) }}
                                            </span>
                                        </p>
                                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sale
                                            Quantity: <span class="mb-0 body-2 font-weight-regular"
                                                style="color: #000000;">{{ item.SQTY }}
                                            </span>
                                        </p>
                                        <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sale Rate :
                                            <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                (item.SRATE).toFixed(2) }}
                                            </span>
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-card v-if="loaderpopup"
                                style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                                class="elevation-0  px-4" width="100%">
                                <v-row class="px-2">
                                    <v-col class="mx-auto text-center my-4">
                                        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-card v-if="detailedpoparray.data == 0 && loaderpopup == false"
                                style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                                class="elevation-0  px-4" width="100%">
                                <v-row class="px-2">
                                    <v-col class="mx-auto text-center my-4">
                                        <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                                        <p class="font-weight-bold black--text">No data available</p>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-card>
                    </v-sheet>
                </v-dialog>
                <!-- <v-tabs-items v-model="tab"> -->
                <!-- <v-tab-item> -->
                <v-card v-for="item in filteredDesserts " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                    class="elevation-0  px-4 pt-2" width="100%">

                    <span @click="detailedpopmobile(item)" class="font-weight-bold">

                        {{ item.SCRIP_SYMBOL }}<v-chip v-if="item.open_QUANTITY > 0" color="blue" class="ml-2" x-small
                            outlined>{{
                                item.open_QUANTITY }}</v-chip><v-icon color="#0037B7">mdi
                            mdi-menu-right</v-icon>

                        <!-- {{ item.COMPANY_CODE }} -->
                        <!-- <span class="caption font-weight-medium">({{
desserts2[item.SCRIP_SYMBOL].length }})</span> -->
                    </span>
                    <v-divider class="mb-2 mt-2"></v-divider>
                    <v-row class="px-2">
                        <v-col>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net Amount
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.BUY_QUANTITY
                                    }}</span>
                            </p>
                            <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.BUY_RATE
                                    }}</span>
                            </p>
                        </v-col>
                        <v-col class="text-end">
                            <p v-if="item.NET_AMOUNT > 0" class="mb-0 body-2 font-weight-regular" style="color: green;">{{
                                item.NET_AMOUNT }}</p>
                            <p v-if="item.NET_AMOUNT < 0" class="mb-0 body-2 font-weight-regular" style="color: red;">{{
                                item.NET_AMOUNT }}</p>
                            <p v-if="item.NET_AMOUNT == 0" class="mb-0 body-2 font-weight-regular" style="color: #000000;">
                                {{ item.NET_AMOUNT }}</p>

                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Quantity: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.SALE_QUANTITY }}

                                </span>
                            </p>
                            <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.SALE_RATE
                                    }}</span>
                            </p>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card v-if="filteredDesserts.length == 0 && loader == false"
                    style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;" class="elevation-0  px-4"
                    width="100%">
                    <v-row class="px-2">
                        <v-col class="mx-auto text-center my-4">
                            <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                            <p class="font-weight-bold black--text">No data available</p>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                    class="elevation-0  px-4" width="100%">
                    <v-row class="px-2 ">
                        <v-col class="mx-auto text-center my-10">
                            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-card>
                <!-- </v-tab-item> -->
                <!-- <v-tab-item>
                        <v-card v-for="item in filteredDesserts1 " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                            class="elevation-0  px-4" width="100%">

                            <p v-if="item.COMPANY_CODE == 'NSE_FNO'" class="text-start pa-2 pb-0 mb-0"
                                style="color: #000000;font-size: 15px;font-weight:459;">{{ item.SCRIP_SYMBOL }}
                            </p>
                            <p v-else-if="item.COMPANY_CODE == 'BSE_CASH' || item.COMPANY_CODE == 'NSE_CASH'"
                                class="text-start pb-0 pa-2 mb-0" style="color: #000000;font-size: 15px;font-weight:459;">
                                {{ item.SCRIP_SYMBOL.split(' ').slice(1).join(' ') }}
                            </p>
                            <span class="font-weight-regular pt-0 caption text-start pa-2 mb-0"> {{ item.TRADE_DATE
                            }}</span>
                            <v-divider class="mb-2"></v-divider>

                            <v-row class="px-2">
                                <v-col>
                                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net Amount:
                                    </p>
                                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.BQTY
                                            }}</span>
                                    </p>
                                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                (item.BRATE.toFixed(2)) }}</span>
                                    </p>


                                </v-col>
                                <v-col class="text-end">
                                    <p v-if="item.NETAMT > 0" class="mb-0 body-2 font-weight-regular" style="color: green;">
                                        {{ item.NETAMT }}</p>
                                    <p v-if="item.NETAMT < 0" class="mb-0 body-2 font-weight-regular" style="color: red;">{{
                                        item.NETAMT }}</p>
                                    <p v-if="item.NETAMT == 0" class="mb-0 body-2 font-weight-regular"
                                        style="color: #000000;">
                                        {{ item.NETAMT }}</p>

                                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Qty: <span
                                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.SQTY }}

                                        </span>
                                    </p>
                                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                (item.SRATE).toFixed(2) }}
                                        </span>
                                    </p>


                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card v-if="filteredDesserts1.length == 0 && loader == false"
                            style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                            class="elevation-0  px-4" width="100%">
                            <v-row class="px-2">
                                <v-col class="mx-auto text-center my-4">
                                    <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                                    <p class="font-weight-bold black--text">No data available</p>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                            class="elevation-0  px-4" width="100%">
                            <v-row class="px-2">
                                <v-col class="mx-auto text-center my-4">
                                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items> -->
            </div>
        </v-container>
    </v-app>
</template>
  
<script>
// import CalendarProfitlose from '@/views/heatmap calendars/CalendarProfitlose.vue';
import { apiurl } from '../../../Api.js'
import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable"
import Chartfor_Dividend from '../../charts/Chartfor_Dividend.vue'


export default {
    components: { Chartfor_Dividend },

    data() {
        return {
            tooltipdata: [
                {
                    name: 'Frozen Yogurt',
                    calories: 159,
                    fat: 6.0,
                    carbs: 24,
                    protein: 4.0,
                    iron: 1,
                },
                {
                    name: 'Ice cream sandwich',
                    calories: 237,
                    fat: 9.0,
                    carbs: 37,
                    protein: 4.3,
                    iron: 1,
                },
                {
                    name: 'Eclair',
                    calories: 262,
                    fat: 16.0,
                    carbs: 23,
                    protein: 6.0,
                    iron: 7,
                },
                {
                    name: 'Cupcake',
                    calories: 305,
                    fat: 3.7,
                    carbs: 67,
                    protein: 4.3,
                    iron: 8,
                },
                {
                    name: 'Gingerbread',
                    calories: 356,
                    fat: 16.0,
                    carbs: 49,
                    protein: 3.9,
                    iron: 16,
                },
                {
                    name: 'Jelly bean',
                    calories: 375,
                    fat: 0.0,
                    carbs: 94,
                    protein: 0.0,
                    iron: 0,
                },
                {
                    name: 'Lollipop',
                    calories: 392,
                    fat: 0.2,
                    carbs: 98,
                    protein: 0,
                    iron: 2,
                },
                {
                    name: 'Honeycomb',
                    calories: 408,
                    fat: 3.2,
                    carbs: 87,
                    protein: 6.5,
                    iron: 45,
                },
                {
                    name: 'Donut',
                    calories: 452,
                    fat: 25.0,
                    carbs: 51,
                    protein: 4.9,
                    iron: 22,
                },
                {
                    name: 'KitKat',
                    calories: 518,
                    fat: 26.0,
                    carbs: 65,
                    protein: 7,
                    iron: 6,
                },
            ],
            headersfortooltip: [
                {
                    text: 'Company',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: 'CMP', value: 'calories' },
                { text: '1year', value: 'fat' },
                { text: '2years', value: 'carbs' },
                { text: '3years', value: 'protein' },
                { text: '4years', value: 'iron' },
            ],
            dialogmobile: false,
            segval: 0,
            summary: true,
            radios: null,
            radios1: null,
            tabgraph:null,
            inject: {
                theme: {
                    default: { isDark: false },
                },
            },
            itemss: [
            ],
            search: '',
            searchde: '',
            expanded: [],
            more: true,
            less: false, more1: true,
            less1: false,
            itemperpage: 8,
            msg: '',

            alert: false,

            sheet: false,
            sheet1: false,
            itemperpage1: 8,
            loader: false,
            headerval: [],
            dialog: false,
            detailedpoparray: [],

            yearindex: "",
            search1: '',

            itemperpage1inner: 0,
            // tab: null,
            // yearfromdown: ["2018", "2019", "2020", "2021", "2022", "2023"],
            desserts: [],
            dessertsoriginal: [],
            scriptname: '',
            dessertspop: [],
            dessertspopexpense: [],
            graph: false,
            calendercard: [

                {
                    'month': 'January',
                    'val': '12,000',
                    'strip': 'from 2 scrips',
                    'des': 'Need 5,000 for expected dividend.',
                    'desclr': 'false'
                },
                {
                    'month': 'february',
                    'strip': 'from 2 scrips',
                    'val': '34,000',
                    'des': '11.3% of expected dividend income',
                    'desclr': 'true'
                },
                {
                    'month': 'March',
                    'val': '33,000',
                    'strip': 'from 2 scrips',
                    'des': '11.3% of expected dividend income',
                    'desclr': 'true'
                },
                {
                    'month': 'April',
                    'strip': 'from 2 scrips',
                    'val': '22,000',
                    'des': '11.3% of expected dividend income',
                    'desclr': 'true'
                },
                {
                    'month': 'May',
                    'val': '45,000',
                    'strip': 'from 2 scrips',
                    'des': 'Need 5,000 for expected dividend.',
                    'desclr': 'false'
                },
                {
                    'strip': 'from 2 scrips',
                    'month': 'June',
                    'val': '95,000',
                    'des': '11.3% of expected dividend income',
                    'desclr': 'true'
                },
                {
                    'month': 'July',
                    'strip': 'from 2 scrips',
                    'val': '85,000',
                    'des': '11.3% of expected dividend income',
                    'desclr': 'true'
                },
                {
                    'month': 'August',
                    'val': '55,000',
                    'strip': 'from 2 scrips',
                    'des': 'Need 5,000 for expected dividend.',
                    'desclr': 'false'
                },
                {
                    'month': 'September',
                    'strip': 'from 2 scrips',
                    'val': '45,000',
                    'des': 'Need 5,000 for expected dividend.',
                    'desclr': 'false'
                },
                {
                    'month': 'October',
                    'val': '15,000',
                    'strip': 'from 2 scrips',
                    'des': '11.3% of expected dividend income',
                    'desclr': 'true'
                },
                {
                    'strip': 'from 2 scrips',
                    'month': 'November',
                    'val': '35,000',
                    'des': '11.3% of expected dividend income',
                    'desclr': 'true'
                },
                {
                    'strip': 'from 2 scrips',
                    'month': 'December',
                    'val': '35,000',
                    'des': '11.3% of expected dividend income',
                    'desclr': 'true'
                },
            ],
            desserts2: [],
            sortBy: 'SCRIP_NAME',
            offset: '',
            segfrom: '',
            dates: [""],
            filterr: [
                "All", "Equities", "Future & Options", "Commodities", "Currencies"
            ],
            filterdata_unpl: 'All',
            filterdata_unpl1: 'All',
            itemsdwl: [
                {
                    title: 'Download Excel',
                },
                {
                    title: 'Download Pdf',
                },
            ],
            show: false,
            Overviewheaders2: [
                { text: "Trade Date", value: "TRADE_DATE", width: "10%", class: "headerfont" },

                // { text: "Symbol", value: "SCRIP_NAME", width: "20%", class: "headerfont" },


                { text: 'Buy Qty', value: 'BQTY', align: "end", class: "headerfont", width: "10%" },
                { text: 'Buy Rate', value: 'BRATE', align: "end", class: "headerfont", width: "10%" },
                // { text: 'Buy Amt', value: 'BAMT', align: "end" },
                { text: 'Sell Qty', value: 'SQTY', align: "end", class: "headerfont", width: "10%" },
                { text: 'Sell Rate', value: 'SRATE', align: "end", class: "headerfont", width: "10%" },
                // { text: 'Sale Amt', value: 'SAMT', align: "end" },
                { text: 'Net Qty', value: 'NETQTY', align: "end", class: "headerfont", width: "10%" },
                { text: 'Net Rate', value: 'NRATE', align: "end", class: "headerfont", width: "10%" },
                // { text: 'NET Amt', value: 'NETAMT', align: "end" },
                // { text: 'Closing Price', value: 'CLSPR', align: "end", class: "headerfont" },
                { text: 'P & L', value: 'NETAMT', align: "end", class: "headerfont", width: "10%" },


            ],
            finaltotal: 0,

            Overviewheaders: [
                { text: "Symbol", value: "SCRIP_SYMBOL", class: "headerfont", },
                // { text: "com", value: "Sort_No", class: "headerfont", },
                // { text: "comcode", value: "company_code", class: "headerfont", },
                // { text: '', value: 'data-table-expand', class: "headerfont" },
                // { text: "Trading Quantity", value: "TRADING_QUANTITY" , align: 'end'},
                // { text: "Trading Amt", value: "TRADING_AMOUNT" , align: 'end'},
                { text: "Buy Qty", value: "BUY_QUANTITY", align: "end", class: "headerfont" },
                { text: "Buy Rate", value: "BUY_RATE", align: "end", class: "headerfont" },
                // { text: "BAmt", value: "BUY_AMOUNT", align: "end", class: "headerfont" },
                { text: "Sell Qty", value: "SALE_QUANTITY", align: "end", class: "headerfont" },
                { text: "Sell Rate", value: "SALE_RATE", align: "end", class: "headerfont" },
                // { text: "SAmt", value: "SALE_AMOUNT", align: "end", class: "headerfont" },
                { text: "Net Qty", value: "NET_QUANTITY", align: "end", class: "headerfont" },
                { text: "Net Rate", value: "NET_RATE", align: "end", class: "headerfont" },
                { text: "Close price", value: "CLOSING_PRICE", align: "end", class: "headerfont" },
                // { text: "NAmt", value: "NET_AMOUNT", align: "end", class: "headerfont" },
                // { text: "Closing Price", value: "CLOSING_PRICE", align: "end", class: "headerfont" },
                { text: "P & L", value: "NOT_PROFIT", align: "end", class: "headerfont" },
            ],
            tab: null,
            items: ['Summary', 'Detailed',],
            desserts23: [],
            sortKey: 'Sort_No',
            detpop: [],
            actid: '',
            susertoken: '',
            loaderpopup: false,
            keyyy: '',
            downsum: true,
            disrealised_pnl: '',
            disunrealised_pnl: '',
            year: '',
            dessertstaxdis: [],
            menu2: false,
            menu23: false,
            todate: '',
            fromdate: '',
            segloader: false,
            fromshow: '',
            sortOrder: 1, // 1 for ascending, -1 for descending

        };
    },
    // components: { CalendarProfitlose },
    computed: {
        sortedItems() {
            return this.dessertsoriginal.slice().sort((a, b) => {
                const modifier = this.sortOrder;
                return modifier * (a[this.sortKey] > b[this.sortKey] ? 1 : -1);
            });
        },
        dateRangeText() {
            return this.dates.join(" ~ ");
        },
        filteredDesserts() {
            const searchTerm = this.search.toLowerCase();
            return this.sortedItems.filter(item => item.SCRIP_SYMBOL.toLowerCase().includes(searchTerm));
        },
        filteredDesserts1() {
            const searchTerm = this.searchde.toLowerCase();
            return this.desserts23.filter(item => item.SCRIP_SYMBOL.toLowerCase().includes(searchTerm));
        },
    },
    created() {
        // Perform default sort on component creation
        this.performDefaultSort();
    },
    methods: {
        performDefaultSort() {
            this.sortItems();
        },
        sortItems() {
            this.dessertsoriginal.sort((a, b) => {
                const modifier = this.sortOrder;
                return modifier * (a[this.sortKey] > b[this.sortKey] ? 1 : -1);
            });
        },

        detailedpop(item) {
            // console.log(item);
            // alert("dfd")
            this.search = ''
            let detailedarr = item
            this.loaderpopup = true
            this.detailedpoparray = {}

            this.finaltotal = 0
            this.detailedpoparray['syname'] = item.SCRIP_SYMBOL
            // console.log(this.detailedpoparray[0]);
            this.scriptname = item.SCRIP_SYMBOL

            this.dialog = true
            const axios = require('axios');
            let data = JSON.stringify({
                "cc": this.actid,
                "from": this.segfrom,
                "to": this.toshow,
                "script": detailedarr.SCRIP_SYMBOL,
                "cocd": detailedarr.company_code
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                // url: 'http://192.168.5.198:5050/getpnlscriptdetail',
                url: `${apiurl}/getpnlscriptdetail`,

                headers: {
                    'Content-Type': 'application/json',
                    'clientid': this.actid,
                    'Authorization': this.susertoken
                },
                data: data
            };
            let axiosthis = this
            axios.request(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    let popval = response.data
                    // console.log(popval.msg == 'No Data Available');
                    // console.log(popval);
                    if (popval.msg != 'No Data Available') {
                        if (axiosthis.detailedpoparray['syname'] == item.SCRIP_SYMBOL) {
                            axiosthis.loaderpopup = false
                            axiosthis.detailedpoparray['data'] = popval

                            for (let i = 0; i < axiosthis.detailedpoparray.data.length; i++) {
                                axiosthis.finaltotal += axiosthis.detailedpoparray.data[i].NETAMT

                            }
                        }

                    } else {
                        axiosthis.loaderpopup = false
                        // axiosthis.detailedpoparray = []
                        // alert("khk")
                        // axiosthis.loaderpopup = false
                        // axiosthis.dialog = false

                        // axiosthis.alert = true
                        // axiosthis.msg = "No data Found"

                        // setTimeout(() => {
                        // axiosthis.alert = false
                        // axiosthis.msg = ""
                        // }, 3000);
                    }


                    // console.log(axiosthis.detailedpoparray);
                })
                .catch((error) => {
                    console.log(error);
                });

            // console.log(item);

            axiosthis.itemperpage1inner = axiosthis.detailedpoparray.data.length

        },
        detailedpopmobile(item) {
            this.search = ''
            let detailedarr = item
            this.detailedpoparray = {}

            this.finaltotal = 0
            this.detailedpoparray['syname'] = item.SCRIP_SYMBOL
            this.dialogmobile = true
            this.loaderpopup = true

            // this.detailedpoparray = detailedarr.detailed

            // this.finaltotal = 0
            // for (let i = 0; i < this.detailedpoparray.length; i++) {
            //     this.finaltotal += this.detailedpoparray[i].NETAMT

            // }
            // console.log(this.detailedpoparray[0]);
            // console.log(item);
            this.scriptname = item.SCRIP_SYMBOL

            // this.itemperpage1inner = this.detailedpoparray.length
            const axios = require('axios');
            let data = JSON.stringify({
                "cc": this.actid,
                "from": this.segfrom,
                "to": this.toshow,
                "script": detailedarr.SCRIP_SYMBOL,
                "cocd": detailedarr.company_code
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                // url: 'http://192.168.5.198:5050/getpnlscriptdetail',
                url: `${apiurl}/getpnlscriptdetail`,

                headers: {
                    'Content-Type': 'application/json',
                    'clientid': this.actid,
                    'Authorization': this.susertoken
                },
                data: data
            };
            let axiosthis = this
            axios.request(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    let popval = response.data
                    if (popval.msg != 'No Data Available') {
                        if (axiosthis.detailedpoparray['syname'] == item.SCRIP_SYMBOL) {
                            axiosthis.loaderpopup = false
                            axiosthis.detailedpoparray['data'] = popval

                            for (let i = 0; i < axiosthis.detailedpoparray.data.length; i++) {
                                axiosthis.finaltotal += axiosthis.detailedpoparray.data[i].NETAMT

                            }
                        }

                    } else {

                        axiosthis.loaderpopup = false
                        // axiosthis.detailedpoparray = []
                    }
                    // console.log(axiosthis.detailedpoparray);
                })
                .catch((error) => {
                    console.log(error);
                });

            // console.log(item);

            axiosthis.itemperpage1inner = axiosthis.detailedpoparray.data.length
        },
        filterseg1(filterdata_unpl1) {
            var data = this.detpop
            // const dataval = this.desserts
            // const data2 = this.desserts
            // const data3 = this.desserts
            const seg = filterdata_unpl1
            // // // //console.log("sssss", seg);
            if (seg == 'Equities') {
                this.desserts23 = []
                for (let i = 0; i < data.length; i++) {
                    if (data[i].company_code == 'NSE_CASH' || data[i].company_code == 'BSE_CASH') {
                        this.desserts23.push(data[i])
                    }
                }


                //console.log(this.disunrealised_pnl, 'this.headerval.total_invested');
            } else if (seg == 'Future & Options') {
                this.desserts23 = []
                // const data1 = this.desserts
                for (let i = 0; i < data.length; i++) {
                    if (data[i].company_code == 'NSE_FNO' || data[i].company_code == 'BSE_FNO') {
                        this.desserts23.push(data[i])
                    }
                }
            } else if (seg == 'Commodities') {
                this.desserts23 = []
                // const data1 = this.desserts
                for (let i = 0; i < data.length; i++) {
                    if (data[i].company_code == 'MCX') {
                        this.desserts23.push(data[i])
                    }
                }
            } else if (seg == 'Currencies') {
                this.desserts23 = []
                // const data1 = this.desserts
                for (let i = 0; i < data.length; i++) {
                    if (data[i].company_code == 'CD_NSE') {
                        this.desserts23.push(data[i])
                    }
                }
            } else if (seg == 'All') {
                this.desserts23 = data
            }
        },
        filterseg(filterdata_unpl) {

            var data = this.dessertspop
            // var data1 = this.dessertspopexpense
            var headrea = this.headervalpop.realised_pnl
            var headrea1 = this.headervalpop.unrealised_pnl
            this.segval = 0
            // console.log(headrea, headrea1);
            // expense_amt
            // unrealised_pnl
            // realised_pnl
            // //console.log(data1,headrea);
            // const dataval = this.desserts
            // const data2 = this.desserts
            // const data3 = this.desserts
            const seg = filterdata_unpl
            // alert("summary");
            // Assuming this.segfrom, this.toshow, and this.actid are defined somewhere in your code
            var segval = ''
            // Create the initial JSON object
            if (seg === "Equities") {
                segval = "eq";
            } else if (seg === "Future & Options") {
                segval = "der";
            } else if (seg === "Commodities") {
                segval = "comm";
            } else if (seg === "Currencies") {
                segval = "curr";
            }

            // Now, apiData contains the updated values
            // You can send apiData to your backend
            if (seg != 'All') {
                this.segloader = true

                const axios = require('axios');
                let data1 = JSON.stringify({
                    "from": this.segfrom,
                    "to": this.toshow,
                    "seg": segval,
                    "cc": this.actid

                });

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    // url: 'http://192.168.5.198:5050/getexpenses',
                    url: `${apiurl}/getexpenses`,

                    headers: {
                        'Content-Type': 'application/json',
                        'clientid': this.actid,
                        'Authorization': this.susertoken
                    },
                    data: data1
                };

                axios.request(config)
                    .then((response) => {
                        this.segloader = false

                        // console.log(JSON.stringify(response.data));
                        this.segval = response.data.Total
                        // console.log(this.segval);
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            }

            if (seg == 'Equities') {

                let addval = 0
                let unaddval = 0

                this.dessertsoriginal = []
                this.dessertstaxdis = []
                for (let i = 0; i < data.length; i++) {
                    if (data[i].company_code == 'BSE_CASH' || data[i].company_code == 'NSE_CASH' || data[i].company_code == 'MF_BSE' || data[i].company_code == 'MF_NSE' || data[i].company_code == 'NSE_SLBM' || data[i].company_code == 'NSE_SPT') {
                        this.dessertsoriginal.push(data[i])

                    }

                }

                // for (let e = 0; e < data1.length; e++) {
                //     if (data1[e].company_code == 'BSE_CASH' || data1[e].company_code == 'NSE_CASH' || data1[e].company_code == 'MF_BSE' || data1[e].company_code == 'MF_NSE' || data1[e].company_code == 'NSE_SLBM' || data1[e].company_code == 'NSE_SPT') {
                //         this.desserts.push(data1[e])
                //     }
                // }

                for (let y = 0; y < this.dessertsoriginal.length; y++) {
                    if (this.dessertsoriginal[y].NET_QUANTITY == 0) {

                        addval += this.dessertsoriginal[y].NOT_PROFIT
                    }

                }
                this.disrealised_pnl = addval
                for (let y = 0; y < this.dessertsoriginal.length; y++) {
                    if (this.dessertsoriginal[y].NET_QUANTITY != 0) {

                        unaddval += this.dessertsoriginal[y].NOT_PROFIT
                    }

                }
                this.disunrealised_pnl = unaddval

                // console.log(this.disrealised_pnl, this.disunrealised_pnl);


                // //console.log(this.headerval.realised_pnl, "sisisisi");






                // }
                // for (let i = 0; i < this.desserts23.length; i++) {
                //     if (this.desserts23[i].NET_QUANTITY != 0) {
                //         this.disunrealised_pnl = this.desserts23[i].reduce((accumulator, object) => {
                //             return accumulator + object.BUY_RATE;
                //         }, 0);
                //     }
                // }
                //console.log(this.disunrealised_pnl, this.disrealised_pnl);
                // // // //console.log(this.dessertsoriginal, "this.dessertsoriginal");
            } else if (seg == 'Future & Options') {
                this.dessertsoriginal = []
                let addval = 0
                let unaddval = 0

                // const data1 = this.dessertsoriginal
                for (let i = 0; i < data.length; i++) {
                    if (data[i].company_code == 'NSE_FNO' || data[i].company_code == 'BSE_FNO   ') {
                        this.dessertsoriginal.push(data[i])

                    }
                }
                for (let y = 0; y < this.dessertsoriginal.length; y++) {
                    if (this.dessertsoriginal[y].NET_QUANTITY == 0) {

                        addval += this.dessertsoriginal[y].NOT_PROFIT
                    }

                }
                this.disrealised_pnl = addval
                for (let y = 0; y < this.dessertsoriginal.length; y++) {
                    if (this.dessertsoriginal[y].NET_QUANTITY != 0) {

                        unaddval += this.dessertsoriginal[y].NOT_PROFIT
                    }
                    // else{
                    //     // alert("kl")
                    // }

                }
                this.disunrealised_pnl = unaddval
                // console.log(this.disrealised_pnl, this.disunrealised_pnl);

                // // // //console.log(this.dessertsoriginal, "this.dessertsoriginal");
            } else if (seg == 'Commodities') {
                this.dessertsoriginal = []
                let addval = 0
                let unaddval = 0

                // const data1 = this.dessertsoriginal
                for (let i = 0; i < data.length; i++) {
                    if (data[i].company_code == 'MCX' || data[i].company_code == 'NCDEX' || data[i].company_code == 'NSE_COM' || data[i].company_code == 'BSE_COM') {
                        this.dessertsoriginal.push(data[i])
                    }
                }
                //console.log(this.dessertsoriginal);
                for (let y = 0; y < this.dessertsoriginal.length; y++) {
                    if (this.dessertsoriginal[y].NET_QUANTITY == 0) {

                        addval += this.dessertsoriginal[y].NOT_PROFIT
                    }

                }
                this.disrealised_pnl = addval
                for (let y = 0; y < this.dessertsoriginal.length; y++) {
                    if (this.dessertsoriginal[y].NET_QUANTITY != 0) {

                        unaddval += this.dessertsoriginal[y].NOT_PROFIT
                    }

                }
                this.disunrealised_pnl = unaddval
                // console.log(this.disrealised_pnl, this.disunrealised_pnl);

                // // // //console.log(this.dessertsoriginal, "this.dessertsoriginal");
            } else if (seg == 'Currencies') {
                this.dessertsoriginal = []
                let addval = 0
                let unaddval = 0

                // const data1 = this.dessertsoriginal
                for (let i = 0; i < data.length; i++) {
                    if (data[i].company_code == 'CD_NSE' || data[i].company_code == 'CD_MCX' || data[i].company_code == 'CD_USE' || data[i].company_code == 'CD_BSE') {
                        this.dessertsoriginal.push(data[i])
                    }
                }

                for (let y = 0; y < this.dessertsoriginal.length; y++) {
                    if (this.dessertsoriginal[y].NET_QUANTITY == 0) {

                        addval += this.dessertsoriginal[y].NOT_PROFIT
                    }

                }
                this.disrealised_pnl = addval
                for (let y = 0; y < this.dessertsoriginal.length; y++) {
                    if (this.dessertsoriginal[y].NET_QUANTITY != 0) {

                        unaddval += this.dessertsoriginal[y].NOT_PROFIT
                    }

                }
                this.disunrealised_pnl = unaddval
                // console.log(this.disrealised_pnl, this.disunrealised_pnl);

                // // // //console.log(this.dessertsoriginal, "this.dessertsoriginal");
            } else if (seg == 'All') {
                this.dessertsoriginal = data
                this.disrealised_pnl = headrea
                this.disunrealised_pnl = headrea1
                this.segval = this.headerval.expense_amt
                // console.log(this.disrealised_pnl, this.disunrealised_pnl);
            }
        },
        downloaddet(item) {

            this.sheet1 = false
            this.radios1 = null
            let data = item
            // // // //console.log("dsdsdsdsds", data);

            if (data == 'Download Excel') {
                // const data = this.desserts23;
                // let jsonObj = JSON.parse(data);
                // data.forEach(function (v) { delete v.ACCOUNTCODE }, { delete v.ACCOUNTNAME });
                let dada = this.desserts23;
                // let jsonObj = JSON.parse(data);
                // data.forEach(function (v) { delete v.ACCOUNTCODE }, { delete v.ACCOUNTNAME });
                //                 dada.forEach(function (v) {
                //                     // delete v.SCRIP_SYMBOL

                //                     delete v.MARKET
                // ;
                //                     // delete v.NSE_SCRIPCODE;
                //                     delete v.EXCHANGE
                // ;
                //                     delete v.company_code
                //                     delete v.CLOSING_PRICE
                //                 });
                const exportType = exportFromJSON.types.csv;
                const filename = `${'Detailed_'}${'Profit and Loss_'}${this.year}`
                if (dada) exportFromJSON({ data: dada, filename: filename, exportType: exportType });
            } else if (data == 'Download Pdf') {
                let rows = [];
                let columnHeader = ['Trade date', 'Symbol', 'Buy Qty', 'Buy Rate', 'Sell Qty', 'Sell Rate', 'Net Qty', 'Net Rate', 'P&L'];
                let pdfName = `${'Detailed_Profit and loss_'}${this.yearis}`
                // doc.text(`Ledger_${this.yearis}`, 10, 10);
                // doc.text('Zebu', 10 ,10)
                this.desserts23.forEach(element => {
                    var temp = [
                        element.TRADE_DATE, element.SCRIP_NAME, element.BQTY, element.BRATE, element.SQTY, element.SRATE, element.NETQTY, element.NRATE, element.PRO
                    ];
                    rows.push(temp);
                });
                var doc = new jsPDF();
                autoTable(doc, { head: [columnHeader], body: rows, });
                doc.save(pdfName + '.pdf');
            }

        },
        downloadsum(item) {

            this.sheet = false
            this.radios = null

            let data = item
            // // //console.log("dsdsdsdsds", data);

            if (data == 'Download Excel') {
                // let dada = this.dessertsoriginal;
                var rows = [];
                console.log(this.dessertsoriginal, 'before');
                for (let t = 0; t < this.dessertsoriginal.length; t++) {
                    const element = this.dessertsoriginal[t];
                    rows.push({
                        "Symbol": element.SCRIP_SYMBOL,
                        "Open Qty": element.open_QUANTITY,
                        "Buy Qty": element.BUY_QUANTITY,
                        'Buy Rate': element.BUY_RATE,
                        'Sell Qty': element.SALE_QUANTITY,
                        'Sell Rate': element.SALE_RATE,
                        'Net Qty': element.NET_QUANTITY,
                        'Net Rate': element.NET_RATE,
                        "Close price": element.CLOSING_PRICE,
                        // 'Segment':element.segments,

                    });
                }
                console.log(rows, 'after');

                const exportType = exportFromJSON.types.csv;

                exportFromJSON({
                    data: rows,
                    fileName: `${'P&L'}${this.fromshow}`,
                    exportType: exportType,
                });
            } else if (data == 'Download Pdf') {
                let rows = [];
                let columnHeader = ['Symbol', 'Open Qty', 'Buy Qty', 'Buy Rate', 'Sell Qty', 'Sell Rate', 'Net Qty', 'Net Rate', 'P&L'];
                let pdfName = `${'Pnl'}${this.fromshow}`;
                this.dessertsoriginal.forEach(element => {
                    var temp = [
                        element.SCRIP_SYMBOL, element.open_QUANTITY, element.BUY_QUANTITY, element.BUY_RATE, element.SALE_QUANTITY, element.SALE_RATE, element.NET_QUANTITY, element.NET_RATE, element.NOT_PROFIT
                    ];
                    rows.push(temp);
                });

                // Create a new jsPDF document
                var doc = new jsPDF();

                // Add header and customize text
                doc.setFontSize(18);
                doc.text("Profit & Loss Data", 80, 10);
                doc.setFontSize(12);
                doc.text(`${'Client Code : '}${this.actid}`, 15, 30);
                doc.setFontSize(12);
                doc.text(`${'From and To : '}${this.fromshow}`, 115, 30);

                // Add image from local file


                // Set font size for the table
                doc.setFontSize(12);

                // Add table using autoTable
                autoTable(doc, { head: [columnHeader], body: rows, startY: 40 });

                // Add custom text
                doc.text("Your Custom Text", 14, doc.autoTable.previous.finalY + 10);

                // Save the PDF
                doc.save(pdfName + '.pdf');
            }
        },
        detailed() {
            this.summary = false
        },
        summary1() {
            this.summary = true
        },
        lessbtn() {
            this.more = true
            this.less = false
            this.itemperpage = 8
        },
        morebtn() {
            this.more = false
            this.less = true
            this.itemperpage = this.dessertsoriginal.length
        },
        lessbtn1() {
            this.more1 = true
            this.less1 = false
            this.itemperpage1 = 8
        },
        morebtn1() {
            this.more1 = false
            this.less1 = true
            this.itemperpage1 = this.desserts23.length
        },

    },
    mounted() {
        this.susertoken = localStorage.getItem("usession");
        this.actid = localStorage.getItem("userid");
        // this.loader = true
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        if (mm < 3) {
            var yearmount = yyyy - 1
        } else {
            yearmount = yyyy
        }
        // var todayapi = dd + '/' + mm + '/' + yyyy;
        var fromapi = '01' + '/' + '04' + '/' + yearmount;
        var today1 = dd + '/' + mm + '/' + yyyy;
        this.toshow = today1
        this.fromshow = fromapi
        this.segfrom = fromapi
        this.fromdateapi = fromapi
        this.filterdata_unpl1 = "All"
        this.filterdata_unpl = "All"

        this.fromshow = (this.fromshow) + '_' + 'to' + '_' + (this.toshow)
        // this.toshow = today1
        // this.fromshow = this.fromshow + '-' + this.toshow

    }
};
</script>

<style lang="scss">
.basil {
    background-color: #FFFBE6 !important;
}

.basil--text {
    color: #020202 !important;
}

.v-tab {
    text-transform: none !important;
}

.v-tabs--grow>.v-tabs-bar .v-tab {
    /* flex: 1 0 auto; */
    max-width: none !important;
}
</style>