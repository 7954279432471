import Vue from 'vue'
import VueRouter from 'vue-router'
// import SignUp from '../views/sign-in&up/SignUp.vue'
// import LandingView from '../views/sign-in&up/LandingView.vue'
// import WelcomeOne from '../views/sign-in&up/WelcomeOne.vue'
import LayOut from '../components/Layout/LayOut.vue'
// import DashBoard from '../views/pages/DashBoard.vue'
import PosiTions from '../views/pages/portfolio_screens/PosiTions.vue'
import HolDings from '../views/pages/portfolio_screens/HolDings.vue'
import LedgerPage from '../views/pages/portfolio_screens/LedgerPage.vue'
// import PosiTions from '../views/pages/portfolio_screens/PosiTions.vue'
// import CorporateActions from '../views/pages/portfolio_screens/CorporateActions.vue'
import Trade_Verify from '../views/pages/portfolio_screens/Trade_Verify.vue'
import Profit_Loss from '../views/pages/fees_taxes/Profit_Loss.vue'
import CalenderPage from '../views/pages/fees_taxes/CalenderPage.vue'
import CalenderPage2 from '../views/pages/fees_taxes/CalenderPage2.vue'
import Daily_pnl from '../views/pages/fees_taxes/Daily_pnl.vue'
import CorporateAction from '../views/pages/fees_taxes/CorporateAction.vue'
import DiviDends_Page from '../views/pages/fees_taxes/DiviDends_page.vue'
import ConractNote from '../views/pages/fees_taxes/ConractNote.vue'
import DashBoard from '../views/pages/DashBoard.vue'
import DashBoard2 from '../views/pages/DashBoard2.vue'
// import Tax_Profit_Loss from '../views/pages/fees_taxes/Tax_Profit_Loss.vue'
import DownLoads from '../views/pages/fees_taxes/DownLoads.vue'
import DownLoadsCpy from '../views/pages/fees_taxes/DownLoadscopy.vue'
import TradeBook from '../views/pages/insights _reports/TradeBook.vue'
import EventPage from '../views/pages/fees_taxes/EventPage.vue'
// import PledgeSrc from '../views/pages/portfolio_screens/PledgeSrc.vue'
import PledgeApi from '../views/pages/portfolio_screens/PledgeApi.vue'
import PledgeReport from '../views/pages/portfolio_screens/PledgeReport.vue'
// import NotifiCations from '../views/pages/NotifiCations.vue'
// import UserProfile from '../views/pages/settings_screens/UserProfile.vue'
// import WealthProfile from '../views/pages/settings_screens/WealthProfile.vue'
// import FamiLy from '../views/pages/settings_screens/FamiLy.vue'
// profile test
import PersonalInfo from '../views/pages/ProfilePages/ProfileInfo.vue'
import BankPage from '../views/pages/ProfilePages/BankInfo.vue'
import DeposltoryInfo from '../views/pages/ProfilePages/DeposltoryInfo.vue'
import SegmentInfo from '../views/pages/ProfilePages/SegmentInfo.vue'
import AnnualincomeInfo from '../views/pages/ProfilePages/AnnualincomeInfo.vue'
import NomineeInfo from '../views/pages/ProfilePages/NomineeInfo.vue'
import ClosureInfo from '../views/pages/ProfilePages/ClosureInfo.vue'
import FamilyInfo from '../views/pages/ProfilePages/FamilyInfo.vue'
import MtF from '../views/pages/ProfilePages/MtF.vue'
import ReFeral from '../views/pages/ProfilePages/ReFeral.vue'
import DownLoad from '../views/pages/ProfilePages/DownLoad.vue'
  



Vue.use(VueRouter)

const routes = [
  {
    path: '/Pledgereport',
    name: 'PledgeReport',
    component: PledgeReport,
  },
  // {
  //   path: '/signup',
  //   name: 'signup',
  //   component: SignUp,
  // },
  // {
  //   path: '/landing',
  //   name: 'landing',
  //   component: LandingView,
  // },
  // {
  //   path: '/Welcome',
  //   name: 'Welcome',
  //   component: WelcomeOne,
  // },
  {
    path: '/dailypnl',
    name: 'dailypnl',
    component: Daily_pnl,
  },
  {
   
    path: '/',
    redirect: 'profile',
    name: 'Layout',
    component: LayOut,
    children: [
      // {
      //   path: '/Dashboard',
      //   name: 'Dashboard',
      //   component: DashBoard,
      // },
      {
        path: '/holding',
        name: 'holding',
        component: HolDings,
      },
      
      {
        path: '/positions',
        name: 'positions',
        component: PosiTions,
      },
      {
        path: '/ledger',
        name: 'ledger',
        component: LedgerPage,
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: DashBoard,
      },
      {
        path: '/dashboard2',
        name: 'dashboard2',
        component: DashBoard2,
      },
      {
        path: '/corporateaction',
        name: 'corporateaction',
        component: CorporateAction,
      },
      {
        path: '/dividendspage',
        name: 'dividendspage',
        component: DiviDends_Page,
      },
      {
        path: '/pdfdownload',
        name: 'pdfdownload',
        component: ConractNote,
      },
      {
        path: '/tradeverify',
        name: 'tradeverify',
        component: Trade_Verify,
      },
      {
        path: '/tradebook',
        name: 'tradebook',
        component: TradeBook,
      },
      {
        path: '/event',
        name: 'event',
        component: EventPage,
      },
      {
        path: '/pnl',
        name: 'pnl',
        component: Profit_Loss,
      },
      {
        path: '/calender',
        name: 'calender',
        component: CalenderPage,
      },
      {
        path: '/calenderpnl',
        name: 'calenderpnl',
        component: CalenderPage2,
      },
      // {
      //   path: '/Pledgereport',
      //   name: 'Tax Profit & Loss',
      //   component: Tax_Profit_Loss,
      // },
      // {
      //   path: '/pledge_unpledge',
      //   name: 'Corporate_actions',
      //   component: CorporateActions,
      // },
      {
        path: '/pledge',
        name: 'PledgeApi',
        component: PledgeApi,
      },
      // {
      //   path: '/pledge',
      //   name: 'PledgeSrc',
      //   component: PledgeSrc,
      // },
     
      {
        path: '/taxpnl',
        name: 'taxpnl',
        component: DownLoads,
      },
      {
        path: '/taxpnlcpy',
        name: 'taxpnlcpy',
        component: DownLoadsCpy,
      },
      // profile test
      {
        path: '/profile',
        name: 'profile',
        component: PersonalInfo,
      },
      {
        path: '/bank',
        name: 'bank',
        component: BankPage,
      },
      {
        path: '/deposltory',
        name: 'deposltory',
        component: DeposltoryInfo,
      },
      {
        path: '/segment',
        name: 'Segmentinfo',
        component: SegmentInfo,
      },
      {
        path: '/annualincome',
        name: 'Annualincomeinfo',
        component: AnnualincomeInfo,
      },
      {
        path: '/nominee',
        name: 'Nominee',
        component: NomineeInfo,
      },
      {
        path: '/closure',
        name: 'Closure',
        component: ClosureInfo,
      },
      {
        path: '/family',
        name: 'family',
        component: FamilyInfo,
      },
      {
        path: '/mtf',
        name: 'mtf',
        component: MtF,
      },
      {
        path: '/refer',
        name: 'ReFeral',
        component: ReFeral,
      },
      {
        path: '/formdownload',
        name: 'formdownload',
        component: DownLoad,
      },

    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
