<template>
    <v-app>
        <v-container class="pa-0 pa-sm-3 pa-md-3 mt-n5">
            <v-alert v-if="alert" dense outlined type="error" style="position: fixed; top: 8 ; right: 0 ; z-index: 3">
                <strong>{{ msg }}</strong>
            </v-alert>
            <div class="pt-16 pa-6 pa-sm-4 pa-md-4 d-none d-md-block">
                <!-- <v-switch x-small v-model="$vuetify.theme.dark" class="mt-n1 mr-2"></v-switch> -->
                <v-col cols="12" class="pl-7">
                    <v-card class="mt-7 elevation-0">
                        <v-toolbar class="mt-3 mb-1 px-1 toolbar-contant" elevation="0" color="white">
                            <v-list class="mr-2" style="background-color: transparent;">
                                <v-list-item class="px-0 pt-0">
                                    <v-list-item-content class=" pt-0">
                                        <v-list-item-title class="text-start"
                                            style="color: #000000;font-size: 20px;font-weight:559;line-height:19px;letter-spacing:-1px">
                                            Profit & Loss </v-list-item-title>

                                        <v-list-item-subtitle class="text-start pt-1"
                                            style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Profit
                                            and Loss of your trades.
                                        </v-list-item-subtitle>
                                       
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <!-- <v-spacer></v-spacer> -->
                            <v-list class="mr-7 pt-0" style="background-color: transparent;">
                                <v-list-item class="pl-0 pa-0" style="min-height: 0px;">

                                    <v-list-item-content class="pl-2 pa-0  d-none d-md-block">
                                        <v-list-item-title v-if="downsum" class="text-start"
                                            style="color: #000000;font-size: 16px;font-weight:559;line-height:16px;">
                                            {{ PnlDate.length }}
                                            Symbols
                                        </v-list-item-title>
                                        <v-list-item-title v-if="!downsum" class="text-start"
                                            style="color: #000000;font-size: 16px;font-weight:559;line-height:16px;">
                                            {{ desserts23.length }}
                                            Symbols
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-menu v-if="downsum && PnlDate.length > 0" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="elevation-0 rounded-pill mt-n2 mr-2" v-bind="attrs" v-on="on">
                                        <v-icon size="20">mdi mdi-download</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="(item, i) in itemsdwl" :key="i" @click="() => { }">
                                        <v-list-item-title @click="downloadsum(item.title)">{{ item.title
                                        }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-menu v-if="!downsum && desserts23.length > 0" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="elevation-0 rounded-pill mt-n2 mr-2" v-bind="attrs" v-on="on">
                                        <v-icon size="20">mdi mdi-download</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="(item, i) in itemsdwl" :key="i" @click="() => { }">
                                        <v-list-item-title @click="downloaddet(item.title)">{{ item.title
                                        }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-menu class="" v-model="menu23" left :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field style="max-width: 205px;" class="mt-4 mr-2" solo rounded flat
                                        v-model="fromshow" label="From date" dense color="black" background-color="#F1F3F8"
                                        prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-card class="pa-2">
                                    <div>
                                        <v-date-picker range :max="maxval" color="black" v-model="fromdate" @input="datemaxvalue('click')"></v-date-picker>
                                        <!-- <v-date-picker  range color="black" v-model="fromdate" @change="gettradedata()"></v-date-picker> -->
                                    </div>
                                    <p v-if="fromdate != '' && fromdate != [] && highlightyears != 'cur'" class="ml-2 caption mb-1 "><span class="red--text">*</span>Your are in {{Number(maxval.split("-")[0]) - 1}}-{{maxval.split("-")[0]}} Financial year 
                                        <v-tooltip  color="black" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                           <span @click="reset"
                                             class="caption blue--text font-weight-bold"
                                        
                                            style="cursor: pointer"
                                            v-bind="attrs"
                                            v-on="on">
                                            Change
                                           </span>
                                        </template>
                                        <span>Click here to move the date forward for Financial year</span>
                                        </v-tooltip>
                                    </p>

                                    <v-divider></v-divider>
                                    <div class="d-flex mt-n2">
                                        <!-- <p class="mb-0 mx-1 pa-4 body-2 font-weight-black" style="cursor: pointer;" @click="gettradedataextra(7)">Last 7 days</p> -->
                                        <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="gettradedataextra(30)">Last 30 days</p> -->
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyears == 'cur', 'black--text': highlightyears != 'cur'}"  @click="gettradedataextrayear(0,'cur')">Current FY</p>
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyears == 'ly', 'black--text': highlightyears != 'ly'}"  @click="gettradedataextrayear(-1,'ly')">Last FY</p>

                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyears == yearsarray[0], 'black--text': highlightyears != yearsarray[0]}"  @click="gettradedataextrayear(-2,yearsarray[0])">{{yearsarray[0]}}</p>
                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyears == yearsarray[1], 'black--text': highlightyears != yearsarray[1]}"  @click="gettradedataextrayear(-3,yearsarray[1])">{{yearsarray[1]}}</p>
                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyears == yearsarray[2], 'black--text': highlightyears != yearsarray[2]}"  @click="gettradedataextrayear(-4,yearsarray[2])">{{yearsarray[2]}}</p>
                                        <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" @click="mountedfun(30)">Last </p> -->
                                    </div>
                                </v-card>
                                
                            </v-menu>
                            <!-- dense prepend-inner-icon="mdi-filter-variant" -->
                            <v-select v-if="downsum" :readonly="segloader" :loading="segloader" :items="filterr"
                                @change="filterseg(filterdata_unpl)" dense v-model="filterdata_unpl" placeholder="filter"
                                solo rounded flat background-color="#F1F3F8" hide-details class="mr-2 mt-n2 fildrop"
                                style="max-width: 140px; font-size: 21px; overflow: hidden;">
                            </v-select>
                            <!-- <v-select v-if="!downsum" :items="filterr" @change="filterseg1(filterdata_unpl1)" dense
                                v-model="filterdata_unpl1" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                                hide-details class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px">
                            </v-select> -->
                            <!-- <v-btn v-if="downsum" @click="downloadsum()" class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon
                                    size="20">mdi mdi-content-save</v-icon></v-btn>
                            <v-btn v-if="!downsum" @click="downloaddet()"
                                class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon size="20">mdi
                                    mdi-content-save</v-icon></v-btn> -->
                            <v-text-field   class="tophundraedmutual  d-none d-md-block mt-4 mr-5"
                                height="36px" background-color="#F1F3F8" label="Search" v-model="search" solo rounded text
                                flat dense>
                                <template v-slot:prepend-inner>
                                    <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px"
                                        height="18px" />
                                </template>
                            </v-text-field>
                        </v-toolbar>
                        <v-divider></v-divider>
                        <!-- <v-text-field style="width: 100%;" class="tophundraedmutual d-md-none mt-4 mr-5" height="36px"
                            background-color="#F1F3F8" label="Search symbols" v-model="search" solo rounded flat dense>
                            <template v-slot:prepend-inner>
                                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px"
                                    height="18px" />
                            </template>
                        </v-text-field> -->
                        <div class="display-flex row   d-none d-md-block pa-8">
                            <v-row>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Realised P&L</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                                                <p class="mb-0" v-if="disrealised_pnl && disrealised_pnl < 0"
                                                    style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (disrealised_pnl).toFixed(2) }} </p>
                                                <p class="mb-0" v-if="disrealised_pnl && disrealised_pnl > 0"
                                                    style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (disrealised_pnl).toFixed(2) }} </p>
                                                <p class="mb-0" v-if="disrealised_pnl && disrealised_pnl == 0"
                                                    style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (disrealised_pnl).toFixed(2) }} </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col>
                                    <v-tooltip color="black"  bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                     <v-card v-bind="attrs" v-on="on" @click="chargerdialoge = true" class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Charges & taxes <v-icon color="#0037B7">mdi mdi-menu-right</v-icon></p>
                                        <v-progress-linear style="width: 30px" v-if="segloader" indeterminate
                                            color="blue"></v-progress-linear>
                                        <v-divider v-else-if="!segloader" class="mt-2" style="border:solid px #000000"
                                            width="30px" color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <!-- <v-skeleton-loader  max-width="300" height="10"
                                                type="card"></v-skeleton-loader> -->

                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">

                                                <p class="mb-0" v-if="segval && segval < 0"
                                                    style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (segval).toFixed(2) }} </p>
                                                <p class="mb-0" v-else-if="segval && segval > 0"
                                                    style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (segval).toFixed(2) }} </p>
                                                <p class="mb-0" v-else-if="segval && segval == 0"
                                                    style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (segval).toFixed(2) }} </p>
                                            </v-card>
                                        </div>
                                    </v-card>
                                                </template>
                                                <span>Click here to see your breakups</span>
                                            </v-tooltip>
                                    
                                </v-col>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Net realised P&L</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">

                                                <p class="mb-0" v-if=" ( Number(disrealised_pnl) + Number(segval))  < 0"
                                                    style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (( Number(disrealised_pnl) + Number(segval))).toFixed(2) }} </p>
                                                <p class="mb-0" v-else-if=" ( Number(disrealised_pnl) + Number(segval)) > 0"
                                                    style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ ( Number(disrealised_pnl) + Number(segval)).toFixed(2) }} </p> 
                                                <p class="mb-0" v-else 
                                                    style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ ( Number(disrealised_pnl) + Number(segval)) }} </p>
                                            </v-card>
                                        </div>
                                    </v-card>

                                </v-col>
                                <v-col>
                                    <v-card class="ml-3 pl-3 py-1 pr-2 " outlined height="90px">
                                        <p class="pt-1 mb-3"
                                            style="font-weight:450;font-size: 16px; color: #000000;line-height: 16px;letter-spacing: -0.4px;">
                                            Unrealised P&L</p>
                                        <v-divider class="mt-2" style="border:solid px #000000" width="30px"
                                            color="#000000"></v-divider>
                                        <div class="display-flex row pt-6 pl-2">
                                            <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">

                                                <p class="mb-0" v-if="disunrealised_pnl && disunrealised_pnl < 0"
                                                    style="color: red;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (disunrealised_pnl).toFixed(2) }} </p>
                                                <p class="mb-0" v-else-if="disunrealised_pnl && disunrealised_pnl > 0"
                                                    style="color: green;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (disunrealised_pnl).toFixed(2) }} </p>

                                                <p class="mb-0" v-else-if="disunrealised_pnl && disunrealised_pnl == 0"
                                                    style="color: black;font-size: 13px;font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (disunrealised_pnl).toFixed(2) }} </p>
                                            </v-card>
                                        </div>
                                    </v-card>

                                </v-col>
                             
                                <!-- {{dessertspopexpense}} -->
                            </v-row>
                        </div>
                        <v-dialog v-model="chargerdialoge" width="500">
                               
                                <v-card>
                                    <v-toolbar class=" mb-1 pt-2 px-2  toolbar-contant" elevation="0" color="white">
                    <v-list class="pb-0" style="background-color: transparent;">
                        <v-list-item class=" pt-0 px-0">
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path
                      d="M11 47H2C1.73478 47 1.48043 46.8946 1.29289 46.7071C1.10536 46.5196 1 46.2652 1 46V28C1 27.7348 1.10536 27.4804 1.29289 27.2929C1.48043 27.1054 1.73478 27 2 27H11C11.2652 27 11.5196 27.1054 11.7071 27.2929C11.8946 27.4804 12 27.7348 12 28V46C12 46.2652 11.8946 46.5196 11.7071 46.7071C11.5196 46.8946 11.2652 47 11 47Z"
                      fill="#C8C8C8" />
                    <path
                      d="M46 47H37C36.7348 47 36.4804 46.8946 36.2929 46.7071C36.1054 46.5196 36 46.2652 36 46V28C36 27.7348 36.1054 27.4804 36.2929 27.2929C36.4804 27.1054 36.7348 27 37 27H46C46.2652 27 46.5196 27.1054 46.7071 27.2929C46.8946 27.4804 47 27.7348 47 28V46C47 46.2652 46.8946 46.5196 46.7071 46.7071C46.5196 46.8946 46.2652 47 46 47Z"
                      fill="#C8C8C8" />
                    <path
                      d="M27 2V4H21V2C21 1.73478 20.8946 1.48043 20.7071 1.29289C20.5196 1.10536 20.2652 1 20 1C19.7348 1 19.4804 1.10536 19.2929 1.29289C19.1054 1.48043 19 1.73478 19 2V11H21V6H27V11H29V2C29 1.73478 28.8946 1.48043 28.7071 1.29289C28.5196 1.10536 28.2652 1 28 1C27.7348 1 27.4804 1.10536 27.2929 1.29289C27.1054 1.48043 27 1.73478 27 2Z"
                      fill="#363636" />
                    <path
                      d="M38 47H10V10C10 9.73478 10.1054 9.48043 10.2929 9.29289C10.4804 9.10536 10.7348 9 11 9H37C37.2652 9 37.5196 9.10536 37.7071 9.29289C37.8946 9.48043 38 9.73478 38 10V47Z"
                      fill="#E3E3E3" />
                    <path
                      d="M20.5 23H16.5C16.3674 23 16.2402 22.9473 16.1464 22.8536C16.0527 22.7598 16 22.6326 16 22.5V20.5C16 20.3674 16.0527 20.2402 16.1464 20.1464C16.2402 20.0527 16.3674 20 16.5 20H20.5C20.6326 20 20.7598 20.0527 20.8536 20.1464C20.9473 20.2402 21 20.3674 21 20.5V22.5C21 22.6326 20.9473 22.7598 20.8536 22.8536C20.7598 22.9473 20.6326 23 20.5 23Z"
                      fill="#43A6DD" />
                    <path
                      d="M20.5 17H16.5C16.3674 17 16.2402 16.9473 16.1464 16.8536C16.0527 16.7598 16 16.6326 16 16.5V14.5C16 14.3674 16.0527 14.2402 16.1464 14.1464C16.2402 14.0527 16.3674 14 16.5 14H20.5C20.6326 14 20.7598 14.0527 20.8536 14.1464C20.9473 14.2402 21 14.3674 21 14.5V16.5C21 16.6326 20.9473 16.7598 20.8536 16.8536C20.7598 16.9473 20.6326 17 20.5 17Z"
                      fill="#43A6DD" />
                    <path
                      d="M20.5 29H16.5C16.3674 29 16.2402 28.9473 16.1464 28.8536C16.0527 28.7598 16 28.6326 16 28.5V26.5C16 26.3674 16.0527 26.2402 16.1464 26.1464C16.2402 26.0527 16.3674 26 16.5 26H20.5C20.6326 26 20.7598 26.0527 20.8536 26.1464C20.9473 26.2402 21 26.3674 21 26.5V28.5C21 28.6326 20.9473 28.7598 20.8536 28.8536C20.7598 28.9473 20.6326 29 20.5 29Z"
                      fill="#43A6DD" />
                    <path
                      d="M20.5 35H16.5C16.3674 35 16.2402 34.9473 16.1464 34.8536C16.0527 34.7598 16 34.6326 16 34.5V32.5C16 32.3674 16.0527 32.2402 16.1464 32.1464C16.2402 32.0527 16.3674 32 16.5 32H20.5C20.6326 32 20.7598 32.0527 20.8536 32.1464C20.9473 32.2402 21 32.3674 21 32.5V34.5C21 34.6326 20.9473 34.7598 20.8536 34.8536C20.7598 34.9473 20.6326 35 20.5 35Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 23H27.5C27.3674 23 27.2402 22.9473 27.1464 22.8536C27.0527 22.7598 27 22.6326 27 22.5V20.5C27 20.3674 27.0527 20.2402 27.1464 20.1464C27.2402 20.0527 27.3674 20 27.5 20H31.5C31.6326 20 31.7598 20.0527 31.8536 20.1464C31.9473 20.2402 32 20.3674 32 20.5V22.5C32 22.6326 31.9473 22.7598 31.8536 22.8536C31.7598 22.9473 31.6326 23 31.5 23Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 17H27.5C27.3674 17 27.2402 16.9473 27.1464 16.8536C27.0527 16.7598 27 16.6326 27 16.5V14.5C27 14.3674 27.0527 14.2402 27.1464 14.1464C27.2402 14.0527 27.3674 14 27.5 14H31.5C31.6326 14 31.7598 14.0527 31.8536 14.1464C31.9473 14.2402 32 14.3674 32 14.5V16.5C32 16.6326 31.9473 16.7598 31.8536 16.8536C31.7598 16.9473 31.6326 17 31.5 17Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 29H27.5C27.3674 29 27.2402 28.9473 27.1464 28.8536C27.0527 28.7598 27 28.6326 27 28.5V26.5C27 26.3674 27.0527 26.2402 27.1464 26.1464C27.2402 26.0527 27.3674 26 27.5 26H31.5C31.6326 26 31.7598 26.0527 31.8536 26.1464C31.9473 26.2402 32 26.3674 32 26.5V28.5C32 28.6326 31.9473 28.7598 31.8536 28.8536C31.7598 28.9473 31.6326 29 31.5 29Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 35H27.5C27.3674 35 27.2402 34.9473 27.1464 34.8536C27.0527 34.7598 27 34.6326 27 34.5V32.5C27 32.3674 27.0527 32.2402 27.1464 32.1464C27.2402 32.0527 27.3674 32 27.5 32H31.5C31.6326 32 31.7598 32.0527 31.8536 32.1464C31.9473 32.2402 32 32.3674 32 32.5V34.5C32 34.6326 31.9473 34.7598 31.8536 34.8536C31.7598 34.9473 31.6326 35 31.5 35Z"
                      fill="#43A6DD" />
                    <path
                      d="M26 40H22C21.7348 40 21.4804 40.1054 21.2929 40.2929C21.1054 40.4804 21 40.7348 21 41V47H27V41C27 40.7348 26.8946 40.4804 26.7071 40.2929C26.5196 40.1054 26.2652 40 26 40Z"
                      fill="#363636" />
                  </svg> -->
                  
                            <v-list-item-content class=" pt-0">
                                <v-list-item-title class="text-start"
                                    style="color: #000000;font-size: 20px;font-weight:559;line-height:25px;letter-spacing:-1px">
                                    Charges and Taxes </v-list-item-title>

                                <v-list-item-subtitle class="text-start pt-1"
                                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Breakups for your charges and taxes
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-spacer></v-spacer>
                            <v-btn class="mt-n6" icon text color="" @click="chargerdialoge = false">
                                <v-icon size="26">mdi mdi-close</v-icon>
                            </v-btn>
                    


                </v-toolbar>
                <v-divider class=" mx-2"></v-divider>
                <v-data-table
              
                    dense
                    :loading="segloader"
                    :headers="headersforchargesandtaxes"
                    :items="dessertspopexpense"
                    item-key="name"
                    height="300px"
                    class="elevation-0 ma-4"
                    hide-default-footer
                    :items-per-page="dessertspopexpense.length"
                ></v-data-table>
                                    <!-- <v-card-text>
                                        {{dessertspopexpense}}
                                    </v-card-text> -->

                                    <v-divider></v-divider>

                                    <!-- <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" text @click="dialog = false">
                                            I accept
                                        </v-btn>
                                    </v-card-actions> -->
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="chargerdialogeMobile" width="500">
                               
                               <v-card style="overflow-y: scroll">
                                   <v-toolbar class=" mb-1 pt-2 px-2  toolbar-contant" elevation="0" color="white">
                                    <v-list class="pb-0" style="background-color: transparent;">
                                        <v-list-item class=" pt-0 px-0">
                                                
                                            <v-list-item-content class=" pt-0">
                                                <v-list-item-title class="text-start"
                                                    style="color: #000000;font-size: 20px;font-weight:559;line-height:25px;letter-spacing:-1px">
                                                    Charges and Taxes </v-list-item-title>

                                                <v-list-item-subtitle class="text-start pt-1"
                                                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Breakups for your charges and taxes
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                    <v-spacer></v-spacer>
                                            <v-btn class="mt-n6" icon text color="" @click="chargerdialogeMobile = false">
                                                <v-icon size="26">mdi mdi-close</v-icon>
                                            </v-btn>
                   


                                    </v-toolbar>
                                    <v-divider class=" mx-2 mb-4"></v-divider>
                                        <div class=" pt-0 px-0 d-flex mx-8"> 
                                            <p class="text-start"
                                                style="color: #000000;font-size: 12px; font-weight:559;">
                                                SCRIP_SYMBOL</p> 
                                            <p class="ml-auto  pt-1"
                                                style="color: #666666;font-size: 12px; ">NET_AMOUNT
                                            </p>
                                        </div>
                                    <v-list v-for="(item, index) in dessertspopexpense" :key="index" class="pb-0" style="background-color: transparent;">
                                            <div class=" pt-0 px-0 d-flex mx-8"> <p class="text-start"
                                                        style="color: #000000;font-size: 12px; font-weight:559;">
                                                       {{item.SCRIP_SYMBOL}}</p>

                                                    <p class="ml-auto  pt-1"
                                                        style="color: #666666;font-size: 12px; ">{{ item.NET_AMOUNT }} 
                                            </p>
                                                
                                            </div>
                                        </v-list>
                                                        
                               </v-card>
                           </v-dialog>
                        <div class="mt-2 mt-sm-0 mt-md-0">
                            <div class="mb-n4 pa-2">
                                <!-- <v-row class="pt-0">
                                    <v-col lg="4" sm="12" md="12" cols="12">
                                        <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
                                            <v-tab @click="downsum = true">
                                                P&L
                                            </v-tab>
                                            <v-tab @click="downsum = false">
                                                Tax p&l
                                            </v-tab>
                                        </v-tabs>
                                    </v-col>
                                </v-row> -->
                                <!-- {{ loader }} -->

                                <!-- {{ dialog }} -->

                                <!-- <v-tabs-items v-model="tab"> -->
                                <!-- <v-tab-item> -->
                                    <div class="d-flex mt-n8">
                                        <v-checkbox
                                        v-model="checkbox"
                                        @change="gettradedata()"
                                        :label="`With open balance`"
                                        ></v-checkbox>
                                        <p class="ml-auto body-2 mb-0 mt-6">
                                           <span class="red--text">*</span> Buy rate and Sell rate is inclusive of brokerage
                                        </p>
                                    </div>
                                <v-data-table   must-sort
                                    :sort-by="['SCRIP_SYMBOL']"
                                    :sort-desc="[false]" :search="search" hide-default-footer :loading="loader" height="495px"
                                    mobile-breakpoint fixed-header :headers="Overviewheaders"
                                    :items="sortedItems" :items-per-page="itemperpage" item-key="NSE_SCRIPCODE"
                                    style="border: 1px solid #efeef3; cursor: pointer" class="elevation-0 rounded-lg "
                                    @click:row="detailedpop">
                                    <template v-slot:[`item.SCRIP_SYMBOL`]="{ item }">


                                        <span class="font-weight-bold">

                                            {{ item.SCRIP_SYMBOL }}

                                            <v-tooltip color="black" v-if="item.open_QUANTITY" v-model="item.show" top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-chip v-bind="attrs" v-on="on"  :color="item.open_QUANTITY > 0 ? 'blue' : item.open_QUANTITY < 0 ? 'red' : 'default'" class="ml-2" small
                                                        outlined>{{
                                                           item.open_QUANTITY }}  @  {{ Number(item.Open_AMOUNT).toFixed(2)}}</v-chip>
                                                </template>
                                                <span>Open Quantity!</span>
                                            </v-tooltip>

                                            <v-icon color="#0037B7">mdi mdi-menu-right</v-icon>

                                            <!-- {{ item.company_code }} -->
                                        </span>
                                    </template>


                                    <template v-slot:[`item.BUY_QUANTITY`]="{ item }">
                                        <span class="font-weight-light">
                                            {{ item.BUY_QUANTITY ? item.BUY_QUANTITY.toFixed(2) : item.BUY_QUANTITY
                                            }}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.CLOSING_PRICE`]="{ item }">
                                        <span class="font-weight-light"> {{ item.CLOSING_PRICE ?
                                            item.CLOSING_PRICE.toFixed(2) : item.CLOSING_PRICE }}</span>
                                    </template>
                                    <template v-slot:[`item.BUY_RATE`]="{ item }">
                                        <span class="font-weight-light">
                                            {{ item.BUY_RATE ? item.BUY_RATE.toFixed(2) : item.BUY_RATE }}
                                        </span>
                                        <p class="font-weight-light caption mb-0">
                                            {{ item.BUY_AMOUNT ? item.BUY_AMOUNT.toFixed(2) : item.BUY_AMOUNT }}</p>

                                    </template>
                                    <template v-slot:[`item.SALE_QUANTITY`]="{ item }">
                                        <span class="font-weight-light">
                                            {{ item.SALE_QUANTITY ? item.SALE_QUANTITY.toFixed(2) :
                                                item.SALE_QUANTITY }}</span>
                                    </template>
                                    <template v-slot:[`item.SALE_RATE`]="{ item }">
                                        <span class="font-weight-light"> {{ item.SALE_RATE ?
                                            item.SALE_RATE.toFixed(2) : item.SALE_RATE }}</span>
                                        <p class="font-weight-light caption mb-0">
                                            {{ item.SALE_AMOUNT ? item.SALE_AMOUNT.toFixed(2) : item.SALE_AMOUNT }}
                                        </p>
                                    </template>

                                    <template v-slot:[`item.NET_QUANTITY`]="{ item }">
                                        <span class="font-weight-light">
                                            {{ item.NET_QUANTITY ? item.NET_QUANTITY.toFixed(2) : item.NET_QUANTITY
                                            }}
                                        </span>
                                    </template>
                                    <template v-slot:[`item.NET_RATE`]="{ item }">
                                        <span class="font-weight-light">
                                            {{ item.NET_RATE ? item.NET_RATE.toFixed(2) : item.NET_RATE }}

                                        </span>
                                        <p class="font-weight-light caption mb-0">
                                            {{ item.NET_AMOUNT ? item.NET_AMOUNT.toFixed(2) : item.NET_AMOUNT }}

                                        </p>

                                    </template>

                                    <!-- <template v-slot:[`item.CLOSING_PRICE`]="{ item }">
                                                <span class="font-weight-light">
                                                    {{ parseInt(item.CLOSING_PRICE) }}</span>
                                            </template> -->
                                    <template v-slot:[`item.NOT_PROFIT`]="{ item }">
                                        <span v-if="item.NOT_PROFIT && item.NOT_PROFIT < 0" class="font-weight-light"
                                            style="color: red;">
                                            {{ item.NOT_PROFIT ? item.NOT_PROFIT.toFixed(2) : item.NOT_PROFIT }}

                                        </span>
                                        <span v-else-if="item.NOT_PROFIT && item.NOT_PROFIT > 0" class="font-weight-light"
                                            style="color: green;">
                                            {{ item.NOT_PROFIT ? item.NOT_PROFIT.toFixed(2) : item.NOT_PROFIT }}

                                        </span>
                                        <span v-else-if="item.NOT_PROFIT && item.NOT_PROFIT == 0" class="font-weight-light"
                                            style="color: black;">
                                            {{ item.NOT_PROFIT ? item.NOT_PROFIT.toFixed(2) : item.NOT_PROFIT }}

                                        </span>
                                    </template>
                                    <template v-slot:no-data>
                                        <v-col class="mx-auto pa-15 mt-5">
                                            <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%"
                                                height="" />
                                            <p class="font-weight-bold black--text">No data available</p>
                                        </v-col>
                                    </template>
                                    <!-- <template v-slot:footer>
                                                <v-divider></v-divider>
                                                <div class="text-center" v-if="more">
                                                    <v-btn text color="primary" dark class="ma-2 text-none"
                                                        @click="morebtn()">
                                                        <span class=""
                                                            style="color: #0037B7;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">Show
                                                            more</span> <v-icon color="black"> mdi-chevron-down</v-icon>
                                                    </v-btn>
                                                </div>
                                                <div class="text-center" v-if="less">
                                                    <v-btn flat text color="primary" dark class="ma-2 text-none"
                                                        @click="lessbtn()">
                                                        <span class=""
                                                            style="color: #0037B7;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">
                                                            Show Less</span> <v-icon color="black">
                                                            mdi-chevron-up</v-icon>
                                                    </v-btn>
                                                </div>
                                            </template> -->

                                </v-data-table>
                                <v-dialog v-model="dialog" width="70%" class="elevation-0">
                                    <v-card class=" elevation-0 pt-3 pb-2" height="100%">
                                        <v-btn :ripple="false" dense class="mt-n4" text icon solo absolute top right
                                            @click="dialog = false"><v-icon size="18" color="grey">mdi
                                                mdi-close</v-icon></v-btn>
                                        <v-toolbar class=" mb-1 mt-4 toolbar-contant" elevation="0" color="white">
                                            <v-list class="" style="background-color: transparent;">
                                                <v-list-item class=" pt-0">
                                                    <v-list-item-content class=" pt-0">
                                                        <v-list-item-title class="text-start"
                                                            style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">
                                                            Detailed P & L </v-list-item-title>
                                                        <v-list-item-subtitle class="text-start pt-1"
                                                            style="color: #666666;font-size: 11px;font-weight:469;line-height:14px">{{
                                                                scriptname }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                            <v-spacer></v-spacer>
                                            <v-list class="mr-7 pt-0" style="background-color: transparent;">
                                                <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
                                                    <v-list-item-content class="pl-2 pa-0">
                                                        <v-list-item-title
                                                            class="text-start font-weight-medium d-none d-md-block"
                                                            style="color: #000000;font-size: 14px;line-height:16px;">
                                                            <!-- {{ detailedpoparray.length }}
                                                            Trades -->
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                            <v-text-field v-model="search1" class="tophundraedmutual mt-4" height="36px"
                                                background-color="#F1F3F8" style="width: 2%;" label="Search" solo rounded
                                                flat dense>
                                                <template v-slot:prepend-inner>
                                                    <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')"
                                                        width="20px" height="18px" />
                                                </template>
                                            </v-text-field>
                                        </v-toolbar>
                                        <v-divider></v-divider>

                                        <div class=" mb-4 mx-4 my-6">

                                            <v-data-table dense :headers="Overviewheaders2" hide-default-footer
                                                :loading="loaderpopup" :search="search1" style="border: 1px solid #efeef3"
                                                class="elevation-0 rounded-lg" height="370px" fixed-header disable-sort
                                                :items="detailedpoparray.data">

                                                <template v-slot:[`item.BQTY`]="{ item }">
                                                    <span class="font-weight-regular subtitle-2"> {{ item.BQTY
                                                    }}</span>
                                                </template>
                                                <template v-slot:[`item.BRATE`]="{ item }">
                                                    <span class="font-weight-regular subtitle-2"> {{
                                                        (item.BRATE.toFixed(2))
                                                    }}</span>
                                                    <p class="font-weight-medium caption mb-0"> {{ (item.BAMT).toFixed(2) }}
                                                    </p>
                                                </template>
                                                <template v-slot:[`item.NRATE`]="{ item }">
                                                    <span class="font-weight-regular subtitle-2"> {{ item.NRATE
                                                    }}</span>
                                                    <p class="font-weight-medium caption mb-0"> {{ item.NETAMT }}
                                                    </p>
                                                </template>


                                                <template v-slot:[`item.SRATE`]="{ item }">
                                                    <span class="font-weight-regular subtitle-2"> {{
                                                        (item.SRATE).toFixed(2)
                                                    }}</span>
                                                    <p class="font-weight-medium caption mb-0"> {{ (item.SAMT).toFixed(2)
                                                    }}</p>
                                                </template>
                                                <template v-slot:[`item.SQTY`]="{ item }">
                                                    <span class="font-weight-regular subtitle-2"> {{ item.SQTY
                                                    }}</span>
                                                </template>
                                                <template v-slot:[`item.TRADE_DATE`]="{ item }">
                                                    <span class="font-weight-bold subtitle-2"> {{ item.TRADE_DATE
                                                    }}</span>
                                                </template>
                                                <template v-slot:[`item.NETAMT`]="{ item }">
                                                    <span v-if="item.NETAMT < 0" class="font-weight-regular subtitle-2"
                                                        style="color: red;">
                                                        {{ item.NETAMT }}</span>
                                                    <span v-if="item.NETAMT > 0" class="font-weight-regular subtitle-2"
                                                        style="color: green;"> {{ item.NETAMT }}</span>
                                                    <span v-if="item.NETAMT == 0" class="font-weight-regular subtitle-2"
                                                        style="color: black;"> {{ item.NETAMT }}</span>
                                                </template>
                                                <template v-slot:no-data>
                                                    <v-col class="mx-auto pa-15 mt-5">
                                                        <img alt="" class="shrink" src="../../../assets/nodata.svg"
                                                            width="15%" height="" />
                                                        <p class="font-weight-bold black--text">No data available
                                                        </p>
                                                    </v-col>
                                                </template>
                                                <template v-slot:footer>
                                                    <v-divider></v-divider>
                                                    <div class="text-end">

                                                        <span class="mr-5 mt-4"
                                                            style="color: black;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">P & L
                                                            : <span v-if="finaltotal > 0" style="color: green"> {{
                                                                finaltotal.toFixed(2)
                                                            }}</span>
                                                            <span v-if="finaltotal < 0" style="color: red"> {{
                                                                finaltotal.toFixed(2)
                                                            }}</span>
                                                        </span>
                                                    </div>

                                                </template>

                                            </v-data-table>



                                        </div>
                                    </v-card>
                                </v-dialog>

                                <!-- </v-tab-item> -->
                                <!-- <v-tab-item> -->
                                <!-- <p>Tax p and l datas</p> -->
                                <!-- </v-tab-item> -->
                                <!-- </v-tabs-items> -->

                            </div>
                        </div>
                    </v-card>
                </v-col>
            </div>
            <div class="d-md-none">
                <v-toolbar class="mt-6 mb-1 px-2  toolbar-contant" elevation="0" color="white">
                    <v-list class="pb-0" style="background-color: transparent;">
                        <v-list-item class=" pt-0 px-0">
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path
                      d="M11 47H2C1.73478 47 1.48043 46.8946 1.29289 46.7071C1.10536 46.5196 1 46.2652 1 46V28C1 27.7348 1.10536 27.4804 1.29289 27.2929C1.48043 27.1054 1.73478 27 2 27H11C11.2652 27 11.5196 27.1054 11.7071 27.2929C11.8946 27.4804 12 27.7348 12 28V46C12 46.2652 11.8946 46.5196 11.7071 46.7071C11.5196 46.8946 11.2652 47 11 47Z"
                      fill="#C8C8C8" />
                    <path
                      d="M46 47H37C36.7348 47 36.4804 46.8946 36.2929 46.7071C36.1054 46.5196 36 46.2652 36 46V28C36 27.7348 36.1054 27.4804 36.2929 27.2929C36.4804 27.1054 36.7348 27 37 27H46C46.2652 27 46.5196 27.1054 46.7071 27.2929C46.8946 27.4804 47 27.7348 47 28V46C47 46.2652 46.8946 46.5196 46.7071 46.7071C46.5196 46.8946 46.2652 47 46 47Z"
                      fill="#C8C8C8" />
                    <path
                      d="M27 2V4H21V2C21 1.73478 20.8946 1.48043 20.7071 1.29289C20.5196 1.10536 20.2652 1 20 1C19.7348 1 19.4804 1.10536 19.2929 1.29289C19.1054 1.48043 19 1.73478 19 2V11H21V6H27V11H29V2C29 1.73478 28.8946 1.48043 28.7071 1.29289C28.5196 1.10536 28.2652 1 28 1C27.7348 1 27.4804 1.10536 27.2929 1.29289C27.1054 1.48043 27 1.73478 27 2Z"
                      fill="#363636" />
                    <path
                      d="M38 47H10V10C10 9.73478 10.1054 9.48043 10.2929 9.29289C10.4804 9.10536 10.7348 9 11 9H37C37.2652 9 37.5196 9.10536 37.7071 9.29289C37.8946 9.48043 38 9.73478 38 10V47Z"
                      fill="#E3E3E3" />
                    <path
                      d="M20.5 23H16.5C16.3674 23 16.2402 22.9473 16.1464 22.8536C16.0527 22.7598 16 22.6326 16 22.5V20.5C16 20.3674 16.0527 20.2402 16.1464 20.1464C16.2402 20.0527 16.3674 20 16.5 20H20.5C20.6326 20 20.7598 20.0527 20.8536 20.1464C20.9473 20.2402 21 20.3674 21 20.5V22.5C21 22.6326 20.9473 22.7598 20.8536 22.8536C20.7598 22.9473 20.6326 23 20.5 23Z"
                      fill="#43A6DD" />
                    <path
                      d="M20.5 17H16.5C16.3674 17 16.2402 16.9473 16.1464 16.8536C16.0527 16.7598 16 16.6326 16 16.5V14.5C16 14.3674 16.0527 14.2402 16.1464 14.1464C16.2402 14.0527 16.3674 14 16.5 14H20.5C20.6326 14 20.7598 14.0527 20.8536 14.1464C20.9473 14.2402 21 14.3674 21 14.5V16.5C21 16.6326 20.9473 16.7598 20.8536 16.8536C20.7598 16.9473 20.6326 17 20.5 17Z"
                      fill="#43A6DD" />
                    <path
                      d="M20.5 29H16.5C16.3674 29 16.2402 28.9473 16.1464 28.8536C16.0527 28.7598 16 28.6326 16 28.5V26.5C16 26.3674 16.0527 26.2402 16.1464 26.1464C16.2402 26.0527 16.3674 26 16.5 26H20.5C20.6326 26 20.7598 26.0527 20.8536 26.1464C20.9473 26.2402 21 26.3674 21 26.5V28.5C21 28.6326 20.9473 28.7598 20.8536 28.8536C20.7598 28.9473 20.6326 29 20.5 29Z"
                      fill="#43A6DD" />
                    <path
                      d="M20.5 35H16.5C16.3674 35 16.2402 34.9473 16.1464 34.8536C16.0527 34.7598 16 34.6326 16 34.5V32.5C16 32.3674 16.0527 32.2402 16.1464 32.1464C16.2402 32.0527 16.3674 32 16.5 32H20.5C20.6326 32 20.7598 32.0527 20.8536 32.1464C20.9473 32.2402 21 32.3674 21 32.5V34.5C21 34.6326 20.9473 34.7598 20.8536 34.8536C20.7598 34.9473 20.6326 35 20.5 35Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 23H27.5C27.3674 23 27.2402 22.9473 27.1464 22.8536C27.0527 22.7598 27 22.6326 27 22.5V20.5C27 20.3674 27.0527 20.2402 27.1464 20.1464C27.2402 20.0527 27.3674 20 27.5 20H31.5C31.6326 20 31.7598 20.0527 31.8536 20.1464C31.9473 20.2402 32 20.3674 32 20.5V22.5C32 22.6326 31.9473 22.7598 31.8536 22.8536C31.7598 22.9473 31.6326 23 31.5 23Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 17H27.5C27.3674 17 27.2402 16.9473 27.1464 16.8536C27.0527 16.7598 27 16.6326 27 16.5V14.5C27 14.3674 27.0527 14.2402 27.1464 14.1464C27.2402 14.0527 27.3674 14 27.5 14H31.5C31.6326 14 31.7598 14.0527 31.8536 14.1464C31.9473 14.2402 32 14.3674 32 14.5V16.5C32 16.6326 31.9473 16.7598 31.8536 16.8536C31.7598 16.9473 31.6326 17 31.5 17Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 29H27.5C27.3674 29 27.2402 28.9473 27.1464 28.8536C27.0527 28.7598 27 28.6326 27 28.5V26.5C27 26.3674 27.0527 26.2402 27.1464 26.1464C27.2402 26.0527 27.3674 26 27.5 26H31.5C31.6326 26 31.7598 26.0527 31.8536 26.1464C31.9473 26.2402 32 26.3674 32 26.5V28.5C32 28.6326 31.9473 28.7598 31.8536 28.8536C31.7598 28.9473 31.6326 29 31.5 29Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 35H27.5C27.3674 35 27.2402 34.9473 27.1464 34.8536C27.0527 34.7598 27 34.6326 27 34.5V32.5C27 32.3674 27.0527 32.2402 27.1464 32.1464C27.2402 32.0527 27.3674 32 27.5 32H31.5C31.6326 32 31.7598 32.0527 31.8536 32.1464C31.9473 32.2402 32 32.3674 32 32.5V34.5C32 34.6326 31.9473 34.7598 31.8536 34.8536C31.7598 34.9473 31.6326 35 31.5 35Z"
                      fill="#43A6DD" />
                    <path
                      d="M26 40H22C21.7348 40 21.4804 40.1054 21.2929 40.2929C21.1054 40.4804 21 40.7348 21 41V47H27V41C27 40.7348 26.8946 40.4804 26.7071 40.2929C26.5196 40.1054 26.2652 40 26 40Z"
                      fill="#363636" />
                  </svg> -->
                            <v-list-item-content class=" pt-0">
                                <v-list-item-title class="text-start"
                                    style="color: #000000;font-size: 20px;font-weight:559;line-height:16px;letter-spacing:-1px">
                                    Profit & Loss </v-list-item-title>

                                <v-list-item-subtitle class="text-start pt-1"
                                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Profit
                                    and Loss of your trades.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-spacer></v-spacer>

                    <v-list class=" pt-0" style="background-color: transparent;">
                        <v-list-item class="pl-0 pa-0" style="min-height: 0px;">
                            <!-- <img alt="" class="shrink" :src="require('@/assets/zebu-stocks/savetaxfund.svg')" width="16px" -->
                            <!-- height="16px" /> -->




                        </v-list-item>
                        <!-- <v-list-item-content class="pa-0 pt-1">
                  <v-list-item-title class=" " style="color: #43A833;font-size: 12px;font-weight:559;line-height:16px;">2
                    recommended</v-list-item-title>
                </v-list-item-content> -->
                    </v-list>


                </v-toolbar>
                <v-divider class=" mx-2"></v-divider>
                <v-card color="#eeeeee" outlined class="mx-2 mt-4 d-md-none elevation-0 rounded-lg pa-6">
                    <!-- <div class="text-center pa-4">
                        <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                            Realised P&L</p>
                        <p v-if="disrealised_pnl && disrealised_pnl < 0" class="headline ctnbtltext-center"
                            style="line-height:28px; color: #E12626; ">
                            {{ (disrealised_pnl).toFixed(2) }}
                        </p>
                        <p v-else-if="disrealised_pnl && disrealised_pnl > 0" class="headline ctnbtl text-center"
                            style="line-height:28px; color: #34A853; ">
                            {{ (disrealised_pnl).toFixed(2) }}
                        </p>
                        <p v-else class="headline ctnbtl text-center" style="line-height:28px; color: #505359; ">
                            0
                        </p>
                    </div> -->
                    <v-card outlined class="elevation-0 rounded-lg pa-4 pb-0">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-row no-gutters>
                                    <v-col cols="5">
                                        <p class="caption mb-0" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                            Realised P&L</p>
                                    </v-col>
                                    <v-col cols="7">
                                        <p v-if="disrealised_pnl < 0 && disrealised_pnl"
                                            class="ctnbtl body-1 text-right" style="line-height:28px; color: #E12626; ">
                                            {{ (disrealised_pnl).toFixed(2) }}

                                        </p>
                                        <p v-else-if="disrealised_pnl > 0 && disrealised_pnl"
                                            class="ctnbtl body-1 text-right" style="line-height:28px; color: #34A853; ">
                                            {{ (disrealised_pnl).toFixed(2) }}

                                        </p>
                                        
                                        <p v-else class="ctnbtl body-1 text-right"
                                            style="line-height:28px; color: #505359; ">
                                            0
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row no-gutters @click="chargerdialogeMobile = true">
                                    <v-col cols="6">
                                        <p class="caption" 
                                            style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                            Charges & taxes <v-icon color="#0037B7">mdi mdi-menu-right</v-icon></p>
                                    </v-col>
                                    <v-col cols="6">




                                        <span v-if="segloader" class="d-flex">
                                            <v-progress-linear class="ml-auto" style="width: 30px" indeterminate
                                                color="blue"></v-progress-linear>
                                        </span>
                                        <p v-else-if="segval < 0 && segval" class="ctnbtl body-1 text-right"
                                            style="line-height:28px; color: #E12626; ">


                                            {{ (segval).toFixed(2) }}

                                        </p>
                                        <p v-else-if="segval > 0 && segval" class="ctnbtl body-1 text-right"
                                            style="line-height:28px; color: #34A853; ">
                                            {{ (segval).toFixed(2) }}

                                        </p>
                                        <p v-else class="ctnbtl body-1 text-right"
                                            style="line-height:28px; color: #505359; ">
                                            0
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row no-gutters>
                                    <v-col cols="5">
                                        <p class="caption" style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                           Net Realised P&L</p>
                                    </v-col>
                                    <v-col cols="7">
                                        <p class="mb-0 text-right body-1" v-if=" ( Number(disrealised_pnl) + Number(segval))  < 0"
                                                    style="color: red; font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ (( Number(disrealised_pnl) + Number(segval))).toFixed(2) }} </p>
                                                <p class="mb-0 text-right body-1" v-else-if=" ( Number(disrealised_pnl) + Number(segval)) > 0"
                                                    style="color: green; font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ ( Number(disrealised_pnl) + Number(segval)).toFixed(2) }} </p> 
                                                <p class="mb-0 text-right body-1" v-else 
                                                    style="color: black; font-weight: 469;line-height: 12px;letter-spacing: 0.9px;">
                                                    {{ ( Number(disrealised_pnl) + Number(segval)) }} </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row no-gutters>
                                    <v-col cols="5">
                                        <p class="caption"
                                            style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                            Unrealised P&L</p>
                                    </v-col>
                                    <v-col cols="7">
                                        <p v-if="disunrealised_pnl < 0 && disunrealised_pnl"
                                            class="ctnbtl body-1 text-right" style="line-height:28px; color: #E12626; ">
                                            {{ (disunrealised_pnl).toFixed(2) }}

                                        </p>
                                        <p v-else-if="disunrealised_pnl > 0 && disunrealised_pnl"
                                            class="ctnbtl body-1 text-right" style="line-height:28px; color: #34A853; ">
                                            {{ (disunrealised_pnl).toFixed(2) }}

                                        </p>
                                        <p v-else class="ctnbtl body-1 text-right"
                                            style="line-height:28px; color: #505359; ">
                                            0
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                           
                        </v-row>
                    </v-card>
                </v-card>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheet" inset>

                        <v-sheet class="text-center" height="200px">
                            <!-- <v-btn class="mt-n6" icon text color="error" @click="sheet = !sheet">
                                <v-icon size="40">mdi mdi-close-circle</v-icon>
                            </v-btn> -->
                            <!-- {{ radios }}/ -->
                            <p class="mb-0 pt-3 title font-weight-medium">Summary P&L</p>
                            <v-card class="elevation-0 ma-8 mt-0">
                                <v-radio-group class="mt-0" v-model="radios" mandatory>
                                    <v-radio color="black" label="Download Pdf" value="Download Pdf"></v-radio>
                                    <v-radio color="black" label="Download Excel" value="Download Excel"></v-radio>
                                </v-radio-group>
                                <v-btn @click="downloadsum(radios)" class="white--text elevation-0" color="black" block
                                    rounded flat solo> {{
                                        radios }}</v-btn>
                            </v-card>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheet1" inset>

                        <v-sheet class="text-center" height="200px">
                            <!-- <v-btn class="mt-n6" icon text color="error" @click="sheet1 = !sheet1">
                                <v-icon size="40">mdi mdi-close-circle</v-icon>
                            </v-btn> -->
                            <!-- {{ radios }}/ -->
                            <p class="mb-0 pt-3 title font-weight-medium">Detailed P&L</p>

                            <v-card class="elevation-0 ma-8 mt-0">
                                <v-radio-group class="mt-0" v-model="radios1" mandatory>
                                    <v-radio color="black" label="Download Pdf" value="Download Pdf"></v-radio>
                                    <v-radio color="black" label="Download Excel" value="Download Excel"></v-radio>
                                </v-radio-group>
                                <v-btn @click="downloaddet(radios1)" class="white--text elevation-0" color="black" block
                                    rounded flat solo> {{
                                        radios1 }}</v-btn>
                            </v-card>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
                <v-divider class="mt-4 mb-2 "></v-divider>
                <div class="d-flex row">
                    <p v-if="summary" class="text-start mt-3 mb-3  ml-2 px-4 "
                        style="color: #696969;font-size: 12px;font-weight:500;line-height:16px;">
                        No of symbols
                        <span class=" pa-1 rounded-lg" style=" color: #000000;">({{ PnlDate.length }})</span>
                    </p>
                    <p v-if="!summary" class="text-start mt-3 mb-3  ml-2 px-4 "
                        style="color: #696969;font-size: 12px;font-weight:500;line-height:16px;">
                        No of symbols
                        <span class=" pa-1 rounded-lg" style=" color: #000000;">({{ desserts23.length }})</span>
                    </p>
                    <v-btn v-if="summary" @click="sheet = true" class="ml-auto mr-6 " flat solo icon> <v-icon>mdi
                            mdi-download</v-icon></v-btn>
                    <v-btn v-if="!summary" @click="sheet1 = true" class="ml-auto mr-6 " flat solo icon> <v-icon>mdi
                            mdi-download</v-icon></v-btn>
                </div>
                <v-divider class="mb-2 mt-2"></v-divider>
                <v-row class="px-5 pa-0 mt-3">
                    <v-col class="pa-0 mt-3">
                        <v-select :items="filterr" :disabled="segloader" @change="filterseg(filterdata_unpl)" dense
                            v-model="filterdata_unpl" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                            hide-details class="mr-2 mt-n3 fildrop" style="max-width: 100%; font-size: 21px">
                        </v-select>
                        <!-- <v-select v-if="!summary" :items="filterr" @change="filterseg1(filterdata_unpl1)" dense
                            v-model="filterdata_unpl1" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                            hide-details class="mr-2 mt-n3 fildrop" style="max-width: 100%; font-size: 21px">
                        </v-select> -->
                    </v-col >
                    <v-col class=" pa-0 px-2">
                        <v-text-field v-if="summary" style="width:100%;" class="tophundraedmutual  " height="36px"
                            background-color="#F1F3F8" label="Search" v-model="search" solo rounded flat dense>
                            <template v-slot:prepend-inner>
                                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px"
                                    height="18px" />
                            </template>
                        </v-text-field>
                        <!-- <v-text-field v-if="!summary" style="width:100%;" class="tophundraedmutual  " height="36px"
                            background-color="#F1F3F8" label="Search" v-model="searchde" solo rounded flat dense>
                            <template v-slot:prepend-inner>
                                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px"
                                    height="18px" />
                            </template>
                        </v-text-field> -->
                       
                    </v-col>
                    <v-col cols="12" class="pa-0">
                        <v-menu class="" v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                            offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                    <v-text-field style="width: 98%;" class="mr-2 mt-n2" solo rounded flat
                                        v-model="fromshow" label="From date" dense color="black" background-color="#F1F3F8"
                                        prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-card class="pa-2">
                                    <div>
                                        <v-date-picker range :max="maxval" color="black" v-model="fromdate" @input="datemaxvalue('click')"></v-date-picker>
                                        <!-- <v-date-picker  range color="black" v-model="fromdate" @change="gettradedata()"></v-date-picker> -->
                                    </div>
                                    <p v-if="fromdate != '' && fromdate != [] && highlightyears != 'cur'" class="ml-2 caption mb-1 "><span class="red--text">*</span>Your are in {{Number(maxval.split("-")[0]) - 1}}-{{maxval.split("-")[0]}} Financial year 
                                        <v-tooltip  color="black" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                           <span @click="reset"
                                             class="caption blue--text font-weight-bold"
                                        
                                            style="cursor: pointer"
                                            v-bind="attrs"
                                            v-on="on">
                                            Change
                                           </span>
                                        </template>
                                        <span>Click here to move the date forward for Financial year</span>
                                        </v-tooltip>
                                    </p>

                                    <v-divider></v-divider>
                                    <div class="d-flex mt-n2">
                                        <!-- <p class="mb-0 mx-1 pa-4 body-2 font-weight-black" style="cursor: pointer;" @click="gettradedataextra(7)">Last 7 days</p> -->
                                        <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="gettradedataextra(30)">Last 30 days</p> -->
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyears == 'cur', 'black--text': highlightyears != 'cur'}"  @click="gettradedataextrayear(0,'cur')">Current FY</p>
                                        <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyears == 'ly', 'black--text': highlightyears != 'ly'}"  @click="gettradedataextrayear(-1,'ly')">Last FY</p>

                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyears == yearsarray[0], 'black--text': highlightyears != yearsarray[0]}"  @click="gettradedataextrayear(-2,yearsarray[0])">{{yearsarray[0]}}</p>
                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyears == yearsarray[1], 'black--text': highlightyears != yearsarray[1]}"  @click="gettradedataextrayear(-3,yearsarray[1])">{{yearsarray[1]}}</p>
                                        <p  class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;" :class="{'blue--text': highlightyears == yearsarray[2], 'black--text': highlightyears != yearsarray[2]}"  @click="gettradedataextrayear(-4,yearsarray[2])">{{yearsarray[2]}}</p>
                                        <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" @click="mountedfun(30)">Last </p> -->
                                    </div>
                                </v-card>
                        </v-menu>
                    </v-col>
                </v-row>
                <div class="  mt-n8 ml-6">
                    <p class="  body-2 mb-0 mt-6">
                        <span class="red--text">*</span> Buy rate and Sell rate is inclusive of brokerage
                    </p>
                    <v-checkbox
                    v-model="checkbox"
                    @change="gettradedata()"
                    :label="`With open balance`"
                    ></v-checkbox>
                    
                </div>
                <v-row class="pt-0 mt-n5">
                    <v-col lg="4" sm="4" md="12" cols="12">
                        <!-- <v-tabs v-model="tab" background-color="transparent" color="basil" grow> -->
                        <!-- <v-tab>
                               
                            </v-tab> -->
                        <!-- <v-tab @click="detailed()">
                                Tax P&L
                            </v-tab> -->
                        <!-- </v-tabs> -->

                    </v-col>

                </v-row>
                <!-- {{ loader }} -->
                <v-dialog fullscreen transition="dialog-bottom-transition" v-model="dialogmobile">
                    <v-sheet class="text-center" style="height: 100vh">
                        <v-list-item-content class=" pt-5 pl-5">
                            <v-list-item-title class="text-start"
                                style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">
                                Detailed P&L</v-list-item-title>

                            <v-list-item-subtitle class="text-start pt-1"
                                style="color: #666666;font-size: 11px;font-weight:469;line-height:14px"> {{ scriptname }}
                            </v-list-item-subtitle>
                            <v-btn absolute right top icon color="black" @click="dialogmobile = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-list-item-content>
                        <v-divider></v-divider>

                        <v-card color="#eeeeee" outlined class="d-md-none elevation-0 rounded-lg pa-6 mt-3 px-3"
                            height="90%" style="overflow: scroll">
                            <div class="text-center pa-4">
                                <p class="caption"
                                    style="color: #696969; letter-spacing: 0.16em; text-transform: uppercase; ">
                                    P & L</p>
                                <p v-if="finaltotal < 0" class="ctnbtl headline mb-0"
                                    style="line-height:28px; color: red; ">
                                    {{ (finaltotal).toFixed(2) }}</p>
                                <p v-else-if="finaltotal > 0" class="ctnbtl headline mb-0"
                                    style="line-height:28px; color: green; ">
                                    {{ (finaltotal).toFixed(2) }}</p>
                                <p v-else-if="finaltotal == 0" class="ctnbtl headline mb-0"
                                    style="line-height:28px; color: rgb(0, 0, 0); ">
                                    {{ (finaltotal).toFixed(2) }}</p>
                                <p v-else-if="finaltotal == ''" class="ctnbtl headline mb-0" style="line-height:28px;  ">
                                    -</p>
                            </div>
                            <v-card v-for="item in detailedpoparray.data " :key="item.index"
                                style="border-top: 5px #F1F3F8 solid;" class="elevation-0  px-4" width="100%">
                                <span class="d-flex row mt-3 mb-3 px-4">
                                    <p class="  pb-0 mb-0" style="color: #000000;font-size: 15px;font-weight:459;">{{
                                        item.TRADE_DATE }}
                                    </p>
                                    <p v-if="item.NETAMT > 0" class="mb-0 ml-auto body-2 font-weight-regular"
                                        style="color: green;">
                                        {{ item.NETAMT }}</p>
                                    <p v-if="item.NETAMT < 0" class="mb-0 ml-auto body-2 font-weight-regular"
                                        style="color: red;">{{
                                            item.NETAMT }}</p>
                                    <p v-if="item.NETAMT == 0" class="mb-0 ml-auto body-2 font-weight-regular"
                                        style="color: #000000;">
                                        {{ item.NETAMT }}</p>
                                </span>

                                <v-divider class="mb-2"></v-divider>
                                <v-row class="px-2">
                                    <v-col>
                                        <p class="mb-1 body-2 font-weight-regular text-start" style="color: #666666;">Net
                                            Quantity:
                                            <span class="mb-0 body-2 font-weight-regular text-start"
                                                style="color: #000000;">{{
                                                    item.NETQTY
                                                }}</span>
                                        </p>
                                        <p class="mb-1 body-2 font-weight-regular text-start" style="color: #666666;">Buy
                                            Quantity:
                                            <span class="mb-0 body-2 font-weight-regular text-start"
                                                style="color: #000000;">{{
                                                    item.BQTY
                                                }}</span>
                                        </p>
                                        <p class="mb-3 body-2 font-weight-regular text-start" style="color: #666666;">Buy
                                            Rate :
                                            <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                item.BRATE }}</span>
                                        </p>
                                    </v-col>
                                    <v-col class="text-end">
                                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net
                                            Rate: <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                (item.NRATE).toFixed(2) }}
                                            </span>
                                        </p>
                                        <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sale
                                            Quantity: <span class="mb-0 body-2 font-weight-regular"
                                                style="color: #000000;">{{ item.SQTY }}
                                            </span>
                                        </p>
                                        <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sale Rate :
                                            <span class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                (item.SRATE).toFixed(2) }}
                                            </span>
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-card v-if="loaderpopup"
                                style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                                class="elevation-0  px-4" width="100%">
                                <v-row class="px-2">
                                    <v-col class="mx-auto text-center my-4">
                                        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-card v-if="detailedpoparray.data == 0 && loaderpopup == false"
                                style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                                class="elevation-0  px-4" width="100%">
                                <v-row class="px-2">
                                    <v-col class="mx-auto text-center my-4">
                                        <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                                        <p class="font-weight-bold black--text">No data available</p>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-card>
                    </v-sheet>
                </v-dialog>
                <!-- <v-tabs-items v-model="tab"> -->
                <!-- <v-tab-item> -->
                <v-card v-for="item in filteredDesserts " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                    class="elevation-0  px-4 pt-2" width="100%">

                    <div class="d-flex ">
                        <span @click="detailedpopmobile(item)" class="font-weight-bold">

                            {{ item.SCRIP_SYMBOL }}<v-chip v-if="item.open_QUANTITY" :color="item.open_QUANTITY > 0 ? 'blue' : item.open_QUANTITY < 0 ? 'red' : 'default'" class="ml-2" x-small
                                outlined>{{
                                    item.open_QUANTITY }}  @  {{ Number(item.Open_AMOUNT).toFixed(2)}}</v-chip><v-icon color="#0037B7">mdi
                                mdi-menu-right</v-icon>

                            <!-- {{ item.COMPANY_CODE }} -->
                            <!-- <span class="caption font-weight-medium">({{
                            desserts2[item.SCRIP_SYMBOL].length }})</span> -->
                            </span>

                    </div>
                   

                    <v-divider class="mb-2 mt-2"></v-divider>
                    <v-row class="px-2">
                        <v-col>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">P&L
                            </p>
                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.BUY_QUANTITY
                                    }}</span>
                            </p>
                            <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.BUY_RATE
                                    }}</span>
                            </p>
                        </v-col>
                        <v-col class="text-end">
                            <p v-if="item.NOT_PROFIT > 0" class="mb-0 body-2 font-weight-regular" style="color: green;">{{
                                item.NOT_PROFIT }}</p>
                            <p v-if="item.NOT_PROFIT < 0" class="mb-0 body-2 font-weight-regular" style="color: red;">{{
                                item.NOT_PROFIT }}</p>
                            <p v-if="item.NOT_PROFIT == 0" class="mb-0 body-2 font-weight-regular" style="color: #000000;">
                                {{ item.NOT_PROFIT }}</p>

                            <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Quantity: <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.SALE_QUANTITY }}

                                </span>
                            </p>
                            <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                                    class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                        item.SALE_RATE
                                    }}</span>
                            </p>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card v-if="filteredDesserts.length == 0 && loader == false"
                    style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;" class="elevation-0  px-4"
                    width="100%">
                    <v-row class="px-2">
                        <v-col class="mx-auto text-center my-4">
                            <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                            <p class="font-weight-bold black--text">No data available</p>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                    class="elevation-0  px-4" width="100%">
                    <v-row class="px-2 ">
                        <v-col class="mx-auto text-center my-10">
                            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-card>
                <!-- </v-tab-item> -->
                <!-- <v-tab-item>
                        <v-card v-for="item in filteredDesserts1 " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                            class="elevation-0  px-4" width="100%">

                            <p v-if="item.COMPANY_CODE == 'NSE_FNO'" class="text-start pa-2 pb-0 mb-0"
                                style="color: #000000;font-size: 15px;font-weight:459;">{{ item.SCRIP_SYMBOL }}
                            </p>
                            <p v-else-if="item.COMPANY_CODE == 'BSE_CASH' || item.COMPANY_CODE == 'NSE_CASH'"
                                class="text-start pb-0 pa-2 mb-0" style="color: #000000;font-size: 15px;font-weight:459;">
                                {{ item.SCRIP_SYMBOL.split(' ').slice(1).join(' ') }}
                            </p>
                            <span class="font-weight-regular pt-0 caption text-start pa-2 mb-0"> {{ item.TRADE_DATE
                            }}</span>
                            <v-divider class="mb-2"></v-divider>

                            <v-row class="px-2">
                                <v-col>
                                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net Amount:
                                    </p>
                                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.BQTY
                                            }}</span>
                                    </p>
                                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                (item.BRATE.toFixed(2)) }}</span>
                                    </p>


                                </v-col>
                                <v-col class="text-end">
                                    <p v-if="item.NETAMT > 0" class="mb-0 body-2 font-weight-regular" style="color: green;">
                                        {{ item.NETAMT }}</p>
                                    <p v-if="item.NETAMT < 0" class="mb-0 body-2 font-weight-regular" style="color: red;">{{
                                        item.NETAMT }}</p>
                                    <p v-if="item.NETAMT == 0" class="mb-0 body-2 font-weight-regular"
                                        style="color: #000000;">
                                        {{ item.NETAMT }}</p>

                                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Qty: <span
                                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.SQTY }}

                                        </span>
                                    </p>
                                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                (item.SRATE).toFixed(2) }}
                                        </span>
                                    </p>


                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card v-if="filteredDesserts1.length == 0 && loader == false"
                            style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                            class="elevation-0  px-4" width="100%">
                            <v-row class="px-2">
                                <v-col class="mx-auto text-center my-4">
                                    <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                                    <p class="font-weight-bold black--text">No data available</p>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                            class="elevation-0  px-4" width="100%">
                            <v-row class="px-2">
                                <v-col class="mx-auto text-center my-4">
                                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items> -->


            </div>
        </v-container>
    </v-app>
</template>
  
<script>
// import CalendarProfitlose from '@/views/heatmap calendars/CalendarProfitlose.vue';
import { apiurl } from '../../../Api.js'
// import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable"
import zebupdfImage from '../../../assets/zebupdf.png';

import ExcelJS from 'exceljs';
import imagePath from '@/assets/zebulogo.svg';
export default {
    data() {
        return {
            maxval : '',
            minval : '',
            dialogmobile: false,
            segval: 0,
            summary: true,
            radios: null,
            radios1: null,
            inject: {
                theme: {
                    default: { isDark: false },
                },
            },
            itemss: [

            ],
            checkbox: true,
            search: '',
            searchde: '',
            expanded: [],
            more: true,
            highlightyears : 'cur',
            less: false, more1: true,
            less1: false,
            itemperpage: 8,
            msg: '',
            resetrange : '',

            alert: false,

            sheet: false,
            sheet1: false,
            itemperpage1: 8,
            loader: true,
            headerval: [],
            dialog: false,
            detailedpoparray: [],

            yearindex: "",
            search1: '',

            itemperpage1inner: 0,
            // tab: null,
            // yearfromdown: ["2018", "2019", "2020", "2021", "2022", "2023"],
            desserts: [],
            PnlDate: [],
            scriptname: '',
            dessertspop: [],
            dessertspopexpense: [],
            desserts2: [],
            sortBy: 'SCRIP_NAME',
            offset: '',
            segfrom: '',
            dates: [""],
            filterr: [
                "All", "Equities", "Future & Options", "Commodities", "Currencies"
            ],
            filterdata_unpl: 'All',
            filterdata_unpl1: 'All',
            itemsdwl: [
                {
                    title: 'Download Excel',
                },
                {
                    title: 'Download Pdf',
                },
            ],
            show: false,
            Overviewheaders2: [
                { text: "Trade Date", value: "TRADE_DATE", width: "10%", class: "headerfont" },

                // { text: "Symbol", value: "SCRIP_NAME", width: "20%", class: "headerfont" },


                { text: 'Buy Qty', value: 'BQTY', align: "end", class: "headerfont", width: "10%" },
                { text: 'Buy Rate', value: 'BRATE', align: "end", class: "headerfont", width: "10%" },
                // { text: 'Buy Amt', value: 'BAMT', align: "end" },
                { text: 'Sell Qty', value: 'SQTY', align: "end", class: "headerfont", width: "10%" },
                { text: 'Sell Rate', value: 'SRATE', align: "end", class: "headerfont", width: "10%" },
                // { text: 'Sale Amt', value: 'SAMT', align: "end" },
                { text: 'Net Qty', value: 'NETQTY', align: "end", class: "headerfont", width: "10%" },
                { text: 'Net Rate', value: 'NRATE', align: "end", class: "headerfont", width: "10%" },
                // { text: 'NET Amt', value: 'NETAMT', align: "end" },
                // { text: 'Closing Price', value: 'CLSPR', align: "end", class: "headerfont" },
                { text: 'P & L', value: 'NETAMT', align: "end", class: "headerfont", width: "10%" },


            ],
            finaltotal: 0,

            Overviewheaders: [
                { text: "Symbol", value: "SCRIP_SYMBOL", class: "headerfont" },
                // { text: "com", value: "Sort_No", class: "headerfont", },
                // { text: "comcode", value: "company_code", class: "headerfont", },
                // { text: '', value: 'data-table-expand', class: "headerfont" },
                // { text: "Trading Quantity", value: "TRADING_QUANTITY" , align: 'end'},
                // { text: "Trading Amt", value: "TRADING_AMOUNT" , align: 'end'},
                { text: "Buy Qty", value: "BUY_QUANTITY", align: "end", class: "headerfont" , sortable: false },
                { text: "Buy Rate", value: "BUY_RATE", align: "end", class: "headerfont" , sortable: false },
                // { text: "BAmt", value: "BUY_AMOUNT", align: "end", class: "headerfont" },
                { text: "Sell Qty", value: "SALE_QUANTITY", align: "end", class: "headerfont" , sortable: false },
                { text: "Sell Rate", value: "SALE_RATE", align: "end", class: "headerfont"  , sortable: false},
                // { text: "SAmt", value: "SALE_AMOUNT", align: "end", class: "headerfont" },
                { text: "Net Qty", value: "NET_QUANTITY", align: "end", class: "headerfont" , sortable: false },
                { text: "Net Rate", value: "NET_RATE", align: "end", class: "headerfont" },
                { text: "Close price", value: "CLOSING_PRICE", align: "end", class: "headerfont" , sortable: false },
                // { text: "NAmt", value: "NET_AMOUNT", align: "end", class: "headerfont" },
                // { text: "Closing Price", value: "CLOSING_PRICE", align: "end", class: "headerfont" },
                { text: "P & L", value: "NOT_PROFIT", align: "end", class: "headerfont" , sortable: false },
            ],
            tab: null,
            items: ['Summary', 'Detailed',],
            desserts23: [],
            sortKey: 'Sort_No',
            detpop: [],
            headersforchargesandtaxes: [
                {
                text: 'Script symbol',
                align: 'start',
                // sortable: false,
                value: 'SCRIP_SYMBOL',
                },
                { text: 'Net amount', value: 'NET_AMOUNT' },
                // { text: 'Fat (g)', value: 'fat' },
                // { text: 'Carbs (g)', value: 'carbs' },
                // { text: 'Protein (g)', value: 'protein' },
                // { text: 'Iron (%)', value: 'iron' },
            ],
            actid: '',
            susertoken: '',
            yearsarray : [],
            loaderpopup: false,
            chargerdialoge: false,
            chargerdialogeMobile : false,
            keyyy: '',
            downsum: true,
            disrealised_pnl: '',
            disunrealised_pnl: '',
            year: '',
            dessertstaxdis: [],
            menu2: false,
            menu23: false,
            todate: [],
            fromdate: [],
            segloader: false,
            profiledetails : [],
            cname : '',
            maxvalforfrom : '',
            displayedcon: [],
            fromshow: '',
            sortOrder: 1, // 1 for ascending, -1 for descending

        };
    },
    // components: { CalendarProfitlose },
    computed: {
        sortedItems() {
            return this.PnlDate 
        },
        dateRangeText() {
            return this.dates.join(" ~ ");
        },
        filteredDesserts() {
            const searchTerm = this.search.toLowerCase();
            return this.sortedItems.filter(item => item.SCRIP_SYMBOL.toLowerCase().includes(searchTerm));
        },
        filteredDesserts1() {
            const searchTerm = this.searchde.toLowerCase();
            return this.desserts23.filter(item => item.SCRIP_SYMBOL.toLowerCase().includes(searchTerm));
        },
    },
    created() {
        // Perform default sort on component creation
        this.performDefaultSort();
    },
    methods: {
        reset(){
            this.maxval = this.resetrange
            this.fromdate = []
            this.highlightyears = ''
        },
        datemaxvalue(item){
            let valuefordate = this.fromdate
            // console.log(valuefordate.length);
        //    if (this.fromdate.length < 1) {
        //         this.fromdate = valuefordate
        //         console.log(this.fromdate.length,this.fromdate)

        //    }else{
        //         this.fromdate = []
        //         this.fromdate = valuefordate
            // console.log(valuefordate[0].split("-")[0])

        //    }
            
            // this.maxval = `${Number(valuefordate.split("-")[0]) + 1}-03-31`
            // console.log(valuefordate,this.maxval,typeof(Number(valuefordate.split("-")[0])))
            // console.log(valuefordate[0])
            // console.log(Number(valuefordate[1]))
            // if (condition) {
                
            // }
            if (Number(valuefordate[0].split("-")[1]) > 3) {
                this.maxval = `${Number(valuefordate[0].split("-")[0]) + 1}-03-31`
                // console.log(this.maxval,"huhu");
                // if (item != 'mounted') {
                //     this.todate = `${Number(valuefordate.split("-")[0]) + 1}-03-31`
                // }
                // console.log(this.maxval,"1")
            }
            else{
                this.maxval = `${Number(valuefordate[0].split("-")[0])}-03-31`
            // console.log(this.maxval,"2")
                // console.log(this.maxval,"huhu");

                // if (item != 'mounted') {
                //         this.todate = `${Number(valuefordate.split("-")[0])}-03-31`
                // }
            }

            if (item == 'click' && valuefordate.length > 1) {
                this.gettradedata()
                this.highlightyears = ''
            }
        },
        decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      // // console.log('Decrypted Data:', decryptedData);
      return decryptedData;
        },

        performDefaultSort() {
            this.sortItems();
        },
        sortItems() {
            this.PnlDate.sort((a, b) => {
                const modifier = this.sortOrder;
                return modifier * (a[this.sortKey] > b[this.sortKey] ? 1 : -1);
            });
        },
        gettradedata() {
            this.menu2 = false
            this.menu23 = false
            this.disrealised_pnl = 0
            this.disunrealised_pnl = 0
            this.disrealised_pnl = 0
            this.segval = 0
            this.headerval.expense_amt = 0
            //   this.eqloader = true
            //   this.dessertsexpand = []
            //   this.dessertsexpandderivative = []
            //   this.dessertsexpandcommodity = []
            //   this.dessertsexpandcurrency = []

            this.disabletabs = true
            this.PnlDate = []
            this.filterdata_unpl1 = "All"
            this.filterdata_unpl = "All"
            // if (this.fromdate != '' && this.todate != '') {
                this.loader = true
                var fromdate = ''
                var todate = ''
                let dateString = this.fromdate[0]
                let dateObject = new Date(dateString);
                let year = dateObject.getFullYear();
                let month = dateObject.getMonth() + 1; // Months are 0-indexed, so add 1
                let day = dateObject.getDate();
                let formattedDate = (day < 10 ? '0' : '') + day + '/' + (month < 10 ? '0' : '') + month + '/' + year;
                fromdate = formattedDate
                this.fromshow = fromdate
                this.segfrom = fromdate
                this.fromdateapi = fromdate
                let dateString1 = this.fromdate[1]
                let dateObject1 = new Date(dateString1);
                let year1 = dateObject1.getFullYear();
                let month1 = dateObject1.getMonth() + 1; // Months are 0-indexed, so add 1
                let day1 = dateObject1.getDate();
                let formattedDate1 = (day1 < 10 ? '0' : '') + day1 + '/' + (month1 < 10 ? '0' : '') + month1 + '/' + year1;
                todate = formattedDate1
                this.toshow = todate
                this.fromshow = this.fromshow + '_' + 'to' + '_' + this.toshow
                // this.equitiesdata()
                // this.derivativesdat()
                if (this.checkbox == true) {
                     var chkbox = 'Y'
                }else{
                    chkbox = 'N'
                }
                const axios = require("axios");
                let data = JSON.stringify({
                    "cc": this.actid,
                    "from": fromdate,
                    "to": todate,
                    "withopen" : chkbox
                });

                let config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: `${apiurl}/getpnl`,

                    headers: {
                        'Content-Type': 'application/json',
                        'clientid': this.actid,
                        'Authorization': this.susertoken
                    },
                    data: data,
                };
                let axiosthis = this;
                axios.request(config)
                    .then((response) => {
                        let res = response.data
                        if (res.msg != 'No Data Available') {
                            axiosthis.loader = false;
                    axiosthis.loader = false;

                    axiosthis.desserts = response.data["transactions"];
                    axiosthis.PnlDate = axiosthis.desserts

                    // console.log(axiosthis.desserts,'kjkjkj');
                    axiosthis.dessertspop = response.data["transactions"];
                    axiosthis.dessertsexpense = response.data["expenses"];
                    axiosthis.dessertspopexpense = response.data["expenses"];
                    var headval = response.data

                    axiosthis.disrealised_pnl = axiosthis.headerval.realised_pnl
                    axiosthis.disunrealised_pnl = axiosthis.headerval.unrealised_pnl
                    axiosthis.headervalpop = response.data;
                    // console.log(axiosthis.headervalpop.realised_pnl, "kjkjkj");
                    // console.log(axiosthis.desserts, 'lklklklk');
                    axiosthis.headerval = headval
                    axiosthis.disunrealised_pnl = axiosthis.headerval.unrealised_pnl
                    axiosthis.disrealised_pnl = axiosthis.headerval.realised_pnl
                    axiosthis.segval = axiosthis.headerval.expense_amt
                    axiosthis.itemperpage = axiosthis.desserts.length
                            // let data1 = JSON.stringify({
                            //     "cc": this.actid,

                            // });

                            // let config1 = {
                            //     method: 'post',
                            //     maxBodyLength: Infinity,
                            //     url: `${apiurl}/getpnlsummary`,
                            //     headers: {
                            //         'Content-Type': 'application/json',
                            //         'clientid': this.actid,
                            //         'Authorization': this.susertoken
                            //     },
                            //     data: data1
                            // };
                            // // var axiosthis = this
                            // axios.request(config1)
                            //     .then((response) => {
                            //         axiosthis.headerval = headval

                            //         axiosthis.disrealised_pnl = axiosthis.headerval.realised_pnl
                            //         axiosthis.disunrealised_pnl = axiosthis.headerval.unrealised_pnl
                            //         axiosthis.segval = axiosthis.headerval.expense_amt

                            //         //// // //console.log(JSON.stringify(response.data));
                            //         // // // //console.log(response.data, typeof response.data,'');
                            //         axiosthis.desserts2 = response.data
                            //         for (const [key, value] of Object.entries(axiosthis.desserts2)) {
                            //             // // // //console.log(key, value, "axiosthis..");
                            //             for (let i = 0; i < value.length; i++) {
                            //                 axiosthis.desserts23.push(value[i])
                            //                 this.keyyy = key
                            //             }
                            //         }
                            //         // console.log(axiosthis.desserts, 'lklklklk');

                            //         for (let i = 0; i < axiosthis.desserts23.length; i++) {
                            //             let smdata = axiosthis.desserts23[i].SCRIP_SYMBOL;
                            //             for (let y = 0; y < axiosthis.desserts.length; y++) {
                            //                 let famItem = axiosthis.desserts[y]['SCRIP_SYMBOL'];
                            //                 if (famItem && smdata === famItem) {
                            //                     // console.log('found');
                            //                     if (!axiosthis.desserts[y].detailed) {
                            //                         axiosthis.desserts[y].detailed = [];
                            //                     }
                            //                     axiosthis.desserts[y].detailed.push(axiosthis.desserts23[i]);
                            //                 }
                            //             }
                            //         }
                            //         axiosthis.PnlDate = axiosthis.desserts
                            //         if (axiosthis.PnlDate != []) {
                            //             axiosthis.loader = false;
                            //         }

                            //         // console.log(axiosthis.desserts, 'lklklklk');
                            //         axiosthis.itemperpage1 = axiosthis.desserts23.length
                            //         axiosthis.detpop = axiosthis.desserts23
                            //         axiosthis.headerval = headval
                            //         axiosthis.disunrealised_pnl = axiosthis.headerval.unrealised_pnl
                            //         axiosthis.disrealised_pnl = axiosthis.headerval.realised_pnl
                            //     })
                            //     .catch((error) => {
                            //         if (error.message == 'Network Error') {
                            //             this.msg = error.message
                            //             axiosthis.loader = false
                            //             axiosthis.alert = true
                            //             setTimeout(() => {
                            //                 axiosthis.alert = false
                            //             }, 5000);
                            //         }
                            //     });
                        } else {
                            axiosthis.loader = false;
                        }
                        // //// // //console.log("axiosthis.desserts", axiosthis.desserts);
                    })
                    .catch((error) => {
                        // // //console.log(error);
                        if (error.message == 'Network Error') {
                            this.msg = error.message
                            axiosthis.loader = false
                            axiosthis.alert = true
                            setTimeout(() => {
                                axiosthis.alert = false
                            }, 5000);
                            // alert(error.message)
                        }
                    });
                // const axios = require('axios');

                // this.equitiesdata()
                // this.derivativesdat()

            // }
        },
        gettradedataextra(valuevalue) {
                this.menu2 = false
                this.menu23 = false
                this.disrealised_pnl = 0
                this.disunrealised_pnl = 0
                this.disrealised_pnl = 0
                this.segval = 0
                this.headerval.expense_amt = 0
                //   this.eqloader = true
                //   this.dessertsexpand = []
                //   this.dessertsexpandderivative = []
                //   this.dessertsexpandcommodity = []
                //   this.dessertsexpandcurrency = []

                this.disabletabs = true
                this.PnlDate = []
                this.filterdata_unpl1 = "All"
                this.filterdata_unpl = "All"
                let valuedate = valuevalue
                // if (this.fromdate != '' && this.todate != '') {
                this.loader = true
                var fromdate = ''
                var todate = ''
                // let dateString = this.fromdate
                var today = new Date();
                let sevthdate = new Date(today);
                sevthdate.setDate(today.getDate() - valuedate);
                let ddsevthdate =  String(sevthdate.getDate()).padStart(2, '0');
                let mmsevthdate = String(sevthdate.getMonth() + 1).padStart(2, '0')
                let yyyysevthdate = sevthdate.getFullYear();
                fromdate = ddsevthdate + "/" + mmsevthdate + "/" + yyyysevthdate;
                this.fromdate = yyyysevthdate + '-' + mmsevthdate + '-' + ddsevthdate;
                this.fromshow = fromdate
                this.segfrom = fromdate
                this.fromdateapi = fromdate
                // let dateString1 = this.todate
                var dateObject1 = new Date();
                
                let year1 = dateObject1.getFullYear();
                let month1 = dateObject1.getMonth() + 1; // Months are 0-indexed, so add 1
                let day1 = dateObject1.getDate();
                let formattedDate1 = (day1 < 10 ? '0' : '') + day1 + '/' + (month1 < 10 ? '0' : '') + month1 + '/' + year1;
                this.todate = year1 + '-' + (month1 < 10 ? '0' : '') + month1 + '-' + (day1 < 10 ? '0' : '')
                todate = formattedDate1
                this.toshow = todate
                this.fromshow = this.fromshow + '_' + 'to' + '_' + this.toshow
                // this.equitiesdata()
                // this.derivativesdat()
                if (this.checkbox == true) {
                     var chkbox = 'Y'
                }else{
                    chkbox = 'N'
                }
                const axios = require("axios");
                let data = JSON.stringify({
                    "cc": this.actid,
                    "from": fromdate,
                    "to": todate,
                    "withopen" : chkbox
                });

                let config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: `${apiurl}/getpnl`,

                    headers: {
                        'Content-Type': 'application/json',
                        'clientid': this.actid,
                        'Authorization': this.susertoken
                    },
                    data: data,
                };
                let axiosthis = this;
                axios.request(config)
                    .then((response) => {
                        let res = response.data
                        if (res.msg != 'No Data Available') {
                            axiosthis.loader = false;
                    axiosthis.loader = false;

                    axiosthis.desserts = response.data["transactions"];
                    axiosthis.PnlDate = axiosthis.desserts

                    // console.log(axiosthis.desserts,'kjkjkj');
                    axiosthis.dessertspop = response.data["transactions"];
                    axiosthis.dessertsexpense = response.data["expenses"];
                    axiosthis.dessertspopexpense = response.data["expenses"];
                    var headval = response.data

                    axiosthis.disrealised_pnl = axiosthis.headerval.realised_pnl
                    axiosthis.disunrealised_pnl = axiosthis.headerval.unrealised_pnl
                    axiosthis.headervalpop = response.data;
                    // console.log(axiosthis.headervalpop.realised_pnl, "kjkjkj");
                    // console.log(axiosthis.desserts, 'lklklklk');
                    axiosthis.headerval = headval
                    axiosthis.disunrealised_pnl = axiosthis.headerval.unrealised_pnl
                    axiosthis.disrealised_pnl = axiosthis.headerval.realised_pnl
                    axiosthis.segval = axiosthis.headerval.expense_amt
                    axiosthis.itemperpage = axiosthis.desserts.length
                            // let data1 = JSON.stringify({
                            //     "cc": this.actid,

                            // });

                            // let config1 = {
                            //     method: 'post',
                            //     maxBodyLength: Infinity,
                            //     url: `${apiurl}/getpnlsummary`,
                            //     headers: {
                            //         'Content-Type': 'application/json',
                            //         'clientid': this.actid,
                            //         'Authorization': this.susertoken
                            //     },
                            //     data: data1
                            // };
                            // // var axiosthis = this
                            // axios.request(config1)
                            //     .then((response) => {
                            //         axiosthis.headerval = headval

                            //         axiosthis.disrealised_pnl = axiosthis.headerval.realised_pnl
                            //         axiosthis.disunrealised_pnl = axiosthis.headerval.unrealised_pnl
                            //         axiosthis.segval = axiosthis.headerval.expense_amt

                            //         //// // //console.log(JSON.stringify(response.data));
                            //         // // // //console.log(response.data, typeof response.data,'');
                            //         axiosthis.desserts2 = response.data
                            //         for (const [key, value] of Object.entries(axiosthis.desserts2)) {
                            //             // // // //console.log(key, value, "axiosthis..");
                            //             for (let i = 0; i < value.length; i++) {
                            //                 axiosthis.desserts23.push(value[i])
                            //                 this.keyyy = key
                            //             }
                            //         }
                            //         // console.log(axiosthis.desserts, 'lklklklk');

                            //         for (let i = 0; i < axiosthis.desserts23.length; i++) {
                            //             let smdata = axiosthis.desserts23[i].SCRIP_SYMBOL;
                            //             for (let y = 0; y < axiosthis.desserts.length; y++) {
                            //                 let famItem = axiosthis.desserts[y]['SCRIP_SYMBOL'];
                            //                 if (famItem && smdata === famItem) {
                            //                     // console.log('found');
                            //                     if (!axiosthis.desserts[y].detailed) {
                            //                         axiosthis.desserts[y].detailed = [];
                            //                     }
                            //                     axiosthis.desserts[y].detailed.push(axiosthis.desserts23[i]);
                            //                 }
                            //             }
                            //         }
                            //         axiosthis.PnlDate = axiosthis.desserts
                            //         if (axiosthis.PnlDate != []) {
                            //             axiosthis.loader = false;
                            //         }

                            //         // console.log(axiosthis.desserts, 'lklklklk');
                            //         axiosthis.itemperpage1 = axiosthis.desserts23.length
                            //         axiosthis.detpop = axiosthis.desserts23
                            //         axiosthis.headerval = headval
                            //         axiosthis.disunrealised_pnl = axiosthis.headerval.unrealised_pnl
                            //         axiosthis.disrealised_pnl = axiosthis.headerval.realised_pnl
                            //     })
                            //     .catch((error) => {
                            //         if (error.message == 'Network Error') {
                            //             this.msg = error.message
                            //             axiosthis.loader = false
                            //             axiosthis.alert = true
                            //             setTimeout(() => {
                            //                 axiosthis.alert = false
                            //             }, 5000);
                            //         }
                            //     });
                        } else {
                            axiosthis.loader = false;
                        }
                        // //// // //console.log("axiosthis.desserts", axiosthis.desserts);
                    })
                    .catch((error) => {
                        // // //console.log(error);
                        if (error.message == 'Network Error') {
                            this.msg = error.message
                            axiosthis.loader = false
                            axiosthis.alert = true
                            setTimeout(() => {
                                axiosthis.alert = false
                            }, 5000);
                            // alert(error.message)
                        }
                    });
                // const axios = require('axios');

                // this.equitiesdata()
                // this.derivativesdat()

            // }
        },
        gettradedataextrayear(valuevalue,item) {
            this.highlightyears = item
                this.menu2 = false
                this.menu23 = false
                this.disrealised_pnl = 0
                this.disunrealised_pnl = 0
                this.disrealised_pnl = 0
                this.segval = 0
                this.headerval.expense_amt = 0
                //   this.eqloader = true
                //   this.dessertsexpand = []
                //   this.dessertsexpandderivative = []
                //   this.dessertsexpandcommodity = []
                //   this.dessertsexpandcurrency = []

                this.disabletabs = true
                this.PnlDate = []
                this.filterdata_unpl1 = "All"
                this.filterdata_unpl = "All"
                let valuedate = valuevalue
                // if (this.fromdate != '' && this.todate != '') {
                this.loader = true
                // var fromdate = ''
                // var todate = ''
                
                // let dateString = this.fromdate
                this.menu23 = false
                // var valuedate = val
                this.menu2 = false
                this.desserts = []
                var today = new Date();
                // this.fromdate = yyyysevthdate + '-' + mmsevthdate + '-' + ddsevthdate;
                // var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                // var mm = 5
                var yyyy = today.getFullYear();
                // var yyyy = 2022
                    // if (mm < 4 ) {
                    //     var yearmount = yyyy
                    // } else {
                    //     yearmount = yyyy - 1
                    // }
                    // var year = yearis
                    // var yearnext = yearmount + 1

                if (valuedate == 0 ) {
                    if (mm < 4) {
                        var yearmount = yyyy - 1
                        var yearnext = yyyy
                    }else {
                        yearmount = yyyy 
                        yearnext = yyyy + 1
                    }
                }

                if (valuedate == -1) {
                    if (mm < 4) {
                    yearmount = yyyy - 2
                    yearnext = yyyy - 1
                    
                    }else{
                    yearmount = yyyy - 1
                    yearnext = yyyy 
                    }
                }
                if (valuedate == -2) {
                    if (mm < 4) {
                    yearmount = yyyy - 3
                    yearnext = yyyy - 2
                    
                    }else{
                    yearmount = yyyy - 2
                    yearnext = yyyy - 1
                    }
                }
                if (valuedate == -3) {
                    if (mm < 4) {
                    yearmount = yyyy - 4
                    yearnext = yyyy - 3
                    
                    }else{
                    yearmount = yyyy - 3
                    yearnext = yyyy - 2
                    }
                }
                if (valuedate == -4) {
                    if (mm < 4) {
                    yearmount = yyyy - 5
                    yearnext = yyyy - 4
                    
                    }else{
                    yearmount = yyyy - 4
                    yearnext = yyyy - 3
                    }
                }
                // var todayapi = dd + '/' + mm + '/' + yyyy;
                var today1 = '31/03/' + yearnext
                // var fromapi = '01' + '/' + mm + '/' + yyyy;
                // var fromapi = seventhdate;
                // this.maxval = yearmount+'-'+mm+'-'+dd
                // let ydd = String(today.getDate() - 1);
                // let ymm = String(today.getDate()).padStart(2, '0');
                // let yeyy = String(today.getMonth() + 1).padStart(2, '0'); 
                // this.firstmax = yearmount + '-' + ymm + '-' + ydd
                // this.fromshow = '01/04/' + yearmount
                // this.fromshow = seventhdate
                this.fromdate = []
                this.fromdate.push(yearmount + '-' + '04' + '-' + '01');
                // console.log(this.fromdate);
                // var  =yyyy  + '/' + dd + '/' + mm
                this.susertoken = localStorage.getItem("usession");
                this.actid = localStorage.getItem("userid");
                this.loader = true
                this.todate = yearnext + '-03-31'
                // console.log(this.fromdate,"kokokokokokok");
                this.fromdate.push( this.todate)
                this.toshow = today1
                this.fromshow = this.fromshow + '_to_' + this.toshow
                this.datemaxvalue()
                // this.equitiesdata()
                // this.derivativesdat()
                if (this.checkbox == true) {
                     var chkbox = 'Y'
                }else{
                    chkbox = 'N'
                }
                const axios = require("axios");
                let data = JSON.stringify({
                    "cc": this.actid,
                    "from": '01/04' + '/' + yearmount,
                    "to": '31/03' + '/' + yearnext,
                    "withopen" : chkbox
                });

                let config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: `${apiurl}/getpnl`,

                    headers: {
                        'Content-Type': 'application/json',
                        'clientid': this.actid,
                        'Authorization': this.susertoken
                    },
                    data: data,
                };
                let axiosthis = this;
                axios.request(config)
                    .then((response) => {
                        let res = response.data
                        if (res.msg != 'No Data Available') {
                            axiosthis.loader = false;
                    axiosthis.loader = false;

                    axiosthis.desserts = response.data["transactions"];
                    axiosthis.PnlDate = axiosthis.desserts

                    // console.log(axiosthis.desserts,'kjkjkj');
                    axiosthis.dessertspop = response.data["transactions"];
                    axiosthis.dessertsexpense = response.data["expenses"];
                    axiosthis.dessertspopexpense = response.data["expenses"];
                    var headval = response.data

                    axiosthis.disrealised_pnl = axiosthis.headerval.realised_pnl
                    axiosthis.disunrealised_pnl = axiosthis.headerval.unrealised_pnl
                    axiosthis.headervalpop = response.data;
                    // console.log(axiosthis.headervalpop.realised_pnl, "kjkjkj");
                    // console.log(axiosthis.desserts, 'lklklklk');
                    axiosthis.headerval = headval
                    axiosthis.disunrealised_pnl = axiosthis.headerval.unrealised_pnl
                    axiosthis.disrealised_pnl = axiosthis.headerval.realised_pnl
                    axiosthis.segval = axiosthis.headerval.expense_amt
                    axiosthis.itemperpage = axiosthis.desserts.length
                            // let data1 = JSON.stringify({
                            //     "cc": this.actid,

                            // });

                            // let config1 = {
                            //     method: 'post',
                            //     maxBodyLength: Infinity,
                            //     url: `${apiurl}/getpnlsummary`,
                            //     headers: {
                            //         'Content-Type': 'application/json',
                            //         'clientid': this.actid,
                            //         'Authorization': this.susertoken
                            //     },
                            //     data: data1
                            // };
                            // // var axiosthis = this
                            // axios.request(config1)
                            //     .then((response) => {
                            //         axiosthis.headerval = headval

                            //         axiosthis.disrealised_pnl = axiosthis.headerval.realised_pnl
                            //         axiosthis.disunrealised_pnl = axiosthis.headerval.unrealised_pnl
                            //         axiosthis.segval = axiosthis.headerval.expense_amt

                            //         //// // //console.log(JSON.stringify(response.data));
                            //         // // // //console.log(response.data, typeof response.data,'');
                            //         axiosthis.desserts2 = response.data
                            //         for (const [key, value] of Object.entries(axiosthis.desserts2)) {
                            //             // // // //console.log(key, value, "axiosthis..");
                            //             for (let i = 0; i < value.length; i++) {
                            //                 axiosthis.desserts23.push(value[i])
                            //                 this.keyyy = key
                            //             }
                            //         }
                            //         // console.log(axiosthis.desserts, 'lklklklk');

                            //         for (let i = 0; i < axiosthis.desserts23.length; i++) {
                            //             let smdata = axiosthis.desserts23[i].SCRIP_SYMBOL;
                            //             for (let y = 0; y < axiosthis.desserts.length; y++) {
                            //                 let famItem = axiosthis.desserts[y]['SCRIP_SYMBOL'];
                            //                 if (famItem && smdata === famItem) {
                            //                     // console.log('found');
                            //                     if (!axiosthis.desserts[y].detailed) {
                            //                         axiosthis.desserts[y].detailed = [];
                            //                     }
                            //                     axiosthis.desserts[y].detailed.push(axiosthis.desserts23[i]);
                            //                 }
                            //             }
                            //         }
                            //         axiosthis.PnlDate = axiosthis.desserts
                            //         if (axiosthis.PnlDate != []) {
                            //             axiosthis.loader = false;
                            //         }

                            //         // console.log(axiosthis.desserts, 'lklklklk');
                            //         axiosthis.itemperpage1 = axiosthis.desserts23.length
                            //         axiosthis.detpop = axiosthis.desserts23
                            //         axiosthis.headerval = headval
                            //         axiosthis.disunrealised_pnl = axiosthis.headerval.unrealised_pnl
                            //         axiosthis.disrealised_pnl = axiosthis.headerval.realised_pnl
                            //     })
                            //     .catch((error) => {
                            //         if (error.message == 'Network Error') {
                            //             this.msg = error.message
                            //             axiosthis.loader = false
                            //             axiosthis.alert = true
                            //             setTimeout(() => {
                            //                 axiosthis.alert = false
                            //             }, 5000);
                            //         }
                            //     });
                        } else {
                            axiosthis.loader = false;
                        }
                        // //// // //console.log("axiosthis.desserts", axiosthis.desserts);
                    })
                    .catch((error) => {
                        // // //console.log(error);
                        if (error.message == 'Network Error') {
                            this.msg = error.message
                            axiosthis.loader = false
                            axiosthis.alert = true
                            setTimeout(() => {
                                axiosthis.alert = false
                            }, 5000);
                            // alert(error.message)
                        }
                    });
                // const axios = require('axios');

                // this.equitiesdata()
                // this.derivativesdat()

            // }
        },
        detailedpop(item) {
            // console.log(item);
            // alert("dfd")
            this.search = ''
            let detailedarr = item
            this.loaderpopup = true
            this.detailedpoparray = {}

            this.finaltotal = 0
            this.detailedpoparray['syname'] = item.SCRIP_SYMBOL
            // console.log(this.detailedpoparray[0]);
            this.scriptname = item.SCRIP_SYMBOL

            this.dialog = true
            const axios = require('axios');
            let data = JSON.stringify({
                "cc": this.actid,
                "from": this.segfrom,
                "to": this.toshow,
                "script": detailedarr.SCRIP_SYMBOL,
                "cocd": detailedarr.company_code
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                // url: 'http://192.168.5.198:5050/getpnlscriptdetail',
                url: `${apiurl}/getpnlscriptdetail`,

                headers: {
                    'Content-Type': 'application/json',
                    'clientid': this.actid,
                    'Authorization': this.susertoken
                },
                data: data
            };
            let axiosthis = this
            axios.request(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    let popval = response.data
                    // console.log(popval.msg == 'No Data Available');
                    // console.log(popval);
                    if (popval.msg != 'No Data Available') {
                        if (axiosthis.detailedpoparray['syname'] == item.SCRIP_SYMBOL) {
                            axiosthis.loaderpopup = false
                            axiosthis.detailedpoparray['data'] = popval

                            for (let i = 0; i < axiosthis.detailedpoparray.data.length; i++) {
                                axiosthis.finaltotal += axiosthis.detailedpoparray.data[i].NETAMT

                            }
                        }
                        axiosthis.itemperpage1inner = axiosthis.detailedpoparray.data.length

                    } else {
                        axiosthis.loaderpopup = false
                        // axiosthis.detailedpoparray = []
                        // alert("khk")
                        // axiosthis.loaderpopup = false
                        // axiosthis.dialog = false

                        // axiosthis.alert = true
                        // axiosthis.msg = "No data Found"

                        // setTimeout(() => {
                        // axiosthis.alert = false
                        // axiosthis.msg = ""
                        // }, 3000);
                    }


                    // console.log(axiosthis.detailedpoparray);
                })
                .catch((error) => {
                    console.log(error);
                });

            // console.log(item);


        },
        detailedpopmobile(item) {
            this.search = ''
            let detailedarr = item
            this.detailedpoparray = {}

            this.finaltotal = 0
            this.detailedpoparray['syname'] = item.SCRIP_SYMBOL
            this.dialogmobile = true
            this.loaderpopup = true

            // this.detailedpoparray = detailedarr.detailed

            // this.finaltotal = 0
            // for (let i = 0; i < this.detailedpoparray.length; i++) {
            //     this.finaltotal += this.detailedpoparray[i].NETAMT

            // }
            // console.log(this.detailedpoparray[0]);
            // console.log(item);
            this.scriptname = item.SCRIP_SYMBOL

            // this.itemperpage1inner = this.detailedpoparray.length
            const axios = require('axios');
            let data = JSON.stringify({
                "cc": this.actid,
                "from": this.segfrom,
                "to": this.toshow,
                "script": detailedarr.SCRIP_SYMBOL,
                "cocd": detailedarr.company_code
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                // url: 'http://192.168.5.198:5050/getpnlscriptdetail',
                url: `${apiurl}/getpnlscriptdetail`,

                headers: {
                    'Content-Type': 'application/json',
                    'clientid': this.actid,
                    'Authorization': this.susertoken
                },
                data: data
            };
            let axiosthis = this
            axios.request(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    let popval = response.data
                    if (popval.msg != 'No Data Available') {
                        if (axiosthis.detailedpoparray['syname'] == item.SCRIP_SYMBOL) {
                            axiosthis.loaderpopup = false
                            axiosthis.detailedpoparray['data'] = popval

                            for (let i = 0; i < axiosthis.detailedpoparray.data.length; i++) {
                                axiosthis.finaltotal += axiosthis.detailedpoparray.data[i].NETAMT

                            }
                        }

                    } else {

                        axiosthis.loaderpopup = false
                        // axiosthis.detailedpoparray = []
                    }
                    // console.log(axiosthis.detailedpoparray);
                })
                .catch((error) => {
                    console.log(error);
                });

            // console.log(item);

            axiosthis.itemperpage1inner = axiosthis.detailedpoparray.data.length
        },
        filterseg1(filterdata_unpl1) {
            var data = this.detpop
            // const dataval = this.desserts
            // const data2 = this.desserts
            // const data3 = this.desserts
            const seg = filterdata_unpl1
            // // // //console.log("sssss", seg);
            if (seg == 'Equities') {
                this.desserts23 = []
                for (let i = 0; i < data.length; i++) {
                    if (data[i].company_code == 'NSE_CASH' || data[i].company_code == 'BSE_CASH') {
                        this.desserts23.push(data[i])
                    }
                }


                //console.log(this.disunrealised_pnl, 'this.headerval.total_invested');
            } else if (seg == 'Future & Options') {
                this.desserts23 = []
                // const data1 = this.desserts
                for (let i = 0; i < data.length; i++) {
                    if (data[i].company_code == 'NSE_FNO' || data[i].company_code == 'BSE_FNO') {
                        this.desserts23.push(data[i])
                    }
                }
            } else if (seg == 'Commodities') {
                this.desserts23 = []
                // const data1 = this.desserts
                for (let i = 0; i < data.length; i++) {
                    if (data[i].company_code == 'MCX') {
                        this.desserts23.push(data[i])
                    }
                }
            } else if (seg == 'Currencies') {
                this.desserts23 = []
                // const data1 = this.desserts
                for (let i = 0; i < data.length; i++) {
                    if (data[i].company_code == 'CD_NSE') {
                        this.desserts23.push(data[i])
                    }
                }
            } else if (seg == 'All') {
                this.desserts23 = data
            }
        },
        filterseg(filterdata_unpl) {

            var data = this.dessertspop
            // var data1 = this.dessertspopexpense
            var headrea = this.headervalpop.realised_pnl
            var headrea1 = this.headervalpop.unrealised_pnl
            this.segval = 0
            // console.log(headrea, headrea1);
            // expense_amt
            // unrealised_pnl
            // realised_pnl
            // //console.log(data1,headrea);
            // const dataval = this.desserts
            // const data2 = this.desserts
            // const data3 = this.desserts
            const seg = filterdata_unpl
            // alert("summary");
            // Assuming this.segfrom, this.toshow, and this.actid are defined somewhere in your code
            var segval = ''
            // Create the initial JSON object
            if (seg === "Equities") {
                segval = "eq";
            } else if (seg === "Future & Options") {
                segval = "fno";
            } else if (seg === "Commodities") {
                segval = "comm";
            } else if (seg === "Currencies") {
                segval = "curr";
            }

            // Now, apiData contains the updated values
            // You can send apiData to your backend
            if (this.checkbox == true) {
                     var chkbox = 'Y'
            }else{
                chkbox = 'N'
            }
            if (seg != 'All') {
                this.segloader = true
                this.dessertspopexpense = []
                const axios = require('axios');
                let data1 = JSON.stringify({
                    "from": this.segfrom,
                    "to": this.toshow,
                    "seg": segval,
                    "cc": this.actid,
                    "withopen" : chkbox


                });

                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    // url: 'http://192.168.5.198:5050/getexpenses',
                    url: `${apiurl}/getpnlexpenses`,

                    headers: {
                        'Content-Type': 'application/json',
                        'clientid': this.actid,
                        'Authorization': this.susertoken
                    },
                    data: data1
                };
                let axiosthis = this
                axios.request(config)
                    .then((response) => {
                        axiosthis.segloader = false
                    //     const combined = {};
                        if (response.data.msg != 'No Data Available') {
                             //     if (Array.isArray(response.data.BSE_CASH)) {
                    //         response.data.BSE_CASH.forEach(item => {
                    //         const symbol = item.SCRIP_SYMBOL;
                    //         if (combined[symbol]) {
                    //         combined[symbol] += item.NET_AMOUNT;
                    //         } else {
                    //         combined[symbol] = item.NET_AMOUNT;
                    //         }
                    //     });
                    //     }

                    //    if (Array.isArray(response.data.NSE_CASH)) {
                    //         response.data.NSE_CASH.forEach(item => {
                    //         const symbol = item.SCRIP_SYMBOL;
                    //         if (combined[symbol]) {
                    //         combined[symbol] += item.NET_AMOUNT;
                    //         } else {
                    //         combined[symbol] = item.NET_AMOUNT;
                    //         }
                    //     });
                    //    }
                    axiosthis.dessertspopexpense = []
                        // this.dessertspopexpense = Object.keys(combined).map(symbol => ({
                        //     SCRIP_SYMBOL: symbol,
                        //     NET_AMOUNT: combined[symbol]
                        // }));
                        axiosthis.dessertspopexpense = response.data.expenses
                        // console.log(axiosthis.displayedcon,"lklklklklklklkk");
                        axiosthis.segval = response.data.expense_amt
                        // this.dessertspopexpense = 
                        // console.log(this.segval);
                        }
                   
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            }

            if (seg == 'Equities') {

                let addval = 0
                let unaddval = 0

                this.PnlDate = []
                this.dessertstaxdis = []
                for (let i = 0; i < data.length; i++) {
                    if (data[i].company_code == 'BSE_CASH' || data[i].company_code == 'NSE_CASH' || data[i].company_code == 'MF_BSE' || data[i].company_code == 'MF_NSE' || data[i].company_code == 'NSE_SLBM' || data[i].company_code == 'NSE_SPT') {
                        this.PnlDate.push(data[i])

                    }

                }

                // for (let e = 0; e < data1.length; e++) {
                //     if (data1[e].company_code == 'BSE_CASH' || data1[e].company_code == 'NSE_CASH' || data1[e].company_code == 'MF_BSE' || data1[e].company_code == 'MF_NSE' || data1[e].company_code == 'NSE_SLBM' || data1[e].company_code == 'NSE_SPT') {
                //         this.desserts.push(data1[e])
                //     }
                // }

                for (let y = 0; y < this.PnlDate.length; y++) {
                    if (this.PnlDate[y].NET_QUANTITY == 0) {

                        addval += this.PnlDate[y].NOT_PROFIT
                    }

                }
                this.disrealised_pnl = addval
                for (let y = 0; y < this.PnlDate.length; y++) {
                    if (this.PnlDate[y].NET_QUANTITY != 0) {

                        unaddval += this.PnlDate[y].NOT_PROFIT
                    }

                }
                this.disunrealised_pnl = unaddval

                // console.log(this.disrealised_pnl, this.disunrealised_pnl);


                // //console.log(this.headerval.realised_pnl, "sisisisi");






                // }
                // for (let i = 0; i < this.desserts23.length; i++) {
                //     if (this.desserts23[i].NET_QUANTITY != 0) {
                //         this.disunrealised_pnl = this.desserts23[i].reduce((accumulator, object) => {
                //             return accumulator + object.BUY_RATE;
                //         }, 0);
                //     }
                // }
                //console.log(this.disunrealised_pnl, this.disrealised_pnl);
                // // // //console.log(this.PnlDate, "this.PnlDate");
            } else if (seg == 'Future & Options') {
                this.PnlDate = []
                let addval = 0
                let unaddval = 0

                // const data1 = this.PnlDate
                for (let i = 0; i < data.length; i++) {
                    if (data[i].company_code == 'NSE_FNO' || data[i].company_code == 'BSE_FNO   ') {
                        this.PnlDate.push(data[i])

                    }
                }
                for (let y = 0; y < this.PnlDate.length; y++) {
                    if (this.PnlDate[y].NET_QUANTITY == 0) {

                        addval += this.PnlDate[y].NOT_PROFIT
                    }

                }
                this.disrealised_pnl = addval
                for (let y = 0; y < this.PnlDate.length; y++) {
                    if (this.PnlDate[y].NET_QUANTITY != 0) {

                        unaddval += this.PnlDate[y].NOT_PROFIT
                    }
                    // else{
                    //     // alert("kl")
                    // }

                }
                this.disunrealised_pnl = unaddval
                // console.log(this.disrealised_pnl, this.disunrealised_pnl);

                // // // //console.log(this.PnlDate, "this.PnlDate");
            } else if (seg == 'Commodities') {
                this.PnlDate = []
                let addval = 0
                let unaddval = 0

                // const data1 = this.PnlDate
                for (let i = 0; i < data.length; i++) {
                    if (data[i].company_code == 'MCX' || data[i].company_code == 'NCDEX' || data[i].company_code == 'NSE_COM' || data[i].company_code == 'BSE_COM') {
                        this.PnlDate.push(data[i])
                    }
                }
                //console.log(this.PnlDate);
                for (let y = 0; y < this.PnlDate.length; y++) {
                    if (this.PnlDate[y].NET_QUANTITY == 0) {

                        addval += this.PnlDate[y].NOT_PROFIT
                    }

                }
                this.disrealised_pnl = addval
                for (let y = 0; y < this.PnlDate.length; y++) {
                    if (this.PnlDate[y].NET_QUANTITY != 0) {

                        unaddval += this.PnlDate[y].NOT_PROFIT
                    }

                }
                this.disunrealised_pnl = unaddval
                // console.log(this.disrealised_pnl, this.disunrealised_pnl);

                // // // //console.log(this.PnlDate, "this.PnlDate");
            } else if (seg == 'Currencies') {
                this.PnlDate = []
                let addval = 0
                let unaddval = 0

                // const data1 = this.PnlDate
                for (let i = 0; i < data.length; i++) {
                    if (data[i].company_code == 'CD_NSE' || data[i].company_code == 'CD_MCX' || data[i].company_code == 'CD_USE' || data[i].company_code == 'CD_BSE') {
                        this.PnlDate.push(data[i])
                    }
                }

                for (let y = 0; y < this.PnlDate.length; y++) {
                    if (this.PnlDate[y].NET_QUANTITY == 0) {

                        addval += this.PnlDate[y].NOT_PROFIT
                    }

                }
                this.disrealised_pnl = addval
                for (let y = 0; y < this.PnlDate.length; y++) {
                    if (this.PnlDate[y].NET_QUANTITY != 0) {

                        unaddval += this.PnlDate[y].NOT_PROFIT
                    }

                }
                this.disunrealised_pnl = unaddval
                // console.log(this.disrealised_pnl, this.disunrealised_pnl);

                // // // //console.log(this.PnlDate, "this.PnlDate");
            } else if (seg == 'All') {
                this.PnlDate = data
                this.dessertspopexpense = this.dessertsexpense
                this.disrealised_pnl = headrea
                this.disunrealised_pnl = headrea1
                this.segval = this.headerval.expense_amt
                // console.log(this.disrealised_pnl, this.disunrealised_pnl);
            }
        },
        convertImageToBase64() {

fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            this.imageBase64 = reader.result;
        };
        reader.readAsDataURL(blob);
    })
    .catch((error) => {
        console.error('Error loading local image:', error);
    });
},
        downloaddet(item) {

            this.sheet1 = false
            this.radios1 = null
            let data = item
            // // // //console.log("dsdsdsdsds", data);

            if (data === 'Download Excel') {
  if (!this.PnlDate || !Array.isArray(this.PnlDate)) {
    console.error('Invalid data for Excel export.');
    return;
  }

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Profit and Loss');

  // Define header rows
  const headerRows = [
    // ["ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED"],
    // [],
    ["","Profit and Loss","Client ID123", this.actid,],
    ["","","Client Name", this.cname],
   
    ["","","PAN", this.profiledetails['PAN_NO']],
    ["","","Email Id", this.profiledetails['CLIENT_ID_MAIL']],
    [],
    ["Realised P&L", "Un Realised P&L","Charges & taxes"],
    [(this.disrealised_pnl || 0).toFixed(2),(this.disunrealised_pnl || 0).toFixed(2),  (this.segval || 0).toFixed(2)],
    [],
    ["Symbol", "Open Qty", "Open Amount","Buy Qty", "Buy Rate", "Sell Qty", "Sell Rate", "Net Qty", "Net Rate", "Close price", "P&L"]
  ];

  // Prepare rows data
  const rows = this.PnlDate.map(element => [
    element.SCRIP_SYMBOL || "",
    element.open_QUANTITY || "",
    element.Open_AMOUNT || "",
    element.BUY_QUANTITY || "",
    element.BUY_RATE || "",
    element.SALE_QUANTITY || "",
    element.SALE_RATE || "",
    element.NET_QUANTITY || "",
    element.NET_RATE || "",
    element.CLOSING_PRICE || "",
    element.NOT_PROFIT || ""
  ]);

  // Combine headerRows with data rows
  const allRows = headerRows.concat(rows);

  // Add rows to the worksheet
  allRows.forEach((row) => {
    const excelRow = worksheet.addRow(row);

    row.forEach((cell, cellIndex) => {
      const cellRef = excelRow.getCell(cellIndex + 1);

      if (cell === "") {
        cellRef.border = {
          top: { style: 'none' },
          left: { style: 'none' },
          bottom: { style: 'none' },
          right: { style: 'none' }
        };
      }

      // Apply font and fill styles based on cell content
      if (['ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED', 'Profit and Loss'].includes(cell)) {
        cellRef.font = { name: 'Arial', size: 14, bold: true };
      } else if (['Symbol', 'Open Qty','Open Amount', 'Buy Qty', 'Buy Rate', 'Sell Qty', 'Sell Rate', 'Net Qty', 'Net Rate', 'Close price', 'P&L'].includes(cell)) {
        cellRef.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1F226B' } };
        cellRef.font = { bold: true ,color: { argb: 'ffffff' }};
      }
      else if (['Realised P&L', 'Un Realised P&L', 'Charges & taxes'].includes(cell)) {
        cellRef.font = { name: 'Arial', size: 11, bold: true };
        cellRef.font = { bold: true };

      }

      if (['Open Qty', 'Open Amount', 'Buy Qty', 'Buy Rate','Sell Qty','Sell Rate','Net Qty','Net Rate','Close price','P&L'].includes(headerRows[headerRows.length - 1][cellIndex])) {
      cellRef.alignment = { horizontal: 'right' };
      cellRef.numFmt = '#,##0.00';  // Set number format for better readability
    } else {
      // Align all other cells to the left
      cellRef.alignment = { horizontal: 'left' };
    }
    });
  });

  // Adjust column widths
  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const cellValue = cell.value ? cell.value.toString() : '';
      maxLength = Math.max(maxLength, cellValue.length);
    });
    column.width = maxLength < 10 ? 10 : maxLength + 2;
  });

  // Add borders to all cells
  worksheet.eachRow({ includeEmpty: false }, (row) => {
    row.eachCell({ includeEmpty: false }, (cell) => {
      if (cell.value !== "") {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      }
    });
  });

  // Add the image to the worksheet
  if (this.imageBase64) {
    const imageId = workbook.addImage({
      base64: this.imageBase64,
      extension: 'svg' // or 'png', 'jpeg', etc. depending on your image format
    });

    worksheet.getRow(1).height = 32.53; // Adjust height to fit the image
    worksheet.getColumn(1).width = 20; // Adjust width to fit the image

    worksheet.addImage(imageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 102.53, height: 40.53 }
    });
  } else {
    console.warn('No image base64 data provided.');
  }

  // Write the Excel file to a buffer
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `P&L_${this.fromshow}.xlsx`;
    link.click();
  }).catch(error => console.error('Error generating Excel file:', error));
}
  else if (data == 'Download Pdf') {
                let rows = [];
                let columnHeader = ['Date', 'Exchange', 'Details', 'Debit', 'Credit', 'Net Amount'];
                let pdfName = `${'Ledger'}${this.yearis}`;
                // console.log(rowsexpense);
                
                // console.log(headerexpense);
                this.sortedItemss.forEach(element => {
                    var temp = [
                        element.VOUCHERDATE, element.COCD, element.NARRATION, element.DR_AMT, element.CR_AMT, element.NET_AMT
                    ];
                    rows.push(temp);
                });
                // console.log(rows);
                // Create a new jsPDF document
                var doc = new jsPDF();

                // Add header and customize text
                doc.setFont('Tenon');
                doc.setFontSize(12);
                doc.text("ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED", 38, 9);
                doc.setFontSize(6);
                doc.text("Correspondence Office:Door No.127, P S K Booshanam Mahal, 1st Floor, 100 Feet Bypass Road, Velachery, Chennai - 42", 38, 13);
                doc.setFontSize(6);
                doc.text("Phone No:044-4855 7991, Fax :044-4855 7991", 38, 16);
                doc.setFontSize(6);
                doc.text("TRADING MEMBER CODE: NSE-EQ,F&O,CDS,COM: 13179 , BSE-EQ,F&O,CD,COM: 6550, MSEI - EQ,F&O,CDS:83300 CIN NO: U67120TZ2013PTC019704", 38, 19);
                doc.setFontSize(6);
                doc.text("Website Address :- www.zebuetrade.com ,Investor Grievances Email id: grievance@zebuetrade.com", 38, 22);
                doc.setFontSize(7);
                doc.setFont('bold');
                doc.text(`${'Client Code   :  '}${this.actid}`, 15, 41);
                doc.setFontSize(7);
                doc.text(`${'Client Name  :  '}${this.cname}`, 15, 45);
                if (this.todate !== '' && this.fromdate !== '') {
                    doc.setFontSize(7);
                    doc.text(`${'From  :  '}${this.fromdateapi}`, 170, 41);
                    doc.setFontSize(7);
                    doc.text(`${'To      :  '}${this.toshow}`, 170, 45);
                }else{
                    doc.setFontSize(7);
                    doc.text(`${'Year  :  '}${this.yearis}`, 176, 41);
                }
                doc.setFontSize(14);
                doc.text('Ledger', 100, 33);
                const dividerY = 26; // Adjust the Y-coordinate based on your layout
                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(0, dividerY, 218, dividerY); 
                // Add image from local file
                doc.addImage(zebupdfImage, 'PNG', 9, 8, 20, 10);
                // Draw a rectangle with black border
                const rectangleX = 15; // X-coordinate of the top-left corner of the rectangle
                const rectangleY = 52; // Y-coordinate of the top-left corner of the rectangle
                const rectangleWidth = 40; // Width of the rectangle
                const rectangleHeight = 17; // Height of the rectangle
                doc.setDrawColor(0); // Set border color to black
                doc.setLineWidth(0.1); // Set border line width
                doc.rect(rectangleX, rectangleY, rectangleWidth, rectangleHeight); // Draw rectangle border

                // Add text inside the rectangle
                
                const textInsideRectangle = 'Opening Balance';
                const textInsideRectangleX = rectangleX + 3; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleY = rectangleY + 5; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.text(textInsideRectangle, textInsideRectangleX, textInsideRectangleY);
                // const dividerYy = 26; // Adjust the Y-coordinate based on your layout
                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(18, 60, 25, 60); 


                
                // Set font size for the table
                const rectangleX2 = 60; // X-coordinate of the top-left corner of the rectangle
                const rectangleY2 = 52; // Y-coordinate of the top-left corner of the rectangle
                const rectangleWidth2 = 40; // Width of the rectangle
                const rectangleHeight2 = 17; // Height of the rectangle
                doc.setDrawColor(0); // Set border color to black
                doc.setLineWidth(0.1); // Set border line width
                doc.rect(rectangleX2, rectangleY2, rectangleWidth2, rectangleHeight2); // Draw rectangle border

                // Add text inside the rectangle
                const textInsideRectangle2 = 'Total Debit';
                const textInsideRectangleX2 = rectangleX2 + 3; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleY2 = rectangleY2 + 5; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.text(textInsideRectangle2, textInsideRectangleX2, textInsideRectangleY2);

                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(63, 60, 70, 60); 
                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(108, 60, 115, 60); 

                const rectangleX3 = 105; // X-coordinate of the top-left corner of the rectangle
                const rectangleY3 = 52; // Y-coordinate of the top-left corner of the rectangle
                const rectangleWidth3 = 40; // Width of the rectangle
                const rectangleHeight3 = 17; // Height of the rectangle
                doc.setDrawColor(0); // Set border color to black
                doc.setLineWidth(0.1); // Set border line width
                doc.rect(rectangleX3, rectangleY3, rectangleWidth3, rectangleHeight3); // Draw rectangle border

                // Add text inside the rectangle
                const textInsideRectangle3 = 'Total Credit';
                const textInsideRectangleX3 = rectangleX3 + 3; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleY3 = rectangleY3 + 5; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.text(textInsideRectangle3, textInsideRectangleX3, textInsideRectangleY3);
                const rectangleX4 = 150; // X-coordinate of the top-left corner of the rectangle
                const rectangleY4 = 52; // Y-coordinate of the top-left corner of the rectangle
                const rectangleWidth4 = 40; // Width of the rectangle
                const rectangleHeight4 = 17; // Height of the rectangle
                doc.setDrawColor(0); // Set border color to black
                doc.setLineWidth(0.1); // Set border line width
                doc.rect(rectangleX4, rectangleY4, rectangleWidth4, rectangleHeight4); // Draw rectangle border

                // Add text inside the rectangle
                const textInsideRectangle4 = 'Closing Balance';
                const textInsideRectangleX4 = rectangleX4 + 3; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleY4 = rectangleY4 + 5; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.text(textInsideRectangle4, textInsideRectangleX4, textInsideRectangleY4);

                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(153, 60, 160, 60); 
                // doc.setLineWidth(0.2); // Set line width
                // doc.setDrawColor(0); // Set line color to black
                // doc.line(108, 60, 115, 60); 
                // Add table using autoTable
                let textColor;
                if (this.showup.opening_balance < 0) {
                textColor = [255, 0, 0]; // Red for negative values
                } else if (this.showup.opening_balance > 0) {
                textColor = [0, 128, 0]; // Green for positive values
                } else {
                textColor = [0, 0, 0]; // Black for zero
                }
                const textInsideRectangleval1 = (this.showup.opening_balance).toFixed(2);
                const textInsideRectangleval1x = rectangleX + 3; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleval1Y = rectangleY + 13; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.setTextColor(textColor[0], textColor[1], textColor[2]);
                doc.text(textInsideRectangleval1, textInsideRectangleval1x, textInsideRectangleval1Y);

                let textColor1;
                // if (this.disunrealised_pnl < 0) {
                textColor1 = [255, 0, 0]; // Red for negative values
                // } else if (this.disunrealised_pnl > 0) {
                // textColor1 = [0, 128, 0]; // Green for positive values
                // } else {
                // textColor1 = [0, 0, 0]; // Black for zero
                // }
                const textInsideRectangleval2 =(this.showup.dr_amt).toFixed(2);
                const textInsideRectangleval2x = rectangleX + 48; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleval2y = rectangleY + 13; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.setTextColor(textColor1[0], textColor1[1], textColor1[2]);
                doc.text(textInsideRectangleval2, textInsideRectangleval2x, textInsideRectangleval2y);

                let textColor2;
                // if (this.segval < 0) {
                //     textColor2 = [255, 0, 0]; // Red for negative values
                // } else if (this.segval > 0) {
                    textColor2 = [0, 128, 0]; // Green for positive values
                // } else {
                //     textColor2 = [0, 0, 0]; // Black for zero
                // }
                const textInsideRectangleval3 = (this.showup.cr_amt).toFixed(2);
                const textInsideRectangleval3x = rectangleX + 93; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleval3y = rectangleY + 13; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.setTextColor(textColor2[0], textColor2[1], textColor2[2]);
                doc.text(textInsideRectangleval3, textInsideRectangleval3x, textInsideRectangleval3y);

                let textColor3;
                if (this.showup.closing_balance < 0) {
                    textColor3 = [255, 0, 0]; // Red for negative values
                } else if (this.showup.closing_balance > 0) {
                    textColor3 = [0, 128, 0]; // Green for positive values
                } else {
                    textColor3 = [0, 0, 0]; // Black for zero
                }
                const textInsideRectangleval4 = (this.showup.closing_balance).toFixed(2);
                const textInsideRectangleval4x = rectangleX + 138; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleval4y = rectangleY + 13; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                doc.setFont('Tenon');

                doc.setTextColor(textColor3[0], textColor3[1], textColor3[2]);
                doc.text(textInsideRectangleval4, textInsideRectangleval4x, textInsideRectangleval4y);
                doc.setFontSize(8);
                // doc.setFont('Tenon');
                const columnStyles = {
                    0: { fontStyle: 'bold',textColor: [0, 0, 0]},
                    // 1 : {halign: 'right'},
                    // 2 : {halign: 'right'},
                    3 : {halign: 'right'},
                    4 : {halign: 'right'},
                    5:  {halign: 'right'},
                    // 6 : {halign: 'right'},
                    // 7 : {halign: 'right'},
                    // 8 : {halign: 'right'}
                    // 1: {} // Index 0 corresponds to the first column ('Symbol')
                };
               
                autoTable(doc, { head: [columnHeader], body: rows, startY: 75, styles: {
                    
                            fontSize: 7,
                            font: 'Tenon',
                            lineColor: [200, 200, 200],
                            // textColor: [0,0,0],
                        },theme: 'grid', // Use the 'grid' theme
                        headStyles: {
                            fillColor: [31, 52, 101], // RGB color for dark blue header background
                            textColor: [255, 255, 255], // White text color for the header
                        },
                        columnStyles: columnStyles,
                        table: {
                            // fillColor: [31, 52, 101]
                            // fillColor: tableBackgroundColor,
                        }, });
                        
                       
                                        // Add custom text
                                        // doc.text("Your Custom Text", 14, doc.autoTable.previous.finalY + 10);

                                        // Save the PDF
                                        doc.save(pdfName + '.pdf');
            }

        },
        downloadsum(item) {

this.sheet = false
this.radios = null

let data = item
// // //console.log("dsdsdsdsds", data);

if (data === 'Download Excel') {
  if (!this.PnlDate || !Array.isArray(this.PnlDate)) {
    console.error('Invalid data for Excel export.');
    return;
  }

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Profit and Loss');

  // Define header rows
  const headerRows = [
    // ["ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED"],
    // [],
    ["","Profit and Loss","Client ID", this.actid,],
    ["","","Client Name", this.cname],
   
    ["","","PAN", this.profiledetails['PAN_NO']],
    ["","","Email Id", this.profiledetails['CLIENT_ID_MAIL']],
    ["","","From Date",this.fromdateapi],
    ["","","To date",this.toshow],

    [],
    ["Realised P&L", "Un Realised P&L","Charges & taxes"],
    [(this.disrealised_pnl || 0).toFixed(2),(this.disunrealised_pnl || 0).toFixed(2),  (this.segval || 0).toFixed(2)],
    [],
    ["Symbol", "Open Qty", "Open Amount", "Buy Qty", "Buy Rate", "Sell Qty", "Sell Rate", "Net Qty", "Net Rate", "Close price", "P&L"]
  ];

  // Prepare rows data
  const rows = this.PnlDate.map(element => [
    element.SCRIP_SYMBOL || "",
    element.open_QUANTITY || 0,
    element.Open_AMOUNT || 0,
    element.BUY_QUANTITY || 0,
    element.BUY_RATE || 0,
    element.SALE_QUANTITY || 0,
    element.SALE_RATE || 0,
    element.NET_QUANTITY || 0,
    element.NET_RATE || 0,
    element.CLOSING_PRICE || 0,
    element.NOT_PROFIT || 0
  ]);

  // Combine headerRows with data rows
  const allRows = headerRows.concat(rows);
  const footerRows = [
    [],
    ["Zebu Share And Wealth Management Private Limited"],
    ["Correspondence Office: Door No.127, P S K Booshanam Mahal, 1st Floor, 100 Feet Bypass Road, Velachery, Chennai - 42"],
    ["Phone No: 044-4855 7991, Fax: 044-4855 7991"],
    ["TRADING MEMBER CODE: NSE-EQ,F&O,CDS,COM: 13179 , BSE-EQ,F&O,CD,COM: 6550, MSEI - EQ,F&O,CDS:83300"],
    ["CIN NO: U67120TZ2013PTC019704 Website Address: www.zebuetrade.com, Investor Grievances Email id: grievance@zebuetrade.com"]
  ];

  allRows.push(...footerRows);
  // Add rows to the worksheet
  allRows.forEach((row,rowIndex) => {
    const excelRow = worksheet.addRow(row);

    row.forEach((cell, cellIndex) => {
      const cellRef = excelRow.getCell(cellIndex + 1);

      if (cell === "") {
        cellRef.border = {
          top: { style: 'none' },
          left: { style: 'none' },
          bottom: { style: 'none' },
          right: { style: 'none' }
        };
      }

      // Apply font and fill styles based on cell content
      if (['ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED', 'Profit and Loss'].includes(cell)) {
        cellRef.font = { name: 'Arial', size: 14, bold: true };
      } else if (['Symbol', 'Open Qty', "Open Amount", 'Buy Qty', 'Buy Rate', 'Sell Qty', 'Sell Rate', 'Net Qty', 'Net Rate', 'Close price', 'P&L'].includes(cell)) {
        cellRef.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1F226B' } };
        cellRef.font = { bold: true ,color: { argb: 'ffffff' }};
      }
      else if (['Realised P&L', 'Un Realised P&L', 'Charges & taxes'].includes(cell)) {
        cellRef.font = { name: 'Arial', size: 11, bold: true };
        cellRef.font = { bold: true };
      }
      if ( rowIndex === 7 || // Row for 'Opening Balance', 'Total Debit', etc.
      rowIndex === 8 ||['Open Qty', 'Open Amount', 'Buy Qty', 'Buy Rate','Sell Qty','Sell Rate','Net Qty','Net Rate','Close price','P&L'].includes(headerRows[headerRows.length - 1][cellIndex])) {
      cellRef.alignment = { horizontal: 'right' };
      cellRef.numFmt = '#,##0.00';  // Set number format for better readability
    } else {
      // Align all other cells to the left
      cellRef.alignment = { horizontal: 'left' };
    }
    });
  });

  // Adjust column widths
  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const cellValue = cell.value ? cell.value.toString() : '';
      maxLength = Math.max(maxLength, cellValue.length);
    });
    column.width = maxLength < 10 ? 10 : maxLength + 2;
  });

  // Add borders to all cells
  worksheet.eachRow({ includeEmpty: false }, (row) => {
    row.eachCell({ includeEmpty: false }, (cell) => {
      if (cell.value !== "") {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      }
    });
  });

  // Add the image to the worksheet
  if (this.imageBase64) {
    const imageId = workbook.addImage({
      base64: this.imageBase64,
      extension: 'svg' // or 'png', 'jpeg', etc. depending on your image format
    });

    worksheet.getRow(1).height = 32.53; // Adjust height to fit the image
    worksheet.getColumn(1).width = 20; // Adjust width to fit the image

    worksheet.addImage(imageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 102.53, height: 40.53 }
    });
  } else {
    console.warn('No image base64 data provided.');
  }

  // Write the Excel file to a buffer
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `P&L_${this.fromshow}.xlsx`;
    link.click();
  }).catch(error => console.error('Error generating Excel file:', error));
}else if (data == 'Download Pdf') {
                let rows = [];
                let rowsexpense = [];
                let headerexpense = ['Symbol', 'Net Amount'];
                let columnHeader = ['Symbol', 'Open Qty', 'Open Amt', 'Buy Qty', 'Buy Rate', 'Sell Qty', 'Sell Rate', 'Net Qty', 'Net Rate', 'P&L'];
                let pdfName = `${'Pnl'}${this.fromshow}`;
                // for (let i = 0; i < this.dessertsexpense.length; i++) {
                //     var temp3 = [
                //     this.dessertsexpense[i].NET_AMOUNT 
                //     ]
                //     rowsexpense.push(temp3)

                // }

                this.dessertsexpense.forEach(element => {
                    var temp = [
                         element.SCRIP_SYMBOL,element.NET_AMOUNT
                    ];
                    rowsexpense.push(temp);
                });
                // this.dessertsexpense.forEach(element => {
                //     var temp3 = [
                //         element.NET_AMOUNT
                //     ]
                //     rowsexpense.push(temp3)
                // });
                // console.log(rowsexpense);
                // this.dessertsexpense.forEach(element => {
                //     var temp2 = 
                //         element.SCRIP_SYMBOL
                    
                //     headerexpense.push(temp2)
                // });

                
                // console.log(headerexpense);
                this.PnlDate.forEach(element => {
                    var temp = [
                        element.SCRIP_SYMBOL, element.open_QUANTITY,element.Open_AMOUNT, element.BUY_QUANTITY, element.BUY_RATE, element.SALE_QUANTITY, element.SALE_RATE, element.NET_QUANTITY, element.NET_RATE, element.NOT_PROFIT
                    ];
                    rows.push(temp);
                });
                // console.log(rows);
                // Create a new jsPDF document
                var doc = new jsPDF();
                // Add header and customize text
                //doc.setFont('Tenon');
                // doc.setFontSize(12);
                // doc.text("ZEBU SHARE AND WEALTH MANAGEMENTS PRIVATE LIMITED", 38, 9);
                // doc.setFontSize(6);
                // doc.text("Correspondence Office:Door No.127, P S K Booshanam Mahal, 1st Floor, 100 Feet Bypass Road, Velachery, Chennai - 42", 38, 13);
                // doc.setFontSize(6);
                // doc.text("Phone No:044-4855 7991, Fax :044-4855 7991", 38, 16);
                // doc.setFontSize(6);
                // doc.text("TRADING MEMBER CODE: NSE-EQ,F&O,CDS,COM: 13179 , BSE-EQ,F&O,CD,COM: 6550, MSEI - EQ,F&O,CDS:83300 CIN NO: U67120TZ2013PTC019704", 38, 19);
                // doc.setFontSize(6);
                // doc.text("Website Address :- www.zebuetrade.com ,Investor Grievances Email id: grievance@zebuetrade.com", 38, 22);
                doc.setFontSize(7);
                doc.setFont('bold');
                doc.text(`${'Client Name   :  '}${this.cname}`, 15, 33);
                doc.setFontSize(7);
                doc.text(`${'Client Code    :  '}${this.actid}`, 15, 37);
                doc.setFontSize(7);
                doc.text(`${'PAN               :  '}${this.profiledetails['PAN_NO']}`, 15, 41);
                doc.setFontSize(7);
                doc.text(`${'Email Id         :  '}${this.profiledetails['CLIENT_ID_MAIL']}`, 15, 45);
                doc.setFontSize(7);
                doc.text(`${'From     :  '}${this.fromdateapi}`, 133, 33);
                doc.setFontSize(7);
                doc.text(`${'To         :  '}${this.toshow}`, 133, 37);
                doc.setFontSize(7);
                doc.text(`${'Mobile  :  '}${this.profiledetails['MOBILE_NO']}`, 133, 41);
                doc.setFontSize(7);
                doc.text(`${'Address :  '}${this.profiledetails['CL_RESI_ADD1']}`, 133, 45);
                doc.setFontSize(7);
                doc.text(`${this.profiledetails['CL_RESI_ADD2']}`, 144, 48);
                doc.setFontSize(7);
                doc.text(`${this.profiledetails['CL_RESI_ADD3']}`, 144, 51);
                doc.setFontSize(14);
                doc.text('Profit and Loss', 90, 14.5);
                const dividerY = 26; // Adjust the Y-coordinate based on your layout
                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(0, dividerY, 218, dividerY); 
                // Add image from local file
                doc.addImage(zebupdfImage, 'PNG', 9, 8, 20, 10);
                // Draw a rectangle with black border
                const rectangleX = 15; // X-coordinate of the top-left corner of the rectangle
                const rectangleY = 56; // Y-coordinate of the top-left corner of the rectangle
                const rectangleWidth = 40; // Width of the rectangle
                const rectangleHeight = 17; // Height of the rectangle
                doc.setDrawColor(0); // Set border color to black
                doc.setLineWidth(0.1); // Set border line width
                doc.rect(rectangleX, rectangleY, rectangleWidth, rectangleHeight); // Draw rectangle border

                // Add text inside the rectangle
                
                const textInsideRectangle = 'Realised P&L';
                const textInsideRectangleX = rectangleX + 3; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleY = rectangleY + 5; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                //doc.setFont('Tenon');

                doc.text(textInsideRectangle, textInsideRectangleX, textInsideRectangleY);
                // const dividerYy = 26; // Adjust the Y-coordinate based on your layout
                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(18, 64, 25, 64); 

                // Set font size for the table
                const rectangleXX = 65; // X-coordinate of the top-left corner of the rectangle
                const rectangleYY = 56; // Y-coordinate of the top-left corner of the rectangle
                const rectangleWidthH = 40; // Width of the rectangle
                const rectangleHeightT = 17; // Height of the rectangle
                doc.setDrawColor(0); // Set border color to black
                doc.setLineWidth(0.1); // Set border line width
                doc.rect(rectangleXX, rectangleYY, rectangleWidthH, rectangleHeightT); // Draw rectangle border

                // Add text inside the rectangle
                const textInsideRectangleE = 'Un Realised P&L';
                const textInsideRectangleXX = rectangleXX + 3; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleYY = rectangleYY + 5; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                //doc.setFont('Tenon');

                doc.text(textInsideRectangleE, textInsideRectangleXX, textInsideRectangleYY);

                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(68, 64, 75, 64); 
                doc.setLineWidth(0.2); // Set line width
                doc.setDrawColor(0); // Set line color to black
                doc.line(118, 64, 125, 64); 

                const rectangleXXX = 115; // X-coordinate of the top-left corner of the rectangle
                const rectangleYYY = 56; // Y-coordinate of the top-left corner of the rectangle
                const rectangleWidthHH = 40; // Width of the rectangle
                const rectangleHeightTT = 17; // Height of the rectangle
                doc.setDrawColor(0); // Set border color to black
                doc.setLineWidth(0.1); // Set border line width
                doc.rect(rectangleXXX, rectangleYYY, rectangleWidthHH, rectangleHeightTT); // Draw rectangle border

                // Add text inside the rectangle
                const textInsideRectangleEE = 'Charges & taxes';
                const textInsideRectangleXXX = rectangleXXX + 3; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleYYY = rectangleYYY + 5; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                //doc.setFont('Tenon');

                doc.text(textInsideRectangleEE, textInsideRectangleXXX, textInsideRectangleYYY);
                // Add table using autoTable
                let textColor;
                if (this.disrealised_pnl < 0) {
                textColor = [255, 0, 0]; // Red for negative values
                } else if (this.disrealised_pnl > 0) {
                textColor = [0, 128, 0]; // Green for positive values
                } else {
                textColor = [0, 0, 0]; // Black for zero
                }
                
                const textInsideRectangleQ = `${(this.disrealised_pnl).toFixed(2)}`;
                const textInsideRectangleXXXX = rectangleX + 3; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleYYYY = rectangleY + 13; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                //doc.setFont('Tenon');

                doc.setTextColor(textColor[0], textColor[1], textColor[2]);
                doc.text(textInsideRectangleQ, textInsideRectangleXXXX, textInsideRectangleYYYY);

                let textColor1;
                if (this.disunrealised_pnl < 0) {
                textColor1 = [255, 0, 0]; // Red for negative values
                } else if (this.disunrealised_pnl > 0) {
                textColor1 = [0, 128, 0]; // Green for positive values
                } else {
                textColor1 = [0, 0, 0]; // Black for zero
                }
                const textInsideRectangleQQ = `${(this.disunrealised_pnl).toFixed(2)}`;
                const textInsideRectangleXXXXX = rectangleX + 53; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleYYYYY = rectangleY + 13; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                //doc.setFont('Tenon');

                doc.setTextColor(textColor1[0], textColor1[1], textColor1[2]);
                doc.text(textInsideRectangleQQ, textInsideRectangleXXXXX, textInsideRectangleYYYYY);

                let textColor2;
                if (this.segval < 0) {
                    textColor2 = [255, 0, 0]; // Red for negative values
                } else if (this.segval > 0) {
                    textColor2 = [0, 128, 0]; // Green for positive values
                } else {
                    textColor2 = [0, 0, 0]; // Black for zero
                }
                const textInsideRectangleQQQ = `${(this.segval).toFixed(2)}`;
                const textInsideRectangleXXXXXX = rectangleX + 103; // Adjust the X-coordinate to leave some margin
                const textInsideRectangleYYYYYY = rectangleY + 13; // Adjust the Y-coordinate to center the text vertically
                doc.setFontSize(8);
                //doc.setFont('Tenon');

                doc.setTextColor(textColor2[0], textColor2[1], textColor2[2]);
                doc.text(textInsideRectangleQQQ, textInsideRectangleXXXXXX, textInsideRectangleYYYYYY);
                doc.setFontSize(8);
                // //doc.setFont('Tenon');
                
                const columnStyles = {
                    0: { fontStyle: 'bold',textColor: [0, 0, 0]},
                    1 : {halign: 'right'},
                    2 : {halign: 'right'},
                    3 : {halign: 'right'},
                    4 : {halign: 'right'},
                    5:  {halign: 'right'},
                    6 : {halign: 'right'},
                    7 : {halign: 'right'},
                    8 : {halign: 'right'}
                    // 1: {} // Index 0 corresponds to the first column ('Symbol')
                };
                const columnStyles2 = {
                    0: { fontStyle: 'bold',textColor: [0, 0, 0],align: 'right'},
                    1 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                    2 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                    3 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                    4 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                    5:  {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                    6 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                    7 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'},
                    8 : {fontStyle: 'bold',textColor: [0, 0, 0],halign: 'right'}
                    // 1: {} // Index 0 corresponds to the first column ('Symbol')
                };
                autoTable(doc, { head: [columnHeader], body: rows, startY: 79, styles: {
                    
                            fontSize: 7,
                            // font: 'Tenon',
                            lineColor: [200, 200, 200],
                            // textColor: [0,0,0],
                        },theme: 'grid', // Use the 'grid' theme
                        headStyles: {
                            fillColor: [31, 52, 101], // RGB color for dark blue header background
                            textColor: [255, 255, 255], // White text color for the header
                        },
                        columnStyles: columnStyles,
                        table: {
                            // fillColor: [31, 52, 101]
                            // fillColor: tableBackgroundColor,
                        }, });
                        if (doc.internal.pages.length > 1) {
                            doc.addPage();
                            doc.autoTable.previous.finalY = 10

} else {
    doc.autoTable.previous.finalY += 20; // Add some spacing between tables
}
                        // doc.text('End table', 10, doc.autoTable.previous.finalY + 10);
                        doc.setFontSize(12);
                        doc.setTextColor(0, 0, 0);
                        doc.text('Charges & taxes', 15, doc.autoTable.previous.finalY + 10);
                        autoTable(doc, {
                            head: [headerexpense],
                            body: rowsexpense,
                            startY: doc.autoTable.previous.finalY + 20, // Adjust the startY position as needed
                            styles: {
                                fontSize: 7,
                                // font: 'Tenon',
                                // textColor: [200, 200, 200], // Set font color to black
                            },theme: 'grid',
                            headStyles: {
                            fillColor: [31, 52, 101], // RGB color for dark blue header background
                            textColor: [255, 255, 255], // White text color for the header
                        },
                        columnStyles: columnStyles2,

                            table: {},
                        });
                                        // Add custom text
                                        // doc.text("Your Custom Text", 14, doc.autoTable.previous.finalY + 10);

                                        // Save the PDF
                                        var totalPages = doc.internal.getNumberOfPages();
                        for (var i = 1; i <= totalPages; i++) {
                            doc.setPage(i);
                            
                            
                            const dividerY = doc.internal.pageSize.height - 13; // Adjust the Y-coordinate based on your layout
                            doc.setFontSize(6);
                            doc.text(14, doc.internal.pageSize.height - 9, 'Zebu Share And Wealth Management Private Limited');
                            doc.setFontSize(4);
                            doc.text(14, doc.internal.pageSize.height - 7, 'Correspondence Office:Door No.127, P S K Booshanam Mahal, 1st Floor, 100 Feet Bypass Road, Velachery, Chennai - 42');
                            doc.setFontSize(4);
                            doc.text(84, doc.internal.pageSize.height - 7, 'Phone No:044-4855 7991, Fax :044-4855 7991');
                            doc.setFontSize(4);
                            doc.text(14, doc.internal.pageSize.height - 5, 'TRADING MEMBER CODE: NSE-EQ,F&O,CDS,COM: 13179 , BSE-EQ,F&O,CD,COM: 6550, MSEI - EQ,F&O,CDS:83300 CIN NO: U67120TZ2013PTC019704');
                            doc.setFontSize(4);
                            doc.text(109, doc.internal.pageSize.height - 5, 'Website Address :- www.zebuetrade.com ,Investor Grievances Email id: grievance@zebuetrade.com');
                            doc.setLineWidth(0.2); // Set line width
                            doc.setDrawColor(0); // Set line color to black
                            doc.line(0, dividerY, 218, dividerY); 
                        }
                                        doc.save(pdfName + '.pdf');
            }
},
        detailed() {
            this.summary = false
        },
        summary1() {
            this.summary = true
        },
        lessbtn() {
            this.more = true
            this.less = false
            this.itemperpage = 8
        },
        morebtn() {
            this.more = false
            this.less = true
            this.itemperpage = this.PnlDate.length
        },
        lessbtn1() {
            this.more1 = true
            this.less1 = false
            this.itemperpage1 = 8
        },
        morebtn1() {
            this.more1 = false
            this.less1 = true
            this.itemperpage1 = this.desserts23.length
        },
        yearlist(){
            var today = new Date();
            let sevthdate = new Date(today);
            
            let mmsevthdate = String(sevthdate.getMonth() + 1).padStart(2, '0')
            
            let yyyysevthdate = sevthdate.getFullYear();
            if (mmsevthdate < 4) {
                var yearmount = yyyysevthdate - 1
                }else{
                    yearmount = yyyysevthdate
                }
                var startYear = yearmount - 4;
            this.yearis = yearmount;

            for (let year = yearmount; year >= startYear; year--) {
            // let yeararraytem = []

            this.yearsarray.push(year)
            }
            this.yearsarray = this.yearsarray.slice(2, 5)
            // console.log(this.yearsarray)
        }

    },
    mounted() {
        this.susertoken = localStorage.getItem("usession");
        this.actid = localStorage.getItem("userid");
        this.yearlist()
        this.cname = localStorage.getItem("cname");
        var res = this.decryptionFunction(localStorage.getItem("profile_data"));
        // console.log('ttttt',JSON.parse(res));
        var resp = JSON.parse(res);
        this.profiledetails = resp.client_data;

        this.loader = true
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        if (mm < 4) {
            var yearmount = yyyy - 1
        } else {
            yearmount = yyyy
        }
        var todayapi = dd + '/' + mm + '/' + yyyy;
        this.resetrange = yyyy + '-' + mm + '-' + dd;
        var fromapi = '01' + '/' + '04' + '/' + yearmount;
        this.fromdate.push((yearmount) + '-' + '04' + '-' +  '01')
        // this.fromdate.push(dd + '/' + mm + '/' + yyyy)
        this.fromdate.push(yyyy + '-' + mm + '-' + dd) 
        // this.fromdate = (yearmount) + '-' + '04' + '-' +  '01'
        // console.log(this.fromdate,"mounted")
        this.minval = yearmount - 2 +  '-' + '04' + '-' +'01'  ;
        var today1 = dd + '/' + mm + '/' + yyyy;
        // this.todate = yyyy + '-' + (mm < 10 ? '0' : '') + mm + '-' + (dd < 10 ? '0' : '')

        if (mm < 4) {
            var maxyearval = yyyy 
        } else {
            maxyearval = yyyy 
        }
        // this.maxval =   maxyearval + '-' + mm + '-' + dd
        this.maxvalforfrom =   maxyearval + '-' + mm + '-' + dd
        this.toshow = today1
        this.fromshow = fromapi
        this.segfrom = fromapi
        this.fromdateapi = fromapi
        this.filterdata_unpl1 = "All"
        this.filterdata_unpl = "All"

        this.fromshow = (this.fromshow) + '_' + 'to' + '_' + (this.toshow)
        // this.toshow = today1
        // this.fromshow = this.fromshow + '-' + this.toshow
        this.datemaxvalue()
        if (this.checkbox == true) {
                     var chkbox = 'Y'
            }else{
                chkbox = 'N'
            }
        const axios = require("axios");
        let data = JSON.stringify({
            "cc": this.actid,
            "from": fromapi,
            "to": todayapi,
            "withopen" : chkbox
        });

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${apiurl}/getpnl`,

            headers: {
                'Content-Type': 'application/json',
                'clientid': this.actid,
                'Authorization': this.susertoken
            },
            data: data,
        };
        let axiosthis = this;
        axios.request(config)
            .then((response) => {
                let res = response.data
                if (res.msg != 'No Data Available') {
                    axiosthis.loader = false;
                    axiosthis.loader = false;

                    axiosthis.desserts = response.data["transactions"];
                    axiosthis.PnlDate = axiosthis.desserts

                    // console.log(axiosthis.desserts,'kjkjkj');
                    axiosthis.dessertspop = response.data["transactions"];
                    axiosthis.dessertsexpense = response.data["expenses"];
                    axiosthis.dessertspopexpense = response.data["expenses"];
                    var headval = response.data

                    axiosthis.disrealised_pnl = axiosthis.headerval.realised_pnl
                    axiosthis.disunrealised_pnl = axiosthis.headerval.unrealised_pnl
                    axiosthis.headervalpop = response.data;
                    // console.log(axiosthis.headervalpop.realised_pnl, "kjkjkj");
                    // console.log(axiosthis.desserts, 'lklklklk');
                    axiosthis.headerval = headval
                    axiosthis.disunrealised_pnl = axiosthis.headerval.unrealised_pnl
                    axiosthis.disrealised_pnl = axiosthis.headerval.realised_pnl
                    axiosthis.segval = axiosthis.headerval.expense_amt
                    axiosthis.itemperpage = axiosthis.desserts.length
                    // let data1 = JSON.stringify({
                    //     "cc": this.actid,

                    // });

                    // let config1 = {
                    //     method: 'post',
                    //     maxBodyLength: Infinity,
                    //     url: `${apiurl}/getpnlsummary`,
                    //     headers: {
                    //         'Content-Type': 'application/json',
                    //         'clientid': this.actid,
                    //         'Authorization': this.susertoken
                    //     },
                    //     data: data1
                    // };
                    // // var axiosthis = this
                    // axios.request(config1)
                    //     .then((response) => {

                    //         //// // //console.log(JSON.stringify(response.data));
                    //         // // // //console.log(response.data, typeof response.data,'');
                    //         axiosthis.desserts2 = response.data
                    //         for (const [key, value] of Object.entries(axiosthis.desserts2)) {
                    //             // // // //console.log(key, value, "axiosthis..");
                    //             for (let i = 0; i < value.length; i++) {
                    //                 axiosthis.desserts23.push(value[i])
                    //                 this.keyyy = key
                    //             }
                    //         }
                    //         // console.log(axiosthis.desserts, 'lklklklk');

                    //         for (let i = 0; i < axiosthis.desserts23.length; i++) {
                    //             let smdata = axiosthis.desserts23[i].SCRIP_SYMBOL;
                    //             for (let y = 0; y < axiosthis.desserts.length; y++) {
                    //                 let famItem = axiosthis.desserts[y]['SCRIP_SYMBOL'];
                    //                 if (famItem && smdata === famItem) {
                    //                     // console.log('found');
                    //                     if (!axiosthis.desserts[y].detailed) {
                    //                         axiosthis.desserts[y].detailed = [];
                    //                     }
                    //                     axiosthis.desserts[y].detailed.push(axiosthis.desserts23[i]);
                    //                 }
                    //             }
                    //         }
                    //         axiosthis.PnlDate = axiosthis.desserts
                    //         if (axiosthis.PnlDate != []) {
                    //             axiosthis.loader = false;
                    //         }

                    //         // console.log(axiosthis.desserts, 'lklklklk');
                    //         axiosthis.itemperpage1 = axiosthis.desserts23.length
                    //         axiosthis.detpop = axiosthis.desserts23


                    //     })
                    //     .catch((error) => {
                    //         if (error.message == 'Network Error') {
                    //             this.msg = error.message
                    //             axiosthis.loader = false
                    //             axiosthis.alert = true
                    //             setTimeout(() => {
                    //                 axiosthis.alert = false
                    //             }, 5000);
                    //         }
                    //     });
                    
                } else {
                    // 
                    axiosthis.loader = false;

                }



                // //// // //console.log("axiosthis.desserts", axiosthis.desserts);
            })
            .catch((error) => {
                // // //console.log(error);
                if (error.message == 'Network Error') {
                    this.msg = error.message
                    axiosthis.loader = false
                    axiosthis.alert = true
                    setTimeout(() => {
                        axiosthis.alert = false
                    }, 5000);
                    // alert(error.message)
                }
            });
        // const axios = require('axios');
        this.convertImageToBase64()



    }
};
</script>

<style lang="scss">
.basil {
    background-color: #FFFBE6 !important;
}

.basil--text {
    color: #020202 !important;
}

.v-tab {
    text-transform: none !important;
}

.v-tabs--grow>.v-tabs-bar .v-tab {
    /* flex: 1 0 auto; */
    max-width: none !important;
}
</style>