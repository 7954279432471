<template>
  <div>
    <button class="mt-16" @click="generatePDF">Generate PDF</button>
  </div>
</template>

<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  data() {
    return {
      tables: [
        {
          heading: 'Table 1',
          data: [
            ['Header1', 'Header2'],
            ['Row1Col1', 'Row1Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
            ['Row2Col1', 'Row2Col2'],
          ],
        },
        {
          heading: 'Table 2',
          data: [
            ['HeaderA', 'HeaderB'],
            ['RowA1', 'RowA2'],
            ['RowA1', 'RowA2'],
            ['RowA1', 'RowA2'],
            ['RowA1', 'RowA2'],
            ['RowA1', 'RowA2'],
            ['RowA1', 'RowA2'],
            ['RowB1', 'RowB2'],
          ],
        },
        // Add more tables as needed
      ],
    };
  },
  methods: {
    generatePDF() {
      const doc = new jsPDF();
      const tables = this.tables;
      let yOffset = 10; // Starting Y offset

      tables.forEach((table, index) => {
        if (index > 0) {
          yOffset += 20; // Add space between tables
        }

        // Add heading
        doc.setFontSize(18);
        doc.setFont('helvetica', 'bold');
        doc.text(table.heading, 10, yOffset);
        yOffset += 10; // Add space after heading

        // Add table
        doc.autoTable({
          startY: yOffset,
          head: [table.data[0]], // Header row
          body: table.data.slice(1), // Data rows
          margin: { top: yOffset },
        });

        // Update Y offset after table
        yOffset = doc.lastAutoTable.finalY + 10; // Add some space before the next table
      });

      doc.save('tables.pdf'); // Save the PDF
    },
  },
};
</script>

<style scoped>
/* Add any custom styling if needed */
</style>
