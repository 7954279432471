<!-- fixed -->
<template>
  <div id="app" class="ma-lg-10 ml-lg-10 ma-md-10 ml-md-10">
    <v-snackbar v-model="errorRestextsnackbar" color="red">
      {{ errorRestext }}

      <template v-slot:action="{ attrs }">
      
        <v-icon @click="errorRestextsnackbar = false" v-bind="attrs" class="float-right" color="white">mdi-close-circle</v-icon>
      </template>
    </v-snackbar>
    <v-snackbar class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" transition="slide-x-reverse-transition"
      v-model="snackbar" :timeout="4000" :value="true" color="warning" text-color="white">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      Session <strong>{{ txt }}</strong> Kindly Login again.
      <v-icon @click="snackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <div class="mx-3 px-1">
      <!-- <div class="py-md-7  d-block d-md-none "></div> -->
      <div class="px-md-3">
        <v-list-item class="pl-0 pl-md-1 ">
            <v-list-item-content>
                <v-list-item-title class="text-start"
                    style="color: #000000;font-size: 20px;font-weight:559;line-height:24px;letter-spacing:-1px">
                    Demat Details</v-list-item-title>
                <v-list-item-subtitle class="text-start pt-1"
                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Allows you to sell your holdings with one-time authorisation.
                </v-list-item-subtitle>
            </v-list-item-content>
       </v-list-item>

        <!-- <p class="font-weight-bold fs-22 mb-0">Demat Details</p>
        <p class="subtitle-2 txt-666">
          Allows you to sell your holdings with one-time authorisation
        </p> -->
      </div>
      <v-divider></v-divider>
      <v-card class="mt-6 py-md-6 px-md-3" elevation="0" :outlined="$vuetify.breakpoint.smAndDown ? false : true" width="100%">
        <v-card v-if="profileData.POA == 'Y'" height="18" elevation="0" class="px-2 mt-1 ml-2"
          style="display: inline; float: right" color="#43A833">
          <p class="pa-0 mb-0" style="font-size: 10px; color: white; margin-top: 2px">
            POA
          </p>
        </v-card>
        <v-card v-else height="18" elevation="0" class="px-2 mt-1 ml-2" style="display: inline; float: right" color="red">
          <p class="pa-0 mb-0" style="font-size: 10px; color: white; margin-top: 2px">
            POA
          </p>
        </v-card>
        <v-card v-if="profileData.DDPI == 'Y'" height="18" elevation="0" class="px-2 mt-1"
          style="display: inline; float: right" color="#43A833">
          <p class="pa-0 mb-0" style="font-size: 10px; color: white; margin-top: 2px">
            DDPI
          </p>
        </v-card>
        <v-card v-else height="18" elevation="0" class="px-2 mt-1" style="display: inline; float: right" color="red">
          <p class="pa-0 mb-0" style="font-size: 10px; color: white; margin-top: 2px">
            DDPI
          </p>
        </v-card>
        <p class="subtitle-1 txt-666 font-weight-medium">
          Demat Account
        </p>

        <v-row no-glutters>
          <!-- <v-col cols="12" sm="6" md="4">
            <v-text-field oncopy="alert('Copying forbidden!');return false" readonly :loading="profileLoader"
              v-model="profileData.CLIENT_ID" label="CLIENT CODE"></v-text-field>
          </v-col> -->
          <v-col cols="12" sm="6" md="3">
            <v-text-field oncopy="alert('Copying forbidden!');return false" :loading="profileLoader" readonly
              label="DP ID" v-model="profileData.DP_ID"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field oncopy="alert('Copying forbidden!');return false" readonly :loading="profileLoader"
              v-model="clientdpcode" label="Client ID"></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field oncopy="alert('Copying forbidden!');return false" readonly :loading="profileLoader"
              v-model="profileData.DP_NAME" label="DP NAME"></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-overlay :value="blurLoader" class="rounded-0" style="z-index:2000">
      <div class="text-center">
        <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
      </div>
    </v-overlay>
    <v-snackbar style="z-index: 9999" class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white"
      transition="slide-x-reverse-transition" v-model="errorSnackbar" :timeout="4000" :value="true" color="error">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      {{ errtext }}
      <v-icon @click="errorSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <v-snackbar class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white"
      transition="slide-x-reverse-transition" v-model="successSnackbar" :timeout="4000" :value="true" color="#43A833">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      {{ Succtext }}
      <v-icon @click="successSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <div v-if="((profileData.POA == 'N') && (profileData.DDPI == 'N'))" class="mt-8" id="app">
      <v-dialog v-model="confirmation" persistent max-width="380">
        <v-card class="rounded-lg px-2 px-md-2">
          <v-card-title class="pr-2">Cancel request? <v-spacer></v-spacer>
            <v-btn icon @click="confirmation = false">
              <v-icon>mdi-close</v-icon>
            </v-btn></v-card-title>
          <v-card-text>
            Are you sure you want to cancel your <b>"{{ this.editType }}"</b> request?
          </v-card-text>
          <v-card-actions class="pb-4">


            <!-- <v-btn
                height="40px"
                block
                color="red darken-1 text-none"
                text
                @click="deleteBankapi"
              >
                Delete
              </v-btn> -->
            <v-btn elevation="0" color="black white--text" rounded block @click="rejectEsign">
              <span class="text-none ">Proceed</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- <div class="px-4 ">
              <p class="font-weight-bold fs-22 mb-0">Demat Debit and Pledge Instruction (DDPI)</p>
              <p class="subtitle-2 txt-666">DDPI is a document that allows a broker to debit the securities from the client's demat account and deliver them to the exchange.</p>

              <v-card
              class="pa-md-6 mb-6" elevation="0"
                :outlined="$vuetify.breakpoint.smAndDown?false:true"
                width="100%" >
              <v-card v-if="profileData.DDPI == 'Y'"
                  height="18"
                  elevation="0"
                  class="px-2 mt-1 ml-2"
                  style="display: inline; float: right"
                  color="#43A833">
                  <p class="pa-0 mb-0"
                    style="font-size: 10px; color: white; margin-top: 2px">
                    Enabled
                  </p>
                </v-card>
                <v-card
                  v-else
                  height="18"
                  elevation="0"
                  class="px-2 mt-1 ml-2"
                  style="display: inline; float: right"
                  color="red">
                  <p
                    class="pa-0 mb-0"
                    style="font-size: 10px; color: white; margin-top: 2px"
                  >
                    Not enabled
                  </p>
                </v-card>
                <p class="subtitle-1 txt-666 font-weight-medium">
                  Do you want to sell your stocks without CDSL T-PIN
                </p>
                <v-row class="mt-2" no-gutters>
                  <v-col>
                    <v-btn
                      v-if="
                        mobStatus.DDPI_status != 'e-signed pending' &&
                        mobStatus.DDPI_status != 'e-signed completed'
                      "
                      :block="$vuetify.breakpoint.xsOnly? true:false" 
                      elevation="0"
                      color="black white--text"
                      :disabled="((profileData.DDPI == 'N')&&(equty.length >= 1))?false:true"
                      rounded
                      :loading="emailLoader"
                      @click="ddpiConfirm = true"
                    >
                      <span class="text-none px-8">Activate DDPI</span>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-card
                  rounded="lg"
                  v-if="mobStatus.DDPI_status == 'e-signed pending'"
                  color="#FCEFD4"
                  elevation="0"
                  class="mt-4"
                >
                  <div class="px-2 py-1">
                    <p
                      style="
                        font-family: 'Inter';
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      "
                    >
                      Your DDPI request is not yet Completed,<span
                        style="color: #0037b7; cursor: pointer"
                        @click="
                          digioEsign(
                            mobStatus.DDPI_fileid,
                            mobStatus.DDPI_client_email,
                            mobStatus.DDPI_session
                          ),
                            (editType = 'DDPI')
                        "
                      >
                        Click here esign</span
                      >
                    </p>
                  </div>
                </v-card>
                <v-card
                  rounded="lg"
                  v-if="mobStatus.DDPI_status == 'e-signed completed'"
                  color="#FCEFD4"
                  elevation="0"
                  class="mt-4"
                >
                  <div class="px-2 py-1">
                    <p
                      style="
                        font-family: 'Inter';
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      "
                    >
                      Your DDPI request is in process
                    </p>
                  </div>
                </v-card>
              </v-card>

              <p class="subtitle-2 txt-666 mt-4">
                *As per the regulation, DDPI activation will be one time process.
              </p>
            </div> -->
      <div class="px-4 ">
        <div class="px-md-3">
          <!-- <p class="font-weight-bold fs-22 mb-0">Demat Debit and Pledge Instruction (DDPI)</p>
          <p class="subtitle-2 txt-666">DDPI is a document that allows a broker to debit the securities from the client's
            demat account and deliver them to the exchange.</p>
         -->
        </div>
        <div>

        </div>
        <!-- <v-card class="py-md-6 px-md-3 pa-0 mb-6 pr-3" elevation="0"
          :outlined="$vuetify.breakpoint.smAndDown ? false : true" width="100%"> -->

        
          <!-- <p v-if="activeSeg" class="subtitle-1 txt-666 font-weight-medium">Equity Segment Inactive, Equity Segment must
            be active <a @click="selectedItem = 3">Click here to activate</a></p>        
              <v-row class="mt-2" no-gutters>
            <v-col>
              <v-btn dense :block="$vuetify.breakpoint.xsOnly ? true : false" elevation="0" color="black white--text"
                :disabled="((profileData.DDPI == 'N') && (activeSeg == false) && (mobStatus.DDPI_status != 'e-signed pending' &&
                  mobStatus.DDPI_status != 'e-signed completed')) ? false : true" rounded :loading="emailLoader"
                @click="ddpiConfirm = true">
                <span class="text-none px-8">Activate DDPI</span>
              </v-btn>
            </v-col>
          </v-row> -->
          
        <!-- </v-card> -->

        <!-- <p class="subtitle-2 txt-666 mt-4">
          *As per the regulation, DDPI activation will be one time process.
        </p> -->
        <!-- <v-card rounded="lg" v-if="mobStatus.DDPI_status == 'e-signed pending'" color="#FCEFD4" elevation="0"
            class="mt-4">
            <div class="px-2 py-1">
              <p style="
                        font-family: 'Inter';
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
                Your DDPI request is not yet Completed,<span style="color: #0037b7; cursor: pointer" @click="
                  digioEsign(
                    mobStatus.DDPI_fileid,
                    mobStatus.DDPI_client_email.toLowerCase(),
                    mobStatus.DDPI_session
                  ),
                  (editType = 'DDPI')
                  ">
                  Click here esign</span>
                <span @click="editType = 'DDPI', fileide = mobStatus.DDPI_fileid, confirmation = true" class="mt-1 mr-2"
                  style="color:red;cursor: pointer;float:right">Cancel request</span>
              </p>
            </div>
          </v-card> -->
          <!-- <v-card rounded="lg" v-if="mobStatus.DDPI_status == 'e-signed completed'" color="#FCEFD4" elevation="0"
            class="mt-4">
            <div class="px-2 py-1">
              <p style="
                        font-family: 'Inter';
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
                Your DDPI request is in process
               
              </p>
            </div>
          </v-card> -->
      </div>
      <!-- DDPI POP UP START -->
      <v-dialog v-model="ddpiConfirm"
        :transition="$vuetify.breakpoint.mobile ? 'dialog-bottom-transition' : 'scale-transition'" persistent
        :fullscreen="$vuetify.breakpoint.mobile" :max-width="addNewSeg ? '500' : '430'" min-width="390">
        <v-card class="rounded-lg px-2 px-md-4">
          <v-form ref="ddpiform">

            <v-card-title class="mb-1 pr-0 black--text font-weight-medium" style="font-size:20px">Debit alert
              <v-spacer></v-spacer>
              <v-btn class="mt2" icon @click="ddpiConfirm = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="pb-1">


              <div class="mx-auto pt-4" align="center">
                <p class="text-center mb-0 subtitle-1 font-weight-bold"> DDPI one time charges </p>

                <v-row align="center" justify="center">
                  <v-col cols="12" sm="6">
                    <v-alert width="100" class="px-4 mt-2 black--text" dense color="black" border="left" text
                      icon="mdi-currency-inr">
                      <strong>250</strong>
                    </v-alert>
                  </v-col>
                </v-row>
                <!-- <v-alert
      dense
      text
      icon="mdi-currency-inr"
    >

       <strong>250</strong> 
    </v-alert> -->
                <p class="mb-1 subtitle-1 font-weight-medium">
                  Available ledger
                </p>
                <p>
                  ₹ {{ leadgerBal }}
                </p>
                <v-checkbox v-if="leadgerBal >= 250" :rules="nomineeField" hide-details :on-icon="'mdi-checkbox-marked-circle'"
                  :off-icon="'mdi-checkbox-blank-circle-outline'"
                  label="I acknowledge to debit amount from my ledger"></v-checkbox>
              </div>

              <v-alert class="mb-0 mt-2 py-1" v-if="leadgerBal >= 250 ? false : true" dense outlined type="info"
                color="red" elevation="0">
                <p style="font-size:14px;" class="text-center mb-0">Insufficient balance, Add fund
                  <a :href="`https://fund.mynt.in/?uid=${actid}&token=${susertoken}`"><b>Click here</b></a>
                </p>
              </v-alert>
            </v-card-text>


            <v-card-actions class="pb-4">
              <v-row no-gutters>
                <!-- <v-col>
                <v-btn
                  block
                  color="grey darken-1"
                  text
                  @click="ddpiConfirm = false"
                >
                  Cancel
                </v-btn>
              </v-col> -->
                <v-col>
                  <!-- :disabled="leadgerBal >= 250?false:true" -->
                   <!-- {{profileData.Pan_Name}} -->
                  <v-btn v-if="leadgerBal >= 250" :loading="ddpisumload" color="black white--text" :disabled="leadgerBal >= 250 ? false : true" rounded elevation="0"
                    dense block @click="ddpiSubmit">
                    Submit
                  </v-btn>

                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <!-- DDPI POP UP END -->
    </div>
    <div class="px-4 " v-if="profileData.DDPI != 'Y' || profileData.POA != 'Y'">
        <div class="px-md-3  ">
          <v-list-item class="pl-0 pl-md-1 ">
            <v-list-item-content>
                <v-list-item-title class="text-start"
                    style="color: #000000;font-size: 20px;font-weight:559;line-height:24px;letter-spacing:-1px">
                    Demat Debit and Pledge Instruction (DDPI)</v-list-item-title>
                <v-list-item-subtitle class="text-start pt-1"
                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">DDPI is a document that allows a broker to debit the securities from the client's
                    demat account and deliver them to the exchange.
                </v-list-item-subtitle>
            </v-list-item-content>
       </v-list-item>


          <!-- <p class="font-weight-bold fs-22 mb-0">Demat Debit and Pledge Instruction (DDPI)</p>
          <p class="subtitle-2 txt-666">DDPI is a document that allows a broker to debit the securities from the client's
            demat account and deliver them to the exchange.</p> -->
          <!-- ui button/ -->
        </div>
        <div>

        </div>
        <v-card class="py-md-6 px-md-3 pa-0 mb-6 pr-3" elevation="0"
          :outlined="$vuetify.breakpoint.smAndDown ? false : true" width="100%">

          <p class="subtitle-1 txt-666 font-weight-medium">
            Do you want to sell your stocks without CDSL T-PIN
          </p>
          <p v-if="activeSeg" class="subtitle-1 txt-666 font-weight-medium">Equity Segment Inactive, Equity Segment must
            be active <a @click="selectedItem = 3">Click here to activate</a></p>          <v-row class="mt-2" no-gutters>
            <v-col>
              <v-btn v-if="accountstatus != 'client_close'" dense :block="$vuetify.breakpoint.xsOnly ? true : false" elevation="0" color="black white--text"
                :disabled="((profileData.DDPI == 'N') && (activeSeg == false) && (mobStatus.DDPI_status != 'e-signed pending' &&
                  mobStatus.DDPI_status != 'e-signed completed')) ? false : true" rounded :loading="emailLoader"
                @click="ddpiConfirm = true">
                <span class="text-none px-8">Activate DDPI</span>
              </v-btn>
              <div v-if="accountstatus == 'client_close'">
                  <v-tooltip color="black" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn disabled class="text-none"  elevation="0" color="black white--text" rounded>
                          Activate DDPI
                        </v-btn>
                      </span>
                    </template>
                    <span>Your trading account is closed</span>
                  </v-tooltip>

                </div>
            </v-col>
          </v-row>
          <v-card rounded="lg" v-if="mobStatus.DDPI_status == 'e-signed pending'" color="#FCEFD4" elevation="0"
            class="mt-4">
            <div class="px-2 py-1">
              <p style="
                        font-family: 'Inter';
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
                Your DDPI request is not yet Completed,<span style="color: #0037b7; cursor: pointer" @click="
                  digioEsign(
                    mobStatus.DDPI_fileid,
                    mobStatus.DDPI_client_email.toLowerCase(),
                    mobStatus.DDPI_session
                  ),
                  (editType = 'DDPI')
                  ">
                  Click here esign</span>
                <span @click="editType = 'DDPI', fileide = mobStatus.DDPI_fileid, confirmation = true" class="mt-1 mr-2"
                  style="color:red;cursor: pointer;float:right">Cancel request</span>
              </p>
            </div>
          </v-card>
          <v-card rounded="lg" v-if="mobStatus.DDPI_status == 'e-signed completed'" color="#FCEFD4" elevation="0"
            class="mt-4">
            <div class="px-2 py-1">
              <p style="
                        font-family: 'Inter';
                        font-size: 10.5px;
                        font-weight: 459;
                        margin: 0;
                        display: inline;
                        color: #666666;
                      ">
                Your DDPI request is in process
                <!-- <span style="color: #0037B7;cursor: pointer;"> Click here esign</span> -->
              </p>
            </div>
          </v-card>
        </v-card>

        <p class="subtitle-2 txt-666 mt-4">
          *As per the regulation, DDPI activation will be one time process.
        </p>
      </div>
  </div>
</template>
<script src="https://app.digio.in/sdk/v10/digio.js"></script>

<script>
const script = document.createElement("script");
script.src = "https://app.digio.in/sdk/v10/digio.js";
document.body.appendChild(script);
import api from "@/Api.js";

import axios from "axios";
export default {
  // name: "BarChart",
  ddpisumload:false,

  components: {},
  computed: {
    mobileNumberRules() {
      return [
        (value) => /^\d{10}$/.test(value) || "Enter a valid number",

      ];
    },
    otpRules() {
      return [
        (value) => !!value || "OTP Required",
        (value) => /^\d{4}$/.test(value) || 'Must be exactly 4 digits',
      ];
    },
    nomineeField() {
      return [(value) => !!value || "Required field"];
    },
    emailNumberRules() {
      return [
        (value) => !!value || "email id is required",
        (value) => /.+@.+\..+/.test(value) || "Email must be valid",
      ];
    },
    depedselectRules() {
      return [(value) => !!value || "Dependent name is required"];
    },
  },
  data() {
    return {
      leadGer: '',
      smAndDown: null,
      txt: "",
      accountNum: "",
      upiDisabled: false,
      verifyBtn: true,
      upiLoader: false,
      errtext: "",
      errorRestextsnackbar: false,
      errorRestext: '',
      snackbar: false,
      checkClientVPA: [],
      verfy: true,
      // items: [],
      isHidden: true,
      client_code: null,
      name: null,
      email: null,
      mobile: null,
      acc_no: null,
      ifsc: null,
      loading: false,
      selection: 0,
      url_data: null,
      oid: null,
      amount: null,
      valid: true,
      vpa: null,
      attrs: null,
      company: null,
      company_code: [],
      amountRules: [
        (v) => !!v || "Enter Amount",
        (v) => (v && v >= 10) || "Should be above 10",
      ],
      upiRules: [
        (v) => !!v || "Enter UPI ID",
        (v) => /.+@.+/.test(v) || "Invalid UPI ID",
      ],
      submit_this_val: null,
      bankDetails: [],
      // snackbar: false,
      clietData: "",
      togg: false,
      selectionAtom: 0,
      profileData: [],
      colatral: "",
      message: "Hai da Goopal",
      secretKey: "asdfghjklzxcvbnm",
      dataToEncrypt: "Sensitive information you want to encrypt.",
      token: "",
      publicIP: "",
      statusChk: [],
      Succtext: "",
      successSnackbar: false,
      profileLoader: true,
      BankData: [],
      submitBtndisable: true,
      equty: [],
      commoditi: [],
      derivat: [],
      editdialog: false,
      ddpisumload:false,
      //asdkjbnasdl
      chha: false,
      nifity: true,
      widthdat: [50, 50, 50, 50, 80],
      chip1: true,
      chip2: true,
      chip3: true,
      chip4: true,
      linkUrl: "#",
      zebu: "https://zebuetrade.com/",
      toggleMenu: true,
      drawer: false,
      selectedItem: 0,
      tab: 0,
      mobileNumber: "",
      cardTitle: "",
      dependency: ["Self", "Spouse", "child Dependent", "parents Dependent"],
      dependencyValue: "Self",
      number: true,
      otpvalid: "",
      otpValue: "",
      dependentName: "",
      headerProps: {
        class: "custom-header",
      },
      subTitle: "",
      mobileVerification: false,
      emailVerification: false,
      emailid: "",
      emailvalid: true,
      cardLoader: false,
      addressVerification: false,
      newNomineepercent: "",
      newNomineeAddress: "",
      newNomineeDob: "",
      newNomineeRelation: "",
      newNomineeName: "",
      nomineePop: false,
      personalDetail: false,
      activePicker: null,
      date: null,
      datee: null,
      datento: null,
      dateento: null,
      datenthre: null,
      dateenthre: null,

      menuu: false,
      menunto: false,
      menuunto: false,

      menunthre: false,
      menuunthre: false,
      menu: false,
      newNomineeProof: "",
      relationNominee: [
        "Mother",
        "Father",
        "Brother",
        "Sister",
        "Daughter",
        "Wife",
        "Husband",
        "Son",
        "Grand-Son",
        "Grand-Father",
        "Grand-Daughter",
        "Grand-Mother",
      ],
      newNomineeCity: "",
      newNomineePincode: "",
      newNomineeState: "",
      newNomineeCountry: "",
      pinLoader: false,
      nomineeForm: false,
      yesorno: "no",
      nomineeYes: false,
      nomineeMobile: "",
      nomineeEmail: "",
      checkbox: true,
      newNomineePan: "",

      nomineeMobilento: "",
      nomineeEmailnto: "",
      checkboxnto: true,
      newNomineePannto: "",

      nomineeMobilenthre: "",
      nomineeEmailnthre: "",
      checkboxnthre: true,
      newNomineePannthre: "",
      newNomineeNamenthre: "",
      newNomineeRelationnthre: "",

      optionOfupdate: "",
      radioBtn: false,
      nomineesubmitBtn: false,
      submissionText: "",
      filedegio: "",
      esignBtn: false,
      newNomineeGuardian: "",
      newNomineeGuardiannto: "",
      newNomineeGuardiannthre: "",

      belowAge: false,
      belowAgento: false,
      belowAgenthre: false,

      newNomineeGuardianRelation: "",
      newNomineeGuardianRelationnto: "",
      newNomineeGuardianRelationnthre: "",
      newNomineegurdianmobile: "",
      newNomineegurdianmobilento: "",
      newNomineegurdianmobilenthre: "",

      checkboxTow: false,
      checkboxThree: false,

      newNomineeNameto: "",
      newNomineeRelationto: "",

      newNomineepercentone: 100,
      newNomineepercentnto: 1,
      newNomineepercentnthre: 1,
      max: 100,
      secnompercentmax: 0,
      thernompercentmax: 0,
      hashpan: "",

      newNomineeGuardianEmail: "",
      newNomineeGuardianAddress: "",
      newNomineeGuardianPincode: "",
      newNomineeGuardianState: "",
      newNomineeGuardianCity: "",
      newNomineeGuardianCountry: "",

      newNomineeGuardianEmailnto: "",
      newNomineeGuardianAddressnto: "",
      newNomineeGuardianPincodento: "",
      newNomineeGuardianStatento: "",
      newNomineeGuardianCitynto: "",
      newNomineeGuardianCountrynto: "",

      newNomineeGuardianEmailnthre: "",
      newNomineeGuardianAddressnthre: "",
      newNomineeGuardianPincodenthre: "",
      newNomineeGuardianStatenthre: "",
      newNomineeGuardianCitynthre: "",
      newNomineeGuardianCountrynthre: "",

      nominee2: false,
      newNomineeName2: "",
      newNomineeRelation2: "",
      newNomineeDob2: "",
      newNomineepercent2: "",

      nominee3: false,
      newNomineeName3: "",
      newNomineeRelation3: "",
      newNomineeDob3: "",
      newNomineepercent3: "",

      usernewmobile: "",
      newmobilerules: [
        (v) => !v || v.length <= 10 || "Enter a valid mobile number",
        (v) => !!v || "Mobile is required",
      ],
      requestotp: true,
      verifyotp: false,
      userOtp: "",
      editType: "",
      mobStatus: [],
      mobileDisable: false,
      usernewemail: "",
      requestotpemail: false,
      useremailOtp: "",
      verifyotpemail: false,
      usernewAdress: "",
      adresschange: false,
      proofTypes: "",
      prooftype: ["Aadhar card", "Passport", "VoterID", "Driving licence"],
      emailDisable: false,
      clientdpcode:'',
      adressproffdoc: null,
      manual: false,
      addressDisable: false,
      blurLoader: false,
      emailLoader: false,
      addressLoader: false,
      mobileLoader: false,
      userEmailadress: "",
      stateAddress: "",
      countryAddress: "",
      distAddress: "",
      pincodeAddress: "",

      // BANK INIT
      bankproffdoc: null,
      bankAccountnumber: "",
      bankIfscCode: "",
      bankchange: false,

      ifscBankInfo: [],
      // setDefault:'',
      existingBanks: [],
      defaultCheckbox: false,
      confirmation: false,
      deletebank: [],
      bankLoader: false,
      annualIncomechange: false,
      selectionas: null,
      proofField: false,
      incorrectPass: false,
      passwordField: false,
      typesOfimg: [
        "Latest 6 months Bank Statement",
        "Latest ITR Copy",
        "Latest 6 months salary slip",
        "DP holding statement as on date",
        "Net worth Certificate",
        "Copy of Form 16 in case of salary income",
      ],
      imgType: null,
      typeProof: "",
      pdfPassword: "",
      passwordeye: false,
      paswordverification: true,
      annualuserOtp: "",
      annualIncomeverifyotp: false,
      items: ["Below 1L", "1L to 5L", "5L to 10L", "10L to 25L", "Above 25L"],
      itemSelected: "",
      yearlyIncomeDisable: false,
      segments: false,
      nsecash: false,
      bsecash: false,
      mfbse: false,
      bsefno: false,
      nsefno: false,
      cdnse: false,
      cdbse: false,
      // cdbse:false,
      bsecom: false,
      nsecom: false,
      icex: false,
      mcx: false,
      segOpt: false,
      addNewSeg: false,
      activateSeg: false,
      tok: "",

      nsecashDisable: false,
      bsecashDisable: false,
      mfDisable: false,

      bsefnoDisable: false,
      nsefnoDisable: false,
      cdnseDisable: false,
      cdbseDisable: false,

      bsecomDisable: false,
      nsecomDisable: false,
      icexDisable: false,
      mcxDisable: false,

      activateSegBtn: false,
      addsegtype: "",
      newSegements: [],
      ddpiConfirm: false,

      segmentbankproffdoc: null,
      inactiveSegments: [],
      activeSeg: '',
      leadgerBal: 0,

      existingSegments: [],
      allSegments: [
        "BSE_CASH",
        "MF_BSE",
        "NSE_CASH",
        "BSE_FNO",
        "NSE_FNO",
        "CD_NSE",
        "CD_BSE",
        "BSE_COM",
        "NSE_COM",
        "ICEX",
        "MCX",
      ],
      newSegments: [],
      newSegmentSelected: [],
      bankproffdocument: false,
      addsegtypeNew: "",
      mfnse: "",
      nseslbm: "",
      deactivateConfirmation: false,
      aadharstate: "",
      aadharcode: "",
      banklogo: "https://rekycbe.mynt.in/autho",
      reasons: [
        "High brokerage and charges",
        "Monthly maintenance charges",
        "Need better margins",
        "Problem with payment and withdrawal",
        "Need better order placement options",
        "App crashes",
        "Issues with back office portals",
        "Problem with customer service",
        "App is complicated to understand",
        "Faced losses",
        "No time to focus on trading",
      ],
      closureReason: "",
      deactivateForm: true,
      deactivateCanceled: false,
      itemsaaass: [
        { text: "My Account" },
        {
          text: "Profile Details",
          icon: "",
          link: "/accountpage",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Bank Accounts Linked",
          icon: "",
          link: "/bankaccount",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Set Auto Pay",
          icon: "",
          link: "/autopay",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Margin against stocks",
          icon: "",
          link: "/noticationsettings",
          mdiicon: "mdi-chevron-right",
        },
        { text: "Manage Funds", link: "secufund" },
        {
          text: "Securities Funds ",
          icon: "",
          link: "managefund",
          mdiicon: "mdi-chevron-right",
        },
      ],
      actid: "",
      susertoken: "",
      noms: [
        { txt: "Yes, add nominee", val: "yes" },
        { txt: "No, i will do it later", val: "no" },
      ],
      bankprooftype: [
        { txt: "Saving Account", val: "Saving Account" },
        { txt: "Current Account", val: "Current Account" },
      ],
      bankproofTypes: "Saving Account",
      file: null,
      dragging: false,
      panimagedata: [],
      panimage: null,
      panFile: "",
      BankProofselect: "bs",
      customLabel: "Nominee address is same as my address",
      panel: 0,
      cardloader: false,
      dissBtn: true,
      dissBtn2: true,
      dissBtn3: true,
      activetab: "tab1",
      nosegmentsPop: false,
      newFamilyRelation: "",
      memberId: "",
      memberMobile: "",
      memberPan: "",
      validFamily: "",
      familyloader: false,
      familySubmission: false,
      endtxt: '',
      familyRequestData: '',
      familystatusloading: false,
      familyrequest: false,
      selectTableitem: [],
      mobileMember: '',
      disabled: false,
      readonly: false,
      errorSnackbar: false,
      fileide: '',
      accountstatus:""
    };
  },

  methods: {
    rejectEsign() {
      let data = JSON.stringify({
        "client_id": this.profileData.CLIENT_ID,
        "file_id": this.fileide,
        "type": this.editType
      });

      let config = {
        method: 'post',
        url: api.api_url + '/manual_cancel_request',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          this.confirmation = false
          // console.log(JSON.stringify(response.data));
          if (response.data.msg == 'canceled successfully') {
            this.Succtext = 'Canceled successfully'
            this.successSnackbar = true
            this.fetchMobileStatus()
            location.reload();
          } else {
            this.errtext = response.data.msg
            this.errorSnackbar = true
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goSso() {
      // if( data)
      // window.location.assign(`http://192.168.5.152:8082/?url=${window.location.href}`)

      window.location.assign(
        `https://desk.mynt.in/?url=${window.location.href}`
      );
    },
    encryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      var derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      var iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      var test = CryptoJS.AES.encrypt(payload, derived_key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString();
      return test;
    },
    decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      // console.log('Decrypted Data:', decryptedData);
      return decryptedData;
    },
    getAvailBal() {
      var axiosThis = this;
   

   
          axiosThis.blurLoader = false;
          axiosThis.profileLoader = false;
          // console.log(response)
       
        
              axiosThis.equty = [];
              axiosThis.derivat = [];
              axiosThis.commoditi = [];

              // console.log(response.data.str)
              var res = axiosThis.decryptionFunction(localStorage.getItem("profile_data"));
              console.warn(JSON.parse(res));
              var resp = JSON.parse(res);
              axiosThis.profileData = resp.client_data;
              axiosThis.hashpan = axiosThis.profileData.PAN_NO.slice(-3);
              axiosThis.BankData = resp.bank_data;

              axiosThis.name = axiosThis.profileData.CLIENT_NAME;
              localStorage.setItem('clinet_name', axiosThis.name)

              axiosThis.client_code = axiosThis.profileData.CLIENT_ID;
              axiosThis.clientdpcode = axiosThis.profileData.CLIENT_DP_CODE.slice(-8)

              if (axiosThis.profileData.Nominee_Name) {
                // axiosThis.multinominee = resp.
                axiosThis.nomineeYes = true;
                axiosThis.newNomineeName = axiosThis.profileData.Nominee_Name;
                axiosThis.newNomineeRelation =
                  axiosThis.profileData.Nominee_Relation;
                axiosThis.newNomineeDob = axiosThis.profileData.Nominee_DOB;
                // axiosThis.newNomineepercent = axiosThis.profileData.Nominee_Name
              }
              axiosThis.fetchMobileStatus();

              // else {
              //   axiosThis.nomineeYes = false

              // }
              axiosThis.profileData.CL_RESI_ADD1 =
                axiosThis.profileData.CL_RESI_ADD1 +
                " " +
                axiosThis.profileData.CL_RESI_ADD2 +
                " " +
                axiosThis.profileData.CL_RESI_ADD3;
              for (
                var i = 0;
                i < axiosThis.profileData.segments_data.length;
                i++
              ) {
                // console.log(axiosThis.profileData.segments_data[i].COMPANY_CODE);
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "BSE_CASH" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "MF_NSE" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "NSE_CASH" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "MF_BSE"
                ) {
                  axiosThis.equty.push(axiosThis.profileData.segments_data[i]);
                }
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "NSE_FNO" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "BSE_FNO" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "CD_BSE" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "CD_NSE"
                ) {
                  axiosThis.derivat.push(
                    axiosThis.profileData.segments_data[i]
                  );
                }
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "BSE_COM" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "NSE_COM" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  "MCX" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE == "ICEX"
                ) {
                  axiosThis.commoditi.push(
                    axiosThis.profileData.segments_data[i]
                  );
                }
              }
              // console.log(axiosThis.derivat);
              // axiosThis.payout.ledger_amount = axiosThis.payout.ledger_amount.toFixed(2)
              // axiosThis.payout.withdraw_amount = axiosThis.payout.withdraw_amount.toFixed(2)

              // axiosThis.cashledg = axiosThis.payout.ledger_amount
            
          
       
          axiosThis.getLeDGERbAL()
  
        
    },
    usercopytest() {
      // console.log(this.usernewemail.toUpperCase(),this.profileData.CLIENT_ID_MAIL.toUpperCase())
      if (
        this.usernewemail.toUpperCase() ==
        this.profileData.CLIENT_ID_MAIL.toUpperCase()
      ) {
        this.usernewemail = "";
        // this.errorSnackbar = true;
        this.errtext = "Enter your new email id";
      }
    },
    fetchMobileStatus() {
      var axiosThis = this;
      let data = JSON.stringify({
        client_id: this.profileData.CLIENT_ID,
      });

      let config = {
        method: "post",
        url: api.api_url + "/add_mob_email_stat",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "application/json",
        },
        data: data,
      };
      // console.log("jhasdgajb",this.client_code,this.tok)
      axios
        .request(config)
        .then((response) => {
          // console.log(response.data);
          if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "clientid not exist") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
          axiosThis.blurLoader = false;
          axiosThis.mobStatus = response.data;

          // if(axiosThis.mobStatus.DDPI_status == "" || axiosThis.mobStatus.DDPI_status == ""){

          // }
          if (
            axiosThis.mobStatus.mobile_status == "e-signed pending" ||
            axiosThis.mobStatus.address_status == "e-signed pending" ||
            axiosThis.mobStatus.email_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.mobileDisable = true;
            axiosThis.emailDisable = true;
            axiosThis.addressDisable = true;
          }
          if (
            axiosThis.mobStatus.address_status == "e-signed completed" ||
            axiosThis.mobStatus.address_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.addressDisable = true;
            // axiosThis.pdfdownloard = axiosThis.mobStatus.DDPI_e_signed_pdf_path 


          }
          if (
            axiosThis.mobStatus.mobile_status == "e-signed completed" ||
            axiosThis.mobStatus.mobile_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.mobileDisable = true;
          }
          if (
            axiosThis.mobStatus.email_status == "e-signed completed" ||
            axiosThis.mobStatus.email_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.emailDisable = true;
          }
          if (
            axiosThis.mobStatus.income_status == "e-signed completed" ||
            axiosThis.mobStatus.income_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.yearlyIncomeDisable = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    digioEsign(file, email, session) {
      var axo = this;
      this.blurLoader = true;
      axo.editdialog = false;
      // console.log(file, email,session)
      // localStorage.setItem('fileid', this.digioFile)
      var options = {
        environment: "production",
        callback: function (t) {
          // axo.statusCheckNominee(axo.client_code);
          // console.log(t);
          // console.log(axo.stata);
          axo.blurLoader = false;

          if (axo.stata != undefined) {
            if (axo.stata.app_status == "e-signed pending") {
              axo.radioBtn = true;
              axo.nomineesubmitBtn = true;
            } else {
              axo.radioBtn = false;
              axo.nomineesubmitBtn = false;
            }
          } else {
            axo.radioBtn = false;
            axo.nomineesubmitBtn = false;
          }

          if (t.message == "Signing cancelled") {
            // if(axo.editType == 'mobile_change') {
            axo.filedownloadDatabase("failure", file);
            axo.fetchMobileStatus();

            // }
            // if(axo.editType == 'email_change') {
            // axo.filedownloadDatabase("failure", file);
            // axo.fetchMobileStatus()

            // }
            // if(axo.editType == 'address_change') {
            // axo.filedownloadDatabase("failure", file);
            // axo.fetchMobileStatus()
            // }
          } else if (t.message == "Signed Successfully") {
            // axo.filedownloadDatabase("success", axo.filedegio?axo.filedegio:axo.stata.file_id);
            // if(axo.editType == 'mobile_change') {
            axo.filedownloadDatabase("success", file);
            // }
            // if(axo.editType == 'email_change') {
            // axo.filedownloadDatabase("success", file);
            // }
            // if(axo.editType == 'address_change') {
            // axo.filedownloadDatabase("success", file);
            // }
          }

          if (t["hasOwnProperty"]("error_code")) {
            // document.getElementById("loading").style.display='none';
            //// console.log('Error Digio')
            //// console.log(t.message)
          } else {
            //document.getElementById("result").innerHTML="API Mandate Authentication Successful"
            //successDigio();
            //// console.log(this.step, this.ekycStatus);
            setTimeout(function () {
              //code goes here
              window.location.reload();
            }, 4500);
          }
        },
      };

      var digio = new Digio(options);
      digio.init(); // Call init on user action (eg.button press and proceed to asynchronous upload)
      // digio.submit(this.digioFile, this.digioMail,);
      // console.log(file,email,session)
      digio.submit(file, email, session);
      // digio.submit("DID230911100935062BG8PAZ3INK43XM", "mageshjack002@gmail.com","GWT2309111009374289K3NVXB6RHUEDO");

      // console.log(digio)
    },
    filedownloadDatabase(stat, fileid) {
      let data = JSON.stringify({
        client_id: this.client_code,
        file_id: fileid,
        response: stat,
        type: this.editType,
      });

      let config = {
        method: "post",

        // url: 'http://192.168.5.121:5555/filedownload',

        url: api.api_url + "/filedownload",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "application/json",
        },
        data: data,
      };
      let axiosThis = this;

      axios
        .request(config)
        .then((response) => {
          if (response.data.emsg == "token expired") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Expired";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();
              window.location.reload();
            }, 2000);
          }
          if (response.data.emsg == "invalid token") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Invalid";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();

              window.location.reload();
            }, 2000);
          }
          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ddpiSubmit() {


      var valid = this.$refs.ddpiform.validate();

      console.log("valid")
      if (valid == true) {
this.ddpisumload = true

        this.editType = "DDPI";

        let data = JSON.stringify({
          "CLIENT_ID": this.client_code,
          "CLIENT_NAME": this.profileData.Pan_Name,
          "MOBILE_NO": this.profileData.MOBILE_NO,
          "CLIENT_ID_MAIL": this.profileData.CLIENT_ID_MAIL,
          "CL_RESI_ADD3": this.profileData.CL_RESI_ADD1,
          "CLIENT_DP_CODE": this.profileData.CLIENT_DP_CODE,
          "PAN_NO": this.profileData.PAN_NO,
        });

        let config = {
          method: 'post',
          url: api.api_url + '/DDPI',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
        var axiosThis = this

        axios.request(config)
          .then((response) => {
      axiosThis.ddpisumload = false,

            axiosThis.ddpiSubmitResponse = response.data
            if ((response.data.fileid) && (response.data.session)) {
              axiosThis.ddpiConfirm = false
              axiosThis.digioEsign(
                response.data.fileid,
                response.data.email,
                response.data.session
              );
            }else{
              axiosThis.errorRestextsnackbar = true
              axiosThis.errorRestext = response.data.msg

            }
            // console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
      axiosThis.ddpisumload = false,

            console.log(error);
          });
      }
    },
    //   checkLedger() {
    //     if (this.leadGer.msg == 'OK') {
    //   this.leadgerBal = Number(this.leadGer.Total)
    // } else {
    //   this.errorRestextsnackbar = true
    //   this.errorRestext = this.leadGer.msg
    // }
    //   },
    getLeDGERbAL() {
      var axiosThis = this
      let data = JSON.stringify({
        "clientid": this.client_code
      });

      let config = {
        method: 'post',
        url: api.api_url + '/all_ledger_balance',
        headers: {
          "Authorization": this.tok,
          "Clientid": this.client_code,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          var res = response.data
          axiosThis.leadGer = res.total
          axiosThis.leadgerBal = res.total
          // console.log('11',typeof axiosThis.leadGer ,'222',axiosThis.leadgerBal);

          // if (res.msg == 'OK') {
          // } else {
          //   axiosThis.errorRestextsnackbar = true
          //   axiosThis.errorRestext = res.msg
          // }
          // console.log(typeof Number(res.Total), typeof (res.Total));
        })
        .catch((error) => {
          console.log(error);
        });

    },

  },
  mounted() {
    this.blurLoader = true;
    let params = new URL(document.location).searchParams;
    // var axiosThis = this;
    var cod = params.get("code");
    var stat = params.get("state");
    var vendorAcc = params.get("sAccountId");
    var vendorToken = params.get("sToken");
    this.accountstatus = localStorage.getItem("clientStatus")

    // console.log("jiji", vendorAcc, vendorToken);

    // console.log("////",cod,stat)
    if (cod && stat) {
      this.aadharstate = stat;
      this.aadharcode = cod;
    }
    // let decoded = decodeURIComponent(window.location.search);
    var actid = params.get("uid");
    var token = params.get("token");
    this.susertoken = localStorage.getItem("usession");
    // console.log(this.susertoken,actid,window.location.search)
    this.actid = localStorage.getItem("userid");
    if (typeof actid == "string" && typeof token == "string") {
      localStorage.setItem("usession", token);
      localStorage.setItem("userid", actid);
      this.susertoken = localStorage.getItem("usession");
      this.actid = localStorage.getItem("userid");
      window.location.assign(window.location.href.split("?")[0]);
    }
    if (vendorAcc == null && vendorToken == null) {
      // console.warn("nully")
      if (this.susertoken && this.actid) {
        var id = this.actid;
        this.tok = this.susertoken;
        // console.log("ifififififififif", this.tok);
        this.getAvailBal(id);
      } else {
        this.goSso();
      }
    }
  },
};
const $ = document.querySelector.bind(document);
const $$ = document.querySelectorAll.bind(document);

let activeLink = null;

window.addEventListener("scroll", () => {
  $$(".section").forEach((section) => {
    const { y } = section.getBoundingClientRect();

    if (y <= 0) {
      if (activeLink != null) {
        activeLink.classList.remove("active");
      }

      activeLink = $(`.nav-link[href~="#${section.id}"]`);
      activeLink.classList.add("active");
    }
  });
});
</script>