<template>
  <v-container class="pa-0  ml-md-5 mt-md-5">
    <v-alert v-if="alert" dense type="error" style="position: fixed; top: 8 ; right: 0 ; z-index: 3">
      <strong>{{ msg }}</strong>
    </v-alert>
    <div v-if="awesome" class="pt-lg-16 pa-lg-6 pa-sm-4 pa-md-4">
      <p class="lstctntit headline mb-1">Dashboard</p>
      <p class="body-1" color="#757575">Snapshot of your
        holdings and trading activity</p>

      <v-row>
        <v-col cols="12" sm="8" md="8">

          <div class="pt-4 ">
            <p class="title mb-1" color="#757575">Wealth
              segmentation</p>
            <v-card outlined class="elevation-0 rounded-lg">
              <v-row>
                <v-col cols="12" sm="5" md="5" class="my-auto">
                  <div class="pa-8">
                    <img src="@/assets/Wealth segmentation chart bw.svg" width="100%">
                  </div>
                </v-col>
                <v-col cols="12" sm="7" md="7" class="my-auto pr-5">

                  <div class="text-center ">
                    <img src="@/assets/No trades yet.svg">
                    <p class="title  mb-4">No trades yet</p>
                    <p class="body-1 px-16">You haven’t added
                      any trades to your account.
                      Start
                      adding your trades to
                      track them.</p>

                    <v-dialog v-model="dialog" width="800">

                      <template v-slot:activator="{ on, attrs }">
                        <v-btn large outlined color="#1877F2" v-bind="attrs" v-on="on">
                          Add
                          your
                          trades!</v-btn>
                      </template>

                      <v-card class="rounded-lg" style="overflow: hidden;">
                        <div>
                          <v-row class=" my-auto">
                            <v-col class=" trmnucolsl" cols="12" sm="5" md="5">
                              <div class="pt-4 px-8">
                                <p class="ctnbtl headline  mb-4" style="color: #505359;">
                                  Add your trades</p>
                                <p class="body-2 pb-0 mb-sm-16 pb-sm-16 mb-md-16 pb-md-16 " style="color: #6B7280;">
                                  You can now
                                  add your current and old trades to start
                                  tracking
                                  them.</p>

                                <p class="mb-1 mt-0 pt-0 mb-sm-1 mt-sm-16 pt-sm-16 mb-md-1 mt-md-16 pt-md-16"
                                  style="color: #000000; letter-spacing: 0.16em;">
                                  <img class="mr-3" src="@/assets/Contract Notes.svg" width="20px">CONTRACT NOTES
                                </p>
                                <p class="body-1 mb-1 pr-0 pr-sm-6 pr-md-6 mb-sm-4 mb-md-4" style="color: #6B7280;">
                                  Contract
                                  notes
                                  contain all the information about your
                                  trades on
                                  the
                                  exchanges.</p>
                              </div>
                            </v-col>
                            <v-col class="trmnucols2" cols="12" sm="7" md="7">
                              <div class="pt-1 px-8 pt-sm-5 pt-md-5">
                                <p class=" mb-4 body-2" style="color: #000000;  ">
                                  Upload Contract Note</p>
                                <p class="ctnbtl title mr-0 pr-0 pr-sm-10 mr-sm-16 pr-md-10 mr-md-16"
                                  style="line-height:28px;">Start trackr by
                                  uploading
                                  your daily contract notes</p>

                                <template>
                                  <div class="rounded-lg text-center px-10 py-5 px-sm-16 py-sm-10 px-md-16 py-md-10"
                                    style="border: 1px dashed #D0D3D6;">
                                    <img class="mb-6" src="@/assets/file drop.svg" width="25px">
                                    <p class="mb-0">You can drop your contract notes here to be uploaded</p>
                                    <v-file-input class="mt-0 pt-0 mt-sm-6 pt-sm-1 mt-md-6 pt-md-1" prepend-icon="">
                                    </v-file-input>
                                  </div>

                                </template>
                                <p class="text-center pt-6" style="color:#1877F2;">
                                  Where
                                  can you find your contract notes?</p>
                              </div>
                            </v-col>
                          </v-row>
                        </div>

                        <!-- <div>
                            <div class="pt-8 pb-10 px-8">
                              <p class=" mb-4 body-2"
                                style="color: #000000;  ">
                                Verify Transactions</p>
                              <p class="ctnbtl title mr-0 pr-0 mr-sm-16 pr-sm-10 mr-md-16 pr-md-10"
                                style="line-height:28px;">We’ve found these
                                transactions
                                from your contract notes, please verify and approve.</p>
                              <v-card class="elevation-1">
                                <v-data-table mobile-breakpoint fixed-header disable-sort :headers="headers"
                                  :items="desserts" :items-per-page="7" hide-default-footer class="elevation-0">
                                  <template v-slot:item="{ item }">
                                    <v-simple-checkbox v-model="item.chbox" disabled></v-simple-checkbox>
                                  </template>
                                </v-data-table>
                                <v-divider></v-divider>
                                <v-row class="px-3 py-3 d-none d-sm-flex d-md-flex">
                                  <v-col cols="12" sm="6" md="6" class="pb-0">
                                    <v-btn small depressed class="ma-0" @click="button">
                                      No trade selected
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6" class="pb-0 text-right">
                                    <v-btn small text color="#1877F2" class="ma-0" @click="button">
                                      Add trades
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </div>
                          </div> -->

                        <!-- <div>
                          <div class="pt-8 pb-10 px-8">
                            <p class=" ctnbtl  mb-4 body-2"
                              style="color: #000000;  ">
                              Add Transactions</p>
                            <p class="ctnbtl title mb-16" style="line-height:28px;">
                              Based on your selection, we are adding trades to your
                              account.</p>

                            <v-row class="mb-16">
                              <v-col>
                                <div>
                                  <p class="caption"
                                    style="color: #000000;  ">
                                    Total Trades Added</p>
                                  <p>
                                    <span class="ctnbtl display-1" style="line-height:28px;">15</span>
                                    trades
                                  </p>
                                </div>
                              </v-col>
                              <v-col>
                                <div>
                                  <p class="caption"
                                    style="color: #000000;  ">
                                    Total Trade Value</p>
                                  <p class="ctnbtl display-1" style="line-height:28px;">
                                    5,42,152<small>.85</small></p>
                                </div>
                              </v-col>
                            </v-row>

                            <v-btn large outlined color="#1877F2" v-bind="attrs" v-on="on">Add to trackr</v-btn>
                          </div>
                        </div> -->
                      </v-card>
                    </v-dialog>
                  </div>

                </v-col>
              </v-row>
            </v-card>
          </div>

        </v-col>

        <v-col cols="12" sm="4" md="4">

          <div class="pt-4">
            <p class="body-1 mb-1" color="#757575">P&L summary
            </p>
            <v-card outlined class="elevation-0 rounded-lg pa-5">
              <div class="mx-auto">
                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="color: #000000;   ">
                      Total Capital</p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="color: #000000;   ">
                      Realised P&L</p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="color: #000000;   ">
                      Current Value</p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="color: #000000;   ">
                      UNRealised P&L</p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>
          <div class="pt-6">
            <p class="body-1 mb-1" color="#757575">Fees & Taxes
            </p>
            <v-card outlined class="elevation-0 rounded-lg pa-5">
              <div class="mx-auto">
                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="color: #000000;   ">
                      Short term P&L</p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="color: #000000;   ">
                      Fees paid</p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <p class="body-2 mb-0" style="color: #000000;   ">
                      Long term P&L</p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                  <v-col>
                    <p class="body-2 mb-0" style="color: #000000;   ">
                      Taxes Liable</p>
                    <span class="ctnbtl title">0</span>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>
          <div class="pt-6">
            <p class="body-1 mb-1" color="#757575">Upcoming events</p>
            <v-card outlined class="elevation-0 rounded-lg px-4">
              <div>
                <v-list-item>
                  <div class="text-center pa-2">
                    <p class="pa-16">No upcoming events for
                      your portfolio</p>
                  </div>
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </v-card>
          </div>

        </v-col>
      </v-row>
    </div>

    <div v-else class="pt-lg-6 pa-lg-6 pa-sm-4 pa-md-4 ml-lg-14">
      <v-toolbar class="mb-3 toolbar-contantd px-md-3" elevation="0" color="white">

      <v-list-item-title class="text-start mt-1 ml-3"
        style="color: #000000;font-size: 20px;font-weight:559;line-height:24px;letter-spacing:-1px">
        Dashboard
        <v-list-item-subtitle class="text-start pt-1 "
        style="color: #666666;font-size: 12px;font-weight:469;line-height:14px">Snapshot of your
        holdings and trading activity
      </v-list-item-subtitle>
      </v-list-item-title>
      
      <v-spacer></v-spacer>
      <v-select style="width: 160px;" dense background-color="#F1F3F8" rounded
                                @change="yearwisedata(yearis)" :items="items" v-model="yearis" flat solo
                                class="mt-n3 dropdown  elevation-0 rounded-pill  " label="Year"
                                prepend-inner-icon="mdi-calendar" hide-details append-icon="mdi-chevron-down"></v-select>
                              </v-toolbar>
      <!-- <button @click="awesome = !awesome">toggle</button> -->
      <v-divider class=" mt-5"></v-divider>
      <!-- <div v-if="loader" class="text-center">
                      <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
                  </div> -->
        <v-row>
          <v-col>

          </v-col>
          <v-col>
           
             <v-card :loading="loader" outlined rounded-pill class="mt-6  mb-2 elevation-0 pr-6 mr-4">
                <p class="body-1 font-weight-bold ml-9 mt-4 mb-4" color="#757575" style="letter-spacing: 0.24em; ">
                    Fund 
                  </p>
                  <v-row class="pl-10 ">

                    <v-col class="pa-2" cols="8">
                      <p class="body-2  mb-2" style="color: #000000;   
                                        ">Opening Balance</p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                    ">Pay in
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Pay Out
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">JV
                                  </p>
                                  <!-- <span>{{Assets_value}}</span> -->

                                </v-col>
                                <v-col class="pa-2" cols="4">
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                
                                    {{ (maindata.opening_balance ? maindata.opening_balance.toFixed(2) : '0.00').toString() }}
                                  </p>
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                  
                                    {{ (maindata.payin ? maindata.payin.toFixed(2) : '0.00').toString() }}
                                  </p>
                                  <p  class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                
                                    {{ (maindata.payout ? maindata.payout.toFixed(2) : '0.00').toString() }}
                                  </p>
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    {{ (jv ? jv.toFixed(2) : '0.00').toString() }}
                                  </p>
                                </v-col>
                              </v-row>

                              <!--  eq -->
                              <p class="body-1 font-weight-bold ml-9 mt-4 mb-4" color="#757575" style="letter-spacing: 0.24em; ">
                    Equity 
                  </p>
                  <v-row class="pl-10 ">

                    <v-col class="pa-2" cols="8">
                      <p class="body-2  mb-2" style="color: #000000;   
                                        ">Realised P&L</p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                    ">Buy delivery turnover 
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Changes
                                  </p>
                                  
                                  <!-- <span>{{Assets_value}}</span> -->

                                </v-col>
                                <v-col class="pa-2" cols="4">
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.trading_realized ? maindata.trading_realized.toFixed(2) : '0.00').toString() }}
                                  </p>
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                     
                                    {{ (maindata.delivery_turnover ? maindata.delivery_turnover.toFixed(2) : '0.00').toString() }}
                                  </p>
                                  <p  class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.eq_charges ? maindata.eq_charges.toFixed(2) : '0.00').toString() }}
                                  </p>
                                  
                                </v-col>
                              </v-row>  
                              <!-- derivative -->
                              <p class="body-1 font-weight-bold ml-9 mt-4 mb-4" color="#757575" style="letter-spacing: 0.24em; ">
                                Derivatives 
                  </p>
                  <v-row class="pl-10 ">

                    <v-col class="pa-2" cols="8">
                      <p class="body-2  mb-2" style="color: #000000;   
                                        "> Realised P&L</p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                    "> Unrealised P&L
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Long option
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Short option
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Charges
                                  </p>
                                
                                  <!-- <span>{{Assets_value}}</span> -->

                                </v-col>
                                <v-col class="pa-2" cols="4">
                                  <p class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.fo_realised ? maindata.fo_realised.toFixed(2) : '0.00').toString() }}
                                  </p>
                                  
                                 
                                  
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.fut_unrealised ? maindata.fut_unrealised.toFixed(2) : '0.00').toString() }}
                                  </p>
                                
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                   
                                    {{ (maindata.opt_buy_dr ? maindata.opt_buy_dr.toFixed(2) : '0.00').toString() }}
                                  </p>
                                  
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.opt_sell_cr ? maindata.opt_sell_cr.toFixed(2) : '0.00').toString() }}
                                  </p>
                                  
                                  <p class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.fo_charges ? maindata.fo_charges.toFixed(2) : '0.00').toString() }}
                                  </p>
                                 
                                </v-col>
                              </v-row>  
                              <p class="body-1 font-weight-bold ml-9 mt-4 mb-4" color="#757575" style="letter-spacing: 0.24em; ">
                                Commodity 
                  </p>
                  <v-row class="pl-10 ">

                    <v-col class="pa-2" cols="8">
                      <p class="body-2  mb-2" style="color: #000000;   
                                        ">Realised  P&L</p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                    ">Unrealised  P&L
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Long option
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Short option
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Changes
                                  </p>
                                
                                  <!-- <span>{{Assets_value}}</span> -->

                                </v-col>
                                <v-col class="pa-2" cols="4">
                                  <p  class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                
                                    {{ (maindata.comm_realised ? maindata.comm_realised.toFixed(2) : '0.00').toString() }}
                                  </p>
                            
                                  
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.comm_unrealised ? maindata.comm_unrealised.toFixed(2) : '0.00').toString() }}
                                  </p>
                                 
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.comm_buy_dr ? maindata.comm_buy_dr.toFixed(2) : '0.00').toString() }}
                                  </p>
                              
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.comm_sell_dr ? maindata.comm_sell_dr.toFixed(2) : '0.00').toString() }}
                                  </p>
                                  
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                   
                                    {{ (maindata.comm_charges ? maindata.comm_charges.toFixed(2) : '0.00').toString() }}
                                  </p>
                                  
                                </v-col>
                              </v-row>  
                              <p class="body-1 font-weight-bold ml-9 mt-4 mb-4" color="#757575" style="letter-spacing: 0.24em; ">
                                Currency 
                  </p>
                  <v-row class="pl-10 ">

                    <v-col class="pa-2" cols="8">
                      <p class="body-2  mb-2" style="color: #000000;   
                                        ">Realised P&L</p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                    ">Unrealised P&L
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Long option
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Short option
                                  </p>
                                  <p class="body-2  mb-2" style="color: #000000;   
                                  ">Changes
                                  </p>
                                
                                  <!-- <span>{{Assets_value}}</span> -->

                                </v-col>
                                <v-col class="pa-2" cols="4">
                                  <p v-if="maindata.curr_realised" class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                                    
                                    {{ (maindata.curr_realised ? maindata.curr_realised.toFixed(2) : '0.00').toString() }}
                                  </p>
                                
                                  
                                  <p v-if="maindata.curr_unrealised" class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                             
                                    {{ (maindata.curr_unrealised ? maindata.curr_unrealised.toFixed(2) : '0.00').toString() }}
                                  </p>

                               
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                               
                                    {{ (maindata.curr_buy_dr ? maindata.curr_buy_dr.toFixed(2) : '0.00').toString() }}
                                  </p>
                                  <!--  -->
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                               
                                    {{ (maindata.curr_sell_dr ? maindata.curr_sell_dr.toFixed(2) : '0.00').toString() }}
                                  </p>
                                  <p   class="body-2  mb-2" style="color: #000000;   text-align: right;  
                                    ">
                               
                                    {{ ( maindata.curr_charges.toFixed(2) ).toString() }}
                                  </p>
                                  <!--  -->
                                   
                                
                                </v-col>
                              </v-row>  
                            

                              <!--  eq -->
                              <p class="body-1 font-weight-bold ml-9 mt-4 mb-4" color="#757575" style="letter-spacing: 0.24em; ">
                    Margin 
                  </p>
                  <v-row :class="index != 0 ? 'mt-n2' : 'mt-0'" v-for="(value, key , index) in maindata.marginsummary" :key="key" class="pl-11 ">

                    <v-col class="pa-1" cols="8">
                      <!-- <v-row v-for="(value, key) in maindata.marginsummary" :key="key" class="pl-10 mt-5">
                        <v-col class="pa-0 " cols="8">
                          <p class="title  mb-0" style="color: #000000;
                            ">{{ key }}</p>
                        </v-col>
                        <v-col class="pa-0" cols="4">
                          <p class="body-1 mb-0" style="color: #000000;   
                            ">{{ (value[0].sum).toFixed(2) }}</p>
                        </v-col>
                      </v-row> -->
                                  <p class="body-2  mb-0" style="color: #000000;   
                                        ">{{ key }}</p>
                                  
                                  
                                  <!-- <span>{{Assets_value}}</span> -->

                                </v-col>
                                <v-col class="pa-2" cols="4">
                                  <p v-if="(value[0].sum).toFixed(2)" class="body-2  mb-0" style="color: #000000;   text-align: right;  
                                    ">
                                    {{ (value[0].sum).toFixed(2) }}
                                  </p>
                                   
                                  
                                </v-col>
                              </v-row>  
              </v-card>
          </v-col>
        </v-row>
         
    </div>
  </v-container>
</template>

<style>
.sumval {
  font-size: 22px;
}

@media only screen and (max-width: 1264px) {
  .sumval {
    font-size: 16px;
  }
}

@media only screen and (max-width: 960px) {
  .sumval {
    font-size: 17px;
  }
}

@media only screen and (max-width: 600px) {
  .sumval {
    font-size: 18px;
  }
}

@media only screen and (max-width: 425px) {
  .sumval {
    font-size: 13px;
  }
}

.chartmdl {
  position: relative;
  text-align: center;
  height: 80%;
  width: 80%;
  padding: 5px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 15px solid #6eb94b;
  display: inline-block;
}


.chartctn {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}


#cardclasscolor {
  height: 54px;
    width: 6px;
    background-color: rgb(170, 170, 170);
    border-color: rgb(170, 170, 170);
}
</style>



<script>
import WealthSegmentation from '../charts/WealthSegmentation.vue';
import HoldingCurve from '../charts/HoldingCurve.vue';
import TradeCurve from '../charts/TradeCurve.vue';
import { apiurl } from '../../Api'
import axios from "axios"

export default {
  data() {
    return {
      /* eslint-disable */
      awesome: false,
      dialog: false,
      loader: true,
      fromdate: '',
      eventloader: false,
      maindata: [],
      items: [],
      opbalance: '',
      eqRealised: '',
      fo_unrealised: '',
      cd_realised: '',
      cd_unrealised: '',
      fulldetails: true,
      funddetails: false,
      wealthdetails: false,
      Margindetails: false,
      pnldetails: false,
      chargesdetails: false,
      cd_sell_cr: '',
      cd_buy_dr: '',
      comm_sell_dr: '',
      clprice :0,
      comm_buy_dr: '',
      comm_unrealised: '',
      fund: 0,
      wealth: 0,
      pnlseg: 0,
      chargesseg: 0,
      jv: 0,
      cd_charges: '',
      eventarray: [],
      upcomeannoncement: [],
      upcomebonus: [],
      upcomedivident: [],
      actid: '',
      Assets_value: 0,
      bill_credit: '',
      yearis: '',

      bill_debit: '',
      comm_charges: '',
      comm_realised: '',
      eq_charges: '',
      fo_charges: '',
      margin : 0,
      fo_realised: '',
      opt_buy_dr: '',
      opt_sell_cr: '',
      DeliveryEq : 0,
      payin: '',
      payout: '',
      alert: false,

      loader: false,
      shorterm_realized: '',
      trading_realized: '',
      susertoken: '',
      headers: [
        { text: "Instruments", value: "" },
        { text: "Qty", value: "" },
        { text: "Buy avg.", value: "" },
        { text: "Invest value", value: "" },
        { text: "Select trade", value: "" },
      ],
    };
  },
  methods: {
    yearwisedata(yearis){
            this.fromshow = ''
            this.yearpop = false
            this.menu2 = false
            this.desserts = []
            
            var today = new Date();
            // var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
            if (mm < 3 && yyyy == yearis) {
                var yearmount = yearis - 1
            } else {
                yearmount = yearis
            }
            // var year = yearis
            let yearnext = yearmount + 1
            let data = JSON.stringify({
                "cc": this.actid,
                "from": '1/04' + '/' + yearmount,
                "to": '31/03' + '/' + yearnext,
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                // url: api.apiurl + '/getLedger',
                url: `${apiurl}/accDashboard`,

                headers: {
                    'Content-Type': 'application/json',
                    'clientid': this.actid,
                    'Authorization': this.susertoken
                },
                data: data
            };
            let axiosthis = this
            axios.request(config)
                .then((response) => {
                  console.log(response.data);

        axiosthis.maindata = response.data
        console.log(axiosthis.maindata);
        axiosthis.loader = false

        axiosthis.opbalance = (axiosthis.maindata.opening_balance).toFixed(2)
        axiosthis.payin = (axiosthis.maindata.payin).toFixed(2)
        axiosthis.payout = (axiosthis.maindata.payout).toFixed(2)
        axiosthis.clprice = (axiosthis.maindata.closing_balance).toFixed(2)

        axiosthis.Assets_value = (axiosthis.maindata.Assets_value).toFixed(2)

        axiosthis.eqRealised = (axiosthis.maindata.shorterm_realized + axiosthis.maindata.trading_realized).toFixed(2)
        axiosthis.fo_realised = (axiosthis.maindata.fo_realised).toFixed(2)
        axiosthis.fo_unrealised = (axiosthis.maindata.fot_unrealised)
        axiosthis.opt_buy_dr = (axiosthis.maindata.opt_buy_dr).toFixed(2)
        axiosthis.opt_sell_cr = (axiosthis.maindata.opt_sell_cr).toFixed(2)
        axiosthis.cd_realised = (axiosthis.maindata.curr_realised).toFixed(2)
        axiosthis.cd_unrealised = (axiosthis.maindata.curr_unrealised).toFixed(2)
        axiosthis.cd_buy_dr = (axiosthis.maindata.curr_buy_dr).toFixed(2)
        axiosthis.cd_sell_cr = (axiosthis.maindata.curr_sell_dr).toFixed(2)

        axiosthis.comm_realised = (axiosthis.maindata.comm_realised).toFixed(2)
        axiosthis.comm_unrealised = (axiosthis.maindata.comm_unrealised).toFixed(2)
        axiosthis.comm_sell_dr = (axiosthis.maindata.comm_sell_dr).toFixed(2)
        axiosthis.comm_buy_dr = (axiosthis.maindata.comm_buy_dr).toFixed(2)

        axiosthis.comm_charges = (axiosthis.maindata.comm_charges).toFixed(2)
        axiosthis.eq_charges = (axiosthis.maindata.eq_charges).toFixed(2)
        axiosthis.fo_charges = (axiosthis.maindata.fo_charges).toFixed(2)
        axiosthis.cd_charges = (axiosthis.maindata.curr_charges).toFixed(2)
        axiosthis.DeliveryEq = (axiosthis.maindata.delivery_turnover).toFixed(2)
        axiosthis.bill_credit = axiosthis.maindata.bill_credit
        axiosthis.bill_debit = axiosthis.maindata.bill_debit
        axiosthis.jv = (Number(axiosthis.bill_credit) - Number(axiosthis.bill_debit)).toFixed(2)

        // Convert axiosthis values to numbers

        axiosthis.opbalance = parseFloat(axiosthis.opbalance);
        axiosthis.payin = parseFloat(axiosthis.payin);
        axiosthis.payout = parseFloat(axiosthis.payout);
        axiosthis.Assets_value = parseFloat(axiosthis.Assets_value);
        axiosthis.eqRealised = parseFloat(axiosthis.eqRealised);
        axiosthis.fo_realised = parseFloat(axiosthis.fo_realised);
        axiosthis.fo_unrealised = parseFloat(axiosthis.fo_unrealised);
        axiosthis.opt_buy_dr = parseFloat(axiosthis.opt_buy_dr);
        axiosthis.opt_sell_cr = parseFloat(axiosthis.opt_sell_cr);
        axiosthis.cd_realised = parseFloat(axiosthis.cd_realised);
        axiosthis.cd_unrealised = parseFloat(axiosthis.cd_unrealised);
        axiosthis.cd_buy_dr = parseFloat(axiosthis.cd_buy_dr);
        axiosthis.cd_sell_cr = parseFloat(axiosthis.cd_sell_cr);
        axiosthis.comm_realised = parseFloat(axiosthis.comm_realised);
        axiosthis.comm_unrealised = parseFloat(axiosthis.comm_unrealised);
        axiosthis.comm_sell_dr = parseFloat(axiosthis.comm_sell_dr);
        axiosthis.comm_buy_dr = parseFloat(axiosthis.comm_buy_dr);
        axiosthis.comm_charges = parseFloat(axiosthis.comm_charges);
        
        axiosthis.DeliveryEq = parseFloat(axiosthis.DeliveryEq);
        axiosthis.eq_charges = parseFloat(axiosthis.eq_charges);
        axiosthis.fo_charges = parseFloat(axiosthis.fo_charges);
        axiosthis.cd_charges = parseFloat(axiosthis.cd_charges);
        axiosthis.jv = parseFloat(axiosthis.jv);

        // Perform addition
        axiosthis.fund = axiosthis.opbalance + axiosthis.payin + axiosthis.payout;
        if (axiosthis.Assets_value < 0) {
          axiosthis.wealth = (axiosthis.Assets_value * -1).toFixed(2)
        }
        // axiosthis.wealth = axiosthis.Assets_value;
        axiosthis.pnlseg = (axiosthis.eqRealised + axiosthis.fo_realised + axiosthis.fot_unrealised + axiosthis.opt_buy_dr + axiosthis.opt_sell_cr + axiosthis.cd_realised + axiosthis.cd_unrealised + axiosthis.cd_buy_dr + axiosthis.cd_sell_cr + axiosthis.comm_realised + axiosthis.comm_unrealised + axiosthis.comm_sell_dr + axiosthis.comm_buy_dr).toFixed(2);
        axiosthis.chargesseg = axiosthis.comm_charges + axiosthis.eq_charges + axiosthis.fo_charges + axiosthis.cd_charges + axiosthis.jv;

        // Now axiosthis.fund, axiosthis.wealth, axiosthis.pnlseg, and axiosthis.chargesseg
        // will have the calculated values with proper type conversion.

        console.log(axiosthis.fund, axiosthis.wealth, axiosthis.pnlseg, axiosthis.chargesseg, "kjkjkj")
        // axiosthis.shorterm_realized = axiosthis.maindata.shorterm_realized
        // axiosthis.trading_realized = axiosthis.maindata.trading_realized
        // axiosthis.chartdataupdate()
        // console.log(axiosthis.Assets_value);
        // axiosthis.maindata =  axiosthis.maindata.
        // axiosthis.maindata =  axiosthis.maindata.
        // axiosthis.maindata =  axiosthis.maindata.
        // axiosthis.maindata =  axiosthis.maindata.
        // axiosthis.maindata =  axiosthis.maindata.
        // axiosthis.maindata =  axiosthis.maindata.
        axiosthis.loader = false

                    
                })
                .catch((error) => {
                    console.log(error);
                    if (error.message == 'Network Error') {
                      axiosthis.msg = error.message
                    axiosthis.loader = false
                    axiosthis.alert = true
                    setTimeout(() => {
                        axiosthis.alert = false
                    }, 5000);
                 
                }
                });

    },
    getColor(value) {
      const sortedValues = [this.clprice, this.chargesseg, this.pnlseg, this.wealth];
      const stval = sortedValues.sort((a, b) => b - a)
      const index = stval.indexOf(value);
      console.log(stval,value, index,' sortedValues');
      switch (index) {
        case 0:
          return '#015FEC';
        case 1:
          return '#1D1D1D';
        case 2:
          return '#AAAAAA';
        case 3:
          return '#001638';
        case 4:
          return '#6eb94b';
        case 5:
          return '#6eb94b';
          default:
          return 'white'; // Default background color
      }
    },

    fundshow() {
      this.fulldetails = false,
        this.funddetails = true

      this.wealthdetails = false
      this.chargesdetails = false
      this.pnldetails = false
      this.chartdataupdate()
      console.log('fund')
    },
    dialogclose() {
      this.fulldetails = true,
        this.funddetails = false
      this.wealthdetails = false
      this.chargesdetails = false
      this.pnldetails = false
      this.chartdataupdate()

    },
    marginshow() {
      this.Margindetails = true
      this.fulldetails = false,
        this.funddetails = false
      this.wealthdetails = false
      this.chargesdetails = false
      this.pnldetails = false
      this.chartdataupdate()
    },
    chargeshow() {
      this.chargesdetails = true
      this.fulldetails = false,
      this.pnldetails = false
      this.wealthdetails = false
      this.funddetails = false
      this.chartdataupdate()
    },
    pnlshow() {
      this.fulldetails = false,
        this.chargesdetails = false
      this.pnldetails = true
      this.wealthdetails = false
      this.funddetails = false
      this.chartdataupdate()
    },
    wealthshow() {
      this.wealthdetails = true
      this.funddetails = false
      this.chargesdetails = false
      this.pnldetails = false
      this.fulldetails = false
      this.chartdataupdate()
    },
    chartdataupdate() {
      console.log('fun call');

      const ctx = document.getElementById('myChart').getContext('2d');
      let data = '';

      function generateChartData(labels, data, backgroundColor) {
        const originalData = {
          labels: labels,
          datasets: [{
            data: data,
            backgroundColor: backgroundColor,
          }]
        };

        // Extract data values and labels
        const dataValues = originalData.datasets[0].data;
        const labelsArray = originalData.labels;

        // Create an array of objects to store data values and corresponding labels
        const dataWithLabels = dataValues.map((value, index) => ({ value, label: labelsArray[index] }));

        // Sort the array based on data values
        dataWithLabels.sort((a, b) => b.value - a.value);

        // Extract sorted labels and data values
        const sortedLabels = dataWithLabels.map(item => item.label);
        const sortedDataValues = dataWithLabels.map(item => item.value);

        // Create the sorted data object
        const sortedData = {
          labels: sortedLabels,
          datasets: [{
            data: sortedDataValues,
            backgroundColor: backgroundColor,
          }]
        };

        return sortedData;
      }

      // Usage example:
      
      if (this.fulldetails) {
        data = generateChartData(['Wealth', 'Pnl', 'Charges','Closing Price'], [this.wealth, this.pnlseg, this.chargesseg,this.clprice], ['#015FEC', '#1D1D1D', '#AAAAAA','#001638']);
      } else if (this.funddetails) {
        data = generateChartData(['Opening Balance', 'Pay in', 'Pay Out'], [this.opbalance, this.payin, this.payout], ['#015FEC', '#1D1D1D', '#AAAAAA']);
      } else if (this.wealthdetails) {
        data = generateChartData(['Delivery Value'], [this.Assets_value], ['#015FEC', '#1D1D1D', '#AAAAAA']);
      } else if (this.pnldetails) {
        data = generateChartData(
          ['Equity Realised', 'FNO Realised', 'Future unrealised', 'Option Buy Dr', 'Option Sell Cr', 'Currency Realised', 'Currency unrealised', 'Currency Buy Dr', 'Currency Sell Cr', 'Commodity Relised', 'Commodity unrealised', 'Commodity Buy Dr', 'Commodity sell Dr'],
          [this.eqRealised, this.fo_realised, this.fo_unrealised, this.opt_buy_dr, this.opt_sell_cr, this.cd_realised, this.cd_unrealised, this.cd_buy_dr, this.cd_sell_cr, this.comm_realised, this.comm_unrealised, this.comm_buy_dr, this.comm_sell_dr],
          ['#015FEC', '#1D1D1D', '#AAAAAA', '#001638']
        );
      } else if (this.chargesdetails) {
        data = generateChartData(['Equity charges', 'FNO charges', 'Currency charges', 'Commdity charges', 'JV'], [this.eq_charges, this.fo_charges, this.cd_charges, this.comm_charges, this.jv], ['#015FEC', '#1D1D1D', '#AAAAAA', '#001638','#001638']);
      }else if(this.Margindetails){
          var VarArray = []
          var KeyVal = []
          for (const key in this.maindata.marginsummary) {
            if (this.maindata.marginsummary.hasOwnProperty(key)) {
              const value = this.maindata.marginsummary[key];
              console.log(`${key}: ${value[0].sum}`,"popopopopopopopo");
              VarArray.push(key)
              let emdata = (value[0].sum).toFixed(2)
              KeyVal.push(Number(emdata))
            } 
          }
          data = generateChartData(VarArray,KeyVal , ['#015FEC', '#1D1D1D', '#AAAAAA', '#001638','#001638']);
        console.log(VarArray,KeyVal,"popopopopopopopo");

      }


      if (this.myChart) {
        this.myChart.destroy(); // Destroy the existing chart
      }

      this.myChart = new Chart(ctx, {
        type: 'doughnut',
        data: data,
        options: {
          legend: {
            display: false,
          },
          cutoutPercentage: 75,
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });
      this.loader = false
    }

  },
  mounted() {
    this.susertoken = localStorage.getItem("usession");
    this.actid = localStorage.getItem("userid");
    this.cname = localStorage.getItem("cname");
    // var res = this.decryptionFunction(localStorage.getItem("profile_data"));
    // console.log('ttttt',JSON.parse(res));
    // var resp = JSON.parse(res);
    // this.profiledetails = resp.client_data;
   

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    // var nextyear = yyyy + 1
    // var startYear = yyyy - 4;
    this.yearis = yyyy
    // for (let year = startYear; year <= yyyy; year++) {
    //   this.items.push(year);
    // }
    // var today = new Date();
    // var yyyy = today.getFullYear();

    if (mm < 4) {
      var yearmount = yyyy - 1
    } else {
      yearmount = yyyy
    }
    var startYear = yearmount - 4;
    this.yearis = yearmount;

    for (let year = yearmount; year >= startYear; year--) {
      this.items.push(year);
    }
    // var todayapi = dd + '/' + mm + '/' + yyyy;
    var fromapi = '01' + '/' + '04' + '/' + yearmount;
    let nextyear = parseInt(yearmount) + 1

    var today1 = dd + '/' + mm + '/' + yearmount;
    // const axios = require('axios');
    let data = JSON.stringify({
      "cc": this.actid,
      "from": fromapi,
      "to": today1
    });
    let axiosthis = this
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${apiurl}/accDashboard`,
      // url: 'http://192.168.5.163:9003/accDashboard',
      headers: {
        'Authorization': this.susertoken,
        'clientid': this.actid,
        'Content-Type': 'application/json' 
      },
      data: data
    }; 
        axios.request(config)
        .then((response) => {
        console.log(response.data);

        axiosthis.maindata = response.data
        console.log(axiosthis.maindata);
        axiosthis.loader = false

        axiosthis.opbalance = (axiosthis.maindata.opening_balance).toFixed(2)
        axiosthis.payin = (axiosthis.maindata.payin).toFixed(2)
        axiosthis.payout = (axiosthis.maindata.payout).toFixed(2)
        axiosthis.clprice = (axiosthis.maindata.closing_balance).toFixed(2)

        axiosthis.Assets_value = (axiosthis.maindata.Assets_value).toFixed(2)

        axiosthis.eqRealised = (axiosthis.maindata.shorterm_realized + axiosthis.maindata.trading_realized).toFixed(2)
        axiosthis.fo_realised = (axiosthis.maindata.fo_realised).toFixed(2)
        axiosthis.fo_unrealised = (axiosthis.maindata.fot_unrealised)
        axiosthis.opt_buy_dr = (axiosthis.maindata.opt_buy_dr).toFixed(2)
        axiosthis.opt_sell_cr = (axiosthis.maindata.opt_sell_cr).toFixed(2)
        axiosthis.cd_realised = (axiosthis.maindata.curr_realised).toFixed(2)
        axiosthis.cd_unrealised = (axiosthis.maindata.curr_unrealised).toFixed(2)
        axiosthis.cd_buy_dr = (axiosthis.maindata.curr_buy_dr).toFixed(2)
        axiosthis.cd_sell_cr = (axiosthis.maindata.curr_sell_dr).toFixed(2)

        axiosthis.comm_realised = (axiosthis.maindata.comm_realised).toFixed(2)
        axiosthis.comm_unrealised = (axiosthis.maindata.comm_unrealised).toFixed(2)
        axiosthis.comm_sell_dr = (axiosthis.maindata.comm_sell_dr).toFixed(2)
        axiosthis.comm_buy_dr = (axiosthis.maindata.comm_buy_dr).toFixed(2)

        axiosthis.comm_charges = (axiosthis.maindata.comm_charges).toFixed(2)
        axiosthis.eq_charges = (axiosthis.maindata.eq_charges).toFixed(2)
        axiosthis.fo_charges = (axiosthis.maindata.fo_charges).toFixed(2)
        axiosthis.cd_charges = (axiosthis.maindata.curr_charges).toFixed(2)
        axiosthis.DeliveryEq = (axiosthis.maindata.delivery_turnover).toFixed(2)
        axiosthis.bill_credit = axiosthis.maindata.bill_credit
        axiosthis.bill_debit = axiosthis.maindata.bill_debit
        axiosthis.jv = (Number(axiosthis.bill_credit) - Number(axiosthis.bill_debit)).toFixed(2)
        // Convert axiosthis values to numbers
        axiosthis.opbalance = parseFloat(axiosthis.opbalance);
        axiosthis.payin = parseFloat(axiosthis.payin);
        axiosthis.payout = parseFloat(axiosthis.payout);
        axiosthis.Assets_value = parseFloat(axiosthis.Assets_value);
        axiosthis.eqRealised = parseFloat(axiosthis.eqRealised);
        axiosthis.fo_realised = parseFloat(axiosthis.fo_realised);
        axiosthis.fo_unrealised = parseFloat(axiosthis.fo_unrealised);
        axiosthis.opt_buy_dr = parseFloat(axiosthis.opt_buy_dr);
        axiosthis.opt_sell_cr = parseFloat(axiosthis.opt_sell_cr);
        axiosthis.cd_realised = parseFloat(axiosthis.cd_realised);
        axiosthis.cd_unrealised = parseFloat(axiosthis.cd_unrealised);
        axiosthis.cd_buy_dr = parseFloat(axiosthis.cd_buy_dr);
        axiosthis.cd_sell_cr = parseFloat(axiosthis.cd_sell_cr);
        axiosthis.comm_realised = parseFloat(axiosthis.comm_realised);
        axiosthis.comm_unrealised = parseFloat(axiosthis.comm_unrealised);
        axiosthis.comm_sell_dr = parseFloat(axiosthis.comm_sell_dr);
        axiosthis.comm_buy_dr = parseFloat(axiosthis.comm_buy_dr);
        axiosthis.comm_charges = parseFloat(axiosthis.comm_charges);
        axiosthis.DeliveryEq = parseFloat(axiosthis.DeliveryEq);
        axiosthis.eq_charges = parseFloat(axiosthis.eq_charges);
        axiosthis.fo_charges = parseFloat(axiosthis.fo_charges);
        axiosthis.cd_charges = parseFloat(axiosthis.cd_charges);
        axiosthis.jv = parseFloat(axiosthis.jv);

        // Perform addition
        axiosthis.fund = axiosthis.opbalance + axiosthis.payin + axiosthis.payout;
        if (axiosthis.Assets_value < 0) {
          axiosthis.wealth = (axiosthis.Assets_value * -1).toFixed(2)
        }
        // axiosthis.wealth = axiosthis.Assets_value;
        axiosthis.pnlseg = (axiosthis.eqRealised + axiosthis.fo_realised + axiosthis.fo_unrealised + axiosthis.opt_buy_dr + axiosthis.opt_sell_cr + axiosthis.cd_realised + axiosthis.cd_unrealised + axiosthis.cd_buy_dr + axiosthis.cd_sell_cr + axiosthis.comm_realised + axiosthis.comm_unrealised + axiosthis.comm_sell_dr + axiosthis.comm_buy_dr).toFixed(2);
        axiosthis.chargesseg = axiosthis.comm_charges + axiosthis.eq_charges + axiosthis.fo_charges + axiosthis.cd_charges + axiosthis.jv;
        axiosthis.margin = axiosthis.maindata.margin.toFixed(2)
        // Now axiosthis.fund, axiosthis.wealth, axiosthis.pnlseg, and axiosthis.chargesseg
        // will have the calculated values with proper type conversion.

        console.log(axiosthis.fund, axiosthis.wealth, axiosthis.pnlseg, axiosthis.chargesseg, "kjkjkj")
        // axiosthis.shorterm_realized = axiosthis.maindata.shorterm_realized
        // axiosthis.trading_realized = axiosthis.maindata.trading_realized
        // axiosthis.chartdataupdate()
        // console.log(axiosthis.Assets_value);
        // axiosthis.maindata =  axiosthis.maindata.
        // axiosthis.maindata =  axiosthis.maindata.
        // axiosthis.maindata =  axiosthis.maindata.
        // axiosthis.maindata =  axiosthis.maindata.
        // axiosthis.maindata =  axiosthis.maindata.
        // axiosthis.maindata =  axiosthis.maindata.
        axiosthis.loader = false

      })
      .catch((error) => {
        console.log(error);
        if (error.message == 'Network Error') {
          axiosthis.msg = error.message
          axiosthis.loader = false
          axiosthis.alert = true
          setTimeout(() => {
            axiosthis.alert = false
          }, 5000);
        }
      });
    // const axios = require('axios');
    // let data1 = '';

    // let config1 = {
    //   method: 'post',
    //   maxBodyLength: Infinity,
    //   url: 'http://192.168.5.142:5001/getEquityCorporateActions',
    //   headers: {},
    //   data: data1
    // };
    // // let axiosthis = this
    // axios.request(config1)
    //   .then((response) => {
    //     function processDateArray(array, datePropertyName) {
    //       array.forEach(item => {
    //         const inputDateString = item[datePropertyName];
    //         const inputDate = new Date(inputDateString);
    //         item[datePropertyName] = inputDate;
    //       });

    //       array.sort((a, b) => b[datePropertyName] - a[datePropertyName]);

    //       array.forEach(item => {
    //         const day = item[datePropertyName].getDate().toString().padStart(2, '0');
    //         const month = (item[datePropertyName].getMonth() + 1).toString().padStart(2, '0');
    //         const year = item[datePropertyName].getFullYear();
    //         item[datePropertyName] = `${day}/${month}/${year}`;
    //       });
    //     }
    //     processDateArray(response.data.announcement, 'board meeting date');
    //     processDateArray(response.data.bonus, 'ex_bonus_date');
    //     processDateArray(response.data.dividend, 'ex-date');
    //     axiosthis.annostablearray = response.data.announcement
    //     if (axiosthis.annostablearray.length > 0) {
    //       for (let i = 0; i < 2; i++) {
    //         axiosthis.upcomeannoncement.push(axiosthis.annostablearray[i])
    //       }

    //     }

    //     axiosthis.bonustablearray = response.data.bonus
    //     console.log(axiosthis.bonustablearray.length, axiosthis.bonustablearray, 'lala')
    //     if (axiosthis.bonustablearray.length > 1) {
    //       for (let i = 0; i < 2; i++) {
    //         axiosthis.upcomebonus.push(axiosthis.bonustablearray[i])
    //         console.log('if')
    //       }
    //     } else {
    //       axiosthis.upcomebonus = axiosthis.bonustablearray

    //     }
    //     //  if (axiosthis.bonustablearray.length > 0) {
    //     //     for (let i = 0; i < 2; i++) {
    //     //       axiosthis.upcomebonus.push(axiosthis.bonustablearray[i])
    //     //     }

    //     //   }
    //     axiosthis.dividendtablearray = response.data.dividend
    //     if (axiosthis.dividendtablearray.length > 1) {
    //       for (let i = 0; i < 2; i++) {
    //         axiosthis.upcomedivident.push(axiosthis.dividendtablearray[i])
    //       }
    //     }

    //     // console.log(axiosthis.upcomeannoncement ,'dada');
    //     axiosthis.rightstablearray = response.data.rights
    //     axiosthis.splittablearray = response.data.split
    //     axiosthis.eventloader = false
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     if (error.message == 'Network Error') {
    //                 this.msg = error.message
    //                 axiosthis.eventloader = false
    //                 axiosthis.alert = true
    //                 setTimeout(() => {
    //                     axiosthis.alert = false
    //                 }, 5000);
    //               }
    //   });

    // this.maxval =   maxyearval + '-' + mm + '-' + dd
    // this.toshow = today1
    // this.fromshow = fromapi
    // this.segfrom = fromapi
    // this.fromdateapi = fromapi

  },

  components: { WealthSegmentation, HoldingCurve, TradeCurve },
}
</script>